
<ValidationObserver v-slot="{ invalid }" tag="section" class="container-fluid">
  <div class="box row">
    <div class="col-md-12">
      <h2>Create new client</h2>
      <form name="create-client" class="form row">
        <ValidationProvider v-slot="{ errors }" name="client title" tag="div" class="col-md-6">
          <div class="form-group col-md-12" :class="{'has-error': errors[0]}">
            <label for="title">Title: *</label>
            <input id="title" v-model.trim="client.title" type="text" class="form-control" required="required">
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" name="subdomain name" rules="required|verify_name" tag="div" class="col-md-6">
          <div class="form-group col-md-12" :class="{'has-error': errors[0]}">
            <label for="name">Subdomain: *</label>
            <input id="name" v-model.trim="client.name" type="text" class="form-control" required="required">
            <small v-if="client.name" id="subdomainHelpBlock" class="form-text text-muted">
              {{ `${isLocalHost ? 'http': 'https'}://${client.name}.${dcrFrontendHost}` }}
            </small>
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <SaveBar
          :saveDisabled="invalid"
          disableSaveAndClose
          @save="createClient"
          @cancel="cancel"
        />
      </form>
    </div>
  </div>
</ValidationObserver>
