<template>
  <div class="item-tree-child">
    <a href="#" class="list-group-item list-group-item-action" @click.prevent="$router.push('/clients/' + $route.params.client + '/educationitems/' + item.id)">
      <div class="row">
        <div class="col-11" :style="indent">{{ item.title }}</div>
        <div class="col-1">
          <button v-if="children.length === 0" class="btn btn-sm btn-danger" title="delete educationitem" @click.stop.prevent="deleteItem(item)">
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </a>
    <item-tree
      v-for="child in children"
      :key="child.slug"
      :children="child.children"
      :item="child"
      :depth="(depth*1) + 1"
      :delete-item="deleteItem"
    >
    </item-tree>
  </div>
</template>
<script>
export default {
  name: 'item-tree',
  props: {
    item: Object,
    children: Array,
    depth: Number,
    deleteItem: Function
  },
  computed: {
    indent () {
      return { transform: `translate(${this.depth * 25}px)` }
    }
  }
}
</script>
