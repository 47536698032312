
<div class="row">
   <div class="col-12">
     <div class="card">
       <h4 class="card-header">
         Library Settings
       </h4>
       <div class="card-body">
         <form v-if="group.library" action="#" role="form">
           <div class="row">
             <div class="form-group col-6">
               <label for="version">Filtertemplate</label>
               <select id="version" v-model="group.library.filtertemplate_id" class="form-control" name="version">
                 <option value="" disabled>select a Filtertemplate</option>
                 <option v-for="template in Filtertemplates" :key="template.id" :value="template.id">{{ template.name }}</option>
               </select>
             </div>
             <div class="form-group col-6">
               <label for="defaultContentType">Default Content Type</label>
               <select id="defaultContentType" v-model.trim="group.library.defaultContentType" class="form-control" name="defaultContentType">
                 <option key="congressresources" value="congressresources">Congress Resources</option>
                 <option key="events" value="events">Events</option>
               </select>
             </div>
           </div>
           <div class="row">
             <div class="form-group col-6">
               <label for="sortOrder">Default Sort By</label>
               <select id="sortBy" v-model="group.library.sortBy" class="form-control" name="sortBy" required="required">
                 <option value="date">date</option>
                 <option value="title">title</option>
                 <option value="event.title.raw">sessiontitle</option>
                 <option value="likes">likes</option>
               </select>
             </div>
             <div class="form-group col-6">
               <label for="sortOrder">Default Sort Order</label>
               <select id="sortOrder" v-model="group.library.sortOrder" class="form-control" name="version">
                 <option value="asc">ascending</option>
                 <option value="desc">descending</option>
               </select>
             </div>
           </div>
           <hr>
           <HideToolbarElements :library="group.library" :hiddenContentTypes="currentHiddenContentTypes"></HideToolbarElements>
         </form>
       </div>
     </div>
   </div>
 </div>
