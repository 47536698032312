<template>
  <v-ace-editor
    :value="value"
    lang="json"
    theme="chrome"
    :height="height"
    class="ace-editor"
    @init="initAceEditor"
  />
</template>

<script>
import 'brace/mode/json'
import 'brace/theme/chrome'
import { IsJsonString } from '../validators'

export default {
  name: 'ace-editor',
  props: {
    value: {
      type: String
    },
    height: {
      type: String,
      default: '150px'
    }
  },
  data () {
    return {}
  },
  methods: {
    initAceEditor (editor) {
      editor.setOption('showPrintMargin', false)

      document.addEventListener('mouseup', () => editor.resize())

      const updateValue = (beautify = false) => {
        let value = editor.getValue()
        const validJSON = IsJsonString(value)

        if (beautify && value && validJSON) {
          value = this.beautifyJSON(value)
        }

        this.$emit('input', value)
      }

      editor.on('blur', () => {
        updateValue(true)
      })

      editor.on('focus', () => {
        updateValue(true)
      })

      editor.on('change', () => {
        updateValue(false)
      })
    },
    beautifyJSON (value) {
      return JSON.stringify(JSON.parse(value), null, 2).trim()
    }
  }
}
</script>
<style scoped>
  .ace-editor {
    resize: vertical;
    border: 1px solid #ccc;
  }
</style>
