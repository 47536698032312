<template>
  <ValidationObserver v-slot="{ invalid }" tag="section" class="container-fluid">
    <div class="box row">
      <div class="col-md-12">
        <h2>Edit domain</h2>
        <form name="edit-domain" class="form row">
          <ValidationProvider v-slot="{ errors }" name="domain host" tag="div" class="col-md-6"
            rules="required|hostname">
            <div class="form-group col-md-12" :class="{'has-error': errors[0]}">
              <label for="host">Host: *</label>
              <input id="host" v-model.trim="domain.domain" type="text" class="form-control" required="required" placeholder="congress.client.com">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="domain client" tag="div" class="col-md-6"
            rules="required|client">
            <div class="form-group col-md-12" :class="{'has-error': errors[0]}">
              <label for="client_id">Client: *</label>
              <b-form-select id="client_id" v-model="domain.client_id" :options="clients"></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <SaveBar
            :saveDisabled="invalid"
            disableSaveAndClose
            @save="updateDomain"
            @cancel="cancel"
          />
        </form>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'editDomain',
  data () {
    return {
      clients: [],
      domain: {
        id: '',
        domain: '',
        client_id: ''
      },
      error: false
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    }
  },
  created () {
    this.fetchData(this.$route.params.id)
  },
  methods: {
    fetchData (domainId) {
      this.isLoading = true
      this.$store
        .dispatch('getDomain', domainId)
        .then(response => {
          this.domain = response.domain
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch domain: ' + error,
            icon: 'error'
          })
        })

      this.$store
        .dispatch('getClientsList')
        .then(response => {
          const clientSelectValues = [
            { value: '', text: 'Please select a client' }
          ]
          response.sort((a, b) => (a.name > b.name) ? 1 : -1)
          response.forEach(client => {
            clientSelectValues.push({ value: client.id, text: client.name })
          })
          this.clients = clientSelectValues
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch clients: ' + error,
            icon: 'error'
          })
        })
    },
    cancel () {
      this.$router.push('/domains')
    },
    updateDomain () {
      this.$store
        .dispatch('updateDomain', { domainId: this.domain.id, data: this.domain })
        .then(response => {
          this.isLoading = false
          if (response.data.domain) {
            this.$swal({
              title: 'Success',
              text: 'Domain updated',
              icon: 'success',
              showConfirmButton: false,
              toast: true,
              position: 'top',
              timer: 2000
            })
            this.$router.push('/domains')
          } else {
            this.$swal('Error', 'Something went wrong', 'error')
          }
        }, response => {
          this.$swal('Error', 'Something went wrong', 'error')
        })
    }
  }
}
</script>

<style>
.box {
  padding-bottom: 15px;
}
</style>
