
<ValidationObserver v-slot="{ invalid }" tag="section" class="container-fluid">
  <div class="row box">
    <div class="col-12">
      <h2>Create package</h2>
      <form name="edit-module" role="form">
        <div class="row">
          <ValidationProvider v-slot="{ errors }" name="package title" tag="div" class="col-5">
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="title">Package Title: *</label>
              <input id="title" v-model.trim="packageData.title" type="text" class="form-control" required="required">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <div class="col-2">
            <div class="form-group">
              <label for="draft">draft-mode:</label><br>
              <input id="form-group" v-model.lazy.trim="packageData.draft" type="checkbox" name="draft" value="1">
            </div>
          </div>
          <div v-if="packageData.sku" class="col-5">
            <div class="form-group">
              <label for="sku">SKU:</label>
              <input id="sku" v-model.lazy.trim="packageData.sku" type="text" class="form-control" name="sku" disabled="true">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="subline">Subline:</label>
              <textarea id="subline" v-model.lazy.trim="packageData.subline" name="subline" class="form-control" cols="30" rows="10"></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="author">Author: </label>
              <input id="author" v-model.lazy.trim="packageData.author" type="text" class="form-control" name="author">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="date">Date: </label>
              <input id="date" v-model.lazy.trim="packageData.date" type="text" class="form-control" name="date">
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="topics">Topics: </label>
              <input id="topics" v-model.lazy.trim="packageData.topics" type="text" class="form-control" name="topics" placeholde="topic 1,topic 2,third topic">
            </div>
          </div>
        </div>
      </form>
    </div>
    <SaveBar
      :saveDisabled="invalid"
      @save="createPackage(close = false)"
      @saveAndClose="createPackage(close = true)"
      @cancel="cancel"
    />
  </div>
</ValidationObserver>
