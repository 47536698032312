
<ValidationObserver v-slot="{ invalid }" tag="section" class="content">
  <div class="box row">
    <div class="col-12">
      <h2>Create new page template</h2>
      <form name="create-module" class="form row">
        <ValidationProvider v-slot="{ errors }" name="name" tag="div" class="col-md-12">
          <div class="form-group" :class="{'has-error': errors[0]}">
            <label for="name">Name: *</label>
            <input id="name" v-model.trim="pageTemplate.name" type="text" class="form-control" name="name" required="required">
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>

        <SaveBar
          :saveDisabled="invalid"
          disableSaveAndClose
          @save="createPageTemplate"
          @cancel="cancel"
        />
      </form>
    </div>
  </div>
</ValidationObserver>
