<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <h4 class="card-header">
          Startpage Settings
        </h4>
        <div class="card-body">
          <form v-if="client.startpage" action="#" role="form">
            <label for="projectRedirect">Redirect Settings</label>
            <div class="form-group col-6">
              <b-form-group label="">
                <b-form-radio v-model="client.startpage.redirectSlug" name="" value="">Keep The
                  startpage</b-form-radio>
                <b-form-radio v-model="client.startpage.redirectSlug" name="library" value="library">Redirect to Client
                  Library</b-form-radio>
              </b-form-group>
            </div>
            <div class="form-group col-6">
              <label for="projectRedirect">Or choose project home to redirect to</label>
              <select id="projectRedirect" v-model="client.startpage.redirectSlug" class="form-control">
                <option value=""></option>
                <option v-for="project in projects" :key="project.name" :value="project.name">
                  {{ project.title }}
                </option>
              </select>
            </div>
            <div class="form-group col-6">
              <small class="text-muted">
                <p>{{ infoText }}</p>
                <ul v-if="!['library', '', undefined].includes(client.startpage?.redirectSlug)">
                  <li>if this has a specific startpage this is shown</li>
                  <li>it this is live w/o startpage the channel home page is shown</li>
                  <li>if this is not live w/o startpage the ondemand library is shown</li>
                </ul>
              </small>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'startpage-settings',
  props: ['client'],
  data () {
    return {
      dcrFrontendHost: import.meta.env.VITE_DCR_FRONTEND_HOST
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    },
    clientName () {
      return this.$route.params.client
    },
    projects () {
      const data = this.$store.getters.getProjectsList
      if (data !== false) {
        return data.filter(project => project.preview === 0)
      }
      return null
    },
    protocol () {
      if (this.dcrFrontendHost.includes('localhost')) {
        return 'http'
      }
      return 'https'
    },
    infoText () {
      if (this.client.startpage.redirectSlug === '') {
        return ''
      }

      return `${this.protocol}://${this.clientName}.${this.dcrFrontendHost}/${this.client.startpage.redirectSlug}`
    }
  },
  created () {
    this.getProjects()
  },
  methods: {
    getProjects () {
      this.isLoading = true
      this.$store
        .dispatch('getProjectsList', this.clientName)
        .then(response => {
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.error = true
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    }
  }
}
</script>
