
<div class="asset-list">
  <SingleAsset
    v-for="(item, index) in items"
    :key="item.id"
    :item="removePrefix(item)"
    :disabled="isDisabled(item)"
    :showLabel="showLabel"
    :showInput="showInput"
    :showHelpText="false"
    :showDeleteButton="isMutable"
    :useFullSize="false"
    :label="label.replace('{n}', index + 1)"
    @input="(item) => emitInput(item)"
    @delete="name => $emit('delete', item)"
  />
  <b-button
    v-if="isMutable"
    class="asset-list__button text-primary"
    :style="showLabel ? 'margin-top: 32px;' : ''"
    variant="light"
    @click="
      $emit(
        'input',
        addPrefix({ id: $uuid.v4(), asset: undefined, name: '' })
      )
    "
  >
    <i class="fa fa-plus-circle"></i> add item
  </b-button>
</div>
