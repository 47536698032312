
<section class="educationitems-list container-fluid">
  <div class="row mb-3 pt-3">
    <div class="col mr-auto">
      <!--form id="search" class="ui form" @submit.prevent>
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text"><i class="fas fa-search"></i></div>
          </div>
          <input name="query" v-model="query" class="form-control" placeholder="Search educationitems">
        </div>
      </form-->
    </div>
    <div class="col-auto">
      <router-link :to="{ name: 'EducationitemCreate', params: { client: $route.params.client } }" class="btn btn-success" title="Create new educationitem">
        <i class="fas fa-plus"></i>
        Create new educationitem
      </router-link>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col">
      <table class="table table-striped table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col" v-for="key in gridColumns" :key="key" @click="sortBy(key)" :class="{ active: sortKey == key }">
              {{ key | capitalize }}
              <i v-if="sortOrders[key] > 0" class="fas fa-caret-down"></i>
              <i v-else class="fas fa-caret-up"></i>
            </th>
            <th scope="col" class="w-1 text-center text-nowrap">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in filteredData" :key="index" @click="editEducationitem(item)" style="cursor:pointer" title="edit educationitem">
            <td v-for="key in gridColumns" :key="key">
              {{ item[key] }}
            </td>
            <td class="w-1 text-center text-nowrap">
              <button class="btn btn-sm btn-danger" @click.stop.prevent="deleteItem(item, index)" title="delete educationitem">
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div> -->

  <div class="row">
    <div class="col">
      <item-tree :educationitems="educationitems" @delete-item="deleteItem"></item-tree>
    </div>
  </div>

</section>
