import Components from './components/index'
import Filters from './filters/index'
import { getTypes, addType, addValidationMessage, set } from './util'
import Directives from './directives/index'

import FieldInput from './fields/fieldInput'
import FieldList from './fields/fieldList'
import FieldSelect from './fields/fieldSelect'
import FieldTextarea from './fields/fieldTextarea'
import BaseField from './fields/baseField'

const Fields = {
  'fieldInput.js': FieldInput,
  'fieldList.js': FieldList,
  'fieldSelect.js': FieldSelect,
  'fieldTextarea.js': FieldTextarea,
  'baseField.js': BaseField
}

const Former = {
  getTypes,
  addType,
  addValidationMessage,
  install (Vue, options) {
    // install our components
    Directives(Vue)
    Components(Vue)
    Filters(Vue)

    Vue.$former = { getTypes, addType, addValidationMessage }
    Vue.prototype.$formerSet = set

    /* add Fields to Formerplugin */
    Object.keys(Fields).forEach((key) => {
      // remove all the .vue crap
      let component = key
        .replace(/^\.\//, '')
        .replace(/\.js/, '')
        .replace(/^field/, '')
      // convert the first letter to lc
      component = component.charAt(0).toLowerCase() + component.slice(1)
      Vue.$former.addType(component, Fields[key])
      Vue.component('former-' + component, Fields[key])
    })
  }
}

// auto install
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(Former)
  // expose formly functions if auto installed
  window.Vue.$former = { getTypes, addType, addValidationMessage }
}
export default Former
