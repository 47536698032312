
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h3>Channels</h3>
      <p>Edit Pages for seperate Channel if it should overwrite ChannelDetail Page</p>
      <hr>
    </div>
  </div>
  <div v-if="partialProjectData" class="row">
    <div class="col-3">
     <div class="list-group">
       <a v-for="channel in channels"
        :key="channel.slug"
        href="#"
        class="list-group-item list-group-item-action"
        :class="getClassForChannel(channel)"
        @click.prevent="editChannelPage(channel)">{{ channel.title }}</a>
     </div>
    </div>
    <div v-if="slug" class="col-9">
      <edit-page
        relationType="project"
        :relation="{ data: project }"
        pageType="channelDetail"
        :slug="slug"
        :clientId="clientId"
      ></edit-page>
    </div>
    <div v-else>
      Please select the page you want to edit.
    </div>
  </div>
</div>
