<template>
  <div class="repeat-section">
      <div class="hide-expression">
        <div v-if="to.wrapper == 'panel'" class="card">
          <div class="card-header d-flex align-items-center">
            <div>
              <div v-if="to.title">{{ to.title }}</div>
              <small v-if="to.help" class="text-muted">{{ to.help }}</small>
            </div>
             <b-button v-if="!hasCollapsedItem" variant="link" class="ml-auto" @click="expandAll()" >
              <i class="far fa-plus-square"></i> Expand All Items
            </b-button>
            <b-button v-else variant="link" class="ml-auto" @click="collapseAll()">
              <i class="far fa-minus-square"></i> Collapse All Items
            </b-button>
          </div>
          <div class="card-body">
            <div class="card-text">
              <div v-if="model[field.key].length == 0" class="d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="addItem()"
                >
                  <i class="fas fa-plus"></i> add new item
                </button>
              </div>
              <div v-for="(item, key) in model[field.key]" v-else :key="item.key + '_' + key">
                <div class="card mb-3 shadow-sm">
                  <div
                    class="card-header d-flex align-items-center"
                    style="cursor:pointer"
                    :title="`Click to ${collapseItems[key] && collapseItems[key].show ? 'collapse':'expand' }`"
                    @click="toggleCollapseItem(key)"
                  >
                    <b-button v-if="collapseItems[key]" variant="link" size="sm">
                      <i v-if="collapseItems[key].show" class="fas fa-chevron-down" />
                      <i v-else class="fas fa-chevron-right" />
                    </b-button>
                    <div class="text-bold ">{{ item.name || `[${key+1}]` }}</div>
                  </div>
                  <b-collapse v-if="collapseItems[key]" :id="'collapseItem_' + key" v-model="collapseItems[key].show">
                    <div class="card-body d-flex flex-column">
                      <former-form :model="item" :form="form" :formerfields="field.fields"></former-form>
                      <button
                        type="button"
                        class="btn btn-danger ml-auto"
                        @click.prevent="deleteItem(item)"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </div>
                  </b-collapse>
                </div>
                <div class="d-flex justify-content-center">
                    <button
                      v-if="key === model[field.key].length-1"
                      type="button"
                      class="btn btn-primary"
                      @click.prevent="addItem()">
                        <i class="fas fa-plus"></i> add new item
                    </button>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div v-if="to.wrapper != 'panel'">
        <h4 v-if="to.title">{{ to.title }}</h4>
        <div v-if="model[field.key].length == 0">
          <button
            type="button"
            class="btn btn-primary"
            @click.prevent="addItem()"
          >
            <i class="fas fa-plus"></i> add new Item
          </button>
        </div>
        <div v-for="(item, key) in model[field.key]" v-else :key="item.key + '_' + key" class="shadow-sm p-3 mb-3 bg-white rounded">
          <div class="row">
            <div class="col">
              <former-form :model="item" :form="form" :formerfields="field.fields"></former-form>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <button
                v-if="key === model[field.key].length-1"
                type="button"
                class="btn btn-primary"
                @click.prevent="addItem()"
              >
                <i class="fas fa-plus"></i> add new item
              </button>
              <button
                type="button"
                class="btn btn-danger"
                @click.prevent="deleteItem(item)"
              >
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseField from './baseField'
export default {
  name: 'formerRepeat',
  mixins: [baseField],
  data () {
    return {
      collapseItems: []
    }
  },
  computed: {
    hasCollapsedItem () {
      return this.collapseItems.some(item => item.show)
    }
  },
  created () {
    if (this.model[this.field.key] === '') {
      this.model[this.field.key] = []
    }
  },
  mounted () {
    if (this.model[this.field.key].length === 0) {
      this.addItem()
    }
    this.collapseItems = this.model[this.field.key].map((item, index) => {
      return { item: item, show: index === 0 }
    })
  },
  methods: {
    addItem () {
      this.model[this.field.key].push({})
      this.collapseItems.push({
        item: this.model[this.field.key][this.model[this.field.key].length],
        show: true
      })
    },
    deleteItem (item) {
      const index = this.model[this.field.key].indexOf(item)
      this.model[this.field.key].splice(index, 1)
    },
    toggleCollapseItem (key) {
      this.collapseItems[key].show = !this.collapseItems[key].show
    },
    collapseAll () {
      this.collapseItems.forEach(item => {
        item.show = false
      })
    },
    expandAll () {
      this.collapseItems.forEach(item => {
        item.show = true
      })
    }
  }
}
</script>
