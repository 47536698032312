
<section class="container-fluid bg-white">
  <Table
    :data="userroles || []"
    :columns="table.columns"
    :actions="table.actions"
    :actionButton="table.actionButton"
    :disableSearch="false"
    :search="table.search"
    @rowClicked="table.clickHandler"
  />
</section>
