<template>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h3>Internal Stream Check Rules</h3>
        <hr>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <form action="#" role="form">
          <div v-for="rule in iscRules" :key="rule.name" class="row">
              <div class="col-10">
                <div class="row">
                  <div class="form-group col-4">
                    <label>rule name</label>
                    <input v-model="rule.name" class="form-control" type="text"/>
                  </div>
                  <div class="form-group col-4">
                    <label>rule tag</label>
                    <input v-model="rule.tag" class="form-control" type="text"/>
                  </div>
                  <div class="form-group col-4">
                    <label>rule type</label>
                    <select v-model="rule.type" class="form-control" type="text">
                      <option value="ip" selected="selected">ip</option>
                      <option value="force">force</option>
                    </select>
                  </div>
                  <div class="form-group col-12">
                    <label>rule</label>
                    <input v-model="rule.rule" class="form-control" type="text"/>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <br>
                <button class="btn btn-sm btn-danger" @click.prevent="deleteIscRule(rule)"><i class="fas fa-trash-alt"></i> delete rule</button>
              </div>
          </div>
        </form>
      </div>
      <div class="col-12">
        <hr>
      </div>
      <div class="col-12 text-center">
        <button class="btn btn-success" @click.prevent="saveIscRules"><i class="fas fa-save"></i> save rules</button>
        <button class="btn btn-primary" @click.prevent="addIscRule"><i class="fas fa-plus"></i> add rule</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectEditIscRules',
  data () {
    return {
      iscRules: []
    }
  },
  created () {
    this.getIscRules()
  },
  methods: {
    addIscRule () {
      this.iscRules.push({ name: '', tag: '', type: 'ip', rule: '' })
    },
    deleteIscRule (rule) {
      this.iscRules.splice(this.iscRules.indexOf(rule), 1)
    },
    saveIscRules () {
      this.isLoading = true
      this.$store
        .dispatch('saveIscRulesByProjectId', { projectId: this.$route.params.id, iscRules: this.iscRules })
        .then(
          response => {
            this.$swal({
              title: 'Success',
              text: 'Isc rules saved',
              icon: 'success',
              showConfirmButton: false,
              toast: true,
              position: 'top',
              timer: 2000
            })
            this.isLoading = false
            this.getIscRules()
          },
          response => { // error
            this.$swal({
              title: 'Error',
              text: 'Could not save rules',
              icon: 'error',
              showConfirmButton: false,
              toast: true,
              position: 'top',
              timer: 2000
            })
            this.isLoading = false
          })
    },
    getIscRules () {
      this.isLoading = true
      this.$store
        .dispatch('getIscRulesByProjectId', this.$route.params.id)
        .then(
          response => {
            this.iscRules = response.data
            this.isLoading = false
          },
          response => { // error
            this.isLoading = false
          })
    }
  }
}
</script>

<style>

</style>
