
<div
  class="form-group former-assetlist"
  :class="[
    { 'former-has-value': hasValue, 'has-error': hasError },
    field.className,
  ]"
>
  <div v-if="to.wrapper === 'panel'" class="card mb-3">
    <div class="card-header">
      <h5 v-if="to.title">{{ to.title }}</h5>
      <small v-if="to.help" class="mb-3">{{ to.help }}</small>
    </div>
    <div class="card-body p-3">
      <AssetList
        :items="items"
        :isMutable="true"
        :showLabel="true"
        :label="to.itemLabel"
        @input="(item) => setAsset(item)"
        @delete="(item) => deleteAsset(item)"
      />
    </div>
  </div>
  <div v-else>
    <h5 v-if="to.title">{{ to.title }}</h5>
    <small v-if="to.help" class="mb-3">{{ to.help }}</small>
    <AssetList
      :items="items"
      :isMutable="true"
      :showLabel="true"
      :label="to.itemLabel"
      @input="(item) => setAsset(item)"
      @delete="(item) => deleteAsset(item)"
    />
  </div>
  <error-display :form="form" :field="field.key"></error-display>
</div>
