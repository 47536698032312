
<div class="item-tree">
  <item-tree-child
    v-for="item in educationitems"
    :key="item.slug"
    :children="item.children"
    :item="item"
    :depth="0"
    :delete-item="deleteItem"
  >
  </item-tree-child>
</div>
