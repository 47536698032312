
<section class="giftcodes-list container-fluid">
  <div class="row pt-3">
    <div class="col-12">
      <button class="btn btn-primary mr-1" @click.prevent="addNewGiftcode">
        <i class="fas fa-plus"></i> add giftcode
      </button>
      <button class="btn btn-primary mr-1" @click.prevent="modal('generate')">
        <i class="fas fa-plus"></i> generate giftcodes
      </button>
      <button class="btn btn-primary mr-1" @click.prevent="modal('upload')">
        <i class="fas fa-plus"></i> upload giftcodes via csv
      </button>
      <button class="btn btn-primary mr-1" @click.prevent="exportGiftcodes">
        <i class="fas fa-download"></i> export giftcodes
      </button>
      <button class="btn btn-danger mr-1" @click.prevent="deleteAll">
        <i class="fas fa-trash"></i> delete all giftcodes
      </button>
    </div>
    <div v-if="giftcodesErrors.length > 0" class="col-12">
      <h4>Dublicated Giftcodes on Import</h4>
      <div v-for="entry in giftcodesErrors" :key="entry.giftcode" class="row bg-warning" style="margin-bottom:1px">
        <div class="col-4">{{ entry.giftcode }}</div>
        <div class="col-4">{{ entry.valid_until }}</div>
        <div class="col-4">{{ entry.capability_group }}</div>
        <div class="col-4" style="padding:1px">
          <button class="btn btn-regular btn-sm pull-right" @click="alterGiftcode(entry)"><i class="fas fa-edit"></i> alter giftcode</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col mr-auto">
      <vue-good-table
        ref="giftcodeTable"
        :columns="columns"
        :rows="giftcodes"
        :search-options="{ enabled: true}"
        :sort-options="{ enabled: true }"
        :pagination-options="{ enabled: true, perPage: 20 }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'edit'">
            <button class="btn btn-sm btn-primary" @click.stop="editEntry(props.formattedRow['giftcode'])"><i class="fas fa-edit"></i></button>
          </span>
          <span v-else-if="props.column.field == 'delete'">
            <button class="btn btn-sm btn-danger" @click.stop="deleteEntry(props.formattedRow['giftcode'])"><i class="fas fa-trash"></i></button>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col-12">
      <button class="btn btn-success" @click.prevent="saveClientGiftcodes">
        <i class="fas fa-save"></i> save giftcodes
      </button><br><br>
    </div>
  </div>
  <b-modal id="giftcodeModal" v-model="showModal" hide-footer size="lg" no-fade :title="giftcodeAction" @hide="cancelModal()">
    <div class="col-12">
      <div v-if="editGiftcode">
        <div class="form-group">
          <label for="giftcode">Giftcode</label>
          <input id="giftcode" v-model.lazy="giftcode.giftcode" type="text" name="giftcode" :class="{ 'is-valid': !giftcode.error, 'text-danger is-invalid': giftcode.error }" class="form-control" @change="checkForDublicates(true)">
        </div>
        <div class="form-group">
          <label for="valid-until">Valid until</label>
          <input id="valid-until" v-model="giftcode.valid_until" type="text" name="valid-until" class="form-control">
        </div>
        <div class="form-group">
          <label for="capability-group">Capability Group</label>
          <input id="capability-group" v-model="giftcode.capability_group" type="text" name="capability-group" class="form-control">
          <b-form-text id="input-live-help">comma seperated list of capability group names</b-form-text>
        </div>
        <button class="btn btn-success" :disabled="giftcode.error === true" @click="changeGiftcodeMethod"><i class="fa fa-check"></i> Save </button>
      </div>
      <div v-if="addGiftcode">
        <div class="form-group">
          <label for="giftcode">Giftcode</label>
          <input id="giftcode" v-model.lazy="giftcode.giftcode" type="text" name="giftcode" :class="{ 'is-valid': !giftcode.error, 'text-danger is-invalid': giftcode.error }" class="form-control" @change="checkForDublicates(true)">
        </div>
        <div class="form-group">
          <label for="valid-until">Valid until</label>
          <input v-model="giftcode.valid_until" type="text" name="valid-until" class="form-control">
        </div>
        <div class="form-group">
          <label for="capability-group">Capability Group</label>
          <input id="capability-group" v-model="giftcode.capability_group" type="text" name="capability-group" class="form-control">
          <b-form-text id="input-live-help">comma seperated list of capability group names</b-form-text>
        </div>
        <button class="btn btn-success" :disabled="giftcode.error === true" @click="addGiftcodeMethod"><i class="fa fa-check"></i> Save </button>
      </div>
      <div v-if="generateGiftcodes">
        <div class="form-group">
          <label for="amount">Number of Giftcodes</label>
          <input id="amount" v-model="generateAmount" type="text" name="amount" class="form-control">
        </div>
        <button class="btn btn-success" @click.prevent="generateCodes()"><i class="fa fa-check"></i> Generate </button>
      </div>
      <div v-if="uploadGiftcodes">
        <div v-if="uploadUrl">
          <h4>Upload CSV</h4>
          <div class="form-check form-check-inline">
            <input id="crs" v-model="overwriteGiftcodes" class="form-check-input" type="checkbox" value="1">
            <label class="form-check-label" for="crs">Overwrite existing giftcodes</label>
          </div>
          <dropzone
            id="uploadGiftCodesCSV"
            ref="fileUpload"
            :options="dropzoneOptions"
            class="alert alert-secondary"
            @vdropzone-success="showSuccess"
            @vdropzone-sending="sendingEvent"
          >
          </dropzone>
        </div>
      </div>
    </div>
  </b-modal>
</section>
