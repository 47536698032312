import getters from './getters'
import actions from './actions'
import mutations from '../mutations'

const state = {
  serverURI: import.meta.env.VITE_API_BASE_URL
}

export default {
  getters,
  actions,
  mutations,
  state
}
