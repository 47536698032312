
<section>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <h4 class="card-header">
          Assets
        </h4>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <template v-for="(asset, index) in assets">
                <div :key="index" class="row py-1" :class="{'bg-light border border-bottom-0': settingsIndex === index}">
                  <div class="col-5">
                    <div class="field-group">
                      <input v-model="asset.name" type="text" class="form-control" placeholder="asset name">
                    </div>
                  </div>
                  <div class="col-5">
                    <div v-if="asset.image" class="imageholder">
                      <img v-if="asset.image.type === 'image'" :src="[assetURI+'/'+client.data.name+'/img/'+asset.image.resource]" class="asset-preview" title="click to change image" @click.prevent="changeImage(asset)">
                      <img v-if="asset.image.type === 'xml'" src="/static/img/xml-icon.png" class="asset-preview" @click.prevent="addImage(asset)">
                    </div>
                    <div v-else>
                      <button class="btn btn-primary" @click.prevent="changeImage(asset)"><i class="far fa-image"></i> select asset</button>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="pull-right">
                      <button class="btn btn-default btn-sm" @click.prevent="showSettings(index)">
                        <i class="fas fa-cog"></i>
                      </button>
                      <button class="btn btn-danger btn-sm" @click.prevent="deleteAsset(index)">
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="settingsIndex === index" :key="'settings-'+index" class="row pb-1 bg-light border border-top-0">
                  <div class="col-md-5">
                    <div class="field-group">
                      <input v-model="asset.title" type="text" class="form-control" placeholder="title">
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div class="field-group">
                      <input v-model="asset.alt" type="text" class="form-control" placeholder="alt-text">
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="mt-2">
            <button class="btn btn-primary" @click.prevent="newAsset"><i class="fas fa-plus"></i> add new entry</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AssetModal
    :id="assetModalId"
    :clientId="clientId"
    :title="'Select Asset for ' + editAsset"
    @setAsset="assetAction">
  </AssetModal>
</section>
