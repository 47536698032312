<template>
   <ValidationObserver ref="observer" v-slot="{ invalid }" tag="section">

    <div class="row">
      <div class="col-12">
        <h2>Update {{spotlightData.title}}</h2>
      </div>

      <div class="col-12">
        <form name="edit-spotlight" role="form col-12">
          <input v-if="spotlightData.id" v-model="spotlightData.id" type="hidden" name="id">
          <b-card no-body class="row">
            <b-tabs ref="tabs" card>
              <b-tab>
                <template slot="title">
                  <i class="fas fa-cog"></i> General
                </template>
                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-header">
                        <h4>General Settings</h4>
                        <small>general settings about the spotlight</small>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <ValidationProvider v-slot="{ errors }" vid="slug" name="slug" tag="div" class="col-5">
                            <div class="form-group" :class="{'has-error': errors[0]}">
                              <label for="slug">Slug: *</label>
                              <input id="slug" v-model.trim="spotlightData.slug" type="text" class="form-control" required="required">
                              <small class="text-muted">Part of the URL to access the spotlights detail page.</small>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </div>
                          </ValidationProvider>
                          <div class="col-2">
                            <button
                              type="button"
                              class="btn btn-primary slug-button"
                              @click="generateSlug"
                            >
                              Generate
                            </button>
                          </div>
                          <div v-if="spotlightData.sku" class="col-5">
                            <div class="form-group">
                              <label for="sku">SKU:</label>
                              <input id="sku" v-model.lazy.trim="spotlightData.sku" type="text" class="form-control" name="sku" readonly="true">
                              <small class="text-muted">Technical identifier.</small>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-6">
                            <input id="draft" v-model.lazy.trim="spotlightData.draft" type="checkbox" name="draft" value="1">
                            <label for="draft">Draft Mode</label><br>
                            <small class="text-muted">This spotlight is only accessible via the direct link.</small>
                          </div>
                        <div class="col-6">
                          <a :href="'http://' + $route.params.client + '.' + dcrFrontendHost + '/spotlights/' + spotlightData.slug" target="_blank" class="btn btn-default"><i class="fas fa-external-link-alt"></i></a>
                          <label>click to preview the spotlight page</label>
                          </div>
                        </div>
                        <hr>
                        <div class="row">
                          <div class="form-group col-6">
                            <label for="category">Category:</label>
                            <input id="category" v-model.lazy.trim="spotlightData.category" type="text" class="form-control" name="category">
                            <small class="text-muted">Define a category to group spotlights in spotlights-list module.</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab active>
                <template slot="title">
                  <i class="fa fa-file-image"></i> Overview Tile
                </template>
                <div class="col-12 mb-4">
                  <div class="card">
                    <div class="card-header">
                      <h4>Overview Tile Settings</h4>
                      <small>configure the tile which will be visible on the spotlights home page</small>
                    </div>
                    <div class="card-body">
                      <EditOverviewTile
                        v-if="spotlightData"
                        type="spotlight"
                        :spotlightData="spotlightData"
                      />
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="fas fa-window-restore"></i> Page Content
                </template>
                <div class="col-12 mb-4">
                  <div class="card">
                    <div class="card-header">
                      <h4>Page Content</h4>
                      <small>configure the spotlights page with modules from scratch or use a predefined template</small>
                    </div>
                    <div class="card-body">
                      <edit-modules :availableModules="availableModules" :data="spotlightData"></edit-modules>
                    </div>
                  </div>
                </div>

                <div v-if="spotlightData.modules && spotlightData.modules.length > 0" class="col-12 text-center">
                  <button class="btn btn-primary" @click.prevent="openCreatePageTemplateModal">
                    <i class="far fa-file-alt mr-2"></i> create template
                  </button>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </form>
      </div>
    </div>
    <hr>
    <SaveBar
      :saveDisabled="invalid"
      @save="saveSpotlight(close = false)"
      @saveAndClose="saveSpotlight(close = true)"
      @cancel="cancel"
    />

    <CreatePageTemplateModal
      v-if="pageForTemplate"
      :id="createPageTemplateModalId"
      :page="pageForTemplate"
      :clientId="client.data.id"
      @cancel="closeCreatePageTemplateModal"
      @create="closeCreatePageTemplateModal"
    />
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'
import EditOverviewTile from './EditOverviewTile.vue'
import slugify from 'slugify'

export default {
  name: 'editSpotlight',
  components: {
    EditOverviewTile
  },
  data () {
    return {
      spotlightData: {
      },
      showModal: false,
      dcrFrontendHost: import.meta.env.VITE_DCR_FRONTEND_HOST,
      availableModules: [],
      createPageTemplateModalId: this.$uuid.v4(),
      slugifyOptions:
      {
        lower: true,
        strict: true
      }
    }
  },
  computed: {
    ...mapGetters({
      client: 'getClient'
    }),
    pageForTemplate () {
      return {
        pagewidgets: this.spotlightData.modules
      }
    },
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    }
  },
  watch: {
    $route (route) {
      if (route.params.id) {
        this.loadData(route.params.id)
        this.getAvailableModules()
      }
    }
  },
  created () {
    if (this.$route.params.id) {
      this.loadData(this.$route.params.id)
    }
    this.getClientData(this.$route.params.client)
    this.getAvailableModules()
  },
  methods: {
    openCreatePageTemplateModal () {
      this.$bvModal.show(this.createPageTemplateModalId)
    },
    closeCreatePageTemplateModal () {
      this.$bvModal.hide(this.createPageTemplateModalId)
    },
    generateSlug () {
      this.spotlightData.slug = slugify(this.spotlightData.title, this.slugifyOptions)
    },
    cancel () {
      this.$router.push('/clients/' + this.$route.params.client + '/spotlights')
    },
    loadData (spotlightId) {
      this.isLoading = true
      this.$store
        .dispatch('getSpotlight', spotlightId)
        .then(response => {
          this.$set(this, 'spotlightData', response.data)
          this.$set(this.spotlightData, 'modules', response.modules)
          this.$set(this.spotlightData, 'asset', response.asset || {})
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    saveSpotlight (close = false) {
      this.isLoading = true
      this.$store
        .dispatch('updateSpotlight', { spotlightId: this.spotlightData.id, client: this.$route.params.client, data: this.spotlightData })
        .then(response => {
          this.$swal({
            title: 'Success',
            text: 'Spotlight was updated',
            icon: 'success',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
          this.isLoading = false
          if (close) {
            this.cancel()
          }
        }).catch(error => {
          this.$refs.observer.setErrors(error.response.data.errors)
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not update spotlight: ' + error.response.data.message,
            icon: 'error'
          })
        })
    },
    getClientData (client) {
      this.isLoading = true
      this.$store
        .dispatch('getClientData', client)
        .then(response => {
          this.isLoading = false
        })
    },
    getAvailableModules () {
      this.isLoading = true
      this.$store
        .dispatch('getModulesForSpotlights')
        .then(response => {
          this.availableModules = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch modules: ' + error,
            icon: 'error'
          })
        })
    }
  }
}
</script>
<style scoped>
  .slug-button {
    margin-top: 30px;
  }
</style>
