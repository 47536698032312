
<ValidationObserver ref="observer" tag="section" class="container-fluid">
 <div class="row">
   <div class="col-12">
     <form name="edit-module" role="form">
       <input v-if="spotlightData.id" v-model="spotlightData.id" type="hidden" name="id">
       <div class="row">
         <ValidationProvider v-slot="{ errors }" vid="title" name="spotlight title" tag="div" class="col-12">
           <div class="form-group" :class="{'has-error': errors[0]}">
             <label for="title">Title: *</label>
             <input id="title" v-model.trim="spotlightData.title" type="text" class="form-control" required="required">
             <small class="text-danger">{{ errors[0] }}</small>
           </div>
         </ValidationProvider>
       </div>
       <div class="row mt-2">
         <div style="width:230px; padding-left:15px; padding-right:15px">
           <SingleAsset
             label="Logo:"
             :item="assetItem"
             :useFullSize="false"
             @input="setAsset"
           />
         </div>
         <div class="col">
           <div class="form-group">
             <label for="subline">Subline:</label>
             <froala-editor
               id="subline"
               v-model="spotlightData.subline"
             />
           </div>
         </div>
       </div>
       <div class="row mt-4">
         <div class="col-6">
           <div class="form-group">
             <label for="author">Author / Footnote:</label>
             <input id="author" v-model.lazy.trim="spotlightData.author" type="text" class="form-control" name="author">
           </div>
         </div>
         <div class="col-6">
           <div class="form-group">
             <label for="date">Date Information:</label>
             <input id="date" v-model.lazy.trim="spotlightData.date" type="text" class="form-control" name="date">
           </div>
         </div>
         <div class="col-12">
           <div class="form-group">
             <label for="topics">Topics: </label>
             <input id="topics" v-model.lazy.trim="spotlightData.topics" type="text" class="form-control" name="topics" placeholde="topic 1,topic 2,third topic">
           </div>
         </div>
       </div>
     </form>
   </div>
 </div>
</ValidationObserver>
