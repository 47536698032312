<template>
  <section class="container-fluid bg-white">
    <Table
      :data="users || []"
      :columns="table.columns"
      :actions="table.actions"
      :actionButton="table.actionButton"
      :disableSearch="false"
      :search="table.search"
      @rowClicked="table.clickHandler"
    />
  </section>
</template>

<script>
export default {
  data () {
    return {
      users: [],
      table: {
        columns: [
          {
            label: 'Lastname',
            property: 'lastname',
            searchable: true,
            sort: { enabled: true, default: true }
          },
          {
            label: 'Firstname',
            property: 'firstname',
            searchable: true,
            sort: { enabled: true }
          },
          {
            label: 'Email',
            property: 'email',
            searchable: true,
            sort: { enabled: true }
          }
        ],
        actions: [
          { icon: 'fas fa-trash-alt', buttonClass: 'btn-danger', action: this.deleteItem }
        ],
        actionButton: {
          icon: 'fa fa-plus',
          label: 'Create new user',
          action: () => this.$router.push({ name: 'UserCreate' })
        },
        clickHandler: (item) => this.$router.push('/users/' + item.id),
        search: {
          placeholder: 'Search users',
          disabled: false
        }
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.isLoading = true
      this.$store
        .dispatch('getUsers')
        .then(response => {
          this.users = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch users: ' + error,
            icon: 'error'
          })
        })
    },
    async deleteItem (item, index) {
      const displayName = `${item.firstname} ${item.lastname}`

      const result = await this.$swal({
        title: 'Are you really sure?',
        text: `The user "${displayName}" will be deleted. This cannot be undone!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#dd4b39',
        cancelButtonText: 'Cancel'
      })

      if (!result?.value) {
        return
      }

      this.isLoading = true

      try {
        await this.$store.dispatch('deleteUser', item.id)
        const userIndexToDelete = this.users.indexOf(item)
        this.users.splice(userIndexToDelete, 1)
        this.$swal({
          title: 'Success',
          text: `The user "${displayName}" has been deleted`,
          icon: 'success',
          showConfirmButton: false,
          toast: true,
          position: 'top',
          timer: 2000
        })
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.error = true
        this.$swal({
          title: 'Error',
          text: `Could not delete user: ${error.message}`,
          icon: 'error'
        })
      }
    }
  }
}
</script>
