<template>
  <section class="giftcodes-list container-fluid">
    <div class="row pt-3">
      <div class="col-12">
        <button class="btn btn-primary mr-1" @click.prevent="addNewGiftcode">
          <i class="fas fa-plus"></i> add giftcode
        </button>
        <button class="btn btn-primary mr-1" @click.prevent="modal('generate')">
          <i class="fas fa-plus"></i> generate giftcodes
        </button>
        <button class="btn btn-primary mr-1" @click.prevent="modal('upload')">
          <i class="fas fa-plus"></i> upload giftcodes via csv
        </button>
        <button class="btn btn-primary mr-1" @click.prevent="exportGiftcodes">
          <i class="fas fa-download"></i> export giftcodes
        </button>
        <button class="btn btn-danger mr-1" @click.prevent="deleteAll">
          <i class="fas fa-trash"></i> delete all giftcodes
        </button>
      </div>
      <div v-if="giftcodesErrors.length > 0" class="col-12">
        <h4>Dublicated Giftcodes on Import</h4>
        <div v-for="entry in giftcodesErrors" :key="entry.giftcode" class="row bg-warning" style="margin-bottom:1px">
          <div class="col-4">{{ entry.giftcode }}</div>
          <div class="col-4">{{ entry.valid_until }}</div>
          <div class="col-4">{{ entry.capability_group }}</div>
          <div class="col-4" style="padding:1px">
            <button class="btn btn-regular btn-sm pull-right" @click="alterGiftcode(entry)"><i class="fas fa-edit"></i> alter giftcode</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col mr-auto">
        <vue-good-table
          ref="giftcodeTable"
          :columns="columns"
          :rows="giftcodes"
          :search-options="{ enabled: true}"
          :sort-options="{ enabled: true }"
          :pagination-options="{ enabled: true, perPage: 20 }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'edit'">
              <button class="btn btn-sm btn-primary" @click.stop="editEntry(props.formattedRow['giftcode'])"><i class="fas fa-edit"></i></button>
            </span>
            <span v-else-if="props.column.field == 'delete'">
              <button class="btn btn-sm btn-danger" @click.stop="deleteEntry(props.formattedRow['giftcode'])"><i class="fas fa-trash"></i></button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-12">
        <button class="btn btn-success" @click.prevent="saveClientGiftcodes">
          <i class="fas fa-save"></i> save giftcodes
        </button><br><br>
      </div>
    </div>
    <b-modal id="giftcodeModal" v-model="showModal" hide-footer size="lg" no-fade :title="giftcodeAction" @hide="cancelModal()">
      <div class="col-12">
        <div v-if="editGiftcode">
          <div class="form-group">
            <label for="giftcode">Giftcode</label>
            <input id="giftcode" v-model.lazy="giftcode.giftcode" type="text" name="giftcode" :class="{ 'is-valid': !giftcode.error, 'text-danger is-invalid': giftcode.error }" class="form-control" @change="checkForDublicates(true)">
          </div>
          <div class="form-group">
            <label for="valid-until">Valid until</label>
            <input id="valid-until" v-model="giftcode.valid_until" type="text" name="valid-until" class="form-control">
          </div>
          <div class="form-group">
            <label for="capability-group">Capability Group</label>
            <input id="capability-group" v-model="giftcode.capability_group" type="text" name="capability-group" class="form-control">
            <b-form-text id="input-live-help">comma seperated list of capability group names</b-form-text>
          </div>
          <button class="btn btn-success" :disabled="giftcode.error === true" @click="changeGiftcodeMethod"><i class="fa fa-check"></i> Save </button>
        </div>
        <div v-if="addGiftcode">
          <div class="form-group">
            <label for="giftcode">Giftcode</label>
            <input id="giftcode" v-model.lazy="giftcode.giftcode" type="text" name="giftcode" :class="{ 'is-valid': !giftcode.error, 'text-danger is-invalid': giftcode.error }" class="form-control" @change="checkForDublicates(true)">
          </div>
          <div class="form-group">
            <label for="valid-until">Valid until</label>
            <input v-model="giftcode.valid_until" type="text" name="valid-until" class="form-control">
          </div>
          <div class="form-group">
            <label for="capability-group">Capability Group</label>
            <input id="capability-group" v-model="giftcode.capability_group" type="text" name="capability-group" class="form-control">
            <b-form-text id="input-live-help">comma seperated list of capability group names</b-form-text>
          </div>
          <button class="btn btn-success" :disabled="giftcode.error === true" @click="addGiftcodeMethod"><i class="fa fa-check"></i> Save </button>
        </div>
        <div v-if="generateGiftcodes">
          <div class="form-group">
            <label for="amount">Number of Giftcodes</label>
            <input id="amount" v-model="generateAmount" type="text" name="amount" class="form-control">
          </div>
          <button class="btn btn-success" @click.prevent="generateCodes()"><i class="fa fa-check"></i> Generate </button>
        </div>
        <div v-if="uploadGiftcodes">
          <div v-if="uploadUrl">
            <h4>Upload CSV</h4>
            <div class="form-check form-check-inline">
              <input id="crs" v-model="overwriteGiftcodes" class="form-check-input" type="checkbox" value="1">
              <label class="form-check-label" for="crs">Overwrite existing giftcodes</label>
            </div>
            <dropzone
              id="uploadGiftCodesCSV"
              ref="fileUpload"
              :options="dropzoneOptions"
              class="alert alert-secondary"
              @vdropzone-success="showSuccess"
              @vdropzone-sending="sendingEvent"
            >
            </dropzone>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Dropzone from 'vue2-dropzone'

export default {
  name: 'giftcode-settings',
  components: {
    Dropzone
  },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  props: ['client'],
  data () {
    return {
      uploadGiftcodes: false,
      generateGiftcodes: false,
      overwriteGiftcodes: false,
      showModal: false,
      generateAmount: 0,
      giftcodes: [],
      giftcodesErrors: [],
      giftcodeAction: 'Handle Giftcode',
      editGiftcode: false,
      addGiftcode: false,
      giftcode: false,
      columns: [
        { label: 'Giftcode', field: 'giftcode' },
        { label: 'Valid until', field: 'valid_until' },
        { label: 'Capability Group', field: 'capability_group' },
        { label: 'Used', field: 'used', type: 'number' },
        { field: 'edit' },
        { field: 'delete' }
      ],
      gridColumns: ['giftcode', 'valid_until', 'capability_group', 'used'],
      sortKey: 'used',
      sortOrders: {},
      order: 1,
      query: ''
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    },
    uploadUrl () {
      return this.$store.state.serverURI + '/api/v2/clients/' + this.client.data.id + '/giftcodes/csv'
    },
    dropzoneOptions () {
      return {
        url: this.uploadUrl,
        maxFilesize: 1,
        headers: {
          Authorization: 'Bearer ' + this.$store.state.token
        }
      }
    },
    filteredData () {
      var sortKey = this.sortKey
      var filterKey = this.query && this.query.toLowerCase()
      var order = this.sortOrders[sortKey] || 1
      var data = this.giftcodes
      if (data && filterKey) {
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1
          })
        })
      }
      if (data && sortKey) {
        data = data.slice().sort(function (a, b) {
          a = a[sortKey]
          b = b[sortKey]
          return (a === b ? 0 : a > b ? 1 : -1) * order
        })
      }
      return data
    }
  },
  created () {
    this.getClientGiftcodes()
  },
  methods: {
    editEntry (code) {
      this.giftcode = JSON.parse(JSON.stringify(this.giftcodes.find(giftcode => giftcode.giftcode === code)))
      this.editGiftcode = true
      this.giftcodeAction = 'Edit Giftcode'
      this.showModal = true
    },
    deleteEntry (code) {
      this
        .$swal({
          title: 'Are you really sure?',
          text: 'The giftcode "' + code + '" will be removed on next save.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#dd4b39',
          cancelButtonText: 'Cancel'
        })
        .then(
          result => {
            if (result.value) {
              const entry = this.giftcodes.find(giftcode => giftcode.giftcode === code)
              this.giftcodes.splice(this.giftcodes.indexOf(entry), 1)
            }
          }
        )
    },
    deleteAll () {
      this
        .$swal({
          title: 'Are you really sure?',
          text: 'All giftcodes will be removed on next save.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#dd4b39',
          cancelButtonText: 'Cancel'
        })
        .then(
          result => {
            if (result.value) {
              this.giftcodes = []
            }
          }
        )
    },
    modal (type) {
      if (type === 'generate') {
        this.giftcodeAction = 'Generate new Giftcode(s)'
        this.generateGiftcodes = true
      } else if (type === 'upload') {
        this.giftcodeAction = 'Upload new Giftcode(s)'
        this.uploadGiftcodes = true
      }
      this.showModal = true
    },
    cancelModal () {
      this.uploadGiftcodes = false
      this.generateGiftcodes = false
      this.editGiftcode = false
      this.addGiftcode = false
      this.showModal = false
    },
    exportGiftcodes () {
      const allGiftcodes = this.$refs.giftcodeTable.filteredRows[0].children
      const giftcodesExport = allGiftcodes.map(item => { return { Giftcode: item.giftcode, 'Valid Until': item.valid_until, 'Capability Group': item.capability_group, Used: item.used } })

      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += [
        Object.keys(giftcodesExport[0]).join(';'),
        ...giftcodesExport.map(item => Object.values(item).join(';'))
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')

      const data = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', data)
      link.setAttribute('download', 'giftcodes.csv')
      link.click()
    },
    addNewGiftcode () {
      this.giftcode = { id: this.$uuid.v4(), client: this.client.data.name, giftcode: '', valid_until: null, capability_group: null, used: 0 }
      this.giftcodeAction = 'Add new Giftcode'
      this.editGiftcode = true
      this.showModal = true
    },
    alterGiftcode (entry) {
      this.giftcode = JSON.parse(JSON.stringify(entry))
      if (!this.validateRandomStr(this.giftcode.giftcode)) {
        this.giftcode.error = true
      }
      this.addGiftcode = true
      this.showModal = true
    },
    generateCodes () {
      for (let i = 1; i <= this.generateAmount; i++) {
        this.giftcodes.push({ id: this.$uuid.v4(), client: this.client.data.name, giftcode: this.randomStr(10), valid_until: null, capability_group: null, used: 0 })
      }
      this.uploadGiftcodes = false
      this.generateGiftcodes = false
      this.generateAmount = 0
      this.showModal = false
    },
    randomStr (m) {
      m = m || 10
      let s = ''
      const r = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'
      for (let i = 0; i < m; i++) {
        s += r.charAt(Math.floor(Math.random() * r.length))
      }
      if (!this.validateRandomStr(s)) {
        return this.randomStr(m)
      }
      return s
    },
    validateRandomStr (string) {
      const entry = this.giftcodes.find(giftcode => giftcode.giftcode === string)
      if (typeof entry === 'undefined') {
        return true
      } else {
        return false
      }
    },
    checkForDublicates (withId = false) {
      if (withId) {
        const entry = this.giftcodes.find(giftcode => giftcode.giftcode === this.giftcode.giftcode && giftcode.id !== this.giftcode.id)
        if (typeof entry === 'undefined') {
          this.giftcode.error = false
        } else {
          this.giftcode.error = true
        }
      } else {
        const entry = this.giftcodes.find(giftcode => giftcode.giftcode === this.giftcode.giftcode)
        if (typeof entry === 'undefined') {
          this.giftcode.error = false
        } else {
          this.giftcode.error = true
        }
      }
    },
    addGiftcodeMethod () {
      const giftcodeError = this.giftcodesErrors.find(giftcode => giftcode.id === this.giftcode.id)
      this.giftcodesErrors.splice(this.giftcodesErrors.indexOf(giftcodeError), 1)
      this.giftcodes.push(this.giftcode)
      this.addGiftcode = false
      this.giftcode = false
      this.showModal = false
    },
    changeGiftcodeMethod () {
      const giftcode = this.giftcodes.find(giftcode => giftcode.id === this.giftcode.id)
      if (typeof giftcode === 'undefined') {
        this.giftcodes.push(this.giftcode)
      } else {
        giftcode.giftcode = this.giftcode.giftcode
        giftcode.valid_until = this.giftcode.valid_until
        giftcode.capability_group = this.giftcode.capability_group
      }
      this.editGiftcode = false
      this.giftcode = false
      this.showModal = false
    },
    sendingEvent (file, xhr, formData) {
    },
    showSuccess (file, response) {
      this.$refs.fileUpload.removeFile(file)
      this.$nextTick(() => {
        response.splice(0, 1)
        if (this.overwriteGiftcodes) {
          this.giftcodes = response
        } else {
          const responseGiftcodes = response
          for (let i = responseGiftcodes.length - 1; i >= 0; --i) {
            if (!this.validateRandomStr(responseGiftcodes[i].giftcode)) {
              this.giftcodesErrors.push(responseGiftcodes[i])
              responseGiftcodes.splice(i, 1)
            }
          }
          this.giftcodes = this.giftcodes.concat(responseGiftcodes)
          this.$swal({
            title: 'Warning',
            text: 'there are some issues with uploaded giftcodes',
            icon: 'warning',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
        }
        this.uploadGiftcodes = false
        this.generateGiftcodes = false
        this.overwriteGiftcodes = false
        this.generateAmount = 0
        this.showModal = false
      })
    },
    getClientGiftcodes () {
      this.isLoading = true
      this.$store
        .dispatch('getClientGiftcodes', this.client.data.id)
        .then(response => {
          this.giftcodes = response
        })
    },
    saveClientGiftcodes () {
      this.isLoading = true
      this.$store
        .dispatch('saveClientGiftcodes', { clientId: this.client.data.id, giftcodes: this.giftcodes })
        .then(response => {
          this.isLoading = false
          this.$swal({
            title: 'Success',
            text: 'Giftcodes have been updated',
            icon: 'success',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
          this.getClientGiftcodes()
        },
        response => { // error
          this.$swal({
            title: 'Error',
            text: 'Errors occurred on saving giftcodes',
            icon: 'error'
          })
          this.getClientGiftcodes()
        })
    },
    sortBy (key) {
      this.sortKey = key
      this.sortOrders[key] = this.sortOrders[key] * -1
      this.order = this.sortOrders[key] * -1
    }
  }
}
</script>

<style style lang="scss">
.giftcodes-list {
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background: white;

  thead {
    background-color: #3c8dbc;
    color: white;
  }

}
</style>
