<template>
  <ValidationObserver v-slot="{ invalid }" tag="section" class="container-fluid">
    <div class="row box">
      <div class="col-12">
        <h2>Create education item</h2>
        <form name="edit-module" role="form">
          <div class="row">
            <ValidationProvider v-slot="{ errors }" name="title" tag="div" class="col-5">
              <div class="form-group" :class="{'has-error': errors[0]}">
                <label for="title">Educationitem Title: *</label>
                <input id="title" v-model.trim="educationitemData.title" type="text" class="form-control" required="required">
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <div class="col-5">
              <div class="form-group">
                <label for="parent">Parent:</label>
                <select id="parent" v-model.lazy="educationitemData.parent_id" name="parent" class="form-control">
                  <option value="">select a Parent Educationitem</option>
                  <option v-for="item in educationitems" :key="item.id" :value="item.id"> {{ item.title }}</option>
                </select>
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <label for="draft">draft-mode:</label><br>
                <input id="draft" v-model.lazy.trim="educationitemData.draft" type="checkbox" name="draft" value="1">
              </div>
            </div>
          </div>
        </form>
      </div>
      <SaveBar
        :saveDisabled="invalid"
        @save="createEducationitem(close = false)"
        @saveAndClose="createEducationitem(close = true)"
        @cancel="cancel"
      />
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'createEducationitem',
  components: {
  },
  data () {
    return {
      educationitemData: {
        draft: 1
      }
    }
  },
  computed: {
    ...mapGetters({
      educationitems: 'getEducationitemsPlain'
    }),
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    }
  },
  watch: {
    $route (route) {
      if (route.params.id) {
        this.loadData(route.params.id)
      }
    }
  },
  created () {
    if (this.$route.params.id) {
      this.loadData(this.$route.params.id)
    }
  },
  methods: {
    cancel () {
      this.$router.push('/clients/' + this.$route.params.client + '/educationitems')
    },
    createEducationitem (close = false) {
      this.isLoading = true
      this.$store
        .dispatch('createEducationitem', { educationitemId: this.educationitemData.id, client: this.$route.params.client, data: this.educationitemData })
        .then(response => {
          this.$swal({
            title: 'Success',
            text: 'Educationitem was updated',
            icon: 'success',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
          this.isLoading = false

          if (close) {
            this.cancel()
            return
          }

          if (!this.$route.params.id) {
            this.$router.push('/clients/' + this.$route.params.client + '/educationitems/' + response.data.id)
            return true
          }
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not save educationitem: ' + error,
            icon: 'error'
          })
        })
    }
  }
}
</script>

<style>
</style>
