<template>
  <section class="container-fluid bg-white">
    <Table
      :data="this.spotlights || []"
      :columns="table.columns"
      :actionButton="table.actionButton"
      :disableSearch="false"
      :search="table.search"
      :actions="table.actions"
      @rowClicked="table.clickHandler"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'listSpotlights',
  data () {
    return {
      table: {
        columns: [
          {
            label: 'Draft',
            icon: true,
            iconClass: (value) => value ? 'fas fa-eye-slash' : 'fas fa-eye',
            transform: (item) => item.draft.includes('fa-eye-slash'),
            sort: { enabled: true }
          },
          {
            label: 'Title',
            property: 'title',
            searchable: true,
            sort: { enabled: true, default: true }
          },
          {
            label: 'Category',
            property: 'category',
            searchable: true,
            sort: { enabled: true }
          },
          {
            label: 'Slug',
            property: 'slug',
            searchable: true,
            sort: { enabled: true }
          }
        ],
        actions: [
          { icon: 'fas fa-external-link-square-alt', action: this.openItemPreview },
          { icon: 'fas fa-trash-alt', buttonClass: 'btn-danger', action: this.deleteItem }
        ],
        actionButton: {
          icon: 'fa fa-plus',
          label: 'Create new spotlight',
          action: () => this.$router.push({ name: 'SpotlightCreate', params: { client: this.$route.params.client } })
        },
        clickHandler: this.editSpotlight,
        search: {
          placeholder: 'Search spotlights',
          disabled: false
        }
      },
      dcrFrontendHost: import.meta.env.VITE_DCR_FRONTEND_HOST
        .replace('http://', '')
        .replace('https://', '')
    }
  },
  computed: {
    ...mapGetters({
      spotlights: 'getSpotlightsList'
    }),
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    },
    isLocalHost () {
      return this.dcrFrontendHost.includes('localhost')
    }
  },
  created () {
    this.$store.commit('SET_SPOTLIGHTS_LIST', false)
    this.getSpotlights()
  },
  methods: {
    openItemPreview (item) {
      const targetUrl = `${this.isLocalHost ? 'http' : 'https'}://${this.$route.params.client}.${this.dcrFrontendHost}/spotlights/${item.slug}`
      window.open(targetUrl, '_blank')
    },
    editSpotlight (item) {
      this.$router.push('/clients/' + this.$route.params.client + '/spotlights/' + item.id)
    },
    getSpotlights (reload = false) {
      if (!this.spotlights || reload) {
        this.isLoading = true
        this.$store
          .dispatch('getSpotlightsList', this.$route.params.client)
          .then(response => {
            this.isLoading = false
          }).catch(error => {
            this.isLoading = false
            this.error = true
            this.$swal({
              title: 'Error',
              text: error,
              icon: 'error'
            })
          })
      }
    },
    deleteItem (item, listIndex) {
      this
        .$swal({
          title: 'Are you really sure?',
          text: 'The spotlight "' + item.title + '" will be deleted. This cannot be undone!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#dd4b39',
          cancelButtonText: 'Cancel'
        })
        .then(
          result => {
            if (result.value) {
              this.isLoading = true
              const data = {
                client: this.$route.params.client,
                item: item
              }
              this.$store
                .dispatch('deleteSpotlight', data)
                .then(response => {
                  this.spotlights.splice(listIndex, 1)
                  this.$swal({
                    title: 'Success',
                    text: 'Spotlight "' + item.title + '" has been deleted',
                    icon: 'success',
                    showConfirmButton: false,
                    toast: true,
                    position: 'top',
                    timer: 2000
                  })
                  this.isLoading = false
                  this.getSpotlights(true)
                }).catch(error => {
                  this.isLoading = false
                  this.error = true
                  this.$swal({
                    title: 'Error',
                    text: error,
                    icon: 'error'
                  })
                })
            }
          }
        )
    }
  }
}
</script>
