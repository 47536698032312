<template>
    <b-modal
      id="searchModal"
      v-model="show"
      scrollable
      size="lg"
      no-fade
      title="Search Resources"
      @show="setResourceIds"
      @hide="closeModal"
      @ok="saveSelection"
    >
      <div class="row">
        <div class="col-12">
          <div class="container-fluid">
            <label for="selected-resources"><strong>Selected resources</strong></label>
            <div v-if="crList.length > 0">
              <draggable v-model="crList" class="row dragArea mb-3" group="listitems">
                <div v-for="item in crList" :key="item" class="col-auto">
                  <span class="badge badge-secondary" style="cursor: grab;">
                  {{ item }}
                  </span>
                  <button type="button" class="close ml-1" data-dismiss="alert" aria-label="remove resource" title="remove resource" @click.prevent="removeResource(item)">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </draggable>
            </div>
            <div v-else>
              no resources selected
            </div>
            <hr>
            <div class="row">
              <div class="form-group col-6">
                <select id="search-type" v-model="search.type" name="search-type" class="form-control">
                  <option value="congressresource">Congress Resources</option>
                  <option value="event">Events</option>
                </select>
              </div>
              <div class="form-group col-6">
                <select id="search-congress" v-model="search.congress" name="search-congress" class="form-control">
                  <option value="" selected="true">Search in all projects</option>
                  <option v-for="project in projects" :key="project.name" :value="project.name">{{ project.title }}</option>
                </select>
              </div>
            </div>
            <div class="input-group mb-3">
              <input id="search-query" v-model="search.query" type="text" class="form-control border border-primary" placeholder="Enter search query" aria-label="Search query" name="search-query" @keydown.enter.prevent="searchResources">
              <div class="input-group-append">
                <button class="btn btn-primary" @click.prevent="searchResources"><i class="fas fa-search"></i> Search</button>
              </div>
            </div>
            <small class="form-text text-muted">
              A search query can be a single term or multiple boolean combined terms (AND, OR, AND NOT). You can use wildcard expressions, too.
              Examples: <code class="text-dark">lung, insuf*</code>, <code class="text-dark">panel NOT discussion</code>, <code class="text-dark">therapy AND metast*</code>
            </small>
            <div v-if="resources" class="mb-4">
              <hr>
              <label for="ids">Search results</label>
              <ul class="list-group">
                <li v-for="resource in resources" :key="resource._id" class="list-group-item" :class="{'bg-success-light': crList.includes(resource._id)}">
                  <div class="row d-flex align-items-center">
                    <div class="col-2">
                      {{ resource._id }}
                      <span v-if="resource._source.cSlide_final_program_nr" class="small">({{resource._source.cSlide_final_program_nr}})</span>
                    </div>
                    <div v-if="resource._source.event.title" class="col-auto">{{ resource._source.event.title }}</div>
                    <div v-if="resource._source.title" class="col-auto">{{ resource._source.title }}</div>
                    <div class="col-auto ml-auto">
                      <button v-if="crList.includes(resource._id)" class="btn btn-sm btn-default pull-right" @click.prevent="removeResource(resource._id)"><i class="fas fa-minus"></i></button>
                      <button v-else class="btn btn-sm btn-default pull-right" @click.prevent="addResource(resource._id)"><i class="fas fa-plus"></i></button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'search-modal',
  components: {
    draggable
  },
  props: ['ids', 'defaultSearchType'],
  data () {
    return {
      show: true,
      search: {
        query: '',
        congress: '',
        type: 'event'
      },
      crList: [],
      resources: false
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    },
    client () {
      return this.$route.params.client
    },
    projects () {
      const data = this.$store.getters.getProjectsList
      if (data !== false) {
        return data
      }
      return null
    }
  },
  created () {
    if (this.defaultSearchType) {
      this.search.type = this.defaultSearchType
    }
    this.getProjects()
    this.setResourceIds()
  },
  methods: {
    searchResources () {
      const params = {
        query: this.search.query,
        project: this.search.congress,
        type: [this.search.type],
        besearch: true
      }
      this.isLoading = true
      this.$store
        .dispatch('searchResources', { client: this.client, params: params })
        .then(response => {
          this.resources = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.error = true
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    getProjects () {
      if (!this.projects) {
        this.isLoading = true
        this.$store
          .dispatch('getProjectsList', this.client)
          .then(response => {
            this.isLoading = false
          }).catch(error => {
            this.isLoading = false
            this.error = true
            this.$swal({
              title: 'Error',
              text: error,
              icon: 'error'
            })
          })
      }
    },
    addResource (id) {
      if (!this.crList.includes(id)) {
        this.crList.push(id)
      }
    },
    removeResource (id) {
      this.crList.splice(this.crList.indexOf(id), 1)
    },
    setResourceIds () {
      this.crList = this.ids.split(',').map(cr => cr.trim())
      this.crList = this.crList.filter(cr => cr !== '')
    },
    saveSelection () {
      this.$emit('updateIds', this.crList.join(', '))
    },
    closeModal () {
      this.$emit('close')
    }
  }
}
</script>
