
<div v-if="partialProjectData" class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <h3>Widgets</h3>
        <hr>
      </div>
    </div>
  </div>
  <section class="container-fluid bg-white">
    <Table
      :data="partialProjectData.modules"
      :columns="table.columns"
      :actions="table.actions"
      :actionButton="table.actionButton"
      :disableSearch="false"
      :search="table.search"
      :rowsNotClickable="table.rowsNotClickable"
      @rowClicked="table.clickHandler"
    />
  </section>

  <div class="col-12">
    <hr>
    <SaveBar
      @save="updateProjectData(close = false)"
      @saveAndClose="updateProjectData(close = true)"
      @cancel="cancel"
    />
  </div>

  <edit-module-modal
    :id="editModuleModalId"
    :title="'Edit Module Settings for: ' + editModule"
    :module="module"
    :moduletype="editModule || ''"
    @cancelAction="resetModule()"
    @updateAction="saveModule($event)"
  />

  <CreateWidgetModal
    :id="createWidgetModalId"
    :availableWidgets="availableModules"
    @widgetAdded="addModule"
    @close="closeCreateWidgetModal"
  />

  <CopyEmbedCodeModal
    :id="copyEmbedCodeModalId"
    :module="module"
    :moduleType="module.name"
    :moduleUrl="this.getModuleUrl(module)"
    :projectName="project.name"
    @close="closeCopyEmbedCodeModal"
  />
</div>
