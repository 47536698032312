
<div class="row">
  <div class="col-12">
    <div class="card">
      <h4 class="card-header">
        Startpage Settings
      </h4>
      <div class="card-body">
        <form v-if="client.startpage" action="#" role="form">
          <label for="projectRedirect">Redirect Settings</label>
          <div class="form-group col-6">
            <b-form-group label="">
              <b-form-radio v-model="client.startpage.redirectSlug" name="" value="">Keep The
                startpage</b-form-radio>
              <b-form-radio v-model="client.startpage.redirectSlug" name="library" value="library">Redirect to Client
                Library</b-form-radio>
            </b-form-group>
          </div>
          <div class="form-group col-6">
            <label for="projectRedirect">Or choose project home to redirect to</label>
            <select id="projectRedirect" v-model="client.startpage.redirectSlug" class="form-control">
              <option value=""></option>
              <option v-for="project in projects" :key="project.name" :value="project.name">
                {{ project.title }}
              </option>
            </select>
          </div>
          <div class="form-group col-6">
            <small class="text-muted">
              <p>{{ infoText }}</p>
              <ul v-if="!['library', '', undefined].includes(client.startpage?.redirectSlug)">
                <li>if this has a specific startpage this is shown</li>
                <li>it this is live w/o startpage the channel home page is shown</li>
                <li>if this is not live w/o startpage the ondemand library is shown</li>
              </ul>
            </small>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
