
<form role="form" @submit.prevent>
  <div class="row">
    <div class="col">
      <former-form :model="mod.model" :form="mod.form" :formerfields="mod.fields"></former-form>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <button class="btn btn-success" @click.prevent="handleSubmission">
        <i class="fa fa-check"></i> Save
      </button>
      <button class="btn btn-default" @click.prevent="cancelSubmission">
        <i class="fas fa-close"></i> Cancel
      </button>
    </div>
  </div>
</form>
