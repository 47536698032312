<template>
  <ValidationObserver v-slot="{ invalid }" tag="section" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <h2 class="card-header">{{advertisementData.title}}</h2>
          <div class="card-body">
            <div class="card mb-4">
              <h4 class="card-header">
                General Settings
              </h4>
              <div class="card-body">
                <div class="row">
                  <ValidationProvider v-slot="{ errors }" name="title" tag="div" class="col-9">
                    <div class="form-group" :class="{'has-error': errors[0]}">
                      <label for="title">Title: *</label>
                      <input id="title" v-model.trim="advertisementData.title" type="text" class="form-control" required="required">
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                  <div class="col-3">
                    <div class="form-group">
                      <label for="boost">Boost: </label>
                      <input id="boost" v-model.trim="advertisementData.boost" type="text" class="form-control">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-4">
              <h4 class="card-header">
                Placement Rules
              </h4>
              <div class="card-body">
                <div class="col">
                  <small class="form-text text-muted">Define the criteria for the visibility of the advertisement.</small>
                  <div v-for="(criteria, key) in placementRules" :key="key" class="row mt-4">
                    <div class="col-xl">
                      <div class="card border-dark mb-3">
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col">
                                <div class="form-group col-6">
                                  <label for="criteriaName">Criteria:</label>
                                  <select id="criteriaName" v-model="criteria.name" class="form-control" name="criteriaName" @change.prevent="updateDefaultOptions(key)">
                                    <option value="location">Location</option>
                                    <option value="filter">Filter</option>
                                    <option value="module">Module</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-auto">
                                <button class="btn btn-sm btn-danger" @click.prevent="deletePlacementRule(key)">
                                  <i class="fas fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="col">
                                <div class="card bg-light mb-3">
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-auto m-auto">
                                        <div class="draggable-handle text-muted"><i class="fas fa-grip-vertical"></i></div>
                                      </div>
                                      <div v-if="criteria.name === 'location'" class="col mr-auto">
                                        <label :for="'locationValue' + key">Location: *</label>
                                        <select :id="'locationValue' + key" v-model="criteria.options.values" class="form-control" name="locationValue">
                                          <option value="library" selected>All Libraries</option>
                                          <option value="live-page" selected>Live Pages</option>
                                        </select>
                                      </div>
                                      <div v-if="criteria.name === 'filter'" class="col mr-auto">
                                        <div class="row">
                                          <ValidationProvider v-slot="{ errors }" name="name" slim>
                                            <div class="form-group col" :class="{'has-error': errors[0]}">
                                              <label :for="'fieldName' + key">Field Name: *</label>
                                              <input :id="'fieldName' + key" v-model="criteria.options.field" class="form-control" type="text" required="required" placeholder="your filter field name like 'Topics'">
                                              <small class="text-muted">field name of your active filter template</small><br>
                                              <small class="text-danger">{{ errors[0] }}</small>
                                            </div>
                                          </ValidationProvider>
                                          <ValidationProvider v-slot="{ errors }" name="value" slim>
                                            <div class="form-group col" :class="{'has-error': errors[0]}">
                                              <label :for="'fieldValues' + key">Field Values: *</label>
                                              <input :id="'fieldValues' + key" v-model="criteria.options.values" class="form-control" type="text" required="required" placeholder="type in your favorite topic">
                                              <small class="text-muted">comma seperated list of field values</small><br>
                                              <small class="text-danger">{{ errors[0] }}</small>
                                            </div>
                                          </ValidationProvider>
                                        </div>
                                      </div>
                                      <div v-if="criteria.name === 'module'" class="col mr-auto">
                                        <div class="row">
                                          <ValidationProvider v-slot="{ errors }" name="name" slim>
                                            <div class="form-group col" :class="{'has-error': errors[0]}">
                                              <label :for="'moduleName' + key">Module Name: *</label>
                                              <select :id="'moduleName' + key" v-model="criteria.options.moduleName" class="form-control" name="moduleName" @change.prevent="updateDefaultOptions(key)">
                                                <option value="live-event-progress" selected>Live Event Progress</option>
                                                <option value="image" selected>Image</option>
                                              </select>
                                              <small class="text-muted">Select a module where to display advertisement.</small><br>
                                              <small class="text-danger">{{ errors[0] }}</small>
                                            </div>
                                          </ValidationProvider>
                                          <ValidationProvider v-slot="{ errors }" name="value" slim>
                                            <div class="form-group col" :class="{'has-error': errors[0]}">
                                              <label :for="'fieldValues' + key">Resource Ids: *</label>
                                              <div class="input-group">
                                                <input :id="'fieldValues' + key" v-model="criteria.options.values" class="form-control col" style="display: inline;" type="text" required="required" placeholder="type in resource ids">
                                                <div class="input-group-append">
                                                  <button class="btn btn-default" type="button" @click="showSearchModal = true"><i class="fas fa-plus"></i></button>
                                                </div>
                                              </div>
                                              <small class="text-muted">Comma seperated list of resource ids.</small><br>
                                              <small class="text-danger">{{ errors[0] }}</small>
                                            </div>
                                          </ValidationProvider>
                                        </div>
                                        <search-modal
                                          v-if="showSearchModal"
                                          :defaultSearchType="'event'"
                                          :ids="criteria.options.values"
                                          @updateIds="updateIds(key, $event)"
                                          @close="showSearchModal = false">
                                        </search-modal>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col">
                      <button class="btn btn-primary" @click.prevent="addPlacementRule()">
                        <i class="fas fa-plus"></i> add new rule
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <h4 class="card-header">
                Content Module
              </h4>
              <div class="card-body">
                <div class="col-12">
                  <small class="form-text text-muted">Define the sponsored content module which whould be shown based on the settings. You can prepare multiple contents and hide them until they should be used.</small>
                  <edit-modules title="" :availableModules="availableModules" :data="advertisementData"></edit-modules>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <SaveBar
      :saveDisabled="invalid"
      @save="updateAdvertisement(close = false)"
      @saveAndClose="updateAdvertisement(close = true)"
      @cancel="cancel"
    />
  </ValidationObserver>
</template>

<script>
export default {
  name: 'edit-advertisement',
  data () {
    return {
      showSearchModal: false,
      advertisementData: {},
      availableModules: [],
      placementRules: []
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    }
  },
  watch: {
    $route (route) {
      if (route.params.id) {
        this.loadData(route.params.id)
        this.getAvailableModules()
      }
    }
  },
  created () {
    if (this.$route.params.id) {
      this.loadData(this.$route.params.id)
    }
    this.getAvailableModules()
  },
  methods: {
    updateIds (key, ids) {
      this.placementRules[key].options.values = ids
    },
    addPlacementRule () {
      if (this.placementRules.length === 0) {
        this.placementRules.push({
          name: 'location',
          options: {
            field: 'ad_tags',
            matchOperator: 'EXACT_MATCH',
            values: 'library'
          }
        })
      } else {
        this.placementRules.push({
          name: 'filter',
          options: {
            field: '',
            matchOperator: 'MATCHES_ONE_OF',
            values: ''
          }
        })
      }
    },
    updateDefaultOptions (index) {
      const rule = this.placementRules[index]
      rule.options.values = ''
      if (rule.name === 'location') {
        rule.options.field = 'ad_tags'
        rule.options.matchOperator = 'EXACT_MATCH'
        rule.options.values = 'library'
      }
      if (rule.options.moduleName === 'live-event-progress') {
        rule.options.field = 'session_ids'
        rule.options.matchOperator = 'MATCHES_ONE_OF'
      }
      if (rule.options.moduleName === 'image') {
        rule.options.field = 'image_ids'
        rule.options.matchOperator = 'MATCHES_ONE_OF'
      }
    },
    deletePlacementRule (index) {
      this.placementRules.splice(index, 1)
    },
    loadData (advertisementId) {
      this.isLoading = true
      this.$store
        .dispatch('getAdvertisement', advertisementId)
        .then(response => {
          this.$set(this, 'advertisementData', response.data)
          this.$set(this.advertisementData, 'modules', response.modules)
          this.$set(this.advertisementData, 'asset', response.asset || {})
          this.placementRules = JSON.parse(response.data.placement_rules) || []
          if (!response.data.placement_rules) {
            this.addPlacementRule()
          }
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    updateAdvertisement (close = false) {
      this.isLoading = true
      this.$set(this.advertisementData, 'placement_rules', JSON.stringify(this.placementRules))
      this.$store
        .dispatch('updateAdvertisement', { advertisementId: this.advertisementData.id, client: this.$route.params.client, data: this.advertisementData })
        .then(response => {
          this.$swal({
            title: 'Success',
            text: 'advertisement was updated',
            icon: 'success',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
          this.isLoading = false
          if (close) {
            this.cancel()
          }
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not update advertisement: ' + error,
            icon: 'error'
          })
        })
    },
    cancel () {
      this.$router.push('/clients/' + this.$route.params.client + '/advertisement')
    },
    getAvailableModules () {
      this.isLoading = true
      this.$store
        .dispatch('getModules')
        .then(response => {
          this.availableModules = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch modules: ' + error,
            icon: 'error'
          })
        })
    }
  }
}
</script>
