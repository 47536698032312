
<div>
  <div
    class="form-group former-asset"
    :class="[
      { 'former-has-value': hasValue, 'has-error': hasError },
      field.className,
    ]"
  >
    <SingleAsset
      :item="{ asset: this.model[this.field.key] }"
      :label="field.templateOptions.label"
      :helpText="field.templateOptions.help"
      :showInput="field.showInput"
      @input="item => setAsset(item)"
    />
    <error-display :form="form" :field="field.key"></error-display>
  </div>
</div>
