
<ValidationObserver v-slot="{ invalid }" tag="section" class="content">
  <div class="box row">
    <div class="col-12">
      <h2>Create new userrole</h2>
      <form name="create-userrole" class="form row">
        <ValidationProvider v-slot="{ errors }" name="name" tag="div" class="col-12">
          <div class="form-group" :class="{'has-error': errors[0]}">
            <label for="name">Name: *</label>
            <input id="name" v-model.trim="userrole.name" type="text" class="form-control" required="required">
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <div class="col-12">
          <div class="form-group">
            <label for="description">Description:</label>
            <textarea id="description" v-model.lazy.trim="userrole.description" name="description" class="form-control" rows="3">
            </textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="parent">select parent userrole:</label>
            <select id="parent" v-model="userrole.parent_id" name="parent" class="form-control">
              <option value="" selected="selected">select role</option>
              <option v-for="role in userroles" :key="role.id" :value="role.id">{{ role.name }}</option>
            </select>
          </div>
        </div>
        <SaveBar
          :saveDisabled="invalid"
          @save="updateUserrole(close = false)"
          @saveAndClose="updateUserrole(close = true)"
          @cancel="cancel"
        />
      </form>
    </div>
  </div>
</ValidationObserver>
