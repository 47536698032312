<template>
  <section class="container-fluid">

    <div class="row">
      <div class="col-12">
        <h2>{{project.title}}</h2>
      </div>
    </div>

    <b-card v-if="availableSectionsData.length > 0" no-body class="row">
      <b-tabs v-model="activeTab" card>
        <b-tab v-for="section in availableSectionsData" :key="section.key">
          <template slot="title">
            <i :class="section.tabHeader.icon"></i> {{section.tabHeader.title}}
          </template>
          <template v-for="c in section.components">
            <component
              :is="c.component"
              :key="section.key + '-' + c.component"
              :type="c.type ? c.type : false"
              :project="project"
              :relationType="c.relationType ? c.relationType : false"
              :relation="c.relation ? c.relation : false"
              :pageType="c.pageType ? c.pageType : false"
              :clientId="client.data.id"
            ></component>
          </template>
        </b-tab>
      </b-tabs>
    </b-card>

    <image-panel v-if="project.client_id" :clientId="project.client_id"></image-panel>

  </section>
</template>

<script>
import EditGeneral from './EditGeneral.vue'
import EditApi from './EditApi.vue'
import EditModules from './EditModules.vue'
import EditNavigation from './EditNavigation.vue'
import EditLibrary from './EditLibrary.vue'
import EditChannels from './EditChannels.vue'
import EditIscRules from './EditIscRules.vue'
import Dropzone from 'vue2-dropzone'

import { mapGetters } from 'vuex'
export default {
  name: 'ProjectEdit',
  components: {
    EditGeneral,
    EditApi,
    EditModules,
    EditNavigation,
    EditLibrary,
    EditChannels,
    EditIscRules,
    Dropzone
  },
  data () {
    return {
      project: {},
      openerText: 'Open',
      isOpen: false,
      uploadUrl: false,
      images: [],
      foldername: '',
      parentId: 0,
      asset: false,
      availableSections: [],
      activeTab: 0
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      client: 'getClient'
    }),
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    },
    validForm () {
      return true
    },
    availableSectionsData () {
      return this.sectionsData.filter(data => {
        return this.availableSections.includes(data.key)
      })
    },
    sectionsData () {
      return [
        { key: 'general', tabHeader: { icon: 'fas fa-database', title: 'General' }, components: [{ component: 'EditGeneral' }] },
        { key: 'project_home', tabHeader: { icon: 'fas fa-home', title: 'ProjectHome' }, components: [{ component: 'edit-page', pageType: 'home', relation: { data: this.project }, relationType: 'project' }] },
        { key: 'library', tabHeader: { icon: 'fas fa-university', title: 'Library' }, components: [{ component: 'edit-page', pageType: 'library', relation: { data: this.project }, relationType: 'project' }, { component: 'EditLibrary' }] },
        { key: 'schedule', tabHeader: { icon: 'fas fa-calendar-alt', title: 'Schedule' }, components: [{ component: 'edit-page', pageType: 'schedule', relation: { data: this.project }, relationType: 'project' }] },
        { key: 'channels_home', tabHeader: { icon: 'fas fa-th-list', title: 'ChannelsHome' }, components: [{ component: 'edit-page', pageType: 'channels', relation: { data: this.project }, relationType: 'project' }] },
        { key: 'channel_detail', tabHeader: { icon: 'fas fa-address-card', title: 'ChannelDetail' }, components: [{ component: 'edit-page', pageType: 'channelDetail', relation: { data: this.project }, relationType: 'project' }] },
        { key: 'channels_pages', tabHeader: { icon: 'fas fa-layer-group', title: 'ChannelsPages' }, components: [{ component: 'EditChannels' }] },
        { key: 'pages', tabHeader: { icon: 'fas fa-window-restore', title: 'Pages' }, components: [{ component: 'edit-pages', pageType: 'page', relation: { data: this.project }, relationType: 'project' }] },
        { key: 'widgets', tabHeader: { icon: 'fas fa-puzzle-piece', title: 'Widgets' }, components: [{ component: 'EditModules' }] },
        { key: 'page_widgets', tabHeader: { icon: 'fas fa-puzzle-piece', title: 'PageWidgets' }, components: [{ component: 'edit-pages', pageType: 'pageWidget', relation: { data: this.project }, relationType: 'project' }] },
        { key: 'navigation', tabHeader: { icon: 'fas fa-sitemap', title: 'Navigation' }, components: [{ component: 'EditNavigation' }] },
        // admin-role
        { key: 'isc_rules', tabHeader: { icon: 'fas fa-file-image', title: 'IscRules' }, components: [{ component: 'EditIscRules' }] },
        { key: 'api', tabHeader: { icon: 'fas fa-exchange-alt', title: 'API' }, components: [{ component: 'EditApi' }] }
      ]
    }
  },
  watch: {
    activeTab (newIndex) {
      if (this.availableSectionsData[newIndex]) {
        const hash = this.$route.hash.replace('#', '')
        const activeTabSlug = this.availableSectionsData[newIndex].tabHeader.title.toLowerCase()
        if (activeTabSlug !== hash) {
          this.$router.push({ hash: activeTabSlug })
        }
      }
    }
  },
  created () {
    this.getClientData(this.$route.params.client)
    this.getProjectById(this.$route.params.id)
    this.setActiveTabByRouteHash()
  },
  methods: {
    saveNavigation (navigation) {
      this.project.menu = navigation
    },
    getClientData (client) {
      this.isLoading = true
      this.$store
        .dispatch('getClientData', client)
        .then(response => {
          this.isLoading = false
        })
    },
    getProjectById (projectId) {
      this.isLoading = true
      this.$store
        .dispatch('getProject', projectId)
        .then(response => {
          this.project = response
          this.getAvailableSections(projectId)
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    getAvailableSections (projectId) {
      this.isLoading = true
      this.$store
        .dispatch('getProjectAvailableSections', projectId)
        .then(response => {
          this.$set(this, 'availableSections', response.sections)
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    setActiveTabByRouteHash () {
      const hash = this.$route.hash.replace('#', '')
      const activeSection = this.sectionsData.find(section => section.tabHeader.title.toLowerCase() === hash)
      if (this.sectionsData.indexOf(activeSection) >= 0) {
        this.activeTab = this.sectionsData.indexOf(activeSection)
      }
    }
  }
}
</script>

<style scoped>
.container-fluid.boxed {
  border-radius: 3px;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

  padding-top: 15px;
  padding-bottom: 15px;
  background: white;
}

</style>
