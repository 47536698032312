<template>
  <section class="container-fluid bg-white">
    <Table
      :data="this.advertisements || []"
      :columns="table.columns"
      :actionButton="table.actionButton"
      :disableSearch="false"
      :search="table.search"
      :actions="table.actions"
      @rowClicked="table.clickHandler"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'advertisements-list',
  data () {
    return {
      table: {
        columns: [
          {
            label: 'Title',
            property: 'title',
            searchable: true,
            sort: { enabled: true, default: true }
          },
          {
            label: 'Boost',
            badge: true,
            badgeClass: () => 'badge-primary',
            property: 'boost',
            sort: { enabled: true }
          }
        ],
        actions: [
          { icon: 'fas fa-trash-alt', buttonClass: 'btn-danger', action: this.deleteItem }
        ],
        actionButton: {
          icon: 'fa fa-plus',
          label: 'Create new advertisement',
          action: () => this.$router.push({ name: 'AdvertisementCreate', params: { client: this.$route.params.client } })
        },
        clickHandler: this.editAdvertisement,
        search: {
          placeholder: 'Search advertisements',
          disabled: false
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      advertisements: 'getAdvertisementsList'
    }),
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    }
  },
  created () {
    this.$store.commit('SET_ADVERTISEMENTS_LIST', false)
    this.getAdvertisements()
  },
  methods: {
    editAdvertisement (item) {
      this.$router.push('/clients/' + this.$route.params.client + '/advertisement/' + item.id)
    },
    getAdvertisements (reload = false) {
      if (!this.advertisements || reload) {
        this.isLoading = true
        this.$store
          .dispatch('getAdvertisementsList', this.$route.params.client)
          .then(response => {
            this.isLoading = false
          }).catch(error => {
            this.isLoading = false
            this.error = true
            this.$swal({
              title: 'Error',
              text: error,
              icon: 'error'
            })
          })
      }
    },
    deleteItem (item, listIndex) {
      this
        .$swal({
          title: 'Are you really sure?',
          text: 'The advertisement "' + item.title + '" will be deleted. This cannot be undone!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#dd4b39',
          cancelButtonText: 'Cancel'
        })
        .then(
          result => {
            if (result.value) {
              this.isLoading = true
              const data = {
                client: this.$route.params.client,
                item: item
              }
              this.$store
                .dispatch('deleteAdvertisement', data)
                .then(response => {
                  this.advertisements.splice(listIndex, 1)
                  this.$swal({
                    title: 'Success',
                    text: 'Advertisement "' + item.title + '" has been deleted',
                    icon: 'success',
                    showConfirmButton: false,
                    toast: true,
                    position: 'top',
                    timer: 2000
                  })
                  this.isLoading = false
                  this.getAdvertisements(true)
                }).catch(error => {
                  this.isLoading = false
                  this.error = true
                  this.$swal({
                    title: 'Error',
                    text: error,
                    icon: 'error'
                  })
                })
            }
          }
        )
    }
  }
}
</script>
