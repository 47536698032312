
<div class="row">
  <div class="col-12">
    <div class="card">
      <h4 class="card-header">
        Login Settings
      </h4>
      <div class="card-body">
        <div class="form-group">
          <label for="noAccessLearnMoreLink">Link for no Access Popup:</label>
          <input id="noAccessLearnMoreLink" v-model.lazy.trim="client.data.noAccessLearnMoreLink" type="text" class="form-control" name="noAccessLearnMoreLink">
          <small class="form-text text-muted">Link for the "learn more" button if the no access pop-up is shown.</small>
        </div>
        <div class="form-group">
              <label for="link_target">Link Target</label>
              <select id="link_target" v-model="client.data.noAccessLearnMoreLinkTarget" class="form-control">
                <option value="">open in current context</option>
                <option value="_blank">open in new tab</option>
                <option value="_top">open in current tab</option>
              </select>
        </div>
        <div class="form-group">
          <label for="postLogoutUrl">Post Logout URL:</label>
          <input id="postLogoutUrl" v-model.lazy.trim="client.data.postLogoutUrl" type="text" class="form-control" name="postLogoutUrl">
          <small class="form-text text-muted">The URL where the user is redirected to after logging out (defaults to client home if empty).</small>
        </div>
        <div>
          <b-icon-exclamation-circle-fill variant="info"/> <span>"Auth Client" and "Capability Groups" need to be setup under "Security"</span>
        </div>
        <div>
          <b-icon-exclamation-circle-fill variant="info"/> <span>Gift codes need to be created under "Gift Codes"</span>
        </div>

      </div>
    </div>
  </div>
</div>
