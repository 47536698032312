<template>
  <section class="container-fluid">
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'Client',
  data () {
    return {}
  },
  computed: {
    validForm () {
      if (this.errors.any()) {
        return true
      }
      return false
    }
  }
}
</script>
