<template>
  <div class="searchfields-settings">
     <div class="row">
      <div class="col-12">
        <h3>Searchfields</h3>
      </div>
      <div class="col-12">
        <h4>Congressresources</h4>
        <div class="form-check">
          <input id="overwriteCrsDefaults" v-model="CrsSearchfieldsOverwrite" type="checkbox" class="form-check-input" name="overwriteCrsDefaults" @change.prevent="handleCrsFields">
          <label class="form-check-label" for="overwriteCrsDefaults">overwrite default searchfields for congressresources</label>
        </div>
        <div v-if="CrsSearchfieldsOverwrite">
          <ul class="list-group">
            <li v-for="entry in searchfieldsByType('congressresource')" :key="entry.field" class="list-group-item">
              <div class="row">
                <div class="col-7 form-group">
                  <label>field</label>
                  <input v-model="entry.field" type="input" class="form-control">
                </div>
                <div class="col-4 form-group">
                  <label>boost</label>
                  <input v-model="entry.boost" type="input" class="form-control">
                </div>
                <div class="col-1">
                  <br>
                  <button class="btn btn-sm btn-danger" @click.prevent="deleteEntryByType(entry, 'congressresource')"><i class="fas fa-trash"></i></button>
                </div>
              </div>
            </li>
          </ul>
          <button class="btn btn-primary btn-sm" @click.prevent="addEntryByType('congressresource')"><i class="fas fa-plus"></i> add field</button>
        </div>
      </div>
      <div class="col-12">
        <hr>
      </div>
      <div class="col-12">
        <h4>Events</h4>
        <div class="form-check">
          <input id="overwriteEventDefaults" v-model="EventSearchfieldsOverwrite" type="checkbox" class="form-check-input" name="overwriteEventDefaults" @change="handleEventFields">
          <label class="form-check-label" for="overwriteEventDefaults">overwrite default searchfields for events</label>
        </div>
        <div v-if="EventSearchfieldsOverwrite">
          <ul class="list-group">
            <li v-for="entry in searchfieldsByType('event')" :key="entry.field" class="list-group-item">
              <div class="row">
                <div class="col-7 form-group">
                  <label>field</label>
                  <input v-model="entry.field" type="input" class="form-control">
                </div>
                <div class="col-4 form-group">
                  <label>boost</label>
                  <input v-model="entry.boost" type="input" class="form-control">
                </div>
                <div class="col-1">
                  <br>
                  <button class="btn btn-sm btn-danger" @click.prevent="deleteEntryByType(entry, 'event')"><i class="fas fa-trash"></i></button>
                </div>
              </div>
            </li>
          </ul>
          <button class="btn btn-primary btn-sm" @click.prevent="addEntryByType('event')"><i class="fas fa-plus"></i> add field</button>
        </div>
      </div>
      <div class="col-12">
        <hr>
      </div>
      <div class="col-12">
        <h4>Timelineitems</h4>
        <div class="form-check">
          <input id="overwriteTimelineDefaults" v-model="TimelineSearchfieldsOverwrite" type="checkbox" class="form-check-input" name="overwriteTimelineDefaults" @change="handleTimelineFields">
          <label class="form-check-label" for="overwriteTimelineDefaults">overwrite default searchfields for timelineitems</label>
        </div>
        <div v-if="TimelineSearchfieldsOverwrite">
          <ul class="list-group">
            <li v-for="entry in searchfieldsByType('timeline')" :key="entry.field" class="list-group-item">
              <div class="row">
                <div class="col-7 form-group">
                  <label>field</label>
                  <input v-model="entry.field" type="input" class="form-control">
                </div>
                <div class="col-4 form-group">
                  <label>boost</label>
                  <input v-model="entry.boost" type="input" class="form-control">
                </div>
                <div class="col-1">
                  <br>
                  <button class="btn btn-sm btn-danger" @click.prevent="deleteEntryByType(entry, 'timeline')"><i class="fas fa-trash"></i></button>
                </div>
              </div>
            </li>
          </ul>
          <button class="btn btn-primary btn-sm" @click.prevent="addEntryByType('timeline')"><i class="fas fa-plus"></i> add field</button>
        </div>
      </div>
      <div class="col-12">
        <hr>
      </div>
      <div class="col-12">
        <h4>Speakers</h4>
        <div class="form-check">
          <input id="overwriteSpeakerDefaults" v-model="SpeakerSearchfieldsOverwrite" type="checkbox" class="form-check-input" name="overwriteSpeakerDefaults" @change="handleSpeakerFields">
          <label class="form-check-label" for="overwriteSpeakerDefaults">overwrite default searchfields for speakers</label>
        </div>
        <div v-if="SpeakerSearchfieldsOverwrite">
          <ul class="list-group">
            <li v-for="entry in searchfieldsByType('speaker')" :key="entry.field" class="list-group-item">
              <div class="row">
                <div class="col-7 form-group">
                  <label>field</label>
                  <input v-model="entry.field" type="input" class="form-control">
                </div>
                <div class="col-4 form-group">
                  <label>boost</label>
                  <input v-model="entry.boost" type="input" class="form-control">
                </div>
                <div class="col-1">
                  <br>
                  <button class="btn btn-sm btn-danger" @click.prevent="deleteEntryByType(entry, 'speaker')"><i class="fas fa-trash"></i></button>
                </div>
              </div>
            </li>
          </ul>
          <button class="btn btn-primary btn-sm" @click.prevent="addEntryByType('speaker')"><i class="fas fa-plus"></i> add field</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'searchfields-settings',
  props: ['client'],
  data () {
    return {
      defaultSearchfields: null,
      CrsSearchfieldsOverwrite: false,
      EventSearchfieldsOverwrite: false,
      TimelineSearchfieldsOverwrite: false,
      SpeakerSearchfieldsOverwrite: false
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    },
    uploadUrl () {
      return this.$store.state.serverURI + '/api/v2/clients/' + this.client.data.id + '/giftcodes'
    },
    dropzoneOptions () {
      return {
        url: this.uploadUrl,
        maxFilesize: 1,
        headers: {
          Authorization: 'Bearer ' + this.$store.state.token
        }
      }
    }
  },
  watch: {
    client (val) {
      if (val.searchfields && val.searchfields.congressresource.length > 0) {
        this.$set(this, 'CrsSearchfieldsOverwrite', true)
      }
      if (val.searchfields && val.searchfields.event.length > 0) {
        this.$set(this, 'EventSearchfieldsOverwrite', true)
      }
      if (val.searchfields && val.searchfields.timeline.length > 0) {
        this.$set(this, 'TimelineSearchfieldsOverwrite', true)
      }
      if (val.searchfields && val.searchfields.speaker.length > 0) {
        this.$set(this, 'SpeakerSearchfieldsOverwrite', true)
      }
    }
  },
  created () {
    this.getDefaultSearchfields()
  },
  mounted () {
  },
  methods: {
    searchfieldsByType (type) {
      if (!this.client.searchfields || !this.client.searchfields[type]) {
        return []
      }
      return this.client.searchfields[type].length === 0 ? this.defaultSearchfields[type] : this.client.searchfields[type]
    },
    getDefaultSearchfields () {
      this.isLoading = true
      this.$store.dispatch('getDefaultSearchfields')
        .then(response => {
          this.defaultSearchfields = response.searchfields
          this.isLoading = false
        })
    },
    addEntryByType (type) {
      this.client.searchfields[type].push({ field: '', boost: '', type: type })
    },
    deleteEntryByType (entry, type) {
      this.client.searchfields[type].splice(this.client.searchfields[type].indexOf(entry), 1)
    },
    handleCrsFields (e) {
      if (!e.target.checked && this.client.searchfields.congressresource !== this.defaultSearchfields.congressresource) {
        this
          .$swal({
            title: 'Are you really sure?',
            text: 'disabling overwrite will delete the defined overwrites',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'disable',
            confirmButtonColor: '#dd4b39',
            cancelButtonText: 'Cancel'
          })
          .then(result => {
            if (result.value) {
              this.client.searchfields.congressresource = []
            } else {
              this.$set(this, 'CrsSearchfieldsOverwrite', true)
            }
          })
      } else if (!e.target.checked && this.client.searchfields.congressresource === this.defaultSearchfields.congressresource) {
        this.client.searchfields.congressresource = []
      }
      if (e.target.checked && this.client.searchfields.congressresource.length === 0) {
        this.client.searchfields.congressresource = JSON.parse(JSON.stringify(this.defaultSearchfields.congressresource))
      }
    },
    handleEventFields (e) {
      if (!e.target.checked && this.client.searchfields.event !== this.defaultSearchfields.event) {
        this
          .$swal({
            title: 'Are you really sure?',
            text: 'disabling overwrite will delete the defined overwrites',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'disable',
            confirmButtonColor: '#dd4b39',
            cancelButtonText: 'Cancel'
          })
          .then(result => {
            if (result.value) {
              this.client.searchfields.event = []
            } else {
              this.$set(this, 'EventSearchfieldsOverwrite', true)
            }
          })
      } else if (!e.target.checked && this.client.searchfields.event === this.defaultSearchfields.event) {
        this.client.searchfields.event = []
      }
      if (e.target.checked && this.client.searchfields.event.length === 0) {
        this.client.searchfields.event = JSON.parse(JSON.stringify(this.defaultSearchfields.event))
      }
    },
    handleTimelineFields (e) {
      if (!e.target.checked && this.client.searchfields.timeline !== this.defaultSearchfields.timeline) {
        this
          .$swal({
            title: 'Are you really sure?',
            text: 'disabling overwrite will delete the defined overwrites',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'disable',
            confirmButtonColor: '#dd4b39',
            cancelButtonText: 'Cancel'
          })
          .then(result => {
            if (result.value) {
              this.client.searchfields.timeline = []
            } else {
              this.$set(this, 'TimelineSearchfieldsOverwrite', true)
            }
          })
      } else if (!e.target.checked && this.client.searchfields.timeline === this.defaultSearchfields.timeline) {
        this.client.searchfields.timeline = []
      }
      if (e.target.checked && this.client.searchfields.timeline.length === 0) {
        this.client.searchfields.timeline = JSON.parse(JSON.stringify(this.defaultSearchfields.timeline))
      }
    },
    handleSpeakerFields (e) {
      if (!e.target.checked && this.client.searchfields.speaker !== this.defaultSearchfields.speaker) {
        this
          .$swal({
            title: 'Are you really sure?',
            text: 'disabling overwrite will delete the defined overwrites',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'disable',
            confirmButtonColor: '#dd4b39',
            cancelButtonText: 'Cancel'
          })
          .then(result => {
            if (result.value) {
              this.client.searchfields.speaker = []
            } else {
              this.$set(this, 'SpeakerSearchfieldsOverwrite', true)
            }
          })
      } else if (!e.target.checked && this.client.searchfields.speaker === this.defaultSearchfields.speaker) {
        this.client.searchfields.speaker = []
      }
      if (e.target.checked && this.client.searchfields.speaker.length === 0) {
        this.client.searchfields.speaker = JSON.parse(JSON.stringify(this.defaultSearchfields.speaker))
      }
    }
  }
}
</script>
