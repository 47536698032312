
<b-modal id="modal-edit" v-model="value" scrollable no-fade :title="title">
  <div class="row">
    <div class="col-12">
      <ValidationObserver slim>
        <form>
          <div class="row">
            <div class="form-group col-12">
              <label for="nav_type">Navigation Type</label>
              <select id="nav_type" v-model="navItem.nav_type" class="form-control">
                <option value="undefined">default</option>
                <option value="asset">asset</option>
                <option value="spotlights">spotlights</option>
                <option value="dropdown">dropdown</option>
              </select>
            </div>
          </div>
          <div v-if="navItem.nav_type !== 'spotlights' && navItem.nav_type !== 'dropdown'" class="row">
            <div class="form-group col-12">
              <label for="link_type">Link Type</label>
              <select id="link_type" v-model="navItem.link_type" class="form-control">
                <option value="external">external link</option>
                <option value="path">path</option>
                <option value="default">internal route name</option>
                <option value="icon">icon</option>
              </select>
            </div>
          </div>
          <ValidationProvider v-slot="{ errors }" name="label" slim>
            <div v-if="navItem.nav_type === 'spotlights'" class="form-group">
              <label for="linktextspotlights">Label</label>
              <input id="linktextspotlights" v-model="navItem.text" type="text" class="form-control" required="required" placeholder="Industry Spotlights" readonly>
              <small class="text-muted">The label can be customized in the translations.</small>
            </div>
            <div v-else class="form-group" :class="{'has-error': errors[0]}">
              <label for="linktext">Label *</label>
              <input id="linktext" v-model="navItem.text" type="text" class="form-control" required="required" placeholder="new item">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <div v-if="navItem.nav_type === 'asset'" class="form-group">
            <SingleAsset
              :item="{ asset: navItem.assetname }"
              label="Asset"
              helpText="Todo"
              :useFullSize="false"
              @input="item => setAsset({ asset: item.asset })"
            />
          </div>
          <div v-if="navItem.nav_type !== 'spotlights' && navItem.nav_type !== 'dropdown'" class="form-group" >
            <label for="link">Link</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-link"></i></span>
              </div>
              <input id="link" v-model="navItem.link" type="text" class="form-control" placeholder="URL, path or internal route name">
            </div>
          </div>
          <div v-if="navItem.nav_type !== 'spotlights' && navItem.nav_type !== 'dropdown'" class="row">
            <div class="form-group col-6">
              <label for="link_target">Link Target</label>
              <select id="link_target" v-model="navItem.link_target" class="form-control">
                <option value="">open in current context</option>
                <option value="_blank">open in new tab</option>
                <option value="_top">open in current tab</option>
              </select>
            </div>
            <div class="form-group col-6">
              <label for="languages">Languages</label>
              <input id="languages" v-model="navItem.languages" type="text" class="form-control" placeholder="de, en, ...">
            </div>
          </div>
          <div v-if="navItem.link_type === 'default'" class="form-group">
            <label for="params">Route parameters</label>
            <input id="params" v-model="navItem.params" type="text" class="form-control" placeholder="{&quot;key&quot;:&quot;value&quot;}">
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <div class="form-check">
                <input id="show_loggedoff" v-model="navItem.show_loggedoff" class="form-check-input" type="checkbox" value="1">
                <label class="form-check-label" for="show_loggedoff"><i class="text-muted fas fa-lock-open"></i> Show if not logged in</label>
              </div>
            </div>
            <div class="form-group col-md-6">
              <div class="form-check">
                <input id="show_loggedin" v-model="navItem.show_loggedin" class="form-check-input" type="checkbox" value="1">
                <label class="form-check-label" for="show_loggedin"><i class="text-muted fas fa-lock"></i> Show if logged in</label>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
  <template v-slot:modal-footer>
    <button class="btn btn-default" @click.prevent="cancelSubmission">
      <i class="fas fa-close"></i> Cancel
    </button>
    <button class="btn btn-primary" @click.prevent="handleSubmission">
      <i class="fa fa-check"></i> Ok
    </button>
  </template>
</b-modal>
