<template>
  <div class="asset-list">
    <SingleAsset
      v-for="(item, index) in items"
      :key="item.id"
      :item="removePrefix(item)"
      :disabled="isDisabled(item)"
      :showLabel="showLabel"
      :showInput="showInput"
      :showHelpText="false"
      :showDeleteButton="isMutable"
      :useFullSize="false"
      :label="label.replace('{n}', index + 1)"
      @input="(item) => emitInput(item)"
      @delete="name => $emit('delete', item)"
    />
    <b-button
      v-if="isMutable"
      class="asset-list__button text-primary"
      :style="showLabel ? 'margin-top: 32px;' : ''"
      variant="light"
      @click="
        $emit(
          'input',
          addPrefix({ id: $uuid.v4(), asset: undefined, name: '' })
        )
      "
    >
      <i class="fa fa-plus-circle"></i> add item
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'asset-list',
  props: {
    items: Array,
    prefix: {
      type: String,
      required: false
    },
    // if asset list items can be added or deleted:
    isMutable: {
      type: Boolean,
      default: false
    },
    showInput: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    removePrefix (item) {
      if (this.prefix) {
        return { ...item, name: item.name.replace(`${this.prefix}.`, '') }
      }
      return item
    },
    addPrefix (item) {
      if (this.prefix) {
        return { ...item, name: `${this.prefix}.${item.name}` }
      }
      return item
    },
    emitInput (item) {
      this.$emit('input', this.addPrefix(item))
    },
    isDisabled (item) {
      return !this.removePrefix(item).name
    }
  }
}
</script>

<style scoped>
.asset-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
}

.asset-list__button {
  --button-size: 200px;

  width: var(--button-size);
  height: var(--button-size);
}
</style>
