<template>
  <div
    class="form-group former-editor"
    :class="[
      { 'former-has-value': model[field.key], 'has-error': hasError },
      field.className,
    ]"
  >
    <froala-editor
      v-model="model[field.key]"
    />
    <error-display :form="form" :field="field.key"></error-display>
  </div>
</template>

<script>
import baseField from './baseField'

export default {
  name: 'formerEditor',
  mixins: [baseField]
}
</script>
