<template>
  <div class="container">

    <div class="row justify-content-center mb-5">
      <div class="text-center col-6">
        <img src="/static/img/logo-sm.png" class="center-block logo">
        <h1 style="color:#FFF"><i class="fas fa-cogs"></i> meta-dcr.com</h1>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-4">
        <div v-if="response" class="alert alert-danger" role="alert">
        {{response}}
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Sign in</h3>
            <form @submit.prevent="checkCreds">
              <div class="form-group">
                <label for="signInEmail">Email</label>
                <input id="signInEmail" v-model="email" type="email" class="form-control" placeholder="Enter email">
              </div>
              <div class="form-group">
                <label for="signInPassword">Password</label>
                <input id="signInPassword" v-model="password" type="password" class="form-control" placeholder="Password">
              </div>
              <button type="submit" class="mt-4 btn btn-block btn-lg btn-success">Sign in</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Login',
  data: function (router) {
    return {
      section: 'Login',
      loading: '',
      email: '',
      password: '',
      response: ''
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    }
  },
  methods: {
    async checkCreds () {
      const store = this.$store
      this.isLoading = true
      this.resetResponse()
      const data = {
        grant_type: 'password',
        client_id: 2,
        client_secret: 'PdM3JHUFEIJHygRkrgshqe1Ian8lM8aGXslT8dmg',
        username: this.email,
        password: this.password
      }

      try {
        const response = await axios.post(this.$store.state.serverURI + '/oauth/token', data)

        this.isLoading = false

        // eslint-disable-next-line camelcase
        if (!response.data?.access_token) {
          throw new Error('Did not receive a valid response')
        }

        // eslint-disable-next-line camelcase
        const { access_token: accessToken } = response.data

        //  success. Let's load up the dashboard
        store.commit('SET_USER', this.email)
        store.commit('SET_TOKEN', accessToken)
        axios.defaults.headers.common.authorization = 'Bearer ' + accessToken

        // // Save to local storage as well
        if (window.localStorage) {
          window.localStorage.setItem('user', this.email)
          window.localStorage.setItem('token', accessToken)
        }

        this.$router.push('/')
      } catch (error) {
        this.isLoading = false

        if (error.response?.data?.message) {
          this.response = error.response.data.message
          return
        }

        this.response = 'Server appears to be offline'
      }
    },
    resetResponse () {
      this.response = ''
    }
  }
}
</script>

<style>

body, .login-page {
  background: #222d32;
}
.container-table {
    display: table;
    color: white;
}
.vertical-center-row {
    display: table-cell;
    vertical-align: middle;
}
.vertical-20p {
  padding-top: 20%;
}
.vertical-10p {
  padding-top: 10%;
}
.logo {
  padding: 1em;
}
.input-group-addon {
  width: 50px;
}
</style>
