import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import DashView from '@/views/Dash.vue'
import LoginView from '@/views/Login.vue'
import NotFoundView from '@/views/404.vue'

// Import Views - Dash
import DashboardView from '@/views/Dashboard/Dashboard.vue'

import AdvertisementsList from '@/views/Advertisements/List.vue'
import AdvertisementView from '@/views/Dashboard/Advertisements.vue'
import CreateAdvertisement from '@/views/Advertisements/Create.vue'
import EditAdvertisement from '@/views/Advertisements/Edit.vue'

import ViewContainer from '@/views/Dashboard/ViewContainer.vue'
import ClientDash from '@/views/Clients/Dash.vue'
import ListClient from '@/views/Clients/List.vue'
import CreateClient from '@/views/Clients/Create.vue'
import EditClient from '@/views/Clients/Edit.vue'

import GroupsView from '@/views/Dashboard/Groups.vue'
import ListGroup from '@/views/Groups/List.vue'
import CreateGroup from '@/views/Groups/Create.vue'
import EditGroup from '@/views/Groups/Edit.vue'

import ProjectsView from '@/views/Dashboard/Projects.vue'
import ListProject from '@/views/Projects/List.vue'
import CreateProject from '@/views/Projects/Create.vue'
import EditProject from '@/views/Projects/Edit.vue'

import PackagesView from '@/views/Dashboard/Packages.vue'
import PackagesList from '@/views/Packages/List.vue'
import CreatePackage from '@/views/Packages/Create.vue'
import EditPackage from '@/views/Packages/Edit.vue'

import SpotlightsView from '@/views/Dashboard/Spotlights.vue'
import SpotlightsList from '@/views/Spotlights/List.vue'
import CreateSpotlight from '@/views/Spotlights/Create.vue'
import EditSpotlight from '@/views/Spotlights/Edit.vue'

import EducationitemsView from '@/views/Dashboard/Educationitems.vue'
import EducationitemsList from '@/views/Educationitems/List.vue'
import CreateEducationitem from '@/views/Educationitems/Create.vue'
import EditEducationitem from '@/views/Educationitems/Edit.vue'

import DomainsList from '@/views/Domains/List.vue'
import CreateDomain from '@/views/Domains/Create.vue'
import EditDomain from '@/views/Domains/Edit.vue'

import PageTemplatesView from '@/views/Dashboard/PageTemplates.vue'
import PageTemplateList from '@/views/PageTemplates/List.vue'
import CreatePageTemplate from '@/views/PageTemplates/Create.vue'
import EditPageTemplate from '@/views/PageTemplates/Edit.vue'

import ModulesView from '@/views/Dashboard/Modules.vue'
import ModuleTemplateList from '@/views/ModuleTemplates/List.vue'
import CreateModuleTemplate from '@/views/ModuleTemplates/Create.vue'
import EditModuleTemplate from '@/views/ModuleTemplates/Edit.vue'

import FiltertemplatesView from '@/views/Dashboard/Filtertemplates.vue'
import FiltertemplatesList from '@/views/Filtertemplates/List.vue'
import CreateFiltertemplate from '@/views/Filtertemplates/Create.vue'
import EditFiltertemplate from '@/views/Filtertemplates/Edit.vue'

import SecurityView from '@/views/Dashboard/Security.vue'
import ListSecurity from '@/views/Security/List.vue'
import CreateSecurity from '@/views/Security/Create.vue'
import EditSecurity from '@/views/Security/Edit.vue'

import GiftcodesView from '@/views/Security/Giftcodes.vue'

import UsersView from '@/views/Dashboard/Users.vue'
import ListUser from '@/views/Users/List.vue'
import CreateUser from '@/views/Users/Create.vue'
import EditUser from '@/views/Users/Edit.vue'

import UsergroupsView from '@/views/Dashboard/Usergroups.vue'
import ListUsergroup from '@/views/Usergroups/List.vue'
import CreateUsergroup from '@/views/Usergroups/Create.vue'
import EditUsergroup from '@/views/Usergroups/Edit.vue'

import UserrolesView from '@/views/Dashboard/Userroles.vue'
import ListUserrole from '@/views/Userroles/List.vue'
import CreateUserrole from '@/views/Userroles/Create.vue'
import EditUserrole from '@/views/Userroles/Edit.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/login',
      component: LoginView
    },
    {
      path: '/',
      component: DashView,
      meta: {
        auth: true
      },
      children: [
        {
          path: '',
          component: DashboardView,
          redirect: '/clients/'
        },
        {
          path: '/clients',
          component: ViewContainer,
          meta: {
            auth: true,
            description: 'manage clients'
          },
          children: [
            {
              path: '',
              component: ListClient,
              name: 'ClientsList',
              meta: {
                auth: true,
                title: 'Clients'
              }
            },
            {
              path: 'create',
              component: CreateClient,
              name: 'ClientCreate',
              meta: {
                auth: true,
                title: 'Create Client',
                description: 'create new client'
              }
            },
            {
              path: ':client',
              component: ClientDash,
              name: 'ClientShow',
              meta: {
                auth: true
              },
              beforeEnter (to, from, next) {
                const clientName = to.params.client
                const clientsList = store.state.clientsList?.map(client => client.name)
                if (clientsList?.includes(clientName)) {
                  next()
                } else {
                  store.dispatch('getClientsList')
                    .then(response => {
                      if (response?.map(client => client.name).includes(clientName)) {
                        next()
                      } else {
                        next('/')
                      }
                    })
                }
              },
              children: [
                {
                  path: 'edit',
                  component: EditClient,
                  name: 'ClientEdit',
                  meta: {
                    auth: true,
                    title: 'Edit Client',
                    description: 'edit client details'
                  }
                },
                {
                  path: 'groups',
                  component: GroupsView,
                  meta: {
                    auth: true,
                    description: 'manage groups'
                  },
                  children: [
                    {
                      path: '',
                      component: ListGroup,
                      name: 'GroupsList',
                      meta: {
                        auth: true,
                        title: 'Groups'
                      }
                    },
                    {
                      path: 'create',
                      component: CreateGroup,
                      name: 'GroupCreate',
                      meta: {
                        auth: true,
                        title: 'Create Group',
                        description: 'create new group'
                      }
                    },
                    {
                      path: ':id',
                      component: EditGroup,
                      name: 'GroupEdit',
                      meta: {
                        auth: true,
                        title: 'Edit Group',
                        description: 'edit group settings'
                      }
                    }
                  ]
                },
                {
                  path: 'projects',
                  component: ProjectsView,
                  meta: {
                    auth: true,
                    description: 'manage projects'
                  },
                  children: [
                    {
                      path: '',
                      component: ListProject,
                      name: 'ProjectsList',
                      meta: {
                        auth: true,
                        title: 'Projects'
                      }
                    },
                    {
                      path: 'create',
                      component: CreateProject,
                      name: 'ProjectCreate',
                      meta: {
                        auth: true,
                        title: 'Create Project',
                        description: 'create new project'
                      }
                    },
                    {
                      path: ':id',
                      component: EditProject,
                      name: 'ProjectEdit',
                      meta: {
                        auth: true,
                        title: 'Edit Project',
                        description: 'edit project details'
                      }
                    }
                  ]
                },
                {
                  path: 'packages',
                  component: PackagesView,
                  meta: {
                    auth: true,
                    description: 'manage packages'
                  },
                  children: [
                    {
                      path: '',
                      component: PackagesList,
                      name: 'PackagesList',
                      meta: {
                        auth: true,
                        title: 'Packages'
                      }
                    },
                    {
                      path: 'create',
                      component: CreatePackage,
                      name: 'PackageCreate',
                      meta: {
                        auth: true,
                        title: 'Create Package',
                        description: 'create new package'
                      }
                    },
                    {
                      path: ':id',
                      component: EditPackage,
                      name: 'PackageEdit',
                      meta: {
                        auth: true,
                        title: 'Edit Package',
                        description: 'edit package details'
                      }
                    }
                  ]
                },
                {
                  path: 'advertisement',
                  component: AdvertisementView,
                  meta: {
                    auth: true,
                    description: 'manage advertisement'
                  },
                  children: [
                    {
                      path: '',
                      component: AdvertisementsList,
                      name: 'AdvertisementsList',
                      meta: {
                        auth: true,
                        title: 'Advertisements'
                      }
                    },
                    {
                      path: 'create',
                      component: CreateAdvertisement,
                      name: 'AdvertisementCreate',
                      meta: {
                        auth: true,
                        title: 'Create Advertisement',
                        description: 'create new advertisement'
                      }
                    },
                    {
                      path: ':id',
                      component: EditAdvertisement,
                      name: 'AdvertisementEdit',
                      meta: {
                        auth: true,
                        title: 'Edit Advertisement',
                        description: 'edit advertisement details'
                      }
                    }
                  ]
                },
                {
                  path: 'spotlights',
                  component: SpotlightsView,
                  meta: {
                    auth: true,
                    description: 'manage spotlights'
                  },
                  children: [
                    {
                      path: '',
                      component: SpotlightsList,
                      name: 'SpotlightsList',
                      meta: {
                        auth: true,
                        title: 'Spotlights'
                      }
                    },
                    {
                      path: 'create',
                      component: CreateSpotlight,
                      name: 'SpotlightCreate',
                      meta: {
                        auth: true,
                        title: 'Create Spotlight',
                        description: 'create new spotlight'
                      }
                    },
                    {
                      path: ':id',
                      component: EditSpotlight,
                      name: 'SpotlightEdit',
                      meta: {
                        auth: true,
                        title: 'Edit Spotlight',
                        description: 'edit spotlight details'
                      }
                    }
                  ]
                },
                {
                  path: 'educationitems',
                  component: EducationitemsView,
                  meta: {
                    auth: true,
                    description: 'manage education items'
                  },
                  children: [
                    {
                      path: '',
                      component: EducationitemsList,
                      name: 'EducationitemsList',
                      meta: {
                        auth: true,
                        title: 'Education Items'
                      }
                    },
                    {
                      path: 'create',
                      component: CreateEducationitem,
                      name: 'EducationitemCreate',
                      meta: {
                        auth: true,
                        title: 'Create Education Item',
                        description: 'create new education item'
                      }
                    },
                    {
                      path: ':id',
                      component: EditEducationitem,
                      name: 'EducationitemEdit',
                      meta: {
                        auth: true,
                        title: 'Edit Education Item',
                        description: 'edit education item settings'
                      }
                    }
                  ]
                },
                {
                  path: 'pagetemplates',
                  component: PageTemplatesView,
                  meta: {
                    auth: true,
                    description: 'manage page templates'
                  },
                  children: [
                    {
                      path: '',
                      component: PageTemplateList,
                      name: 'ClientPageTemplateList',
                      meta: {
                        auth: true,
                        title: 'Page Templates'
                      }
                    },
                    {
                      path: 'create',
                      component: CreatePageTemplate,
                      name: 'ClientPageTemplateCreate',
                      meta: {
                        auth: true,
                        title: 'Create Page Template',
                        description: 'create new page template'
                      }
                    },
                    {
                      path: ':id',
                      component: EditPageTemplate,
                      name: 'ClientPageTemplateEdit',
                      meta: {
                        auth: true,
                        title: 'Edit Page Template',
                        description: 'edit page template settings'
                      }
                    }
                  ]
                },
                {
                  path: 'filtertemplates',
                  component: FiltertemplatesView,
                  meta: {
                    auth: true,
                    description: 'manage filtertemplates'
                  },
                  children: [
                    {
                      path: '',
                      component: FiltertemplatesList,
                      name: 'FiltertemplatesList',
                      meta: {
                        auth: true,
                        title: 'Filter Templates'
                      }
                    },
                    {
                      path: 'create',
                      component: CreateFiltertemplate,
                      name: 'FiltertemplateCreate',
                      meta: {
                        auth: true,
                        title: 'Create Filter Template',
                        description: 'create new filter template'
                      }
                    },
                    {
                      path: ':id',
                      component: EditFiltertemplate,
                      name: 'FiltertemplateEdit',
                      meta: {
                        auth: true,
                        title: 'Edit Filter Template',
                        description: 'edit filter template settings'
                      }
                    }
                  ]
                },
                {
                  path: 'giftcodes',
                  component: GiftcodesView,
                  name: 'Giftcodes',
                  meta: {
                    auth: true,
                    title: 'Gift codes',
                    description: 'manage gift codes'
                  }
                }
              ]
            }
          ]
        },
        {
          path: '/domains',
          component: ViewContainer,
          meta: {
            auth: true,
            description: 'manage domains'
          },
          children: [
            {
              path: '',
              component: DomainsList,
              name: 'DomainsList',
              meta: {
                auth: true,
                title: 'Domains'
              }
            },
            {
              path: 'create',
              component: CreateDomain,
              name: 'DomainCreate',
              meta: {
                auth: true,
                title: 'Domain Client',
                description: 'create new domain'
              }
            },
            {
              path: ':id',
              component: EditDomain,
              name: 'DomainEdit',
              meta: {
                auth: true,
                title: 'Edit Domain',
                description: 'edit domain details'
              }
            }
          ]
        },
        {
          path: '/pagetemplates',
          component: PageTemplatesView,
          meta: {
            auth: true,
            description: 'manage page templates'
          },
          children: [
            {
              path: '',
              component: PageTemplateList,
              name: 'PageTemplateList',
              meta: {
                auth: true,
                title: 'Page Templates'
              }
            },
            {
              path: 'create',
              component: CreatePageTemplate,
              name: 'PageTemplateCreate',
              meta: {
                auth: true,
                title: 'Create Page Template',
                description: 'create new page template'
              }
            },
            {
              path: ':id',
              component: EditPageTemplate,
              name: 'PageTemplateEdit',
              meta: {
                auth: true,
                title: 'Edit Page Template',
                description: 'edit page template settings'
              }
            }
          ]
        },
        {
          path: '/moduletemplates',
          component: ModulesView,
          meta: {
            auth: true,
            description: 'manage module templates'
          },
          children: [
            {
              path: '',
              component: ModuleTemplateList,
              name: 'ModuleTemplatesList',
              meta: {
                auth: true,
                title: 'Module Templates'
              }
            },
            {
              path: 'create',
              component: CreateModuleTemplate,
              name: 'ModuleTemplateCreate',
              meta: {
                auth: true,
                title: 'Create Module Template',
                description: 'create new module template'
              }
            },
            {
              path: ':id',
              component: EditModuleTemplate,
              name: 'ModuleTemplateEdit',
              meta: {
                auth: true,
                title: 'Edit Module Template',
                description: 'edit module template settings'
              }
            }
          ]
        },
        {
          path: '/security',
          component: SecurityView,
          meta: {
            auth: true,
            description: 'manage securities'
          },
          children: [
            {
              path: '',
              component: ListSecurity,
              name: 'SecuritiesList',
              meta: {
                auth: true,
                title: 'Securities'
              }
            },
            {
              path: 'create',
              component: CreateSecurity,
              name: 'SecurityCreate',
              meta: {
                auth: true,
                title: 'Create Security',
                description: 'create new security'
              }
            },
            {
              path: ':id',
              component: EditSecurity,
              name: 'SecurityEdit',
              meta: {
                auth: true,
                title: 'Edit Security',
                description: 'edit security details'
              }
            }
          ]
        },
        {
          path: '/users',
          component: UsersView,
          meta: {
            auth: true,
            description: 'manage users'
          },
          children: [
            {
              path: '',
              component: ListUser,
              name: 'UsersList',
              meta: {
                auth: true,
                title: 'Users'
              }
            },
            {
              path: 'create',
              component: CreateUser,
              name: 'UserCreate',
              meta: {
                auth: true,
                title: 'Create User',
                description: 'create new user profile'
              }
            },
            {
              path: ':id',
              component: EditUser,
              name: 'UserEdit',
              meta: {
                auth: true,
                title: 'Edit User',
                description: 'edit user settings'
              }
            },
            {
              path: 'profile/:id',
              component: EditUser,
              name: 'UserProfileEdit',
              meta: {
                auth: true,
                title: 'Edit User Profile',
                description: 'edit user profile settings'
              }
            }
          ]
        },
        {
          path: '/usergroups',
          component: UsergroupsView,
          meta: {
            auth: true,
            description: 'manage usergroups'
          },
          children: [
            {
              path: '',
              component: ListUsergroup,
              name: 'UsergroupsList',
              meta: {
                auth: true,
                title: 'Usergroups'
              }
            },
            {
              path: 'create',
              component: CreateUsergroup,
              name: 'UsergroupCreate',
              meta: {
                auth: true,
                title: 'Create Usergroup',
                description: 'create new usergroup'
              }
            },
            {
              path: ':id',
              component: EditUsergroup,
              name: 'UsergroupEdit',
              meta: {
                auth: true,
                title: 'Edit Usergroup',
                description: 'edit usergroup settings'
              }
            }
          ]
        },
        {
          path: '/userroles',
          component: UserrolesView,
          meta: {
            auth: true,
            description: 'manage userroles'
          },
          children: [
            {
              path: '',
              component: ListUserrole,
              name: 'UserrolesList',
              meta: {
                auth: true,
                title: 'Userroles'
              }
            },
            {
              path: 'create',
              component: CreateUserrole,
              name: 'UserroleCreate',
              meta: {
                auth: true,
                title: 'Create Userrole',
                description: 'create new userrole'
              }
            },
            {
              path: ':id',
              component: EditUserrole,
              name: 'UserroleEdit',
              meta: {
                auth: true,
                title: 'Edit Userrole',
                description: 'edit userrole settings'
              }
            }
          ]
        }
      ]
    },
    {
      // not found handler
      path: '*',
      component: NotFoundView
    }
  ],
  mode: 'history',
  scrollBehavior: function (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  },
  linkActiveClass: 'active'
})
