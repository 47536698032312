
<section class="container-fluid">

  <div class="row">
    <div class="col-12">
      <h2>{{project.title}}</h2>
    </div>
  </div>

  <b-card v-if="availableSectionsData.length > 0" no-body class="row">
    <b-tabs v-model="activeTab" card>
      <b-tab v-for="section in availableSectionsData" :key="section.key">
        <template slot="title">
          <i :class="section.tabHeader.icon"></i> {{section.tabHeader.title}}
        </template>
        <template v-for="c in section.components">
          <component
            :is="c.component"
            :key="section.key + '-' + c.component"
            :type="c.type ? c.type : false"
            :project="project"
            :relationType="c.relationType ? c.relationType : false"
            :relation="c.relation ? c.relation : false"
            :pageType="c.pageType ? c.pageType : false"
            :clientId="client.data.id"
          ></component>
        </template>
      </b-tab>
    </b-tabs>
  </b-card>

  <image-panel v-if="project.client_id" :clientId="project.client_id"></image-panel>

</section>
