
<b-modal
  id="createPageModal"
  v-model="show"
  scrollable
  no-fade
  size="lg"
  :title="'Create new page'"
  hide-footer
  @hide="closeModal">
  <form role="form" @submit.prevent>
    <div class="row pl-3 pr-3">
      <div class="col">
        <h5 class="mb-3 mt-4">Empty Page</h5>
        <ul class="list-group mb-5">
          <button type="button" class="list-group-item list-group-item-action" @click.prevent="createPage()">
            <div class="d-flex align-items-center font-weight-bold">
              <i class="far fa-file mr-2"></i>
              Create Empty Page
            </div>
          </button>
        </ul>

        <div class="mb-3 row">
          <label for="searchTemplates" class="col-sm-6 col-form-label">
            <h5 class="mb-0">Page Templates</h5>
          </label>
          <div class="col-sm-6">
            <form id="search" class="ui form" @submit.prevent>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text"><i class="fas fa-search"></i></div>
                </div>
                <input v-model="query" name="query" class="form-control" placeholder="Search page templates">
              </div>
            </form>
          </div>
        </div>

        <ul class="list-group mb-5">
          <li class="list-group-item pt-2 pb-2 bg-light font-weight-bold d-flex justify-content-between">
            <div>Name</div>
            <div>Type</div>
          </li>
          <button v-for="pageTemplate in filteredData" :key="pageTemplate.id" type="button" class="list-group-item list-group-item-action d-flex justify-content-between" @click.prevent="createPage(pageTemplate)">
            <div class="d-flex align-items-center font-weight-bold">
              <i class="far fa-file-alt mr-2"></i>
              {{ pageTemplate.name }}
            </div>
            <span class="badge ml-3 mt-1" :class="[pageTemplate.type === 'clientTemplate' ? 'badge-success' : 'badge-warning']">{{ getType(pageTemplate.type) }}</span>
          </button>
          <li v-if="filteredData.length === 0" class="list-group-item d-flex align-items-center bg-ligh">
              <i class="far fa-question-circle mr-3"></i>
              no templates found
          </li>
        </ul>
      </div>
    </div>
  </form>
</b-modal>
