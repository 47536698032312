
<div class="input-group">
  <div class="input-group-prepend">
    <div class="input-group-text">
      <i class="fas fa-search"></i>
    </div>
  </div>
  <input
    ref="search"
    :value="value"
    class="form-control"
    autocomplete="off"
    :placeholder="placeholder"
    @input="valueChanged"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
  />
</div>
