<template>
  <ValidationObserver
    v-slot="{ invalid }"
    tag="section"
    class="container-fluid"
  >
    <div class="row">
      <div class="col-12">
        <h2>{{ group.data.title }}</h2>
      </div>
    </div>

    <div class="row">
      <form name="create-group" class="form col-12">
        <input v-model="group.data.id" type="hidden" name="id" />
        <b-card no-body class="row">
          <b-tabs v-model="activeTab" card>
            <b-tab>
              <template slot="title">
                <i class="fa fa-database"></i> General
              </template>
              <div class="row">
                <div class="col-12 mb-4">
                  <div class="card">
                    <h4 class="card-header">Group Settings</h4>
                    <div class="card-body">
                      <div class="row">
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="title"
                          tag="div"
                          class="col-6"
                        >
                          <div
                            class="form-group"
                            :class="{ 'has-error': errors[0] }"
                          >
                            <label for="title">Title: *</label>
                            <input
                              id="title"
                              v-model.trim="group.data.title"
                              type="text"
                              class="form-control"
                              required="required"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </div>
                        </ValidationProvider>
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="name"
                          tag="div"
                          class="col-6"
                        >
                          <div
                            class="form-group"
                            :class="{ 'has-error': errors[0] }"
                          >
                            <label for="name">Name: *</label>
                            <input
                              id="name"
                              v-model.trim="group.data.name"
                              type="text"
                              class="form-control"
                              required="required"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </div>
                        </ValidationProvider>
                        <div class="form-group col-12">
                          <label for="description">Group description</label>
                          <froala-editor
                            id="description"
                            v-model="group.data.description"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 mb-4">
                  <div class="card">
                    <h4 class="card-header">Group Assets</h4>
                    <div class="card-body">
                      <div class="row col-12" style="gap: 16px;">
                        <SingleAsset
                          :item="logoAsset"
                          label="Logo"
                          helpText="Set custom group logo"
                          :useFullSize="false"
                          @input="item => setAsset({ ...logoAsset, asset: item.asset })"
                        />
                        <SingleAsset
                          :item="headerBannerAsset"
                          label="Header Banner"
                          helpText="Banner for group pages"
                          :useFullSize="false"
                          @input="item => setAsset({ ...headerBannerAsset, asset: item.asset })"
                        />
                        <SingleAsset
                          :item="headerBannerMobileAsset"
                          label="Header Banner (mobile)"
                          helpText="Banner for short resolutions"
                          :useFullSize="false"
                          @input="item => setAsset({ ...headerBannerMobileAsset, asset: item.asset })"
                        />
                        <SingleAsset
                          :item="teaserAsset"
                          label="Teaser"
                          helpText=""
                          :useFullSize="false"
                          @input="item => setAsset({ ...teaserAsset, asset: item.asset })"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template slot="title">
                <i class="fas fa-home"></i> Startpage
              </template>
              <edit-page
                v-if="group.data.id"
                pageType="home"
                :relation="group"
                relationType="group"
                :clientId="group.data.client_id"
              ></edit-page>
            </b-tab>
            <b-tab>
              <template slot="title">
                <i class="fas fa-university"></i> Library
              </template>
              <edit-page
                v-if="group.data.id"
                pageType="library"
                :relation="group"
                relationType="group"
                :clientId="group.data.client_id"
              ></edit-page>
              <edit-library class="mt-4" :group="group"></edit-library>
            </b-tab>
            <b-tab>
              <template slot="title">
                <i class="fas fa-sitemap"></i> Navigation
              </template>
              <edit-navigation :group="group"></edit-navigation>
            </b-tab>
          </b-tabs>
        </b-card>
      </form>
    </div>

    <div class="row">
      <div class="col-12">
        <br />
      </div>
      <SaveBar
        :saveDisabled="invalid"
        @save="updateGroup(false)"
        @saveAndClose="updateGroup(true)"
        @cancel="cancel"
      />
    </div>

    <image-panel
      v-if="group.data.client_id"
      :clientId="group.data.client_id"
    ></image-panel>
  </ValidationObserver>
</template>

<script>
import EditLibrary from './EditLibrary.vue'
import EditNavigation from './EditNavigation.vue'

export default {
  name: 'editGroup',
  components: {
    EditLibrary,
    EditNavigation
  },
  data () {
    return {
      group: {
        data: {
          groupAssets: [],
          id: '',
          title: '',
          name: '',
          description: ''
        },
        assets: [],
        languages: [],
        library: {}
      },
      defaultRoutes: [
        {
          name: 'Home',
          protected: false
        },
        {
          name: 'Detail',
          protected: false
        },
        {
          name: 'Live',
          protected: false
        },
        {
          name: 'Project',
          protected: false
        }
      ],
      openerText: 'Open',
      isOpen: false,
      uploadUrl: false,
      images: [],
      foldername: '',
      parentId: 0,
      asset: false,
      availableLanguages: {},
      select: {},
      translations: false,
      activeLang: false,
      translationsOverwrites: {},
      securitysettings: false,
      addgroup: {},
      editGroup: false,
      settingsIndex: false,
      activeTab: 0,
      tabs: ['general', 'startpage', 'library', 'navigation', 'assets']
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    },
    logoAsset () {
      const defaultAsset = this.getDefaultAsset('logo')

      if (!this.group?.data?.groupAssets) {
        return defaultAsset
      }

      return this.group.data.groupAssets.find(item => item.name === 'logo') || defaultAsset
    },
    headerBannerAsset () {
      const defaultAsset = this.getDefaultAsset('header.banner')

      if (!this.group?.data?.groupAssets) {
        return defaultAsset
      }

      return this.group.data.groupAssets.find(item => item.name === 'header.banner') || defaultAsset
    },
    headerBannerMobileAsset () {
      const defaultAsset = this.getDefaultAsset('header.banner.mobile')

      if (!this.group?.data?.groupAssets) {
        return defaultAsset
      }

      return this.group.data.groupAssets.find(item => item.name === 'header.banner.mobile') || defaultAsset
    },
    teaserAsset () {
      const defaultAsset = this.getDefaultAsset('teaser')

      if (!this.group?.data?.groupAssets) {
        return defaultAsset
      }

      return this.group.data.groupAssets.find(item => item.name === 'teaser') || defaultAsset
    }
  },
  watch: {
    'group.data' (data) {
      this.uploadUrl =
        this.$store.state.serverURI +
        '/api/v2/clients/' +
        data.client_id +
        '/images'
    },
    activeTab (newIndex) {
      const hash = this.$route.hash.replace('#', '')
      const activeTabSlug = this.tabs[newIndex]
      if (activeTabSlug !== hash) {
        this.$router.push({ hash: activeTabSlug })
      }
    }
  },
  created () {
    this.group.data.id = this.$route.params.id
    this.getGroupById(this.group.data.id)
    this.setActiveTabByRouteHash()
  },
  methods: {
    getDefaultAsset (assetName) {
      return {
        id: this.$uuid.v4(),
        asset: undefined,
        name: assetName
      }
    },
    setAsset (item) {
      if (!this.group.data.groupAssets || !Array.isArray(this.group.data.groupAssets)) {
        return
      }

      const index = this.group.data.groupAssets.findIndex(element => item.id === element.id)

      if (index !== -1) {
        return this.$set(this.group.data.groupAssets, index, item)
      }
      return this.group.data.groupAssets.push(item)
    },
    showSettings (index) {
      if (this.settingsIndex !== index) {
        this.settingsIndex = index
      } else {
        this.settingsIndex = false
      }
    },
    changeImage (asset) {
      this.$store.commit('TOGGLE_IMAGE_PANEL', true)
      this.asset = asset
    },
    getGroupById (groupId) {
      this.isLoading = true
      this.$store
        .dispatch('getGroup', groupId)
        .then((response) => {
          this.group = response
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    updateGroup (close) {
      this.isLoading = true
      this.$store
        .dispatch('updateGroup', {
          groupId: this.group.data.id,
          data: this.group
        })
        .then((response) => {
          this.$swal({
            title: 'Success',
            text: 'Group was updated',
            icon: 'success',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
          this.getGroupById(this.group.data.id)
          this.isLoading = false
          if (close) {
            this.cancel()
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not update group: ' + error,
            icon: 'error'
          })
        })
    },
    cancel () {
      this.$router.push('/clients/' + this.$route.params.client + '/groups')
    },
    newAsset () {
      this.group.assets.push({
        name: '',
        title: '',
        alt: ''
      })
      this.$nextTick(() => {
        const input = this.$refs.assetNameInput.slice(-1).pop()
        if (input) {
          input.focus()
        }
      })
    },
    deleteAsset (index) {
      this.group.assets.splice(index, 1)
    },
    setActiveTabByRouteHash () {
      const hash = this.$route.hash.replace('#', '')
      if (this.tabs.indexOf(hash) >= 0) {
        this.activeTab = this.tabs.indexOf(hash)
      }
    }
  }
}
</script>

<style></style>
