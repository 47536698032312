<template>
    <section class="content">
      <router-view></router-view>
    </section>
</template>

<script>
export default {
  name: 'projects',
  data () {
    return {
    }
  },
  computed: {
    callAPI () {
      return this.$parent.callAPI
    },
    changeloading () {
      return this.$parent.changeloading
    }
  }
}
</script>

<style>

</style>
