
<ValidationObserver v-slot="{ invalid }" tag="section" class="container-fluid">
  <div class="row box">
    <div class="col-12">
      <h2>Create education item</h2>
      <form name="edit-module" role="form">
        <div class="row">
          <ValidationProvider v-slot="{ errors }" name="title" tag="div" class="col-5">
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="title">Educationitem Title: *</label>
              <input id="title" v-model.trim="educationitemData.title" type="text" class="form-control" required="required">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <div class="col-5">
            <div class="form-group">
              <label for="parent">Parent:</label>
              <select id="parent" v-model.lazy="educationitemData.parent_id" name="parent" class="form-control">
                <option value="">select a Parent Educationitem</option>
                <option v-for="item in educationitems" :key="item.id" :value="item.id"> {{ item.title }}</option>
              </select>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <label for="draft">draft-mode:</label><br>
              <input id="draft" v-model.lazy.trim="educationitemData.draft" type="checkbox" name="draft" value="1">
            </div>
          </div>
        </div>
      </form>
    </div>
    <SaveBar
      :saveDisabled="invalid"
      @save="createEducationitem(close = false)"
      @saveAndClose="createEducationitem(close = true)"
      @cancel="cancel"
    />
  </div>
</ValidationObserver>
