

<section class="content">
  <div v-if="error" class="alert alert-danger fade in">
    <strong>Sorry!</strong> {{ errorMsg }}
  </div>
  <div v-if="success" class="alert alert-success fade in">
    <strong>Success!</strong> {{ successMsg }}
  </div>

  <router-view></router-view>
</section>

