
<ValidationObserver v-slot="{ invalid }" tag="section" class="content">
  <div class="box row">
    <div class="col-12">
      <h2>Update user</h2>
      <form name="create-user" class="form row">
        <div class="col-6">
          <ValidationProvider v-slot="{ errors }" name="firstname" slim>
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="firstname">Firstname: *</label>
              <input id="firstname" v-model.trim="user.firstname" type="text" class="form-control" required="required">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="lastname" slim>
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="lastname">Lastname: *</label>
              <input id="lastname" v-model.trim="user.lastname" type="text" class="form-control" required="required">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
        </div>
        <ValidationProvider v-slot="{ errors }" name="email" tag="div" class="col-6">
          <div class="form-group" :class="{'has-error': errors[0]}">
            <label for="email">E-Mail: *</label>
            <input id="email" v-model.trim="user.email" type="text" class="form-control" required="required" autocomplete="new-mail">
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <div class="col-6">
          <ValidationProvider v-slot="{ errors }" name="new password" slim>
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="newpassword">new Password:</label>
              <input id="newpassword" v-model="user.newpassword" type="password" class="form-control" autocomplete="new-password">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="new password confirmation" slim>
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="newpassword_confirmation">Confirm new Password:</label>
              <input id="newpassword_confirmation" v-model="user.newpassword_confirmation" type="password" class="form-control" autocomplete="new-password">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
        </div>
        <div v-if="loggedInUser.is_admin" class="col-6">
          <ValidationProvider v-slot="{ errors }" name="userrole" slim>
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="userrole">Userrole: *</label>
              <select id="userrole" v-model="user.userrole" class="form-control" required="required">
                <option value="">select a userrole</option>
                <option v-for="role in userroles" :key="role.id" :value="role.id">{{ role.name }}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <div class="form-group">
            <label for="usergroup">Usergroup:</label>
            <select id="usergroup" v-model="user.usergroup" name="usergroup" class="form-control">
              <option value="">select a usergroup</option>
              <option v-for="group in usergroups" :key="group.id" :value="group.id">{{ group.name }}</option>
            </select>
          </div>
        </div>
        <SaveBar
          :saveDisabled="invalid"
          @save="updateUser(close = false)"
          @saveAndClose="updateUser(close = true)"
          @cancel="cancel"
        />
      </form>
    </div>
  </div>
</ValidationObserver>
