<template>
  <froala
      :id="id"
      ref="editor"
      :value="value"
      :tag="'textarea'"
      :config="froalaConfig"
    ></froala>
</template>

<script>

export default {
  name: 'FroalaEditor',
  props: {
    value: {
      type: String
    },
    id: {
      type: String
    },
    height: {
      type: Number,
      default: 135
    }
  },
  data () {
    const self = this
    return {
      froalaConfig: {
        heightMin: self.height,
        events: {
          blur: function () {
            const editor = self.$refs.editor.getEditor()

            let code
            if (editor.codeView.isActive()) {
              code = editor.codeView.get()
            } else {
              code = editor.html.get()
            }

            self.$emit('input', code)
          }
        },
        key: 'CTD5xB3F1A2B2A9B2wahznE1uqaC3vyzdC3E3G3A7B6C5A4E4E3H3==',
        attribution: false,
        paragraphFormatSelection: true,
        toolbarButtons: {
          moreMisc: {
            buttons: ['html', 'paragraphFormat'],
            align: 'left',
            buttonsVisible: 2
          },
          moreText: {
            buttons: [
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'subscript',
              'superscript',
              'fontFamily',
              'fontSize',
              'textColor',
              'backgroundColor',
              'inlineClass',
              'inlineStyle',
              'clearFormatting'
            ],
            buttonsVisible: 3
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'formatOLSimple',
              'alignRight',
              'alignJustify',
              'formatOL',
              'formatUL',
              'paragraphStyle',
              'lineHeight',
              'outdent',
              'indent',
              'quote'
            ],
            buttonsVisible: 3
          },
          moreRich: {
            buttons: ['insertLink', 'undo', 'redo'],
            buttonsVisible: 3
          }
        },
        htmlAllowedTags: ['a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'bdi', 'bdo', 'blockquote', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col',
          'colgroup', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3',
          'h4', 'h5', 'h6', 'header', 'hgroup', 'hr', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'menu', 'menuitem',
          'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param', 'picture', 'pre', 'progress', 'queue', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'section', 'select',
          'small', 'source', 'span', 'strike', 'strong', 'sub', 'summary', 'sup', 'svg', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track', 'u', 'ul', 'use',
          'var', 'video', 'wbr'],
        htmlRemoveTags: ['style', 'base'],
        htmlUntouched: true,
        pastePlain: true,
        htmlExecuteScripts: false,
        codeBeautifierOptions: {
          end_with_newline: false
        }
      }
    }
  }
}
</script>
