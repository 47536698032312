<template>
  <section class="container-fluid bg-white">
    <Table
      :data="this.pageTemplates || []"
      :columns="table.columns"
      :actionButton="table.actionButton"
      :disableSearch="false"
      :search="table.search"
      :actions="table.actions"
      @rowClicked="table.clickHandler"
    />
    <CreatePageTemplateModal
      v-if="selectedPageTemplate"
      :id="createPageTemplateModalId"
      title="Copy page template"
      :page="selectedPageTemplate"
      :clientId="isClientRoute ? client.data.id : undefined"
      @cancel="closeCreatePageTemplateModal"
      @create="() => {
        closeCreatePageTemplateModal()
        fetchData()
      }"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      selectedPageTemplate: undefined,
      createPageTemplateModalId: this.$uuid.v4(),
      pageTemplates: [],
      table: {
        columns: [
          {
            label: 'Name',
            property: 'name',
            searchable: true,
            sort: {
              enabled: true,
              default: true
            }
          },
          {
            label: 'Created at',
            property: 'created_at',
            sort: { enabled: true }
          },
          {
            label: 'Updated at',
            property: 'updated_at',
            sort: { enabled: true }
          },
          {
            label: 'Type',
            badge: true,
            badgeClass: (value) => value === 'client template' ? 'badge-success' : 'badge-warning',
            transform: (item) => this.getType(item.type),
            searchable: true,
            sort: { enabled: true, default: !!this.$route.params.client }
          }
        ],
        actions: [
          {
            icon: 'far fa-clone',
            action: (item) => {
              this.selectedPageTemplate = JSON.parse(JSON.stringify(item))
              this.selectedPageTemplate.name = `Copy of ${this.selectedPageTemplate.name}`
              this.$nextTick(this.openCreatePageTemplateModal)
            }
          },
          { icon: 'fas fa-trash-alt', buttonClass: 'btn-danger', action: this.deleteItem }
        ],
        actionButton: {
          icon: 'fa fa-plus',
          label: 'Create new page template',
          action: () => this.$router.push(this.createTemplateLink)
        },
        clickHandler: this.editPageTemplate,
        search: {
          placeholder: 'Search page templates',
          disabled: false
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      client: 'getClient'
    }),
    isClientRoute () {
      return !!this.$route.params.client
    },
    createTemplateLink () {
      if (this.isClientRoute) {
        return { name: 'ClientPageTemplateCreate', params: { client: this.$route.params.client } }
      }
      return { name: 'PageTemplateCreate' }
    }
  },
  created () {
    if (this.isClientRoute) {
      this.getClientData(this.$route.params.client)
      return
    }

    this.fetchData()
  },
  methods: {
    openCreatePageTemplateModal () {
      this.$bvModal.show(this.createPageTemplateModalId)
    },
    closeCreatePageTemplateModal () {
      this.$bvModal.hide(this.createPageTemplateModalId)
      this.selectedPageTemplate = undefined
    },
    editPageTemplate (pageTemplate) {
      if (this.isClientRoute) {
        this.$router.push({ name: 'ClientPageTemplateEdit', params: { id: pageTemplate.id } })
      } else {
        this.$router.push({ name: 'PageTemplateEdit', params: { id: pageTemplate.id } })
      }
    },
    getClientData (client) {
      this.isLoading = true
      this.$store
        .dispatch('getClientData', client)
        .then(response => {
          this.fetchData()
          this.isLoading = false
        })
    },
    fetchData () {
      this.isLoading = true
      this.$store
        .dispatch('getPageTemplates', { clientId: this.isClientRoute ? this.client.data.id : undefined })
        .then(response => {
          this.pageTemplates = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch page templates: ' + error,
            icon: 'error'
          })
        })
    },
    deleteItem (item) {
      this
        .$swal({
          title: 'Are you really sure?',
          html: 'The page template <strong>"' + item.name + '"</strong> will be deleted. This cannot be undone!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#dd4b39',
          cancelButtonText: 'Cancel'
        })
        .then(
          result => {
            if (result.value) {
              this.isLoading = true
              this.$store
                .dispatch('deletePage', { pageId: item.id })
                .then(response => {
                  const index = this.pageTemplates.indexOf(this.pageTemplates.find(pageTemplate => pageTemplate.id === item.id))
                  this.pageTemplates.splice(index, 1)
                  this.$swal({
                    title: 'Success',
                    text: 'Page template"' + item.name + '" has been deleted',
                    icon: 'success',
                    showConfirmButton: false,
                    toast: true,
                    position: 'top',
                    timer: 2000
                  })
                  this.isLoading = false
                }).catch(error => {
                  this.isLoading = false
                  this.error = true
                  this.$swal({
                    title: 'Error',
                    text: 'could not delete page template: ' + error,
                    icon: 'error'
                  })
                })
            }
          }
        )
    },
    getType (type) {
      switch (type) {
        case 'adminTemplate':
          return 'master template'
        case 'clientTemplate':
          return 'client template'
        default:
          return 'unknown type'
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.fetchData()
    next()
  }
}
</script>
