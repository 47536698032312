<template>
  <ValidationObserver tag="section" class="content">
    <div class="row">
      <div class="col-12">
        <form v-if="pageTemplate" name="create-module" class="form row">
          <input v-model="pageTemplate.id" type="hidden" name="id">
          <div class="col-12">
            <edit-page :name="pageTemplate.name" :client="'cirse'" @update="getPageTemplateById($route.params.id)"></edit-page>
          </div>
        </form>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import EditPage from './EditPage'

export default {
  components: { EditPage },
  data () {
    return {
      pageTemplate: undefined
    }
  },
  created () {
    this.getPageTemplateById(this.$route.params.id)
  },
  methods: {
    cancel () {
      if (this.$route.params.client) {
        this.$router.push({ name: 'ClientPageTemplateList' })
      } else {
        this.$router.push({ name: 'PageTemplateList' })
      }
    },
    getPageTemplateById (pageTemplateId) {
      this.isLoading = true
      this.$store
        .dispatch('getPage', pageTemplateId)
        .then(response => {
          this.pageTemplate = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    }
  }
}
</script>
