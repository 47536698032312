

<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h3>Internal Stream Check Rules</h3>
      <hr>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <form action="#" role="form">
        <div v-for="rule in iscRules" :key="rule.name" class="row">
            <div class="col-10">
              <div class="row">
                <div class="form-group col-4">
                  <label>rule name</label>
                  <input v-model="rule.name" class="form-control" type="text"/>
                </div>
                <div class="form-group col-4">
                  <label>rule tag</label>
                  <input v-model="rule.tag" class="form-control" type="text"/>
                </div>
                <div class="form-group col-4">
                  <label>rule type</label>
                  <select v-model="rule.type" class="form-control" type="text">
                    <option value="ip" selected="selected">ip</option>
                    <option value="force">force</option>
                  </select>
                </div>
                <div class="form-group col-12">
                  <label>rule</label>
                  <input v-model="rule.rule" class="form-control" type="text"/>
                </div>
              </div>
            </div>
            <div class="col-2">
              <br>
              <button class="btn btn-sm btn-danger" @click.prevent="deleteIscRule(rule)"><i class="fas fa-trash-alt"></i> delete rule</button>
            </div>
        </div>
      </form>
    </div>
    <div class="col-12">
      <hr>
    </div>
    <div class="col-12 text-center">
      <button class="btn btn-success" @click.prevent="saveIscRules"><i class="fas fa-save"></i> save rules</button>
      <button class="btn btn-primary" @click.prevent="addIscRule"><i class="fas fa-plus"></i> add rule</button>
    </div>
  </div>
</div>
