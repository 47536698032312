<template>
  <section class="container-fluid bg-white">
    <Table
      :data="this.clients || []"
      :columns="table.columns"
      :actionButton="table.actionButton"
      :disableSearch="false"
      :search="table.search"
      :actions="table.actions"
      @rowClicked="table.clickHandler"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'listClients',
  data () {
    return {
      table: {
        columns: [
          {
            label: 'Title',
            property: 'title',
            searchable: true,
            sort: { enabled: true, default: true }
          },
          {
            label: 'Name',
            property: 'name',
            searchable: true,
            sort: { enabled: true }
          },
          {
            label: 'Matomo Site Id',
            property: 'matomoSiteId',
            sort: { enabled: true }
          },
          {
            label: 'Video Tracking',
            property: 'enableVideoTracking',
            icon: true,
            iconClass: (value) => value ? 'fas fa-check' : '',
            sort: { enabled: true }
          },
          {
            label: 'Sentry',
            property: 'enableSentry',
            icon: true,
            iconClass: (value) => value ? 'fas fa-check' : '',
            sort: { enabled: true }
          }
        ],
        actions: [
          { icon: 'fas fa-external-link-square-alt', action: this.openInNewTab }
        ],
        actionButton: {
          icon: 'fa fa-plus',
          label: 'Create new client',
          action: () => this.$router.push({ name: 'ClientCreate' })
        },
        clickHandler: (item) => this.$router.push({ name: 'ClientEdit', params: { client: item.name } }),
        search: {
          placeholder: 'Search clients',
          disabled: false
        }
      },
      dcrFrontendHost: import.meta.env.VITE_DCR_FRONTEND_HOST
        .replace('http://', '')
        .replace('https://', '')
    }
  },
  computed: {
    ...mapGetters({
      clients: 'getClientsList',
      user: 'getUser'
    }),
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    },
    isLocalHost () {
      return this.dcrFrontendHost.includes('localhost')
    }
  },
  created () {
    this.getClients()
  },
  methods: {
    getClients () {
      this.isLoading = true
      const params = {}
      this.$store
        .dispatch('getClientsList', { params: params })
        .then(() => {
          this.isLoading = false
        })
    },
    openInNewTab (item) {
      const targetUrl = `${this.isLocalHost ? 'http' : 'https'}://${item.name}.${this.dcrFrontendHost}`
      window.open(targetUrl, '_blank')
    }
  }
}
</script>
