<template>
  <div>
    <div
      class="form-group former-asset"
      :class="[
        { 'former-has-value': hasValue, 'has-error': hasError },
        field.className,
      ]"
    >
      <SingleAsset
        :item="{ asset: this.model[this.field.key] }"
        :label="field.templateOptions.label"
        :helpText="field.templateOptions.help"
        :showInput="field.showInput"
        @input="item => setAsset(item)"
      />
      <error-display :form="form" :field="field.key"></error-display>
    </div>
  </div>
</template>

<script>
import baseField from './baseField'

export default {
  name: 'former-asset',
  mixins: [baseField],
  data: function () {
    return {}
  },
  computed: {
    hasValue () {
      return !!this.model[this.field.key]
    }
  },
  methods: {
    setAsset (item) {
      this.model[this.field.key] = item.asset
    }
  }
}
</script>

<style lang="scss">
.field__asset {
  flex: 1;
  margin: 1rem;
  min-width: 0;
}
</style>
