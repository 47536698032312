<template>
  <ValidationObserver v-slot="{ invalid }" tag="section" class="container-fluid">
    <div class="box row">
      <div class="col-12">
        <h2>Create new group</h2>
        <form name="create-group" class="form">
          <div class="row">
            <ValidationProvider v-slot="{ errors }" name="title" tag="div" class="col-6">
              <div class="form-group" :class="{'has-error': errors[0]}">
                <label for="title">Title: *</label>
                <input id="title" v-model.trim="group.title" type="text" class="form-control" required="required">
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" name="name" tag="div" class="col-6">
              <div class="form-group" :class="{'has-error': errors[0]}">
                <label for="name">Name: *</label>
                <input id="name" v-model.trim="group.name" type="text" class="form-control" required="required">
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <div class="col-12">
              <div class="form-group">
                <label for="description">Group description</label>
                <textarea id="description" v-model="group.description" name="description" class="form-control" rows="5"></textarea>
              </div>
            </div>
            <SaveBar
             :saveDisabled="invalid"
             disableSaveAndClose
             @save="createGroup"
             @cancel="() => $router.push(`/clients/${$route.params.client}/groups`)"
            />
          </div>
        </form>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'createGroup',
  data () {
    return {
      group: {
        title: '',
        name: '',
        description: ''
      }
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    }
  },
  created () {
  },
  methods: {
    createGroup () {
      this.isLoading = true
      this.$store
        .dispatch('createGroup', { client: this.$route.params.client, data: this.group })
        .then(response => {
          this.isLoading = false
          this.$router.push('/clients/' + this.$route.params.client + '/groups/' + response.id)
        }, response => {
          this.$swal({
            title: 'Error',
            text: 'could not save group data',
            icon: 'error'
          })
          this.isLoading = false
        })
    }
  }
}
</script>

<style>
.box {
  padding-bottom: 15px;
}
</style>
