<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <div class="input-group-text">
        <i class="fas fa-search"></i>
      </div>
    </div>
    <input
      ref="search"
      :value="value"
      class="form-control"
      autocomplete="off"
      :placeholder="placeholder"
      @input="valueChanged"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
  </div>
</template>

<script>

export default {
  name: 'SearchBar',
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String
    },
    focusOnLoad: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    this.$refs.search.focus()
  },
  methods: {
    valueChanged () {
      const value = this.$refs.search.value
      this.$emit('input', value)
    }
  }
}
</script>
