<template>
  <ValidationObserver v-slot="{ invalid }" tag="section" class="content">
    <div class="box row">
      <div class="col-12">
        <h2>Create new page template</h2>
        <form name="create-module" class="form row">
          <ValidationProvider v-slot="{ errors }" name="name" tag="div" class="col-md-12">
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="name">Name: *</label>
              <input id="name" v-model.trim="pageTemplate.name" type="text" class="form-control" name="name" required="required">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>

          <SaveBar
            :saveDisabled="invalid"
            disableSaveAndClose
            @save="createPageTemplate"
            @cancel="cancel"
          />
        </form>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      pageTemplate: {
        name: '',
        type: 'adminTemplate'
      }
    }
  },
  computed: {
    ...mapGetters({
      client: 'getClient'
    })
  },
  created () {
    if (this.$route.params.client) {
      this.getClientData(this.$route.params.client)
      this.pageTemplate.type = 'clientTemplate'
    }
  },
  methods: {
    cancel () {
      if (this.$route.params.client) {
        this.$router.push({ name: 'ClientPageTemplateList' })
      } else {
        this.$router.push({ name: 'PageTemplateList' })
      }
    },
    getClientData (client) {
      this.isLoading = true
      this.$store
        .dispatch('getClientData', client)
        .then(response => {
          this.isLoading = false
        })
    },
    goToTemplateEdit (template) {
      if (this.$route.params.client) {
        this.$router.push({ name: 'ClientPageTemplateEdit', params: { client: this.$route.params.client, id: template.id } })
      } else {
        this.$router.push({ name: 'PageTemplateEdit', params: { id: template.id } })
      }
    },
    createPageTemplate () {
      this.isLoading = true
      const createPageTemplateOptions = {
        pageTemplate: this.pageTemplate,
        clientId: this.$route.params.client ? this.client.data.id : undefined
      }

      this.$store
        .dispatch('createPageTemplate', createPageTemplateOptions)
        .then(response => {
          this.isLoading = false
          this.$swal({
            title: 'Success',
            text: `Page template "${this.pageTemplate.name}" created`,
            icon: 'success',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
          this.goToTemplateEdit(response)
        }, response => {
          this.$swal({
            title: 'Error',
            text: 'could not save page template data',
            icon: 'error'
          })
          this.isLoading = false
        })
    }
  }
}
</script>

<style>

</style>
