
<section class="container-fluid bg-white">
  <Table
    :data="this.pageTemplates || []"
    :columns="table.columns"
    :actionButton="table.actionButton"
    :disableSearch="false"
    :search="table.search"
    :actions="table.actions"
    @rowClicked="table.clickHandler"
  />
  <CreatePageTemplateModal
    v-if="selectedPageTemplate"
    :id="createPageTemplateModalId"
    title="Copy page template"
    :page="selectedPageTemplate"
    :clientId="isClientRoute ? client.data.id : undefined"
    @cancel="closeCreatePageTemplateModal"
    @create="() => {
      closeCreatePageTemplateModal()
      fetchData()
    }"
  />
</section>
