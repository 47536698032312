
<div class="language-settings">
  <div v-if="isLoading" class="row">
    <div class="col-12 d-flex justify-content-center mb-3">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
  <div v-else class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <h3>Language &amp; Translations</h3>
          <hr>
        </div>
      </div>
    </div>
    <div class="col-12">
      <p>
        <strong>Select languages for client</strong>
      </p>
    </div>
    <div class="col-12">
      <ul class="list-group">
        <li v-for="translation in translations" :key="translation.language_code" class="list-group-item clearfix">{{ translation.language_code }} - {{ translation.name }} :
          <button v-if="translation.default == false" class="btn btn-primary" @click.prevent="selectDefault(translation)">make default</button>
          <button class="btn btn-danger pull-right" style="margin-left:10px;" @click.prevent="deleteLanguage(translation)">
            <i class="fas fa-trash-alt"></i>
          </button>
          <button class="btn btn-warning pull-right" @click.prevent="editTranslation(translation)">
            <i class="fa fa-edit"></i> Open translation
          </button>
        </li>
        <li v-if="availableLanguages.length > 0" class="list-group-item">
          <select v-model="select" class="form-control">
            <option v-for="language in availableLanguages" :key="language.name" :value="language">{{ language.name }}</option>
          </select>
          <button class="btn btn-success" :disabled="!select" @click.prevent="addLanguage(select)">
            <i class="fa fa-plus"></i>
          </button>
        </li>
      </ul>
    </div>
    <div class="col-12">
      <hr>
    </div>
    <div class="col-12">
      <div class="form-group">
        <div class="form-check">
          <input id="useLanguageInSearch" v-model="client.data.useLanguageInSearch" class="form-check-input" type="checkbox" value="1">
          <label class="form-check-label" for="useLanguageInSearch"><i class="text-muted fas fa-search"></i> use language in search</label>
        </div>
      </div>
    </div>
    <div v-if="selectedTranslation || showSaveChangesButton" class="col-12">
      <Table v-if="selectedTranslation"
        :data="this.translationsOverwrites[selectedTranslation.name]"
        :columns="table.columns"
        :actionButton="table.actionButton"
        :disableSearch="false"
        :search="table.search"
        @rowClicked="table.clickHandler"
        @dataChanged="this.itemChanged"
      />

      <div class="pull-right">
        <button class="btn btn-success" @click.prevent="saveTranslations">
          <i class="fa fa-check"></i> Save Changes
        </button>
        <button class="btn btn-default" @click.prevent="cancelEditing">
          <i class="fas fa-close"></i> Cancel
        </button>
      </div>
    </div>
  </div>
</div>
