<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <h4 class="card-header">
          Capabilities
        </h4>
        <div class="card-body">
          <div class="capability-settings">
            <div v-if="capabilities.length > 0" class="row">
              <div class="form-group col-7">
                <label>Definition</label>
              </div>
              <div class="form-group col-3">
                <label>Group</label>
              </div>
              <div class="form-group col-1">
                <label>Logged in</label>
              </div>
            </div>
            <div v-else class="row">
              <div class="form-group col-12">
                <label>No Capabilities defined. Click</label>
                  <button class="btn btn-link inline-link" @click.prevent="addNewDefaultCapability(true)">here</button>
                  <label>to add default capabilities.</label>
              </div>
            </div>
            <div v-for="(capability, index) in capabilities" :key="capability.id">
              <div class="row">
                <div class="form-group col-7">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="`capabilities ${index+1}`"
                    rules="required|capabilityJson"
                    tag="div"
                  >
                    <AceEditor
                      v-model="capability.capability"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="form-group col-3">
                  <input v-model.trim="capability.capability_group" type="text" name="capability_group" class="form-control" v-on:input="capability.logged_in = 1">
                </div>
                <div class="col-1 form-group">
                  <div class="form-check">
                    <input :id="capability.id + '_logged_in'" v-model="capability.logged_in" class="form-check-input" type="checkbox">
                    <label class="form-check-label" :for="capability.id + '_logged_in'"></label>
                  </div>
                </div>
                <div class="col-1">
                  <button class="btn btn-danger" @click.prevent="capabilities.splice(index,1)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            <div>
              <button class="btn btn-primary" @click.prevent="addNewDefaultCapability(false)"><i class="fas fa-plus"></i> add capability</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mt-4">
      <div class="card">
        <h4 class="card-header">
          Access Settings
        </h4>
        <div class="card-body">
          <div class="form-group">
            <label for="authClient">Auth Client:</label>
            <input id="authClient" v-model.lazy.trim="client.data.authClient" type="text" class="form-control" name="authClient">
            <small class="form-text text-muted">The authClient needs to be configured to enable giftcode creation. Typically it is the client name in lower case.</small>
          </div>
          <label>Security:</label>
          <div v-if="client.securities && client.securities.length > 0" class="row mb-4">
            <div class="col-4">
              <div class="list-group striped">
                <a
                  v-for="(security,key) in client.securities"
                  :key="security.id"
                  class="list-group-item list-group-item-action"
                  href="#securitySettings"
                  @click.prevent="showSecuritySettings(security, key)"
                >
                  <div class="d-flex w-100 justify-content-between align-items-center">
                    <h5 class="m-0">{{security.name}}</h5>
                    <button class="btn btn-danger btn-sm" @click.stop.prevent="removeSecurity(key)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-8">
              <template v-if="editSecurity">
                <div class="row">
                  <div class="col">
                    <h5>Edit settings for {{ editSecurity }}</h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <edit-security-settings :key="security.id" :security="security" @cancelConfig="cancel()" @saveSecurity="saveSecurity($event)"></edit-security-settings>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <select v-model="newSecurity" name="addSecurity" class="form-control">
                <option value="">select a security to add</option>
                <option v-for="security in availableSecurity" :key="security.name" :value="security">{{security.name}}</option>
              </select>
            </div>
            <div class="col">
              <button class="btn btn-primary" :disabled="newSecurity == ''" @click.prevent="addSecurity">
                <i class="fa fa-plus"></i> add security
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint vue/no-mutating-props: 0 */
import EditSecuritySettings from './EditSecuritySettings.vue'

export default {
  name: 'edit-security',
  components: { EditSecuritySettings },
  props: {
    capabilities: {
      type: Array,
      default: () => []
    },
    client: {
      type: Object
    }
  },
  data () {
    return {
      newSecurity: '',
      editSecurity: false,
      security: {},
      availableSecurity: []
    }
  },
  created () {
    this.getSecurityList()

    this.capabilities.sort(this.compareByGroup)
  },
  methods: {
    formatJSON (value) {
      return JSON.stringify(JSON.parse(value), null, 2).trim()
    },
    addNewDefaultCapability (isDefault) {
      if (isDefault) {
        this.capabilities.push({
          id: this.$uuid.v4(),
          capability: this.formatJSON('{"all":true}'),
          capability_group: '',
          logged_in: 1,
          client: this.client.data.authClient ? this.client.data.authClient : this.client.data.name
        })
        this.capabilities.push({
          id: this.$uuid.v4(),
          capability: this.formatJSON('{"meta":{"name":["regular","live","widget"]}}'),
          capability_group: '',
          logged_in: 0,
          client: this.client.data.authClient ? this.client.data.authClient : this.client.data.name
        })
      } else {
        this.capabilities.push({
          id: this.$uuid.v4(),
          capability: '',
          capability_group: '',
          logged_in: 0,
          client: this.client.data.authClient ? this.client.data.authClient : this.client.data.name
        })
      }
    },
    addSecurity () {
      this.client.securities.push(this.newSecurity)
      this.newSecurity = ''
    },
    removeSecurity (key) {
      this.cancel()
      this.client.securities.splice(key, 1)
    },
    cancel () {
      this.$set(this, 'security', {})
      this.$set(this, 'editSecurity', false)
    },
    saveSecurity (securityData) {
      const pivot = {
        client_id: this.client.data.id,
        data: JSON.stringify(securityData),
        security_id: this.security.id
      }
      this.$set(this.client.securities[this.security.key], 'pivot', pivot)
      this.security = {}
      this.editSecurity = false
    },
    showSecuritySettings (security, key) {
      this.cancel()
      this.security.layout = ''
      this.security.key = key
      this.security.id = security.id
      this.security.pivotId = security.pivot ? security.pivot.id : null
      this.$set(this.security, 'configuration', JSON.parse(security.configuration))
      this.$set(this.security, 'model', JSON.parse(security.model))
      if (security.pivot) {
        if (security.pivot.data) {
          this.$set(this.security, 'model', JSON.parse(security.pivot.data))
        }
      }
      this.editSecurity = security.name
    },
    getSecurityList () {
      this.isLoading = true
      this.$store
        .dispatch('getSecurityList')
        .then(response => {
          this.availableSecurity = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    compareByGroup (a, b) {
      let firstElementGroup = a.capability_group
      let secondElementGroup = b.capability_group

      if (typeof firstElementGroup !== 'string') {
        firstElementGroup = ''
      }

      if (typeof secondElementGroup !== 'string') {
        secondElementGroup = ''
      }

      if (firstElementGroup.toLowerCase() < secondElementGroup.toLowerCase()) {
        return -1
      }

      if (firstElementGroup.toLowerCase() > secondElementGroup.toLowerCase()) {
        return 1
      }

      if (firstElementGroup.toLowerCase() === secondElementGroup.toLowerCase()) {
        return a.capability.localeCompare(b.capability)
      }
      // fallback for unknown edge cases; 0 = no change of order of the elements in question
      return 0
    }
  }
}
</script>

<style>
.inline-link {
  padding-left: 0;
  padding-right: 0;
  vertical-align: baseline;
}
.drag .selected-securities {
  border-radius: 5px;
  padding: 5px;
  min-height: 250px;
  border: 1px dotted #666;
  background-color: #ccc;
}
.drag .available-securities {
  border-radius: 5px;
  padding: 5px;
  border: 1px dotted #666;
  background-color: #ccc;
}
.drag .active-security {
  border-radius: 5px;
  padding: 5px;
  border: 1px dotted #bbbbbb;
  background-color: #eee;
}
</style>
