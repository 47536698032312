<template>
  <section>
    <div>
      <div class="form-group">
        <label for="name">Name</label>
        <input id="name" v-model="model.name" type="text" name="name" class="form-control">
      </div>
      <div class="form-group">
        <label for="heading">Heading (optional)</label>
        <input id="heading" v-model="model.heading" type="text" name="heading" class="form-control">
      </div>
      <SingleAsset
        :item="{ asset: model.assetname }"
        label="Asset"
        :useFullSize="false"
        @input="item => setAsset(item)"
      />
      <div class="form-group">
        <label for="title">Custom Classes (CSS)</label>
        <input id="title" v-model="model.customClasses" type="text" name="title" class="form-control">
        <small class="form-text text-muted">Define custom CSS classes (optional). Separate multiple class names with commas.</small>
      </div>
      <div class="form-group">
        <label for="assetlink">Asset Link</label>
        <input id="assetlink" v-model="model.assetlink" type="text" name="assetlink" class="form-control">
      </div>
      <div class="form-group">
        <label for="linktarget">Link Target</label>
        <select id="linktarget" v-model="model.linktarget" class="form-control">
          <option value="_blank">open in new tab</option>
          <option value="_self" selected>open in current context</option>
          <option value="_top">open in current tab</option>
        </select>
      </div>
      <div class="form-group form-check">
        <input id="showSingleRow" v-model.trim="model.showSingleRow" type="checkbox" name="showSingleRow" class="form-check-input">
        <label for="showSingleRow">Do not wrap Content</label>
      </div>
      <div class="form-group">
        <label for="type">Resource Type</label>
        <select id="type" v-model="model.type" name="type" class="form-control">
          <option value="congressresource" selected="selected">Congress Resources</option>
          <option value="event">Events</option>
        </select>
      </div>
      <div class="form-group">
        <label for="ids">Resources Ids</label>
        <input id="ids" v-model="model.ids" type="text" name="ids" class="form-control">
      </div>
      <button class="btn btn-default mb-3" @click.prevent="showSearch = true"><i class="fas fa-search"></i> Search Resources</button>
    </div>
    <b-modal v-model="showSearch" size="lg" scrollable no-fade title="Search Resources">
      <label for="selected-resources"><strong>Selected resources</strong></label>
      <div v-if="crList.length > 0">
        <draggable v-model="crList" class="row dragArea mb-3" group="listitems">
          <div v-for="item in crList" :key="item" class="col-auto">
            <span class="badge badge-secondary" style="cursor: grab;">
            {{ item }}
            </span>
            <button type="button" class="close ml-1" data-dismiss="alert" aria-label="remove resource" title="remove resource" @click.prevent="removeResource(item)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </draggable>
      </div>
      <div v-else>
        no resources selected
      </div>
      <hr>
      <div class="row">
        <div class="form-group col-6">
          <select id="search-type" v-model="search.type" name="search-type" class="form-control">
            <option value="congressresource" selected="selected">Congress Resource</option>
            <option value="event">Events</option>
          </select>
        </div>
        <div class="form-group col-6">
          <select id="search-congress" v-model="search.congress" name="search-congress" class="form-control">
            <option value="" selected="true">Search in all projects</option>
            <option v-for="project in projects" :key="project.name" :value="project.name">{{ project.title }}</option>
          </select>
        </div>
      </div>
      <div class="input-group mb-3">
        <input id="search-query" v-model="search.query" type="text" class="form-control border border-primary" placeholder="Enter search query" aria-label="Search query" name="search-query" @keydown.enter.prevent="searchResources">
        <div class="input-group-append">
          <button class="btn btn-primary" @click.prevent="searchResources"><i class="fas fa-search"></i> Search</button>
        </div>
      </div>
      <small class="form-text text-muted">
        A search query can be a single term or multiple boolean combined terms (AND, OR, AND NOT). You can use wildcard expressions, too.
        Examples: <code class="text-dark">lung, insuf*</code>, <code class="text-dark">panel NOT discussion</code>, <code class="text-dark">therapy AND metast*</code>
      </small>
      <div v-if="resources" class="mb-4">
        <hr>
        <label for="ids">Search results</label>
        <ul class="list-group">
          <li v-for="resource in resources" :key="resource._id" class="list-group-item" :class="{'bg-success-light': crList.includes(resource._id)}">
            <div class="row">
              <div class="col-2">
                {{ resource._id }}
                <span v-if="resource._source.cSlide_final_program_nr" class="small">({{resource._source.cSlide_final_program_nr}})</span>
              </div>
              <div class="col-4">{{ resource._source.event.title }}</div>
              <div class="col-4">{{ resource._source.title }}</div>
              <div class="col-2">
                <button v-if="crList.includes(resource._id)" class="btn btn-sm btn-default pull-right" @click.prevent="removeResource(resource._id)"><i class="fas fa-minus"></i></button>
                <button v-else class="btn btn-sm btn-default pull-right" @click.prevent="addResource(resource._id)"><i class="fas fa-plus"></i></button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <template v-slot:modal-footer>
        <button class="btn btn-default" @click.prevent="showSearch = false">
          <i class="fas fa-close"></i> Cancel
        </button>
        <button class="btn btn-primary" @click.prevent="saveSelection">
          <i class="fa fa-check"></i> Ok
        </button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'highlight-teaser',
  components: {
    draggable
  },
  props: ['model'],
  data () {
    return {
      showSearch: false,
      crList: [],
      search: {
        query: '',
        congress: '',
        type: 'congressresource'
      },
      resources: false
    }
  },
  computed: {
    projects () {
      const data = this.$store.getters.getProjectsList
      if (data !== false) {
        return data.sort((a, b) => (a.title === b.title ? 0 : a.title > b.title ? 1 : -1))
      }
      return null
    }
  },
  created () {
    this.getProjects()
    this.crList = this.model.ids.split(',')
    this.crList = this.crList.filter(cr => cr !== '')
  },
  methods: {
    setAsset (item) {
      if (!item) {
        this.$set(this.model, 'assetname', undefined)
      }
      this.$set(this.model, 'assetname', item.asset)
    },
    searchResources () {
      const params = {
        query: this.search.query,
        project: this.search.congress,
        type: [this.search.type],
        besearch: true
      }
      this.isLoading = true
      this.$store
        .dispatch('searchResources', { client: this.$route.params.client, params: params })
        .then(response => {
          this.resources = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.error = true
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    getProjects () {
      if (!this.projects) {
        this.isLoading = true
        this.$store
          .dispatch('getProjectsList', this.$route.params.client)
          .then(response => {
            this.isLoading = false
          }).catch(error => {
            this.isLoading = false
            this.error = true
            this.$swal({
              title: 'Error',
              text: error,
              icon: 'error'
            })
          })
      }
    },
    addResource (id) {
      if (!this.crList.includes(id)) {
        this.crList.push(id)
      }
    },
    removeResource (id) {
      this.crList.splice(this.crList.indexOf(id), 1)
    },
    saveSelection () {
      this.model.ids = this.crList.join(',')
      this.showSearch = false
    }
  }
}
</script>
