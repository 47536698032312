
<ValidationObserver v-slot="{ invalid }" tag="section" class="content">
  <div class="box row">
    <div class="col-md-12">
      <h2>Create new security</h2>
      <form name="create-security" class="form row">
        <ValidationProvider v-slot="{ errors }" name="name" tag="div" class="col-md-12">
          <div class="form-group col-md-12" :class="{'has-error': errors[0]}">
            <label for="name">Name: *</label>
            <input id="name" v-model.trim="security.name" type="text" class="form-control" required="required">
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="configuration"
          tag="div"
          rules="required|json"
          class="col-md-12"
        >
          <div class="form-group col-md-12" :class="{'has-error': errors[0]}">
            <label for="configuration">Configuration: *</label>
            <AceEditor
              v-model="security.configuration"
              height="350px"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <SaveBar
          :saveDisabled="invalid"
          disableSaveAndClose
          @save="createSecurity"
          @cancel="cancel"
        />
      </form>
    </div>
  </div>
</ValidationObserver>
