
<b-modal
  :id="id"
  scrollable
  size="lg"
  no-fade
  title="Create new widget"
  @hide="closeModal"
>
  <div class="row">
    <div class="col-6">
      <h4 class="pl-2">
        Widget Types
      </h4>
    </div>
    <SearchBar
      v-model="query"
      class="col-6"
      placeholder="Search widget types"
    />
  </div>
  <div
    class="list-group"
  >
    <div
      v-if="filteredWidgets.length === 0"
      class="pl-2"
    >
      No widget found for the search query.
    </div>
    <div
      v-for="(widget, key) in filteredWidgets"
      :key="key"
      class="list-group-item list-group-item-action"
      @click="addNewWidget(widget)"
    >
      <i class="fa fa-puzzle-piece"></i> {{ widget.name }}
    </div>
  </div>

  <template
    #modal-footer
  >
    <button
      class="btn btn-default"
      @click="closeModal"
    >
      Cancel
    </button>
  </template>
</b-modal>
