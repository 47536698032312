import Vue from 'vue'

// css

import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'

import 'admin-lte'
import 'admin-lte/plugins/pace/pace.min'
import 'admin-lte/plugins/pace/pace.min.css'
import 'admin-lte/dist/css/AdminLTE.min.css'
import 'admin-lte/dist/css/skins/skin-blue.min.css'

import './customize.scss'

import 'vue-good-table/dist/vue-good-table.css'

// Froala: import css and plugin definitions
import 'froala-editor/css/froala_editor.pkgd.min.css'

// Froala: Plugins for 'more misc...'
import 'froala-editor/js/plugins/code_view.min'

// Froala: Plugins for 'more text...'
import 'froala-editor/js/plugins/font_family.min'
import 'froala-editor/js/plugins/font_size.min'
import 'froala-editor/js/plugins/colors.min'
import 'froala-editor/js/plugins/inline_style.min'

// Froala: Plugins for 'more paragraph...'
import 'froala-editor/js/plugins/align.min'
import 'froala-editor/js/plugins/lists.min'
import 'froala-editor/js/plugins/paragraph_format.min'
import 'froala-editor/js/plugins/paragraph_style.min'
import 'froala-editor/js/plugins/quote.min'
import 'froala-editor/js/plugins/line_height.min'

// Froala: Plugins for 'more rich...'
import 'froala-editor/js/plugins/link.min'

// Froala: Plugins for general setup
import 'froala-editor/js/plugins/char_counter.min'
import 'froala-editor/js/plugins/code_beautifier.min'

// Fontawesome: import font for fancy icons
import '@fortawesome/fontawesome-free/css/all.min.css'

// vue-select for fancy select field stuff
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueFroala from 'vue-froala-wysiwyg'
import VAceEditor from 'vue2-ace-editor'
import AssetModal from './components/AssetModal'
import EditAssets from './components/EditAssets'
import EditModuleModal from './components/EditModuleModal'
import EditModules from './components/EditModules'
import EditPage from './components/EditPage'
import EditPages from './components/EditPages'
import EditNavigationModal from './components/EditNavigationModal'
import ImagePanel from './components/ImagePanel'
import HideToolbarElements from './components/HideToolbarElements'
import SearchModal from './components/SearchModal'
import CreatePageModal from './components/CreatePageModal'
import FroalaEditor from './components/FroalaEditor'
import AceEditor from './components/AceEditor'
import SaveBar from './components/SaveBar'
import CreateWidgetModal from './components/CreateWidgetModal'
import SearchBar from './components/SearchBar'
import Table from './components/Table'
import CreatePageTemplateModal from './components/CreatePageTemplateModal'
import GeneralPageConfig from './components/GeneralPageConfig'
import SingleAsset from './components/SingleAsset'
import AssetList from './components/AssetList'
import CopyEmbedCodeModal from './components/CopyEmbedCodeModal'

import VueFormer from './plugins/vue-former'
import formerAceEditor from './plugins/vue-former/fields/AceEditor.vue'
import formerGroup from './plugins/vue-former/fields/Group.vue'
import formerRepeat from './plugins/vue-former/fields/Repeat.vue'
import formerCheckbox from './plugins/vue-former/fields/Checkbox.vue'
import formerEditor from './plugins/vue-former/fields/Editor.vue'
import formerAsset from './plugins/vue-former/fields/Asset.vue'
import formerAssetList from './plugins/vue-former/fields/AssetList.vue'

import UUID from 'vue-uuid'

import VueGoodTablePlugin from 'vue-good-table'

// vee-validate for proper form validation - V.3
import { ValidationProvider, ValidationObserver } from 'vee-validate'

Vue.use(VueFroala)

// import global components
Vue.component('v-select', vSelect)
Vue.component('image-panel', ImagePanel)
Vue.component('HideToolbarElements', HideToolbarElements)
Vue.component('AssetModal', AssetModal)
Vue.component('edit-module-modal', EditModuleModal)
Vue.component('edit-navigation-modal', EditNavigationModal)
Vue.component('edit-assets', EditAssets)
Vue.component('edit-modules', EditModules)
Vue.component('edit-page', EditPage)
Vue.component('edit-pages', EditPages)
Vue.component('search-modal', SearchModal)
Vue.component('create-page-modal', CreatePageModal)
Vue.component('froala-editor', FroalaEditor)
Vue.component('v-ace-editor', VAceEditor)
Vue.component('AceEditor', AceEditor)
Vue.component('SaveBar', SaveBar)
Vue.component('CreateWidgetModal', CreateWidgetModal)
Vue.component('SearchBar', SearchBar)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Table', Table)
Vue.component('CreatePageTemplateModal', CreatePageTemplateModal)
Vue.component('GeneralPageConfig', GeneralPageConfig)
Vue.component('SingleAsset', SingleAsset)
Vue.component('AssetList', AssetList)
Vue.component('CopyEmbedCodeModal', CopyEmbedCodeModal)

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// sweetalert for fancy stuff
Vue.use(VueSweetalert2)

Vue.use(VueFormer)
Vue.use(UUID)

Vue.use(VueGoodTablePlugin)

Vue.component('former-ace-editor', formerAceEditor)
Vue.component('former-group', formerGroup)
Vue.component('former-repeat', formerRepeat)
Vue.component('former-checkbox', formerCheckbox)
Vue.component('former-editor', formerEditor)
Vue.component('former-asset', formerAsset)
Vue.component('former-assetlist', formerAssetList)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

VueFormer.addType('ace-editor', formerAceEditor)
VueFormer.addType('group', formerGroup)
VueFormer.addType('repeat', formerRepeat)
VueFormer.addType('checkbox', formerCheckbox)
VueFormer.addType('editor', formerEditor)
VueFormer.addType('asset', formerAsset)
VueFormer.addType('assetlist', formerAssetList)
