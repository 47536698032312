import axios from 'axios'
import qs from 'qs'

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL
axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params, { arrayFormat: 'indices' })
}

const actions = {
  async getTranslations (context, clientId) {
    const url = context.state.serverURI + '/api/v2/clients/' + clientId + '/translations'
    return (await axios.get(url)).data
  },
  async getTranslationDefaults (context, langauageShortCode) {
    const url = context.state.serverURI + '/api/v2/translations/default/' + langauageShortCode
    return (await axios.get(url)).data
  },
  async saveTranslations (context, { clientId, translations }) {
    const url = context.state.serverURI + '/api/v2/clients/' + clientId + '/translations'
    return (await axios.post(url, translations)).data
  }
}

export default actions
