<template>
  <div class="row pl-3">
    <div class="col-12">
      <button
        class="mr-1"
        :class="saveButton.class"
        :disabled="saveDisabled"
        @click.prevent="save"
      >
        <i
          class="mr-1"
          :class="saveButton.iconClass">
        </i>
        <span>{{ saveButton.label }}</span>
      </button>
      <button
        v-if="!disableSaveAndClose"
        class="mr-1"
        type="submit"
        :disabled="saveDisabled"
        :class="saveAndCloseButton.class"
        @click.prevent="saveAndClose"
      >
        <i
          class="mr-1"
          :class="saveAndCloseButton.iconClass">
        </i>
        <span>{{ saveAndCloseButton.label }}</span>
      </button>
      <button
        :class="cancelButton.class"
        @click.prevent="cancel"
      >
        <i
          class="mr-1"
          :class="cancelButton.iconClass">
        </i>
        <span>{{ cancelButton.label }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SaveBar',
  props: {
    saveDisabled: {
      type: Boolean,
      default: false
    },
    disableSaveAndClose: {
      type: Boolean,
      default: false
    },
    saveButton: {
      type: Object,
      default: () => ({
        iconClass: 'fas fa-save',
        label: 'save',
        class: 'btn btn-success'
      })
    },
    saveAndCloseButton: {
      type: Object,
      default: () => ({
        iconClass: 'fas fa-save',
        label: 'save + close',
        class: 'btn btn-success'
      })
    },
    cancelButton: {
      type: Object,
      default: () => ({
        iconClass: 'fas fa-times',
        label: 'cancel',
        class: 'btn btn-default'
      })
    }
  },
  methods: {
    save () {
      this.$emit('save')
    },
    saveAndClose () {
      this.$emit('saveAndClose')
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
