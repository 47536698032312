<template>
  <section class="educationitems-list container-fluid">
    <div class="row mb-3 pt-3">
      <div class="col mr-auto">
        <!--form id="search" class="ui form" @submit.prevent>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text"><i class="fas fa-search"></i></div>
            </div>
            <input name="query" v-model="query" class="form-control" placeholder="Search educationitems">
          </div>
        </form-->
      </div>
      <div class="col-auto">
        <router-link :to="{ name: 'EducationitemCreate', params: { client: $route.params.client } }" class="btn btn-success" title="Create new educationitem">
          <i class="fas fa-plus"></i>
          Create new educationitem
        </router-link>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col">
        <table class="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th scope="col" v-for="key in gridColumns" :key="key" @click="sortBy(key)" :class="{ active: sortKey == key }">
                {{ key | capitalize }}
                <i v-if="sortOrders[key] > 0" class="fas fa-caret-down"></i>
                <i v-else class="fas fa-caret-up"></i>
              </th>
              <th scope="col" class="w-1 text-center text-nowrap">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filteredData" :key="index" @click="editEducationitem(item)" style="cursor:pointer" title="edit educationitem">
              <td v-for="key in gridColumns" :key="key">
                {{ item[key] }}
              </td>
              <td class="w-1 text-center text-nowrap">
                <button class="btn btn-sm btn-danger" @click.stop.prevent="deleteItem(item, index)" title="delete educationitem">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->

    <div class="row">
      <div class="col">
        <item-tree :educationitems="educationitems" @delete-item="deleteItem"></item-tree>
      </div>
    </div>

  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemTree from './ItemTree.vue'
export default {
  name: 'listEducationitems',
  components: { ItemTree },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  data () {
    return {
      // query: ''
    }
  },
  computed: {
    ...mapGetters({
      educationitems: 'getEducationitemsList'
    }),
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    }
  },
  created () {
    this.$store.commit('SET_EDUCATIONITEMS_LIST', false)
    this.getEducationitems()
  },
  methods: {
    editEducationitem (item) {
      this.$router.push('/clients/' + this.$route.params.client + '/educationitems/' + item.id)
    },
    getEducationitems (reload = false) {
      if (!this.educationitems || reload) {
        this.isLoading = true
        this.$store
          .dispatch('getEducationitemsList', this.$route.params.client)
          .then(response => {
            this.isLoading = false
          }).catch(error => {
            this.isLoading = false
            this.error = true
            this.$swal({
              title: 'Error',
              text: error,
              icon: 'error'
            })
          })
      }
    },
    deleteItem (item) {
      this
        .$swal({
          title: 'Are you really sure?',
          text: 'The educationitem "' + item.title + '" will be deleted. This cannot be undone!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#dd4b39',
          cancelButtonText: 'Cancel'
        })
        .then(
          result => {
            if (result.value) {
              this.isLoading = true
              const data = {
                client: this.$route.params.client,
                item: item
              }
              this.$store
                .dispatch('deleteEducationitem', data)
                .then(response => {
                  this.$swal({
                    title: 'Success',
                    text: 'Educationitem "' + item.name + '" has been deleted',
                    icon: 'success',
                    showConfirmButton: false,
                    toast: true,
                    position: 'top',
                    timer: 2000
                  })
                  this.isLoading = false
                  this.getEducationitems(true)
                }).catch(error => {
                  this.isLoading = false
                  this.error = true
                  this.$swal({
                    title: 'Error',
                    text: error,
                    icon: 'error'
                  })
                })
            }
          }
        )
    }
  }
}
</script>

<style style lang="scss">
.educationitems-list {
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background: white;

  thead {
    background-color: #3c8dbc;
    color: white;
  }

}
</style>
