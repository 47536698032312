<template>
  <div class="form-group form-check former-input" :class="[ {'former-has-value': model[field.key], 'has-error': hasError}, field.className]">
    <input :id="'cb_'+this.field.key" v-model="model[field.key]" type="checkbox" class="form-check-input" @blur="onBlur" @focus="onFocus" @click="onClick" @change="onChange" @keyup="onKeyup" @keydown="onKeydown">
    <label v-if="to.label" class="form-check-label" :for="'cb_'+this.field.key">{{to.label}}</label>
    <small v-if="to.help"><br>{{ to.help }}</small>
    <error-display :form="form" :field="field.key"></error-display>
  </div>
</template>

<script>
import baseField from './baseField'
export default {
  name: 'formerCheckbox',
  mixins: [baseField],
  mounted () {
    this.model[this.field.key] = this.model[this.field.key] || this.field.default || false
    //  if (this.model[this.field.key]) {
    //    this.model[this.field.key] = true
    //  } else {
    //    this.model[this.field.key] = false
    //  }
  },
  methods: {
    onChange: function (e) {

      // this.$set(this.form[this.field.key], '$dirty', true);
      // this.runFunction('onChange', e);

    }
  }
}
</script>
