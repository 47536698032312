
<ValidationObserver
  v-slot="{ invalid }"
  tag="section"
  class="container-fluid"
>
  <div class="row">
    <div class="col-12">
      <h2>{{ group.data.title }}</h2>
    </div>
  </div>

  <div class="row">
    <form name="create-group" class="form col-12">
      <input v-model="group.data.id" type="hidden" name="id" />
      <b-card no-body class="row">
        <b-tabs v-model="activeTab" card>
          <b-tab>
            <template slot="title">
              <i class="fa fa-database"></i> General
            </template>
            <div class="row">
              <div class="col-12 mb-4">
                <div class="card">
                  <h4 class="card-header">Group Settings</h4>
                  <div class="card-body">
                    <div class="row">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="title"
                        tag="div"
                        class="col-6"
                      >
                        <div
                          class="form-group"
                          :class="{ 'has-error': errors[0] }"
                        >
                          <label for="title">Title: *</label>
                          <input
                            id="title"
                            v-model.trim="group.data.title"
                            type="text"
                            class="form-control"
                            required="required"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="name"
                        tag="div"
                        class="col-6"
                      >
                        <div
                          class="form-group"
                          :class="{ 'has-error': errors[0] }"
                        >
                          <label for="name">Name: *</label>
                          <input
                            id="name"
                            v-model.trim="group.data.name"
                            type="text"
                            class="form-control"
                            required="required"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                      <div class="form-group col-12">
                        <label for="description">Group description</label>
                        <froala-editor
                          id="description"
                          v-model="group.data.description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 mb-4">
                <div class="card">
                  <h4 class="card-header">Group Assets</h4>
                  <div class="card-body">
                    <div class="row col-12" style="gap: 16px;">
                      <SingleAsset
                        :item="logoAsset"
                        label="Logo"
                        helpText="Set custom group logo"
                        :useFullSize="false"
                        @input="item => setAsset({ ...logoAsset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="headerBannerAsset"
                        label="Header Banner"
                        helpText="Banner for group pages"
                        :useFullSize="false"
                        @input="item => setAsset({ ...headerBannerAsset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="headerBannerMobileAsset"
                        label="Header Banner (mobile)"
                        helpText="Banner for short resolutions"
                        :useFullSize="false"
                        @input="item => setAsset({ ...headerBannerMobileAsset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="teaserAsset"
                        label="Teaser"
                        helpText=""
                        :useFullSize="false"
                        @input="item => setAsset({ ...teaserAsset, asset: item.asset })"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fas fa-home"></i> Startpage
            </template>
            <edit-page
              v-if="group.data.id"
              pageType="home"
              :relation="group"
              relationType="group"
              :clientId="group.data.client_id"
            ></edit-page>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fas fa-university"></i> Library
            </template>
            <edit-page
              v-if="group.data.id"
              pageType="library"
              :relation="group"
              relationType="group"
              :clientId="group.data.client_id"
            ></edit-page>
            <edit-library class="mt-4" :group="group"></edit-library>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fas fa-sitemap"></i> Navigation
            </template>
            <edit-navigation :group="group"></edit-navigation>
          </b-tab>
        </b-tabs>
      </b-card>
    </form>
  </div>

  <div class="row">
    <div class="col-12">
      <br />
    </div>
    <SaveBar
      :saveDisabled="invalid"
      @save="updateGroup(false)"
      @saveAndClose="updateGroup(true)"
      @cancel="cancel"
    />
  </div>

  <image-panel
    v-if="group.data.client_id"
    :clientId="group.data.client_id"
  ></image-panel>
</ValidationObserver>
