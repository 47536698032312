
<ValidationObserver v-slot="{ invalid }" tag="section" class="container-fluid">
  <div class="row box">
    <div class="col-12">
      <h2>Edit education item</h2>
      <form name="edit-module" role="form">
        <input v-if="educationitemData.id" v-model="educationitemData.id" type="hidden" name="id">
        <div class="row">
          <ValidationProvider v-slot="{ errors }" name="title" tag="div" class="col-6">
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="title">Educationitem Title: *</label>
              <input id="title" v-model.trim="educationitemData.title" type="text" class="form-control" required="required">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <div v-if="educationitemData.slug" class="col-6">
            <div class="form-group">
              <label for="slug">slug:</label>
              <input id="slug" v-model.lazy.trim="educationitemData.slug" type="text" class="form-control" name="slug">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-9">
            <div class="form-group">
              <label for="parent">Parent:</label>
              <select id="parent" v-model.lazy="educationitemData.parent_id" name="parent" class="form-control">
                <option value="">select a Parent Educationitem</option>
                <option v-for="item in educationitems" :key="item.id" :value="item.id"> {{ item.title }}</option>
              </select>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <label for="draft">draft-mode:</label><br>
              <input id="draft" v-model.lazy.trim="educationitemData.draft" type="checkbox" name="draft" value="1">
            </div>
          </div>
          <div class="col-1">
            <label>&nbsp;</label><br>
            <a :href="'http://' + $route.params.client + '.' + dcrFrontendHost + '/education/' + educationitemData.slug" target="_blank" class="btn btn-default"><i class="fas fa-eye"></i></a>
          </div>
        </div>
      </form>
    </div>
    <div class="col-12 mt-4">
      <edit-modules title="educationitem content" :availableModules="availableModules" :data="educationitemData"></edit-modules>
    </div>
    <SaveBar
      :saveDisabled="invalid"
      @save="saveEducationitem(close = false)"
      @saveAndClose="saveEducationitem(close = true)"
      @cancel="cancel"
    />
  </div>
</ValidationObserver>
