
<div class="row">
  <div class="col-12">
    <div class="card">
      <h4 class="card-header">
        Pagecontent for {{ pageType }}
      </h4>
      <div class="card-body">
        <div v-if="page">
          <grid-layout
            :layout="layout"
            :col-num="12"
            :row-height="30"
            :is-draggable="true"
            :is-resizable="true"
            :vertical-compact="true"
            :margin="[10, 10]"
            :use-css-transforms="true"
          >
            <grid-item
              v-for="(item, key) in layout"
              :key="item.i"
              ref="layout"
              class="card"
              :class="{'text-light bg-secondary border-none': item.hide, 'border-secondary': !item.hide} "
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
              drag-allow-from=".vue-draggable-handle"
              drag-ignore-from=".no-drag"
              @resized="updateLayout"
              @moved="updateLayout"
            >
              <div :class="{'bg-transparent': item.hide} " class="card-header vue-draggable-handle">
                <template v-if="moduleSelected(key)">
                  <span v-if="getModuleName(page.pagewidgets[key])" class="text-nowrap">
                    <strong>
                      {{getModuleName(page.pagewidgets[key])}}
                    </strong>
                  </span>

                </template>
                <span v-else class="text-nowrap">new module</span>
                <button type="button" class="close no-drag" title="remove" aria-label="remove" @click.prevent="removeItem(item)">
                  <span aria-hidden="true">&times;</span>
                </button>
                <span
                  v-if="moduleSelected(key)"
                  class="float-right no-drag mr-4 mt-1"
                >
                  <input :id="'checkbox-'+pageType+item.i" v-model="item.hide" type="checkbox" @change="updateLayout">
                  <label :for="'checkbox-'+pageType+item.i">hide</label>
                </span>
                <div v-if="moduleSelected(key)">
                  <span class="text-nowrap">
                    <div
                      class="badge badge-dark"
                    >
                      <span>{{page.pagewidgets[key].name}}</span>
                    </div>
                  </span>
                </div>
              </div>
              <div class="card-body">
                <div v-if="moduleSelected(key)" class="row">
                  <div class="col">
                    <button class="btn btn-primary" @click.prevent="showModuleSettings(page.pagewidgets[key],key)">
                      <i class="fas fa-pencil-alt"></i><span class="d-none d-xl-inline"> settings</span>
                    </button>
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-primary mr-1" title="clone module" @click.prevent="cloneModule(item, key)">
                      <i class="fas fa-copy"></i>
                    </button>
                    <button class="btn btn-danger" @click.prevent="removeModule(key)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
                <div v-else class="form-group">
                  <select id="module" v-model="page.pagewidgets[key]" class="form-control" @change.prevent="setLayout(key, item)">
                    <option value="undefined" selected disabled>Please select module template</option>
                    <option v-for="module in availableModules" :key="module.name + key" :value="JSON.parse(JSON.stringify(module))">{{module.name}}</option>
                  </select>
                </div>
              </div>
            </grid-item>
          </grid-layout>
          <div class="row">
            <div class="col-12 text-center">
              <button class="btn btn-primary" @click.prevent="addModule">
                <i class="fa fa-plus"></i> add module
              </button>
            </div>
          </div>
        </div>
        <div v-else class="container-fluid">
          <div class="row form-inline">
            <div class="col-12 text-center">
              <button class="btn btn-primary" :disabled="invalid" @click.prevent="showCreatePageModal = true">
                Create New Page
              </button>
            </div>
          </div>
        </div>

        <div v-if="page" class="col-12">
          <hr>
        </div>

        <div v-if="page" class="col-12 text-center">
          <button class="btn btn-success mr-1" @click.prevent="updatePage(close = true)">
            <i class="fas fa-save mr-1"></i> save page
          </button>
          <button class="btn btn-primary mr-1" @click.prevent="openCreatePageTemplateModal">
            <i class="far fa-file-alt mr-1"></i> create template
          </button>
          <button class="btn btn-danger mr-1" @click.prevent="deletePage(page.id)">
            <i class="fas fa-trash mr-1"></i> delete page
          </button>
        </div>

        <edit-module-modal
          :id="editModuleModalId"
          :title="'Edit Module Settings for: ' + editModule"
          :module="module"
          :moduletype="editModule || ''"
          @cancelAction="resetModule()"
          @updateAction="saveModule($event)"
          />

        <create-page-modal
          v-if="showCreatePageModal"
          :clientId="clientId"
          :pageType="pageType"
          @createPage="createPage($event)"
          @close="showCreatePageModal = false"
          />

        <CreatePageTemplateModal
          v-if="page"
          :id="createPageTemplateModalId"
          :page="page"
          :clientId="clientId"
          @cancel="closeCreatePageTemplateModal"
          @create="closeCreatePageTemplateModal"
        />
      </div>
    </div>
  </div>
</div>

