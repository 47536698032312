import store from '../store'
import router from './'

// Some middleware to help us ensure the user is authenticated.
router.beforeEach((to, from, next) => {
  if (to.meta.auth && (store.state.token === null || store.state.token === 'null')) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
})
