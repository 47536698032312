<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }" tag="section" class="container-fluid">
    <div class="box row">
      <div class="col-12">
        <h2>Create new Project</h2>
      </div>
      <div class="col-md-12">

        <form>
          <div class="row align-items-center">
            <ValidationProvider v-slot="{ errors }" vid="title" name="project title" tag="div" class="col-xl col-md-12">
              <div class="form-group" :class="{'has-error': errors[0]}">
                <label for="title">Title *</label>
                <input id="title" v-model="project.title" type="text" class="form-control" required="required">
                <small class="text-danger">{{ errors[0] }}</small>
                <b-form-text id="input-live-help">human readable, e.g. Congress 2018</b-form-text>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" vid="name" name="project name" tag="div" class="col-xl col-md-12">
              <div class="form-group" :class="{'has-error': errors[0]}">
                <label for="name">Name *</label>
                <input id="name" v-model="project.name" type="text" class="form-control" name="name" required="required">
                <small class="text-danger">{{ errors[0] }} </small>
                <b-form-text id="input-live-help">URL friendly, e.g. congress2018</b-form-text>
              </div>
            </ValidationProvider>
          </div>

          <div class="row align-items-center">
            <div class="col-xl col-md-12">
              <div class="form-check">
                <input id="previewCheckbox" v-model="project.preview" class="form-check-input" type="checkbox">
                <label class="form-check-label" for="previewCheckbox">preview</label>
              </div>
              <b-form-text id="input-live-help">Project will be hidden from automatic lists and search results but will be directly accessible via project URL</b-form-text>
            </div>
            <div class="col-xl col-md-12">
              <label>Project URL</label>
              <div class="text-muted">{{projectUrl}}</div>
            </div>
          </div>

          <hr>

          <div v-if="error" class="row">
            <div class="col">
              <div class="alert alert-danger" role="alert">
                <i class="fas fa-exclamation-triangle"></i> {{ errorMsg }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <h4>Data Source</h4>
            </div>
          </div>

          <ValidationProvider v-slot="{ errors }" vid="collection" name="collection" tag="div" class="form-row align-items-center">
            <div class="col-xl col-md-12 form-group" :class="{'has-error': errors[0]}">
              <div v-if="collections">
                <label for="collection">Collection *</label>
                <select id="collection" v-model="project.collection" class="form-control" required="required" @change="collectionChangeHandler">
                  <option value="" selected>Please select collection</option>
                  <option v-for="collection in collections" :key="collection.id" :value="collection.id">{{ collection.title + ' (' + collection.id + ')' }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
              <div v-else class="col">
                <button class="btn btn-success text-center" @click.prevent="getCollections">find Collections</button>
              </div>
            </div>
          </ValidationProvider>

          <div class="row">
            <div class="col-12">
              <h5>Live Content</h5>
            </div>
          </div>

          <div class="form-row align-items-center">
            <div class="col-xl col-md-12 form-group">
              <label for="liveDistribution">Distribution</label>
              <div v-if="liveDistributions.length > 0">
                <select id="liveDistribution" v-model="project.liveDistribution" name="liveDistribution" class="form-control">
                  <option value="" selected>Please select live distribution</option>
                  <option v-for="liveDistribution in liveDistributions" :key="liveDistribution.id" :value="liveDistribution.id">{{ (liveDistribution.shortname || liveDistribution.title) + ' (' + liveDistribution.id + ')' }}</option>
                </select>
              </div>
              <div v-else class="col">
                No live distributions defined for collection.
              </div>
            </div>
          </div>

          <div class="form-row align-items-center">
            <div class="col-xl col-md-12 form-group">
              <label>Channels</label><br>
              <div v-if="channels.length > 0" class="d-flex flex-wrap">
                <button v-for="(channel, index) in channels" :key="index" style="min-width:8em;" class="flex-fill btn mr-1 mb-1" :class="channel.active ? 'btn-success' : 'btn-warning'" @click.prevent="changeChannelStatus(index)">{{ channel.title }}</button>
                <div class="row">
                  <div class="col">
                    <button class="btn btn-light" @click.prevent="selectAllChannels">select all channels</button>
                    <button class="btn btn-light" @click.prevent="deselectAllChannels">deselect all channels</button>
                  </div>
                </div>
              </div>
              <div v-else class="col">
                No channels found for distribution.
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <h5>On Demand Content</h5>
            </div>
          </div>

          <div class="form-row align-items-center">
            <ValidationProvider v-slot="{ errors }" vid="ondemandDistribution" name="ondemand distribution" slim>
              <div class="col-xl col-md-12 form-group">
                <label for="ondemandDistribution">Distribution</label>
                <div v-if="ondemandDistributions.length > 0">
                  <select id="ondemandDistribution" v-model="project.ondemandDistribution" name="ondemandDistribution" class="form-control" @change="ondemandDistributionChangeHandler">
                    <option value="" selected>Please select ondemand distribution</option>
                    <option v-for="ondemandDistribution in ondemandDistributions" :key="ondemandDistribution.id" :value="ondemandDistribution.id">{{ (ondemandDistribution.shortname || ondemandDistribution.title) + ' (' + ondemandDistribution.id + ')' }}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
                <div v-else class="col">
                  No ondemand distributions defined for collection.
                </div>
              </div>
            </ValidationProvider>
          </div>

          <div class="form-row align-items-center">
            <div class="col-xl col-md-12">
              <div class="form-group">
                <label for="filter">Filter for Congressresources</label>
                <div class="input-group">
                  <input id="filter" v-model="searchindex.filterPart1" type="text" class="form-control" name="filterPart1">
                  <div class="input-group-append input-group-prepend">
                    <span class="input-group-text">!</span>
                  </div>
                  <input v-model="searchindex.filterPart2" type="text" class="form-control" name="filterPart2">
                </div>
                <small id="searchindexfilter" class="form-text text-muted">
                  {{searchindexFilter}}
                </small>
              </div>
            </div>

            <div class="col-xl col-md-12">
              <div class="form-group">
                <label for="eventfilter">Filter for Events</label>
                <div class="input-group">
                  <input id="eventfilter" v-model="searchindex.eventfilterPart1" type="text" class="form-control" name="eventfilterPart1">
                  <div class="input-group-append input-group-prepend">
                    <span class="input-group-text">!</span>
                  </div>
                  <input v-model="searchindex.eventfilterPart2" type="text" class="form-control" name="eventfilterPart2">
                </div>
                <small id="searchindexEventfilter" class="form-text text-muted">
                  {{searchindexEventfilter}}
                </small>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-md-12">
              <label for="timezone">Timezone</label>
              <v-select id="timezone" v-model="project.timezone" :options="timezones" :reduce="label => label.value" label="label"></v-select>
            </div>
          </div>

          <hr>

          <SaveBar
            :saveDisabled="invalid"
            disableSaveAndClose
            @save="saveProject"
            @cancel="cancel"
          />
        </form>

      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ProjectCreate',
  data () {
    return {
      project: {},
      error: false,
      errorMsg: '',
      collections: [],
      channels: [],
      liveDistributions: [],
      ondemandDistributions: [],
      searchindex: {}
    }
  },
  computed: {
    ...mapGetters({
      client: 'getClient'
    }),
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    },
    projectUrl () {
      return 'https://' + this.$route.params.client + '.' + import.meta.env.VITE_DCR_FRONTEND_HOST + '/' + (this.project.name || '...')
    },
    timezones () {
      return this.$store.getters.getTimezones.map(tz => {
        return { label: '(UTC ' + tz.offsetString + ') ' + tz.name, value: tz.name }
      })
    },
    searchindexFilter () {
      if (!this.searchindex.filterPart1 && !this.searchindex.filterPart2) {
        return ''
      }
      return this.searchindex.filterPart1 + '!' + this.searchindex.filterPart2
    },
    searchindexEventfilter () {
      if (!this.searchindex.eventfilterPart1 || !this.searchindex.eventfilterPart2) {
        return ''
      }
      return this.searchindex.eventfilterPart1 + '!' + this.searchindex.eventfilterPart2
    }

  },
  watch: {
    client (val) {
      if (val) {
        if (val.data && val.data.id) {
          this.$set(this.project, 'client_id', this.client.data.id)
        }
        if (val.data && val.data.apiClient) {
          this.$set(this.project, 'apiClient', this.client.data.apiClient)
        }
        if (val.data && val.data.apiClientSecret) {
          this.$set(this.project, 'apiClientSecret', this.client.data.apiClientSecret)
        }
      }
    }
  },
  created () {
    if (this.client && this.client.data && this.client.data.id) {
      this.$set(this.project, 'apiClient', this.client.data.apiClient)
      this.$set(this.project, 'apiClientSecret', this.client.data.apiClientSecret)
    } else {
      this.getClientData(this.$route.params.client)
    }
    this.getCollections()
  },
  methods: {
    getClientData (client) {
      this.isLoading = true
      this.isLoading = true
      this.$store
        .dispatch('getClientData', client)
        .then(response => {
          this.isLoading = false
        })
    },
    getCollections () {
      const params = {
        apiClient: this.project.apiClient,
        apiClientSecret: this.project.apiClientSecret
      }
      this.isLoading = true
      this.$store
        .dispatch('getCollections', params)
        .then(
          response => {
            this.collections = response.collections
            this.isLoading = false
          },
          response => { // error
            this.$swal({
              title: 'Error',
              text: 'Could not load collections',
              icon: 'error'
            })
            this.isLoading = false
          })
        .then(() => {
          this.project.collection = ''
        })
    },
    getDistributions () {
      const params = {
        apiBaseUrl: this.project.apiBaseUrl,
        apiTokenBaseUrl: this.project.apiTokenBaseUrl,
        apiClient: this.project.apiClient,
        apiClientSecret: this.project.apiClientSecret,
        collection: this.project.collection,
        apiVersion: 'v2'
      }
      this.$store
        .dispatch('getDistributions', params)
        .then(
          response => {
            this.liveDistributions = response.distributions.filter(distribution => distribution.isLive)
            this.ondemandDistributions = response.distributions.filter(distribution => distribution.isOndemand)
            this.isLoading = false

            this.project.liveDistribution = ''
            this.project.ondemandDistribution = ''
          },
          response => { // error
            this.$swal({
              title: 'Error',
              text: 'Could not load distributions',
              icon: 'error'
            })
            this.isLoading = false
          })
        .then(() => {
          this.initSearchindexFilterPart1()
          this.getChannels()
        })
    },
    getChannels () {
      this.isLoading = true
      const params = {
        apiClient: this.project.apiClient,
        apiClientSecret: this.project.apiClientSecret
      }

      if (this.project.liveDistribution) {
        params.distribution = this.project.liveDistribution
      } else {
        params.collection = this.project.collection
      }

      this.$store
        .dispatch('getChannels', params)
        .then(
          response => {
            this.channels = response.channels
          },
          response => { // error
            this.$swal({
              title: 'Error',
              text: 'Could not load channels',
              icon: 'error'
            })
          }
        )
        .finally(
          this.isLoading = false
        )
    },
    collectionChangeHandler () {
      this.setProjectDate()
      this.getDistributions()
      this.initSearchindexFilterPart2()
    },
    liveDistributionChangeHandler () {
      this.getChannels()
    },
    ondemandDistributionChangeHandler () {
      this.initSearchindexFilterPart1()
    },
    changeChannelStatus (channelsIndex) {
      if (this.channels[channelsIndex].active) {
        this.$set(this.channels[channelsIndex], 'active', false)
      } else {
        this.$set(this.channels[channelsIndex], 'active', true)
      }
      this.project.channels = this.channels
    },
    selectAllChannels () {
      for (let i = 0; i <= this.channels.length - 1; i++) {
        this.$set(this.channels[i], 'active', true)
      }
      this.project.channels = this.channels
    },
    deselectAllChannels () {
      for (let i = 0; i <= this.channels.length - 1; i++) {
        this.$set(this.channels[i], 'active', false)
      }
      this.project.channels = this.channels
    },
    initSearchindexFilterPart1 () {
      if (this.project.ondemandDistribution) {
        this.$set(this.searchindex, 'filterPart1', '[distribution.' + this.project.ondemandDistribution + ']')
        this.$set(this.searchindex, 'eventfilterPart1', '[distribution.' + this.project.ondemandDistribution + ']')
      } else {
        if (this.project.collection) {
          this.$set(this.searchindex, 'filterPart1', '[collection.' + this.project.collection + ']')
          this.$set(this.searchindex, 'eventfilterPart1', '[collection.' + this.project.collection + ']')
        }
      }

      this.$set(this.searchindex, 'filter', this.searchindexFilter)
      this.$set(this.searchindex, 'eventfilter', this.searchindexEventfilter)
    },
    initSearchindexFilterPart2 () {
      if (!this.searchindex.filterPart2) {
        this.$set(this.searchindex, 'filterPart2', '[status.6]')
      }
      if (!this.searchindex.eventfilterPart2) {
        this.$set(this.searchindex, 'eventfilterPart2', '[status.6]')
      }
      if (!this.searchindex.filterPart1 || !this.searchindex.eventfilterPart1) {
        this.initSearchindexFilterPart1()
      }
    },
    setProjectDate () {
      const date = this.collections.filter(collection => collection.id === this.project.collection).map(collection => collection.datestart)
      this.$set(this.project, 'date', date[0])
    },
    saveProject () {
      this.isLoading = true
      this.project.indexname = this.$route.params.client + '_' + this.project.name
      this.$set(this.searchindex, 'filter', this.searchindexFilter)
      this.$set(this.searchindex, 'eventfilter', this.searchindexEventfilter)
      this.project.searchindex = this.searchindex
      this.$store
        .dispatch('createProject', { client: this.$route.params.client, data: this.project })
        .then(response => {
          const projectCreatedAlert = new Promise((resolve, reject) => {
            this.$swal({
              title: 'Success',
              text: response.message,
              icon: 'success',
              showConfirmButton: false,
              toast: true,
              position: 'top',
              timer: 2000
            }).then(resolve).catch(reject)
          })
          this.createIndex(response.id, projectCreatedAlert)
          this.isLoading = false
          this.$router.push('/clients/' + this.$route.params.client + '/projects/' + response.id + '#api')
        }).catch(error => {
          this.$refs.observer.setErrors(error.response.data.errors)
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not update project: ' + error.response.data.message,
            icon: 'error',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
        })
    },
    async createIndex (projectId, projectCreatedAlert) {
      try {
        await this.$store.dispatch('createIndex', projectId)
        await projectCreatedAlert
        this.$swal({
          title: 'Success',
          text: 'index created',
          icon: 'success',
          showConfirmButton: false,
          toast: true,
          position: 'top',
          timer: 2000
        })
      } catch (error) {
        await projectCreatedAlert
        this.$swal({
          title: 'Error creating index',
          text: 'Index could not be created',
          icon: 'error',
          showConfirmButton: false,
          toast: true,
          position: 'top',
          timer: 2000
        })
      }
    },
    cancel () {
      this.$router.push('/clients/' + this.$route.params.client + '/projects')
    }
  }
}
</script>
<style>
.box {
  padding: 15px;
}

.error-text{
  color: #dc3545;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
