<template>
  <section class="content">
    <router-view></router-view>
  </section>
</template>

<script>
export default {
  name: 'advertisements',
  data () {
    return {
    }
  },
  computed: {
  }
}
</script>
