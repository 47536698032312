<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }" tag="section" class="content">
    <div class="box row">
      <div class="col-12">
        <h2>Create new user</h2>
        <form name="create-user" class="form row">
          <div class="col-6">
            <ValidationProvider v-slot="{ errors }" name="firstname" slim>
              <div class="form-group" :class="{'has-error': errors[0]}">
                <label for="firstname">Firstname: *</label>
                <input id="firstname" v-model.trim="user.firstname" type="text" class="form-control" required="required">
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" name="lastname" slim>
              <div class="form-group" :class="{'has-error': errors[0]}">
                <label for="lastname">Lastname: *</label>
                <input id="lastname" v-model.trim="user.lastname" type="text" class="form-control" required="required">
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
          </div>
          <ValidationProvider v-slot="{ errors }" name="email" tag="div" class="col-6">
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="email">E-Mail: *</label>
              <input id="email" v-model.trim="user.email" type="email" class="form-control" required="required" autocomplete="new-mail">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" vid="pass" name="password" tag="div" class="col-6">
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="pass">Password: *</label>
              <input id="pass" v-model="user.pass" type="password" class="form-control" required="required" autocomplete="new-password">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" vid="pass_confirmation" name="password confirmation" tag="div" class="col-6">
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="pass_confirmation">Confirm Password: *</label>
              <input id="pass_confirmation" v-model="user.pass_confirmation" type="password" class="form-control" required="required" autocomplete="new-password">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="userrole" tag="div" class="col-6">
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="userrole">Userrole: *</label>
              <select id="userrole" v-model="user.userrole" name="userrole" class="form-control" required="required">
                <option value="">select a userrole</option>
                <option v-for="role in userroles" :key="role.id" :value="role.id">{{ role.name }}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <div class="col-6">
            <div class="form-group">
              <label for="usergroup">Usergroup:</label>
              <select id="usergroup" v-model="user.usergroup" name="usergroup" class="form-control">
                <option value="">select a usergroup</option>
                <option v-for="group in usergroups" :key="group.id" :value="group.id">{{ group.name }}</option>
              </select>
            </div>
          </div>
          <SaveBar
            :saveDisabled="invalid"
            disableSaveAndClose
            @save="createUser"
            @cancel="cancel"
          />
        </form>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
export default {
  data () {
    return {
      user: {
        firstname: '',
        lastname: '',
        email: '',
        pass: '',
        pass_confirmation: '',
        userrole: '',
        usergroup: ''
      },
      userroles: [],
      usergroups: []
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    }
  },
  created () {
    this.getUsergroups()
    this.getUserroles()
  },
  methods: {
    cancel () {
      this.$router.push('/users')
    },
    getUserroles () {
      if (!this.clients) {
        this.isLoading = true
        this.$store
          .dispatch('getUserroles')
          .then(response => {
            this.userroles = response
            this.isLoading = false
          })
      }
    },
    getUsergroups () {
      if (!this.clients) {
        this.isLoading = true
        this.$store
          .dispatch('getUsergroups')
          .then(response => {
            this.usergroups = response
            this.isLoading = false
          })
      }
    },
    createUser () {
      this.isLoading = true
      this.$store
        .dispatch('createUser', this.user)
        .then(response => {
          this.isLoading = false
          this.$swal({
            title: 'Success',
            text: 'User was created',
            icon: 'success',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
          this.$router.push('/users')
        }, error => {
          if (error.response.data.errors) {
            this.$refs.observer.setErrors(error.response.data.errors)
          }
          this.$swal({
            title: 'Error',
            text: 'could not create new user',
            icon: 'error',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
          this.isLoading = false
        })
    }
  }
}
</script>

<style>

</style>
