
<ValidationObserver v-slot="{ invalid }" tag="section" class="content">
  <div class="box row">
    <div class="col-12">
      <h2>Update module template</h2>
      <form name="create-module" class="form row">
        <input v-model="module.id" type="hidden" name="id">
        <ValidationProvider v-slot="{ errors }" name="name" tag="div" class="col-9">
          <div class="form-group" :class="{'has-error': errors[0]}">
            <label for="name">Name: *</label>
            <input id="name" v-model.trim="module.name" type="text" class="form-control" required="required">
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <div class="col-3">
          <div class="input-group">
            <label for="package_module">Use Module for Packages / Spotlights: </label>
            <input id="package_module" v-model.lazy.trim="module.package_module" type="checkbox" name="package_module" value="1">
          </div>
        </div>
        <ValidationProvider
          v-slot="{ errors }"
          name="model"
          tag="div"
          rules="required|json"
          class="col-12"
        >
          <div class="form-group" :class="{'has-error': errors[0]}">
            <label for="model">Model: *</label>
            <AceEditor
              v-model="module.model"
              height="350px"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="configuration"
          tag="div"
          rules="required|json"
          class="col-12"
        >
          <div class="form-group" :class="{'has-error': errors[0]}">
            <label for="configuration">Configuration: *</label>
            <AceEditor
              v-model="module.configuration"
              height="350px"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <SaveBar
          :saveDisabled="invalid"
          @save="updateModuleTemplate(close = false)"
          @saveAndClose="updateModuleTemplate(close = true)"
          @cancel="cancel"
        />
      </form>
    </div>
  </div>
</ValidationObserver>
