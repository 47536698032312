
<section>
  <div class="row">
    <div class="col-12">
      <h3>{{title}}</h3>
    </div>
  </div>
  <div v-if="showModules">
    <grid-layout
      :layout="layout"
      :col-num="12"
      :row-height="30"
      :is-draggable="true"
      :is-resizable="true"
      :vertical-compact="true"
      :margin="[10, 10]"
      :use-css-transforms="true"
    >
      <grid-item
        v-for="(item, key) in layout"
        :key="item.i"
        ref="layout"
        class="card"
        :class="{'text-light bg-secondary border-none': item.hide, 'border-secondary': !item.hide} "
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        drag-allow-from=".vue-draggable-handle"
        drag-ignore-from=".no-drag"
        @resized="updateLayout"
        @moved="updateLayout"
      >
        <div :class="{'bg-transparent': item.hide} " class="card-header vue-draggable-handle">
          <template v-if="moduleSelected(key)">
            <span v-if="getModuleName(data.modules[key])" class="text-nowrap">
              <strong>
                {{getModuleName(data.modules[key])}}
              </strong>
            </span>
          </template>
          <span v-else class="text-nowrap">new module</span>
          <button type="button" class="close no-drag" title="remove" aria-label="remove" @click.prevent="removeItem(item)">
            <span aria-hidden="true">&times;</span>
          </button>
          <span
            v-if="moduleSelected(key)"
            class="float-right no-drag mr-4 mt-1"
          >
            <input :id="'checkbox-'+title+item.i" v-model="item.hide" type="checkbox" @change="updateLayout">
            <label :for="'checkbox-'+title+item.i">hide</label>
          </span>
          <div v-if="moduleSelected(key)">
            <span class="text-nowrap">
              <div
                class="badge badge-dark"
              >
                <span>{{data.modules[key].name}}</span>
              </div>
            </span>
          </div>
        </div>
        <div class="card-body">
          <div v-if="moduleSelected(key)" class="row">
            <div class="col">
              <button class="btn btn-primary" @click.prevent="showModuleSettings(data.modules[key],key)">
                <i class="fas fa-pencil-alt"></i><span class="d-none d-xl-inline"> settings</span>
              </button>
            </div>
            <div class="col-auto">
              <button class="btn btn-primary" title="clone module" @click.prevent="cloneModule(item, key)">
                <i class="fas fa-copy"></i>
              </button>
              <button class="btn btn-danger" @click.prevent="removeModule(key)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
          <div v-else class="form-group">
            <select id="module" v-model="data.modules[key]" class="form-control" @change.prevent="setLayout(key, item)">
              <option value="undefined" selected disabled>Please select module template</option>
              <option v-for="module in availableModules" :key="module.name + key" :value="JSON.parse(JSON.stringify(module))">{{module.name}}</option>
            </select>
          </div>
        </div>
      </grid-item>
    </grid-layout>
    <div class="row">
      <div class="col-12 text-center">
        <button class="btn btn-primary" @click.prevent="addRow">
          <i class="fa fa-plus"></i> add module
        </button>
      </div>
    </div>
  </div>
  <div v-else class="container-fluid">
    <div class="row form-inline">
      <div class="col-12 text-center">
        <button class="btn btn-primary" @click.prevent="showCreatePageModal = true">
          Create New Page
        </button>
      </div>
    </div>
  </div>

  <edit-module-modal
    :id="editModuleModalId"
    :title="'Edit Module Settings for: ' + editModule"
    :module="module"
    :moduletype="editModule || ''"
    @cancelAction="resetModule()"
    @updateAction="saveModule($event)"
    />

  <create-page-modal
    v-if="showCreatePageModal"
    :clientId="data.client_id"
    @createPage="createModules($event)"
    @close="showCreatePageModal = false"
    />

</section>
