<template>
  <div class="language-settings">
    <div v-if="isLoading" class="row">
      <div class="col-12 d-flex justify-content-center mb-3">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <h3>Language &amp; Translations</h3>
            <hr>
          </div>
        </div>
      </div>
      <div class="col-12">
        <p>
          <strong>Select languages for client</strong>
        </p>
      </div>
      <div class="col-12">
        <ul class="list-group">
          <li v-for="translation in translations" :key="translation.language_code" class="list-group-item clearfix">{{ translation.language_code }} - {{ translation.name }} :
            <button v-if="translation.default == false" class="btn btn-primary" @click.prevent="selectDefault(translation)">make default</button>
            <button class="btn btn-danger pull-right" style="margin-left:10px;" @click.prevent="deleteLanguage(translation)">
              <i class="fas fa-trash-alt"></i>
            </button>
            <button class="btn btn-warning pull-right" @click.prevent="editTranslation(translation)">
              <i class="fa fa-edit"></i> Open translation
            </button>
          </li>
          <li v-if="availableLanguages.length > 0" class="list-group-item">
            <select v-model="select" class="form-control">
              <option v-for="language in availableLanguages" :key="language.name" :value="language">{{ language.name }}</option>
            </select>
            <button class="btn btn-success" :disabled="!select" @click.prevent="addLanguage(select)">
              <i class="fa fa-plus"></i>
            </button>
          </li>
        </ul>
      </div>
      <div class="col-12">
        <hr>
      </div>
      <div class="col-12">
        <div class="form-group">
          <div class="form-check">
            <input id="useLanguageInSearch" v-model="client.data.useLanguageInSearch" class="form-check-input" type="checkbox" value="1">
            <label class="form-check-label" for="useLanguageInSearch"><i class="text-muted fas fa-search"></i> use language in search</label>
          </div>
        </div>
      </div>
      <div v-if="selectedTranslation || showSaveChangesButton" class="col-12">
        <Table v-if="selectedTranslation"
          :data="this.translationsOverwrites[selectedTranslation.name]"
          :columns="table.columns"
          :actionButton="table.actionButton"
          :disableSearch="false"
          :search="table.search"
          @rowClicked="table.clickHandler"
          @dataChanged="this.itemChanged"
        />

        <div class="pull-right">
          <button class="btn btn-success" @click.prevent="saveTranslations">
            <i class="fa fa-check"></i> Save Changes
          </button>
          <button class="btn btn-default" @click.prevent="cancelEditing">
            <i class="fas fa-close"></i> Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function transformTranslationForEdit (translation) {
  const result = []
  function flattenObject (prefix, object) {
    for (const key in object) {
      const value = object[key]
      if (typeof value === 'object') {
        flattenObject(prefix + key + '.', value)
        continue
      }

      const selectorKey = 'translations.' + prefix + key
      result.push({
        key: prefix + key,
        selector: selectorKey,
        default: value,
        overwrite: translation.overwrites[selectorKey] || ''
      })
    }
  }

  flattenObject('', translation.standard)

  return result
}

export default {
  name: 'language-settings',
  props: ['client'],
  data () {
    return {
      isLoading: true,
      select: null,
      showSaveChangesButton: false,
      selectedTranslation: null,
      translations: [],
      activeLang: false,
      translationsOverwrites: {},
      table: {
        columns: [
          {
            label: 'Key',
            property: 'key',
            searchable: true,
            sort: { enabled: true, default: true }
          },
          {
            label: 'Default',
            property: 'default',
            searchable: true,
            sort: { enabled: true }
          },
          {
            label: 'Overwrite',
            property: 'overwrite',
            searchable: true,
            editable: true,
            sort: { enabled: true }
          }
        ],
        clickHandler: (item) => undefined,
        search: {
          placeholder: 'Search in translations',
          disabled: false
        }
      }
    }
  },
  computed: {
    availableLanguages: {
      get () {
        // filter out the languages for which we already have translations
        if (!Array.isArray(this.$store.getters.getAvailableLanguages)) {
          return []
        }

        const languageIdsOfExistingTranslations = this.translations.map(translation => translation.language_id)
        return this.$store.getters.getAvailableLanguages.filter(language => {
          return languageIdsOfExistingTranslations.indexOf(language.id) === -1
        })
      }
    }
  },
  created () {
    this.fetchData(this.$route.params.client)
  },
  methods: {
    fetchData (clientName) {
      this.isLoading = true
      this.$store
        // we cannot assume that any of the crucial properties of the inherited client are populated yet
        .dispatch('getClientData', clientName)
        .then(() => {
          return this.$store
            .dispatch('getTranslations', this.client.data.id)
        })
        .then(response => {
          this.translations = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch trainslations: ' + error,
            icon: 'error'
          })
        })
    },
    editTranslation (selectedTranslation) {
      const translationLanguageName = selectedTranslation.name
      if (!this.translationsOverwrites[translationLanguageName]) {
        this.translationsOverwrites[translationLanguageName] = transformTranslationForEdit(selectedTranslation)
      }
      this.selectedTranslation = selectedTranslation
    },
    addLanguage (language) {
      this.isLoading = true
      this.$store
        .dispatch('getTranslationDefaults', language.short_code)
        .then(response => {
          this.translations.push(response)
          this.showSaveChangesButton = true
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch trainslation defaults: ' + error,
            icon: 'error'
          })
        })
    },
    deleteLanguage (translation) {
      const message = translation.default
        ? 'These are the translations for the default language "' + translation.name + '" - delete anyway?'
        : 'The translations for "' + translation.name + '" will be deleted.'

      this
        .$swal({
          title: 'Are you sure?',
          text: message,
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#dd4b39',
          cancelButtonText: 'Cancel'
        })
        .then(result => {
          if (result.value) {
            this.showSaveChangesButton = true

            if (this.selectedTranslation === translation) {
              this.selectedTranslation = null
            }
            delete this.translationsOverwrites[translation.name]

            const removalIndex = this.translations.indexOf(translation)
            this.translations.splice(removalIndex, 1)
            this.$swal({
              title: 'Success',
              text: 'Language "' + translation.name + '" will be removed',
              icon: 'success',
              showConfirmButton: false,
              toast: true,
              position: 'top',
              timer: 2000
            })
          }
        })
    },
    itemChanged (item, newValue) {
      this.translationsOverwrites[this.selectedTranslation.name][item.meta.index].overwrite = newValue
    },
    selectDefault (newDefaultTranslation) {
      this.showSaveChangesButton = true
      this.translations.forEach(translation => {
        translation.default = translation === newDefaultTranslation
      })
    },
    cancelEditing () {
      this.selectedTranslation = null
    },
    saveTranslations () {
      const payload = []
      this.translations.forEach(translation => {
        const overwritesPayload = {}
        if (this.translationsOverwrites[translation.name]) {
          this.translationsOverwrites[translation.name].forEach(overwriteRow => {
            overwritesPayload[overwriteRow.selector] = overwriteRow.overwrite
          })
        } else {
          for (const key in translation.overwrites) {
            if (key.startsWith('translations.')) {
              overwritesPayload[key] = translation.overwrites[key]
            }
          }
        }

        payload.push({
          language_id: translation.language_id,
          default: translation.default,
          overwrites: overwritesPayload
        })
      })
      this.isLoading = true
      this.$store
        .dispatch('saveTranslations', { clientId: this.client.data.id, translations: payload })
        .then(response => {
          this.$swal({
            title: 'Success',
            text: 'successfully saved translations-overwrites',
            icon: 'success'
          })
          this.selectedTranslation = null
          this.showSaveChangesButton = false
          this.translations = response
          this.isLoading = false
        }, (response) => {
          this.$swal({
            title: 'Error',
            text: 'could not save translations-overwrites',
            icon: 'error'
          })
          this.isLoading = false
        })
    }
  }
}
</script>
<style lang="scss">
  .language-settings {
    table {
        display: flex;
        flex-flow: column;
        width: 100%;
        max-height: 700px;
    }

    thead {
        flex: 0 0 auto;
    }

    tbody {
        flex: 1 1 auto;
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
    }

    tr {
        width: 100%;
        display: table;
        table-layout: fixed;

        input {
          width: 100%;
        }
    }
  }
</style>
