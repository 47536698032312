
<ValidationObserver ref="observer" v-slot="{ invalid }" tag="section" class="container-fluid">
  <div class="box row">
    <div class="col-12">
      <h2>Create new Project</h2>
    </div>
    <div class="col-md-12">

      <form>
        <div class="row align-items-center">
          <ValidationProvider v-slot="{ errors }" vid="title" name="project title" tag="div" class="col-xl col-md-12">
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="title">Title *</label>
              <input id="title" v-model="project.title" type="text" class="form-control" required="required">
              <small class="text-danger">{{ errors[0] }}</small>
              <b-form-text id="input-live-help">human readable, e.g. Congress 2018</b-form-text>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" vid="name" name="project name" tag="div" class="col-xl col-md-12">
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="name">Name *</label>
              <input id="name" v-model="project.name" type="text" class="form-control" name="name" required="required">
              <small class="text-danger">{{ errors[0] }} </small>
              <b-form-text id="input-live-help">URL friendly, e.g. congress2018</b-form-text>
            </div>
          </ValidationProvider>
        </div>

        <div class="row align-items-center">
          <div class="col-xl col-md-12">
            <div class="form-check">
              <input id="previewCheckbox" v-model="project.preview" class="form-check-input" type="checkbox">
              <label class="form-check-label" for="previewCheckbox">preview</label>
            </div>
            <b-form-text id="input-live-help">Project will be hidden from automatic lists and search results but will be directly accessible via project URL</b-form-text>
          </div>
          <div class="col-xl col-md-12">
            <label>Project URL</label>
            <div class="text-muted">{{projectUrl}}</div>
          </div>
        </div>

        <hr>

        <div v-if="error" class="row">
          <div class="col">
            <div class="alert alert-danger" role="alert">
              <i class="fas fa-exclamation-triangle"></i> {{ errorMsg }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h4>Data Source</h4>
          </div>
        </div>

        <ValidationProvider v-slot="{ errors }" vid="collection" name="collection" tag="div" class="form-row align-items-center">
          <div class="col-xl col-md-12 form-group" :class="{'has-error': errors[0]}">
            <div v-if="collections">
              <label for="collection">Collection *</label>
              <select id="collection" v-model="project.collection" class="form-control" required="required" @change="collectionChangeHandler">
                <option value="" selected>Please select collection</option>
                <option v-for="collection in collections" :key="collection.id" :value="collection.id">{{ collection.title + ' (' + collection.id + ')' }}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
            <div v-else class="col">
              <button class="btn btn-success text-center" @click.prevent="getCollections">find Collections</button>
            </div>
          </div>
        </ValidationProvider>

        <div class="row">
          <div class="col-12">
            <h5>Live Content</h5>
          </div>
        </div>

        <div class="form-row align-items-center">
          <div class="col-xl col-md-12 form-group">
            <label for="liveDistribution">Distribution</label>
            <div v-if="liveDistributions.length > 0">
              <select id="liveDistribution" v-model="project.liveDistribution" name="liveDistribution" class="form-control">
                <option value="" selected>Please select live distribution</option>
                <option v-for="liveDistribution in liveDistributions" :key="liveDistribution.id" :value="liveDistribution.id">{{ (liveDistribution.shortname || liveDistribution.title) + ' (' + liveDistribution.id + ')' }}</option>
              </select>
            </div>
            <div v-else class="col">
              No live distributions defined for collection.
            </div>
          </div>
        </div>

        <div class="form-row align-items-center">
          <div class="col-xl col-md-12 form-group">
            <label>Channels</label><br>
            <div v-if="channels.length > 0" class="d-flex flex-wrap">
              <button v-for="(channel, index) in channels" :key="index" style="min-width:8em;" class="flex-fill btn mr-1 mb-1" :class="channel.active ? 'btn-success' : 'btn-warning'" @click.prevent="changeChannelStatus(index)">{{ channel.title }}</button>
              <div class="row">
                <div class="col">
                  <button class="btn btn-light" @click.prevent="selectAllChannels">select all channels</button>
                  <button class="btn btn-light" @click.prevent="deselectAllChannels">deselect all channels</button>
                </div>
              </div>
            </div>
            <div v-else class="col">
              No channels found for distribution.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h5>On Demand Content</h5>
          </div>
        </div>

        <div class="form-row align-items-center">
          <ValidationProvider v-slot="{ errors }" vid="ondemandDistribution" name="ondemand distribution" slim>
            <div class="col-xl col-md-12 form-group">
              <label for="ondemandDistribution">Distribution</label>
              <div v-if="ondemandDistributions.length > 0">
                <select id="ondemandDistribution" v-model="project.ondemandDistribution" name="ondemandDistribution" class="form-control" @change="ondemandDistributionChangeHandler">
                  <option value="" selected>Please select ondemand distribution</option>
                  <option v-for="ondemandDistribution in ondemandDistributions" :key="ondemandDistribution.id" :value="ondemandDistribution.id">{{ (ondemandDistribution.shortname || ondemandDistribution.title) + ' (' + ondemandDistribution.id + ')' }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
              <div v-else class="col">
                No ondemand distributions defined for collection.
              </div>
            </div>
          </ValidationProvider>
        </div>

        <div class="form-row align-items-center">
          <div class="col-xl col-md-12">
            <div class="form-group">
              <label for="filter">Filter for Congressresources</label>
              <div class="input-group">
                <input id="filter" v-model="searchindex.filterPart1" type="text" class="form-control" name="filterPart1">
                <div class="input-group-append input-group-prepend">
                  <span class="input-group-text">!</span>
                </div>
                <input v-model="searchindex.filterPart2" type="text" class="form-control" name="filterPart2">
              </div>
              <small id="searchindexfilter" class="form-text text-muted">
                {{searchindexFilter}}
              </small>
            </div>
          </div>

          <div class="col-xl col-md-12">
            <div class="form-group">
              <label for="eventfilter">Filter for Events</label>
              <div class="input-group">
                <input id="eventfilter" v-model="searchindex.eventfilterPart1" type="text" class="form-control" name="eventfilterPart1">
                <div class="input-group-append input-group-prepend">
                  <span class="input-group-text">!</span>
                </div>
                <input v-model="searchindex.eventfilterPart2" type="text" class="form-control" name="eventfilterPart2">
              </div>
              <small id="searchindexEventfilter" class="form-text text-muted">
                {{searchindexEventfilter}}
              </small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <label for="timezone">Timezone</label>
            <v-select id="timezone" v-model="project.timezone" :options="timezones" :reduce="label => label.value" label="label"></v-select>
          </div>
        </div>

        <hr>

        <SaveBar
          :saveDisabled="invalid"
          disableSaveAndClose
          @save="saveProject"
          @cancel="cancel"
        />
      </form>

    </div>
  </div>
</ValidationObserver>
