<template>
  <div class="row">
    <div class="form-group col-sm-12 col-md-12">
      <div class="form-group">
        <label>Hide Toolbar Elements</label>
        <small class="form-text text-muted">You can select and hide multiple elements on the Library Toolbar.</small>
      </div>
      <div class="form-group col-sm-12 col-md-12">
        <div v-for="(contentType, index) in hiddenContentTypes" :key="hiddenContentTypes[index].field" class="form-group">
          <input :id="hiddenContentTypes[index].field" v-model="hiddenContentTypes[index].value" type="checkbox" :name="hiddenContentTypes[index].field" class="form-check-input" @change="handleCheckboxChange()">
          <label :for="hiddenContentTypes[index].field">Hide {{ hiddenContentTypes[index].field }} Content Type</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HideToolbarElements',
  props: ['library', 'hiddenContentTypes'],
  data () {
    return {}
  },
  methods: {
    handleCheckboxChange () {
      if (this.library) {
        this.library.hiddenContentTypes = JSON.stringify(this.hiddenContentTypes)
      }
    }
  }
}
</script>
