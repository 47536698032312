<template>
  <div
    class="form-group former-assetlist"
    :class="[
      { 'former-has-value': hasValue, 'has-error': hasError },
      field.className,
    ]"
  >
    <div v-if="to.wrapper === 'panel'" class="card mb-3">
      <div class="card-header">
        <h5 v-if="to.title">{{ to.title }}</h5>
        <small v-if="to.help" class="mb-3">{{ to.help }}</small>
      </div>
      <div class="card-body p-3">
        <AssetList
          :items="items"
          :isMutable="true"
          :showLabel="true"
          :label="to.itemLabel"
          @input="(item) => setAsset(item)"
          @delete="(item) => deleteAsset(item)"
        />
      </div>
    </div>
    <div v-else>
      <h5 v-if="to.title">{{ to.title }}</h5>
      <small v-if="to.help" class="mb-3">{{ to.help }}</small>
      <AssetList
        :items="items"
        :isMutable="true"
        :showLabel="true"
        :label="to.itemLabel"
        @input="(item) => setAsset(item)"
        @delete="(item) => deleteAsset(item)"
      />
    </div>
    <error-display :form="form" :field="field.key"></error-display>
  </div>
</template>

<script>
import baseField from './baseField'

export default {
  name: 'former-assetlist',
  mixins: [baseField],
  data () {
    return {
      items: [],
      assetIds: []
    }
  },
  computed: {
    hasValue () {
      return !!this.model[this.field.key]
    }
  },
  watch: {
    assetIds (newVal) {
      this.model[this.field.key] = newVal
    }
  },
  created () {
    this.validateField()
    this.initalizeAssetIds()
    this.initalizeItems()
  },
  methods: {
    updateAssetIds () {
      this.assetIds = this.items.map((item) => {
        return item.asset
      })
    },
    setAsset (item) {
      const index = this.items.findIndex((element) => item.id === element.id)

      if (index !== -1) {
        this.$set(this.items, index, item)
      } else {
        this.items.push({
          ...item,
          name: `${this.items.length + 1}`
        })
      }
      this.updateAssetIds()
    },
    deleteAsset (item) {
      const index = this.items.findIndex((element) => item.id === element.id)
      this.$delete(this.items, index)
      this.updateAssetIds()
    },
    initalizeAssetIds () {
      this.assetIds = this.model[this.field.key]
    },
    initalizeItems () {
      this.items = this.assetIds.map((assetId, index) => {
        return {
          asset: assetId,
          name: (index + 1).toString(),
          id: this.$uuid.v4()
        }
      })
    },
    validateField () {
      if (this.model[this.field.key] === '') {
        this.model[this.field.key] = []
      }
    }
  }
}
</script>
