import { extend } from 'vee-validate'
import { required, integer } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'The {_field_} field is required.'
})

extend('integer', {
  ...integer,
  message: 'The {_field_} field must be an integer.'
})

extend('positiveInteger', {
  ...integer,
  message: 'The {_field_} field must be a positive integer.',
  validate (value) {
    return /^[1-9]\d*$/.test(value)
  }
})

export const IsJsonString = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const VALID_HOSTNAME_PATTERN = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/
// https://www.makeuseof.com/regular-expressions-validate-url/#:~:text=The%20regex%20will%20consider%20a,characters%20and%2For%20special%20characters.
const VALID_ABSOLUT_URL_PATTERN = /^(http(s)*:\/\/.)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{1,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/

extend('hostname', {
  validate: (value) => VALID_HOSTNAME_PATTERN.test(value),
  message: 'Input must be a valid hostname eg: project.customer.org'
})

extend('absolut_url', {
  validate: (value) => VALID_ABSOLUT_URL_PATTERN.test(value),
  message: 'Input must be a valid Url eg: https://testPage.com'
})

extend('client', {
  validate: (value) => !(!value),
  message: 'Please select a client from the list'
})

extend('IsJsonString', IsJsonString)

extend('capabilityJson', {
  validate: (value) => IsJsonString(value),
  message: 'The capability definition is not valid JSON.'
})

extend('json', {
  validate: (value) => IsJsonString(value),
  message: 'The {_field_} content is not valid JSON.'
})

extend('verify_name', value => {
  const clientNames = []
  if (value && clientNames.indexOf(value.toLowerCase()) < 0) {
    return true
  }

  return `There is already a client with that ${value}.`
})
