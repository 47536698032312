
<ValidationObserver v-slot="{ invalid }" tag="section" class="section">
  <div v-if="page" class="row">
    <div class="col-12 mb-3 d-flex align-items-center justify-content-between">
      <h2 class="m-0">{{ name }}</h2>
      <div class="badge" :class="[page.type === 'clientTemplate' ? 'badge-success' : 'badge-warning']">{{ getType(page.type) }}</div>
    </div>

    <div class="col-12">
      <form name="edit-pagetemplate" role="form col-12">
        <b-card no-body class="row">
          <b-tabs ref="tabs" card>
            <b-tab>
              <template slot="title">
                <i class="fas fa-cog"></i> General
              </template>
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-md-12">
                      <general-page-config
                        v-model="generalPageConfig"
                      ></general-page-config>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab active>
              <template slot="title">
                <i class="fas fa-window-restore"></i> Page Content
              </template>
              <div
                v-if="isLocked && user.is_admin"
                class="alert alert-warning mx-2"
                role="alert"
              >
                <i class="fa fa-info-circle mr-2"></i>
                Open master template in <router-link :to="{ name: 'PageTemplateEdit', id: $route.params.id }">Admin Settings</router-link> to edit page content.
              </div>
              <div class="row">
                <div class="col-12">
                  <grid-layout
                    :layout="layout"
                    :col-num="12"
                    :row-height="30"
                    :is-draggable="!isLocked"
                    :is-resizable="true"
                    :vertical-compact="true"
                    :margin="[10, 10]"
                    :use-css-transforms="true"
                  >
                    <grid-item
                      v-for="(item, key) in layout"
                      :key="item.i"
                      ref="layout"
                      class="card"
                      :class="{'text-light bg-secondary border-none': item.hide, 'border-secondary': !item.hide} "
                      :x="item.x"
                      :y="item.y"
                      :w="item.w"
                      :h="item.h"
                      :i="item.i"
                      drag-allow-from=".vue-draggable-handle"
                      drag-ignore-from=".no-drag"
                      @resized="updateLayout"
                      @moved="updateLayout"

                    >
                      <div :class="{'bg-transparent': item.hide} " class="card-header vue-draggable-handle">
                        <template v-if="moduleSelected(key)">
                          <span v-if="getModuleName(page.pagewidgets[key])" class="text-nowrap">
                            <strong>
                              {{getModuleName(page.pagewidgets[key])}}
                            </strong>
                          </span>
                        </template>
                        <span v-else class="text-nowrap">new module</span>
                        <button
                          type="button"
                          class="close no-drag"
                          title="remove" aria-label="remove"
                          :disabled="isLocked"
                          @click.prevent="removeItem(item)"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <span class="float-right no-drag mr-4 mt-1">
                          <input
                            :id="'checkbox-'+page.type+item.i"
                            v-model="item.hide"
                            type="checkbox"
                            :disabled="isLocked"
                            @change="updateLayout"
                          >
                          <label :for="'checkbox-'+page.type+item.i">hide</label>
                        </span>
                        <div>
                          <span class="text-nowrap">
                            <div class="badge badge-dark">
                              <span v-if="page.pagewidgets[key]">{{page.pagewidgets[key].name}}</span>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="card-body">
                        <div v-if="moduleSelected(key)" class="row">
                          <div class="col">
                            <button
                              class="btn btn-primary"
                              :disabled="isLocked"
                              @click.prevent="showModuleSettings(page.pagewidgets[key],key)"
                            >
                              <i class="fas fa-pencil-alt"></i><span class="d-none d-xl-inline"> settings</span>
                            </button>
                          </div>
                          <div class="col-auto">
                          <button
                            class="btn btn-primary"
                            title="clone module"
                            :disabled="isLocked"
                            @click.prevent="cloneModule(item, key)"
                          >
                            <i class="fas fa-copy"></i>
                          </button>
                            <button
                              class="btn btn-danger"
                              :disabled="isLocked"
                              @click.prevent="removeModule(key)"
                            >
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                        <div v-else class="form-group">
                          <select id="module" v-model="page.pagewidgets[key]" class="form-control" @change.prevent="setLayout(key, item)">
                            <option value="undefined" selected disabled>Please select module template</option>
                            <optgroup label="for all page types">
                              <option v-for="module in availableModules.filter(module => module.package_module)" :key="module.name + key" :value="JSON.parse(JSON.stringify(module))">{{ module.name }}</option>
                            </optgroup>
                            <optgroup label="currently not for package/spotlight pages">
                              <option v-for="module in availableModules.filter(module => !module.package_module)" :key="module.name + key" :value="JSON.parse(JSON.stringify(module))">{{ module.name }}</option>
                            </optgroup>
                          </select>
                        </div>
                      </div>
                    </grid-item>
                  </grid-layout>
                  <div class="row">
                    <div class="col-12 text-center">
                      <button
                        class="btn btn-primary my-3"
                        :disabled="isLocked"
                        @click.prevent="addModule"
                      >
                        <i class="fa fa-plus"></i> add module
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
          <hr class="m-0">
          <div class="d-flex bg-white px-3">
            <div class="d-flex flex-column p-3 align-items-start justify-content-between">
              <small class="text-muted ml-1">created</small>
              <div class="badge badge-light mb-1">{{ page.created_at }}</div>
            </div>
            <div class="d-flex flex-column p-3 align-items-start justify-content-between">
              <small class="text-muted ml-1">last updated</small>
              <div class="badge badge-light mb-1">{{ page.updated_at }}</div>
            </div>
          </div>
        </b-card>
      </form>
      <SaveBar
        :saveDisabled="invalid || isLocked"
        class="mt-4"
        @save="updatePage(close = false)"
        @saveAndClose="updatePage(close = true)"
        @cancel="cancel"
      />

      <edit-module-modal
        :id="editModuleModalId"
        :title="'Edit Module Settings for: ' + editModule"
        :module="module"
        :moduletype="editModule || ''"
        @cancelAction="resetModule()"
        @updateAction="saveModule($event)"
        />
    </div>
  </div>
</ValidationObserver>
