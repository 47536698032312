<template>
  <ValidationObserver v-slot="{ invalid }" tag="section" class="container-fluid filter-template py-3">
    <div class="row">
      <ValidationProvider v-slot="{ errors }" name="name" tag="div" class="col-xl-9">
        <div class="form-group" :class="{'has-error': errors[0]}">
          <label for="name">Name *</label>
          <input id="name" v-model.trim="filtertemplate.name" type="text" class="form-control" required="required">
          <small class="text-danger">{{ errors[0] }}</small>
        </div>
      </ValidationProvider>
    </div>
    <hr>
    <div class="row">
      <div class="col">
        <h3>Filter</h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <draggable v-model="filtertemplate.filters" class="dragArea" group="filters" handle=".draggable-handle" filter=".no-drag" :preventOnFilter="false" @end="resetFilterSort()">
          <div v-for="(filter,key) in filtertemplate.filters" :key="key" class="row">

            <div class="col-xl-9">
              <div class="card border-dark mb-3">

                <div class="card-header draggable-handle">
                  <div class="row">
                    <div class="col-auto m-auto">
                      <div class="draggable-handle text-muted"><i class="fas fa-grip-horizontal"></i></div>
                    </div>
                    <div class="col-lg-4 col-xl-3 no-drag">
                      <input v-model="filter.title" class="form-control" type="text" name="filterTitle" placeholder="Title">
                    </div>
                    <div class="form-check col-auto pt-2 no-drag">
                      <input id="open" v-model="filter.open" class="form-check-input" type="checkbox" value="1">
                      <label class="form-check-label" for="open">Default open</label>
                    </div>
                    <div class="col mr-auto">
                      <button class="btn btn-danger pull-right no-drag" @click.prevent="deleteFilter(key)">
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <ul class="list-group list-group-flush">

                  <li class="list-group-item">
                    <div class="form-row">
                      <div class="form-group col-auto">
                        <label for="filterType">Type</label>
                        <select id="filterType" v-model="filter.type" class="form-control" name="version">
                          <option value="field">Filter by Field</option>
                          <option value="values">Filter by Values</option>
                          <option value="date">Filter by Date</option>
                          <option value="language">Filter by Language</option>
                        </select>
                      </div>
                      <div class="form-group col ml-2">
                        <legend class="col-form-label">Scope</legend>
                        <div class="form-check form-check-inline">
                          <input id="crs" v-model="filter.scope_crs" class="form-check-input" type="checkbox" value="1">
                          <label class="form-check-label" for="crs">CRs</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input id="events" v-model="filter.scope_events" class="form-check-input" type="checkbox" value="1">
                          <label class="form-check-label" for="events">Events</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input id="slides" v-model="filter.scope_slides" class="form-check-input" type="checkbox" value="1">
                          <label class="form-check-label" for="slides">Slides</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input id="people" v-model="filter.scope_people" class="form-check-input" type="checkbox" value="1" disabled>
                          <label class="form-check-label" for="people">People</label>
                        </div>
                      </div>
                      <div class="form-group col-auto">
                        <label for="maxVisibleItems">Max. initially visible items</label>
                        <input id="maxVisibleItems" v-model="filter.maxVisibleItems" class="form-control" type="number">
                      </div>
                      <div class="form-group col-auto">
                        <label for="languages">Languages</label>
                        <input id="languages" v-model="filter.languages" class="form-control" type="text">
                      </div>
                    </div>
                  </li>

                  <li class="list-group-item">
                    <h5>Fields</h5>
                    <draggable v-model="filter.filterfields" class="dragArea" group="'filterfields'-key" handle=".draggable-handle" filter=".no-drag" @end="resetFilterfieldSort(filter)">
                      <div v-for="(filterfield,filterkey) in filter.filterfields" :key="filterkey" class="row">
                        <div class="col">
                            <div class="card bg-light mb-3">
                              <div class="card-body">

                                <div class="row">
                                  <div class="col-auto m-auto">
                                    <div class="draggable-handle text-muted"><i class="fas fa-grip-vertical"></i></div>
                                  </div>
                                  <div v-if="filter.type === 'field'" class="col mr-auto">
                                    <input v-model="filterfield.field" class="form-control" type="text" placeholder="field name">
                                  </div>
                                  <div v-if="filter.type === 'values'" class="col mr-auto">
                                    <div class="row">
                                      <ValidationProvider v-slot="{ errors }" name="name" slim>
                                        <div class="form-group col" :class="{'has-error': errors[0]}">
                                          <label for="filterType">Name *</label>
                                          <input id="filterType" v-model="filterfield.field" class="form-control" type="text" required="required">
                                          <small class="text-danger">{{ errors[0] }}</small>
                                        </div>
                                      </ValidationProvider>
                                      <ValidationProvider v-slot="{ errors }" name="value" slim>
                                        <div class="form-group col" :class="{'has-error': errors[0]}">
                                          <label for="filterType">Value *</label>
                                          <input id="filterType" v-model="filterfield.value" class="form-control" type="text" required="required">
                                          <small class="text-danger">{{ errors[0] }}</small>
                                        </div>
                                      </ValidationProvider>
                                    </div>
                                    <div class="row">
                                      <div class="form-group col">
                                        <label for="filterType">Label (optional)</label>
                                        <textarea id="filterType" v-model="filterfield.label" class="form-control" rows="1"></textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-auto">
                                    <button class="btn btn-sm btn-danger" @click.prevent="deleteFilterfield(filter, filterkey)">
                                      <i class="fas fa-trash"></i>
                                    </button>
                                  </div>
                                </div>

                                <div class="row">
                                  <div v-if="filter.type === 'date'" class="col">
                                    <div class="row">
                                      <div class="form-group col">
                                        <select v-model="filterfield.field" class="form-control" name="version">
                                          <option value="year">Filter by Year</option>
                                          <option value="days">Filter by Day</option>
                                        </select>
                                      </div>
                                      <div class="form-group col">
                                        <input v-model="filterfield.value" class="form-control" type="text" placeholder="filter value">
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="form-group col">
                                        <textarea v-model="filterfield.label" class="form-control" rows="2"></textarea>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>

                            </div>
                          </div>
                      </div>
                    </draggable>

                    <button v-if="filter.type !== 'field' || !filter.filterfields || filter.filterfields.length === 0" class="btn btn-primary" @click.prevent="addFilterfield(filter)">
                      <i class="fas fa-plus"></i> Add filter field
                    </button>
                  </li>

                </ul>

              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button class="btn btn-primary" @click.prevent="addFilter()">
          <i class="fas fa-plus"></i> add Filter
        </button>
      </div>
    </div>
    <hr>
    <SaveBar
      :saveDisabled="invalid"
      disableSaveAndClose
      @save="saveFiltertemplate"
      @cancel="cancel"
    />

  </ValidationObserver>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: { draggable },
  data () {
    return {
      filtertemplate: {
      }
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    }
  },
  created () {
    this.getFiltertemplateData()
  },
  methods: {
    getFiltertemplateData () {
      this.isLoading = true
      this.$store
        .dispatch('getFiltertemplate', this.$route.params.id)
        .then(response => {
          this.isLoading = false
          this.filtertemplate = response
        }, response => {
          this.$swal({
            title: 'Error',
            text: 'could not save group data',
            icon: 'error'
          })
          this.isLoading = false
        })
    },
    addFilter () {
      this.filtertemplate.filters.push({
        title: '',
        type: 'field',
        sort: this.filtertemplate.filters.length + 1,
        filterfields: [],
        scope_crs: 1,
        scope_events: 1,
        scope_slides: 1,
        scope_people: 1,
        maxVisibleItems: 10
      })
    },
    cancel () {
      this.$router.push('/clients/' + this.$route.params.client + '/filtertemplates')
    },
    deleteFilter (key) {
      this.filtertemplate.filters.splice(key, 1)
      this.resetFilterSort()
    },
    resetFilterSort () {
      this.filtertemplate.filters.forEach((filter, key) => {
        this.$set(this.filtertemplate.filters[key], 'sort', (key + 1) * 1)
      })
    },
    addFilterfield (filter) {
      filter.filterfields.push({
        field: null,
        sort: filter.filterfields.length + 1
      })
    },
    deleteFilterfield (filter, key) {
      filter.filterfields.splice(key, 1)
      this.resetFilterfieldSort(filter)
    },
    resetFilterfieldSort (filter) {
      filter.filterfields.forEach((filterfield, key) => {
        this.$set(filter.filterfields[key], 'sort', (key + 1) * 1)
      })
    },
    saveFiltertemplate () {
      this.isLoading = true
      this.$store
        .dispatch('updateFiltertemplate', { filtertemplateId: this.$route.params.id, data: this.filtertemplate })
        .then(response => {
          this.isLoading = false
          this.$router.push('/clients/' + this.$route.params.client + '/filtertemplates')
        }, response => {
          this.$swal({
            title: 'Error',
            text: 'could not save group data',
            icon: 'error'
          })
          this.isLoading = false
        })
    }
  }
}
</script>

<style>
.draggable-handle {
  cursor: grab;
}
.filter-template {
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background: white;
}
</style>
