import Vue from 'vue'

const mutations = {
  SET_USER (state, user) {
    Vue.set(state, 'user', user)
  },
  SET_TOKEN (state, token) {
    state.token = token
    Vue.set(state, 'token', token)
  },
  LOAD_PROJECT (state, project) {
    Vue.set(state, 'newProject', project)
  },
  UPDATE_PROJECT (state, project) {
    Vue.set(state, 'newProject', project)
  },
  NEXT_STEP (state) {
    state.newProject.step++
  },
  PREVIOUS_STEP (state) {
    state.newProject.step--
  },
  SET_TIMEZONES (state, timezones) {
    Vue.set(state, 'timezones', timezones)
  },
  TOGGLE_IMAGE_PANEL (state, status) {
    Vue.set(state, 'openImagePanel', status)
  },
  SET_LOADING_STATE: (state, loading) => {
    Vue.set(state, 'isLoading', loading)
  },
  SET_CLIENTS_LIST: (state, clients) => {
    Vue.set(state, 'clientsList', clients)
  },
  SET_CLIENT: (state, client) => {
    Vue.set(state, 'client', client)
  },
  SET_GROUPS_LIST: (state, groups) => {
    Vue.set(state, 'groupsList', groups)
  },
  SET_GROUP_NAME: (state, name) => {
    Vue.set(state, 'groupName', name)
  },
  SET_PROJECT_NAME: (state, name) => {
    Vue.set(state, 'projectName', name)
  },
  SET_PROJECTS_LIST: (state, projects) => {
    Vue.set(state, 'projectsList', projects)
  },
  SET_PACKAGES_LIST: (state, packages) => {
    Vue.set(state, 'packagesList', packages)
  },
  SET_SPOTLIGHTS_LIST: (state, spotlights) => {
    Vue.set(state, 'spotlightsList', spotlights)
  },
  SET_EDUCATIONITEMS_LIST: (state, educationitems) => {
    Vue.set(state, 'educationitemsList', educationitems)
  },
  SET_PAGES_LIST: (state, pages) => {
    Vue.set(state, 'pagesList', pages)
  },
  SET_AVAILABLE_LANGUAGES: (state, languages) => {
    Vue.set(state, 'availableLanguages', languages)
  },
  SET_ADVERTISEMENTS_LIST: (state, advertisements) => {
    Vue.set(state, 'advertisementsList', advertisements)
  }
}

export default mutations
