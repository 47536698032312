<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <h4 class="card-header">
          Pagecontent for {{ pageType }}
        </h4>
        <div class="card-body">
          <div v-if="page">
            <grid-layout
              :layout="layout"
              :col-num="12"
              :row-height="30"
              :is-draggable="true"
              :is-resizable="true"
              :vertical-compact="true"
              :margin="[10, 10]"
              :use-css-transforms="true"
            >
              <grid-item
                v-for="(item, key) in layout"
                :key="item.i"
                ref="layout"
                class="card"
                :class="{'text-light bg-secondary border-none': item.hide, 'border-secondary': !item.hide} "
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
                drag-allow-from=".vue-draggable-handle"
                drag-ignore-from=".no-drag"
                @resized="updateLayout"
                @moved="updateLayout"
              >
                <div :class="{'bg-transparent': item.hide} " class="card-header vue-draggable-handle">
                  <template v-if="moduleSelected(key)">
                    <span v-if="getModuleName(page.pagewidgets[key])" class="text-nowrap">
                      <strong>
                        {{getModuleName(page.pagewidgets[key])}}
                      </strong>
                    </span>

                  </template>
                  <span v-else class="text-nowrap">new module</span>
                  <button type="button" class="close no-drag" title="remove" aria-label="remove" @click.prevent="removeItem(item)">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <span
                    v-if="moduleSelected(key)"
                    class="float-right no-drag mr-4 mt-1"
                  >
                    <input :id="'checkbox-'+pageType+item.i" v-model="item.hide" type="checkbox" @change="updateLayout">
                    <label :for="'checkbox-'+pageType+item.i">hide</label>
                  </span>
                  <div v-if="moduleSelected(key)">
                    <span class="text-nowrap">
                      <div
                        class="badge badge-dark"
                      >
                        <span>{{page.pagewidgets[key].name}}</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="card-body">
                  <div v-if="moduleSelected(key)" class="row">
                    <div class="col">
                      <button class="btn btn-primary" @click.prevent="showModuleSettings(page.pagewidgets[key],key)">
                        <i class="fas fa-pencil-alt"></i><span class="d-none d-xl-inline"> settings</span>
                      </button>
                    </div>
                    <div class="col-auto">
                      <button class="btn btn-primary mr-1" title="clone module" @click.prevent="cloneModule(item, key)">
                        <i class="fas fa-copy"></i>
                      </button>
                      <button class="btn btn-danger" @click.prevent="removeModule(key)">
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div v-else class="form-group">
                    <select id="module" v-model="page.pagewidgets[key]" class="form-control" @change.prevent="setLayout(key, item)">
                      <option value="undefined" selected disabled>Please select module template</option>
                      <option v-for="module in availableModules" :key="module.name + key" :value="JSON.parse(JSON.stringify(module))">{{module.name}}</option>
                    </select>
                  </div>
                </div>
              </grid-item>
            </grid-layout>
            <div class="row">
              <div class="col-12 text-center">
                <button class="btn btn-primary" @click.prevent="addModule">
                  <i class="fa fa-plus"></i> add module
                </button>
              </div>
            </div>
          </div>
          <div v-else class="container-fluid">
            <div class="row form-inline">
              <div class="col-12 text-center">
                <button class="btn btn-primary" :disabled="invalid" @click.prevent="showCreatePageModal = true">
                  Create New Page
                </button>
              </div>
            </div>
          </div>

          <div v-if="page" class="col-12">
            <hr>
          </div>

          <div v-if="page" class="col-12 text-center">
            <button class="btn btn-success mr-1" @click.prevent="updatePage(close = true)">
              <i class="fas fa-save mr-1"></i> save page
            </button>
            <button class="btn btn-primary mr-1" @click.prevent="openCreatePageTemplateModal">
              <i class="far fa-file-alt mr-1"></i> create template
            </button>
            <button class="btn btn-danger mr-1" @click.prevent="deletePage(page.id)">
              <i class="fas fa-trash mr-1"></i> delete page
            </button>
          </div>

          <edit-module-modal
            :id="editModuleModalId"
            :title="'Edit Module Settings for: ' + editModule"
            :module="module"
            :moduletype="editModule || ''"
            @cancelAction="resetModule()"
            @updateAction="saveModule($event)"
            />

          <create-page-modal
            v-if="showCreatePageModal"
            :clientId="clientId"
            :pageType="pageType"
            @createPage="createPage($event)"
            @close="showCreatePageModal = false"
            />

          <CreatePageTemplateModal
            v-if="page"
            :id="createPageTemplateModalId"
            :page="page"
            :clientId="clientId"
            @cancel="closeCreatePageTemplateModal"
            @create="closeCreatePageTemplateModal"
          />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { GridLayout, GridItem } from 'vue-grid-layout'

import EditPageMixin from '../mixins/EditPageMixin.vue'

export default {
  name: 'edit-page',
  components: {
    GridLayout,
    GridItem
  },
  mixins: [EditPageMixin],
  props: ['relationType', 'relation', 'pageType', 'clientId', 'slug', 'title', 'pageId', 'invalid'],
  data () {
    return {
      availableModules: [],
      editModule: false,
      module: {
        configuration: [],
        model: {},
        id: '',
        layout: {},
        pivotId: 0
      },
      layout: [],
      page: undefined,
      showCreatePageModal: false,
      editModuleModalId: this.$uuid.v4(),
      createPageTemplateModalId: this.$uuid.v4()
    }
  },
  computed: {
    showPage () {
      if (this.page?.pagewidgets.length > 0) { return true }
      return false
    },
    typeNotPageOrNoId () {
      const pageWithoutId = this.pageType === 'page' && !this.pageId
      return !pageWithoutId
    }
  },
  watch: {
    pageId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.layout = []
        this.page = undefined
        if (newVal) {
          this.getPage(this.pageId)
        }
      }
    },
    title (newVal) {
      if (this.page) {
        this.page.title = newVal
      }
    },
    slug (newVal) {
      if (this.pageType === 'channelDetail') {
        this.layout = []
        this.getPage()
      } else {
        if (this.page) {
          this.page.slug = newVal
        }
      }
    }
  },
  created () {
    if (this.typeNotPageOrNoId) {
      this.getPage(this.pageId)
    }
    this.getModules()
  },
  methods: {
    openCreatePageTemplateModal () {
      this.$bvModal.show(this.createPageTemplateModalId)
    },
    closeCreatePageTemplateModal () {
      this.$bvModal.hide(this.createPageTemplateModalId)
    },
    createPage (template) {
      this.isLoading = true
      this.showCreatePageModal = false
      const params = {
        relation: this.relationType,
        relId: this.relation.data.id,
        type: this.pageType,
        slug: this.slug,
        title: this.title || '',
        name: this.title || '',
        pagewidgets: template?.pagewidgets || []
      }

      this.$store
        .dispatch('createPage', params)
        .then(response => {
          this.page = response
          this.loadLayout()
          this.isLoading = false
          this.$emit('created', this.page.id)
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not create page: ' + error,
            icon: 'error'
          })
        })
    },
    async getPage (pageId) {
      this.isLoading = true
      try {
        if (pageId) {
          const page = await this.$store.dispatch('getPage', pageId)
          this.page = page
        } else {
          const params = {
            types: [this.pageType],
            slug: this.slug
          }

          params[`${this.relationType}Id`] = this.relation?.data?.id

          const pages = await this.$store.dispatch('getPages', params)
          if (pages.length > 1 && this.pageType !== 'page') {
            console.error(`More than one page found for type ${this.pageType} and ${this.relationType}Id: ${this.relation?.data?.id}`)
          }

          if (!this.slug) {
            this.page = pages.find((page) => !page.slug)
          } else {
            this.page = pages[0]
          }
        }

        if (this.page?.pagewidgets) {
          this.loadLayout()
        }
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        return this.$swal({
          title: 'Error',
          text: 'Could not fetch page: ' + error,
          icon: 'error'
        })
      }
    },
    cleanupDataForApi () {
      if (this.page.client_id === '') {
        delete this.page.client_id
      }

      if (this.page.group_id === '') {
        delete this.page.group_id
      }

      if (this.page.project_id === '') {
        delete this.page.project_id
      }

      if (this.page.type === 'adminTemplate') {
        delete this.page.client_id
      }

      const titleOrName = this.page.title || this.page.name

      this.page.title = titleOrName
      this.page.name = titleOrName
    },
    updatePage (close = false) {
      this.isLoading = true

      this.cleanupDataForApi()

      this.$store
        .dispatch('updatePage', { pageId: this.page.id, data: this.page })
        .then(
          response => {
            this.$swal({
              title: 'Success',
              text: 'Pagecontent for ' + this.pageType + ' was updated',
              icon: 'success',
              showConfirmButton: false,
              toast: true,
              position: 'top',
              timer: 2000
            })
            this.isLoading = false
            this.$emit('updated')
          }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not update page: ' + error,
            icon: 'error'
          })
        })
    },
    deletePage (id) {
      this
        .$swal({
          title: 'Are you really sure?',
          text: 'The page will be deleted. This cannot be undone!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#dd4b39',
          cancelButtonText: 'Cancel'
        })
        .then(result => {
          if (result.value) {
            this.$store
              .dispatch('deletePage', { pageId: id })
              .then(response => {
                this.page = undefined
                this.layout = []
                this.$swal({
                  title: 'Success',
                  text: 'Page was deleted',
                  icon: 'success',
                  showConfirmButton: false,
                  toast: true,
                  position: 'top',
                  timer: 2000
                })
                this.isLoading = false
                this.$emit('deleted')
              }).catch(error => {
                this.isLoading = false
                this.$swal({
                  title: 'Error',
                  text: 'Could not delete page: ' + error,
                  icon: 'error'
                })
              })
          }
        })
    },
    loadLayout (page = null) {
      if (page != null) {
        this.showCreatePageModal = false
        this.page = page
      }
      this.page.pagewidgets.forEach(module => {
        this.layout.push(JSON.parse(module.pivot.layout))
      })
      this.layout.forEach((item, key) => {
        item.i = '' + key
      })
    }
  }
}
</script>

<style>
.drag .selected-modules {
  border-radius: 5px;
  padding: 5px;
  min-height: 250px;
  border: 1px dotted #666;
  background-color: #ccc;
}
.drag .available-modules {
  border-radius: 5px;
  padding: 5px;
  border: 1px dotted #666;
  background-color: #ccc;
}
.drag .active-module {
  border-radius: 5px;
  padding: 5px;
  border: 1px dotted #bbbbbb;
  background-color: #eee;
}
.is-clone {
  opacity: 1;
  animation-name: cloneHighlighting;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes cloneHighlighting {
  0% {
    opacity: 0;
    box-shadow: 0px 0px 2px 6px rgba(3, 102, 214, 0.3);
  }
  10% {
    opacity: 1;
  }
  100% {
    box-shadow: 0px 0px 0px 6px transparent;
  }
}
</style>
