<template>
  <div class="imagepanel" :class="{ isOpen: isOpen }">

    <div class="imagepanel__opener">
      <button class="btn btn-primary" @click="toggle">
        <i class="fas fa-clipboard"></i> {{ openerText }}
      </button>
    </div>

    <div class="imagepanel__content container">

      <div class="row mb-3">
        <div v-if="uploadUrl" class="col">
          <h4>Client Assets</h4>
          <div v-if="assets.length > 0 && parentId !== ''">
            <button class="btn btn-primary" @click="oneUp">
              <i class="fas fa-level-up-alt"></i> up <strong>...</strong>
            </button>
            <button class="btn btn-default disabled">
              <i class="far fa-folder-open"></i> <span>{{ currentFolderName }}</span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="assets.length == 0" class="row mb-3">
        <div class="col">
          <div class="alert alert-info" role="alert">
            <h5 class="alert-heading">No assets available...</h5>
            <hr>
            You can upload new assets by dropping them in the upload area below.<br>
            If you expect to have a lot of assets, you can organize them in folders.
          </div>
        </div>
      </div>

      <div v-if="folders.length > 0" class="row mb-3">
        <div class="col">
          <ul class="col list-group">
            <li v-for="folder in folders" :key="folder.id" class="list-group-item">
              <div class="folder">
                <button class="btn btn-primary" @click.prevent="setParentId(folder.id)">
                  <span>
                    <i class="far fa-folder"></i> {{ folder.name }}</span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="form-row mb-3">
        <div class="col">
          <input v-model="newFoldername" type="text" name="newfoldername" placeholder="folder name" class="form-control">
        </div>
        <div class="col">
          <button class="btn btn-success" @click.prevent="createFolder()">
            <i class="fas fa-plus"></i> create new folder
          </button>
        </div>
      </div>

      <div class="row mb-3">
        <div v-if="uploadUrl" class="col">
          <h4>Upload Images</h4>
          <dropzone
            id="uploadProjectImages"
            ref="fileUpload"
            :options="dropzoneOptions"
            class="alert alert-secondary"
            @vdropzone-success="showSuccess"
            @vdropzone-sending="sendingEvent"
          >
          </dropzone>
        </div>
      </div>

      <div v-if="images.length > 0" class="row mb-3">
        <div class="col">
          <ul class="list-group">
            <li v-for="image in images" :key="image.resource" class="list-group-item">

              <div class="row">
                <div class="col-3">
                  <img v-if="image.type === 'image'" :src="[assetURI+'/'+client.data.name+'/img/'+image.resource]" width="100%">
                  <img v-else src="/static/img/xml-icon.png" width="100%">
                </div>
                <div class="col-auto ml-auto">
                  <div class="btn-group" role="group">
                    <button type="button" class="btn btn-sm btn-danger" @click.prevent="deleteAsset(image)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col" :title="image.resource">
                  {{image.name}}
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <small class="text-muted">uploaded at: {{image.created_at}}</small>
                </div>
              </div>

            </li>
          </ul>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Dropzone from 'vue2-dropzone'

import { mapGetters } from 'vuex'

export default {
  name: 'ImagePanel',
  components: {
    Dropzone
  },
  props: ['clientId'],
  data () {
    return {
      openerText: 'Assets',
      assets: [],
      newFoldername: '',
      parentId: '',
      asset: false
    }
  },
  computed: {
    ...mapGetters({
      getAssets: 'getImages',
      client: 'getClient'
    }),
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    },
    isOpen () {
      return this.$store.state.openImagePanel
    },
    uploadUrl () {
      return this.$store.state.serverURI + '/api/v2/clients/' + this.clientId + '/images'
    },
    assetURI () {
      return this.$store.state.assetURI
    },
    dropzoneOptions () {
      return {
        url: this.uploadUrl,
        maxFilesize: 10,
        headers: {
          Authorization: 'Bearer ' + this.$store.state.token
        },
        parent_id: ''
      }
    },
    images () {
      const images = this.assets.filter(
        asset => (asset.type === 'image' || asset.type === 'xml') && asset.parent_id === this.parentId
      )
      return images
    },
    folders () {
      return this.assets.filter(
        asset => asset.type === 'folder' && asset.parent_id === this.parentId
      )
    },
    currentFolderName () {
      const folders = this.assets.filter(
        asset => asset.type === 'folder' && asset.id === this.parentId
      )
      if (folders.length === 1) {
        return folders.pop().name
      }

      return ''
    }
  },
  created () {
    this.loadImages()
  },
  methods: {
    open () {
      this.openerText = 'Close'
      this.$store.commit('TOGGLE_IMAGE_PANEL', true)
    },
    close () {
      this.openerText = 'Assets'
      this.$store.commit('TOGGLE_IMAGE_PANEL', false)
    },
    toggle () {
      if (this.isOpen) {
        this.close()
      } else {
        this.open()
        this.loadImages()
      }
    },
    sendingEvent (file, xhr, formData) {
      formData.append('parent_id', this.parentId)
    },
    setParentId (id) {
      this.parentId = id
    },
    oneUp () {
      const current = this.assets.filter(asset => {
        return asset.id === this.parentId
      })
      this.setParentId(current[0].parent_id)
    },
    createFolder () {
      if (this.newFoldername) {
        this.isLoading = true
        const data = {
          name: this.newFoldername,
          parent_id: this.parentId,
          type: 'folder'
        }
        this.$store
          .dispatch('createAssetFolder', { clientId: this.clientId, data: data })
          .then(response => {
            this.newFoldername = ''
            this.$nextTick(() => {
              this.loadImages(true)
            })
            this.isLoading = false
          }).catch(error => {
            this.isLoading = false
            this.error = true
            this.$swal({
              title: 'Error',
              text: error,
              icon: 'error'
            })
          })
      }
    },
    async deleteAsset (asset) {
      const response = await this.$swal({
        title: 'Are you really sure?',
        text: 'This asset might be in use somewhere. Only continue if you know what you are doing.',
        input: 'checkbox',
        inputValue: 0, // false
        inputPlaceholder: 'I want to delete this asset',
        confirmButtonText: 'Delete <i class="fa fa-trash-alt"></i>',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light ml-2'
        },
        buttonsStyling: false,
        inputValidator: (result) => {
          return !result && 'please confirm you want to delete this asset'
        }
      })

      if (!response.isConfirmed) {
        return
      }

      this.isLoading = true
      const params = {
        clientId: this.clientId,
        asset_id: asset.id
      }
      this.$store
        .dispatch('deleteAsset', params)
        .then(
          response => {
            if (response.error) {
              this.isLoading = false
              this.$swal({
                title: 'Error',
                text: response.error,
                icon: 'error'
              })
            } else {
              this.assets.splice(this.assets.indexOf(asset), 1)
              this.isLoading = false
              this.$swal({
                title: 'Success',
                text: 'Asset "' + asset.name + '" has been deleted',
                icon: 'success',
                showConfirmButton: false,
                toast: true,
                position: 'top',
                timer: 2000
              })
            }
            this.isLoading = false
          },
          error => {
            this.$swal({
              title: 'Error',
              text: 'Could not delete asset: ' + error,
              icon: 'error'
            })
          }
        )
    },
    showSuccess (file) {
      this.$refs.fileUpload.removeFile(file)
      this.$nextTick(() => {
        this.loadImages(true)
      })
    },
    loadImages (isUploadProcess = false) {
      this.isLoading = true
      this.$store
        .dispatch('loadAssets', { clientId: this.clientId, isUploadProcess: isUploadProcess })
        .then(() => {
          this.assets = this.getAssets(this.clientId)
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.error = true
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    }
  }
}
</script>

<style lang="scss">
  .imagepanel {

    .imagepanel__opener {
      z-index: 999;
      position: absolute;
      top: 62px;
      right: 0;
      transition: all 0.3s ease-out;

      @media screen and (max-width: 767px) {
        top: 110px;
      }

    }

    .imagepanel__content {
      padding-top: 66px;
      width: 50vw;
      max-width: 100%;
      min-height: calc(100% - 54px);
      position: absolute;
      right: 0px;
      top: 0;
      background-color: white;
      transform: translateX(calc(100% + 10px)) rotateY(90deg); // 10px box-shadow
      transition: transform 0.3s ease-out;
      box-shadow: -10px 0px 10px -9px rgba(0, 0, 0, 0.75);

      > div:last-child {
        margin-bottom: 100px;
      }

      @media screen and (max-width: 767px) {
        padding-top: 160px;
        width: 100%;
      }

    }

     &.isOpen {

      .imagepanel__content {
        transform: translateX(0) rotateY(0deg);
        z-index: 999;
      }

      .imagepanel__opener {
        right: 50vw;

        @media screen and (max-width: 767px) {
          right: calc(100% - 100px);
        }

      }

    }

  }
</style>
