<template>
    <section class="content">
      <router-view></router-view>
    </section>
</template>

<script>
export default {
  name: 'educationitems',
  data () {
    return {
    }
  },
  computed: {
  }
}
</script>

<style>

</style>
