<template>
  <ValidationObserver v-slot="{ invalid }" tag="section" class="content">
    <div class="box row">
      <div class="col-12">
        <h2>Update security</h2>
        <form name="create-security" class="form row">
          <input v-model="security.id" type="hidden" name="id">
          <ValidationProvider v-slot="{ errors }" name="name" tag="div" class="col-12">
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="name">Name: *</label>
              <input id="name" v-model.trim="security.name" type="text" class="form-control" required="required">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="model"
            tag="div"
            rules="required|json"
            class="col-12"
          >
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="model">Model: *</label>
              <AceEditor
                v-model="security.model"
                height="350px"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="configuration"
            tag="div"
            rules="required|json"
            class="col-12"
          >
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="configuration">Configuration: *</label>
              <AceEditor
                v-model="security.configuration"
                height="350px"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <SaveBar
            :saveDisabled="invalid"
            @save="updateSecurity(close = false)"
            @saveAndClose="updateSecurity(close = true)"
            @cancel="cancel"
          />
        </form>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
export default {
  data () {
    return {
      security: {
        name: '',
        model: '',
        configuration: '',
        id: ''
      }
    }
  },
  computed: {
  },
  created () {
    this.getSecurityById(this.$route.params.id)
  },
  methods: {
    cancel () {
      this.$router.push('/security')
    },
    getSecurityById (securityId) {
      this.isLoading = true
      this.$store
        .dispatch('getSecurity', securityId)
        .then(response => {
          this.security = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    updateSecurity (close = false) {
      this.isLoading = true
      this.$store
        .dispatch('updateSecurity', { securityId: this.security.id, data: this.security })
        .then(response => {
          this.$swal({
            title: 'Success',
            text: 'Security was updated',
            icon: 'success',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
          this.isLoading = false
          if (close) {
            this.cancel()
          }
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not update security: ' + error,
            icon: 'error'
          })
        })
    }
  }
}
</script>
