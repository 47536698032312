

<div class="tablelist pt-3">

  <div class="row pb-2">
    <div class="col mr-auto">
      <SearchBar
        v-if="searchEnabled"
        ref="searchInput"
        v-model="query"
        :placeholder="searchPlaceHolder"
        @focus="searchbarFocused=true"
        @blur="searchbarFocused=false"
      />
    </div>
    <div v-if="actionButton" class="col-auto">
      <button
        class="btn btn-success"
        tabindex="-1"
        @click.prevent="actionButton.action"
      >
        <i v-if="actionButton.icon" :class="actionButton.icon"></i>
        <span class="d-none d-xl-inline"> {{actionButton.label}} </span>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <table class="table table-striped table-hover table-bordered">
        <thead>
          <tr>
            <th
              v-for="(column, index) in columns"
              :key="`tablehead-${index}`"
              scope="col"
              :class="{ active: isActiveSortColumn(index) }"
              @click="onTableheadClick(column, index)"
            >
              {{ column.label }}
              <span v-if="column.sort && column.sort.enabled">
                <i v-if="!isActiveSortColumn(index)" class="fas fa-sort"/>
                <i v-if="isActiveSortColumn(index) && sortOptions.order === 'ASC'" class="fas fa-sort-up" />
                <i v-if="isActiveSortColumn(index) && sortOptions.order === 'DESC'" class="fas fa-sort-down" />
              </span>
            </th>

            <th v-if="actions" class="w-1 text-center text-nowrap">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, dataIndex) in filteredData"
            :key="`data-${dataIndex}`"
            ref="row"
            :class="{
              focused: focusIndex === dataIndex,
              notclickable: rowsNotClickable
            }"
            tabindex="0"
            @focus="onRowFocus(item.data, dataIndex)"
            @blur="onRowBlur(item.data, dataIndex)"
            @click="onRowClick(item.data, item.meta.index)"
          >
            <td
              v-for="(column, columnIndex) in columns"
              :key="`column-${columnIndex}`"
              class="p-10"
            >
              <div
                v-if="column.badge"
                :class="[column.badgeClass(getColumnData(item, column))]"
                class="badge"
              >
                <span> {{getColumnData(item, column)}} </span>
              </div>
              <i
                v-else-if="column.icon"
                :class="[column.iconClass(getColumnData(item, column))]"
              />
              <input v-else-if="column.editable"
                 :value="getColumnData(item, column)"
                 @input="event => onDataChanged(item, event.target.value)"/>
              <span v-else > {{getColumnData(item, column)}} </span>
            </td>

            <template v-if="actions">
              <td
                class="p-10 w-1 text-center text-nowrap"
              >
                <button
                  v-for="(action, actionIndex) in actions"
                  :key="`action-${actionIndex}`"
                  :class="[action.buttonClass ? action.buttonClass : 'btn-primary']"
                  class="btn btn-sm mr-1"
                  tabindex="-1"
                  :title="action.title"
                  @click.stop="action.action(item.data, item.meta.index)"
                >
                  <i v-if="action.icon" :class="action.icon"></i>
                  <span class="d-none d-xl-inline"> {{action.label}} </span>
                </button>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>

