
<div class="form-field-group clearfix">
  <div v-if="to.wrapper === 'panel'" class="card mb-3">
    <div v-if="to.title" class="card-header">
     {{ to.title }}
     <small v-if="to.help"><br>{{ to.help }}</small>
    </div>
    <div class="card-body">
      <div class="card-text">
        <former-form
          :model="model[field.key]"
          :form="form"
          :formerfields="field.fields"
          :class="field.className"
        />
      </div>
    </div>
  </div>
  <div v-if="to.wrapper !== 'panel'" class="clearfix">
    <h5 v-if="to.title">{{ to.title }}</h5>
    <former-form :model="model[field.key]" :form="form" :formerfields="field.fields"></former-form>
  </div>
</div>
