<template>
  <div class="navigation row">
    <div class="col-6">
      <div class="card">
        <h4 class="card-header">
          Usermenu
        </h4>
        <div class="card-body">
          <div class="form-group form-check">
            <input id="showUserMenu" v-model.trim="client.data.showUserMenu" type="checkbox" name="showUserMenu" class="form-check-input">
            <label for="showUserMenu">Show Usermenu</label>
            <small class="form-text text-muted">The user menu is shown in the top navigation bar beside the search.</small>
          </div>
          <div class="row">
            <div class="col-12">
              <draggable v-model="usermenu" draggable=".dragitem" group="menuitems-um" class="list-group dragbox" @end="resetSort('usermenu')">
                <div v-for="(item, key) in usermenu" :key="key" class="list-group-item list-group-item-secondary dragitem" :class="{'is-spotlight': item.nav_type === 'spotlights'}">
                  <div class="row">
                    <div class="col">
                      <ul class="list-unstyled">
                        <li class="row">
                          <div class="col">
                            <strong>
                              <label v-if="item.nav_type === 'spotlights'"><i class="fas fa-link"></i></label>
                              {{ item.text }}
                            </strong> <small class="text-muted pl-2">{{ item.languages }}</small>
                            <label v-if="item.show_loggedin"><i class="text-muted fas fa-lock pl-2"></i></label>
                            <label v-if="item.show_loggedoff"><i class="text-muted fas fa-lock-open pl-2"></i></label>
                          </div>
                          <div class="col-auto">
                            <button class="btn btn-sm btn-default" @click.prevent="editEditNavigationItem(item)">
                              <i class="fas fa-edit fa-fw"></i>
                            </button>
                            <button class="btn btn-sm btn-danger" @click.prevent="deleteEntry(item, 'headerLeft')">
                              <i class="fas fa-trash-alt fa-fw"></i>
                            </button>
                          </div>
                        </li>
                        <li v-if="item.link" class="text-muted"><small>{{ item.link }}</small></li>
                        <li class="text-muted">
                          <small v-if="item.link_type">Type: {{ item.link_type }} </small>
                          <small v-if="item.link_type && item.link_target"> | </small>
                          <small v-if="item.link_target">Target: {{ item.link_target }}</small>
                        </li>
                        <li v-if="item.params" class="text-muted"><small>Parameter: {{ item.params }}</small></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <button slot="footer" class="btn btn-primary" @click.prevent="addEntry('usermenu', '', 'usermenu')">
                  <i class="fas fa-plus"></i> add menu item
                </button>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="card">
        <h4 class="card-header">
          Spotlights Menu
        </h4>
        <div class="card-body">
          <div class="form-group form-check">
            <input id="showSpotlightsMenu" v-model.trim="client.data.showSpotlightsMenu" type="checkbox" name="showSpotlightsMenu" class="form-check-input">
            <label for="showSpotlightsMenu">Show Industry Spotlights Menu</label>
            <small class="form-text text-muted">Please add a menu item of navigation-type 'spotlights' in the navigation area it should be visible.</small>
          </div>
          <div class="row">
            <div class="col-12">
              <draggable v-model="spotlightsmenu" draggable=".dragitem" group="menuitems-um" class="list-group dragbox" @end="resetSort('spotlightsmenu')">
                <div v-for="(item, key) in spotlightsmenu" :key="key" class="list-group-item list-group-item-secondary dragitem" :class="{'is-spotlight': item.nav_type === 'spotlights'}">
                  <div class="row">
                    <div class="col">
                      <ul class="list-unstyled">
                        <li class="row">
                          <div class="col">
                            <strong>
                              <label v-if="item.nav_type === 'spotlights'"><i class="fas fa-link"></i> </label>
                              {{ item.text }}
                            </strong> <small class="text-muted pl-2">{{ item.languages }}</small>
                            <label v-if="item.show_loggedin"><i class="text-muted fas fa-lock pl-2"></i></label>
                            <label v-if="item.show_loggedoff"><i class="text-muted fas fa-lock-open pl-2"></i></label>
                          </div>
                          <div class="col-auto">
                            <button class="btn btn-sm btn-default" @click.prevent="editEditNavigationItem(item)">
                              <i class="fas fa-edit fa-fw"></i>
                            </button>
                            <button class="btn btn-sm btn-danger" @click.prevent="deleteEntry(item, 'spotlightsmenu')">
                              <i class="fas fa-trash-alt fa-fw"></i>
                            </button>
                          </div>
                        </li>
                        <li v-if="item.link" class="text-muted"><small>{{ item.link }}</small></li>
                        <li class="text-muted">
                          <small v-if="item.link_type">Type: {{ item.link_type }} </small>
                          <small v-if="item.link_type && item.link_target"> | </small>
                          <small v-if="item.link_target">Target: {{ item.link_target }}</small>
                        </li>
                        <li v-if="item.params" class="text-muted"><small>Parameter: {{ item.params }}</small></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <button slot="footer" class="btn btn-primary" @click.prevent="addEntry('spotlightsmenu', '', 'spotlightsmenu')">
                  <i class="fas fa-plus"></i> add menu item
                </button>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mt-4">
      <div class="card">
        <h4 class="card-header">
          Header Navigation
        </h4>
        <div class="card-body">
          <div class="form-group form-check">
            <input id="combineMenuitemsOnMobile" v-model.trim="client.data.combineMenuitemsOnMobile" type="checkbox" name="combineMenuitemsOnMobile" class="form-check-input">
            <label for="combineMenuitemsOnMobile">Use Single Menu On Mobile</label>
            <small class="form-text text-muted">Combines the left and right navigation menus into a single menu for mobile devices (recommended).</small>
          </div>
           <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col">
                  <label>Left Navigation</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <draggable v-model="headerLeft" draggable=".dragitem" group="menuitems-hl" class="list-group dragbox" style="cursor: all-scroll;" @end="resetSort('headerLeft')">
                    <div v-for="(item, key) in headerLeft" :key="key" class="dragitem">
                      <div
                        v-if="isTopLevelItem(item)"
                        class="list-group-item list-group-item-secondary d-flex align-items-start mt-1"
                        :class="{'is-spotlight': item.nav_type === 'spotlights'}"
                      >
                        <div class="pr-3">
                          <i v-if="item.nav_type === 'dropdown'" class="fas fa-chevron-circle-down text-secondary"></i>
                          <i v-else-if="item.nav_type === 'asset'" class="fas fa-image text-secondary"></i>
                          <i v-else-if="item.nav_type === 'spotlights'" class="fas fa-star"></i>
                          <i v-else-if="item.link_type === 'external'" class="fas fa-external-link-alt text-secondary"></i>
                          <i v-else class="fas fa-link text-secondary"></i>
                        </div>

                        <div class="text-nowrap w-100 overflow-hidden">
                          <ul class="list-unstyled flex-fill">
                            <li class="row">
                              <div class="col">
                                <strong>
                                  {{ item.text }}
                                  </strong> <small class="text-muted pl-2">{{ item.languages }}</small>
                                <label v-if="item.show_loggedin"><i class="text-muted fas fa-lock fa-xs pl-2"></i></label>
                                <label v-if="item.show_loggedoff"><i class="text-muted fas fa-lock-open fa-xs pl-2"></i></label>
                              </div>
                            </li>
                            <li v-if="item.link" class="text-muted"><small>{{ item.link }}</small></li>
                            <li class="text-muted">
                              <small v-if="item.link_type">Type: {{ item.link_type }} </small>
                              <small v-if="item.link_type && item.link_target"> | </small>
                              <small v-if="item.link_target">Target: {{ item.link_target }}</small>
                            </li>
                            <li v-if="item.params" class="text-muted"><small>Parameter: {{ item.params }}</small></li>
                          </ul>
                        </div>

                        <div class="d-flex align-items-end">
                          <button class="btn btn-sm btn-primary shadow-sm ml-1" @click.prevent="editEditNavigationItem(item)">
                            <i class="fas fa-edit fa-fw"></i>
                          </button>
                          <button class="btn btn-sm btn-danger shadow-sm ml-1" @click.prevent="deleteEntry(item, 'headerLeft')">
                            <i class="fas fa-trash-alt fa-fw"></i>
                          </button>
                        </div>

                      </div>
                      <div v-if="item.nav_type === 'dropdown'">
                          <div class="list-group-item list-group-item-secondary py-2 px-3 d-flex justify-content-between">
                            <div>
                            </div>
                            <div>
                              <button class="btn py-1" type="button" data-toggle="collapse" :data-target="'#collapse-headerLeft_' + key" title="toggle menu items">
                                <strong>{{ getChildMenuItems(item, headerLeft).length }}</strong> menu items
                                <i class="fas fa-chevron-down fa-fw"></i>
                              </button>
                              <button class="btn btn-sm btn-default shadow-sm" title="add menu item" @click.prevent="addEntry('header', 'left', 'headerLeft', true, item.id)">
                                <i class="fas fa-plus fa-fw"></i>
                              </button>
                            </div>
                          </div>
                      </div>
                      <div v-if="item.nav_type === 'dropdown'" :id="'collapse-headerLeft_' + key" class="collapse list-unstyled ml-4 mt-1">
                          <div v-for="child in getChildMenuItems(item, headerLeft)" :key="child.id">
                            <div class="list-group-item bg-light d-flex align-items-start mt-2">
                              <div class="pr-3">
                                <i v-if="child.nav_type === 'asset'" class="fas fa-image"></i>
                                <i v-else-if="child.nav_type === 'spotlights'" class="fas fa-star"></i>
                                <i v-else class="fas fa-link"></i>
                              </div>
                              <div class="text-nowrap w-100 overflow-hidden">
                                <ul class="list-unstyled flex-fill">
                                  <li class="row">
                                    <div class="col">
                                      <strong>
                                        {{ child.text }}
                                        </strong> <small class="text-muted pl-2">{{ child.languages }}</small>
                                      <label v-if="child.show_loggedin"><i class="text-muted fas fa-lock fa-xs pl-2"></i></label>
                                      <label v-if="child.show_loggedoff"><i class="text-muted fas fa-lock-open fa-xs pl-2"></i></label>
                                    </div>
                                  </li>
                                  <li v-if="child.link" class="text-muted"><small>{{ child.link }}</small></li>
                                  <li class="text-muted">
                                    <small v-if="child.link_type">Type: {{ item.link_type }} </small>
                                    <small v-if="child.link_type && item.link_target"> | </small>
                                    <small v-if="child.link_target">Target: {{ item.link_target }}</small>
                                  </li>
                                  <li v-if="child.params" class="text-muted"><small>Parameter: {{ child.params }}</small></li>
                                </ul>
                              </div>
                              <div class="d-flex align-items-end">
                                <button class="btn btn-sm btn-primary ml-1" @click.prevent="editEditNavigationItem(child)">
                                  <i class="fas fa-edit fa-fw"></i>
                                </button>
                                <button class="btn btn-sm btn-danger ml-1" @click.prevent="deleteEntry(child, 'headerLeft')">
                                  <i class="fas fa-trash-alt fa-fw"></i>
                                </button>
                              </div>
                            </div>
                            <div class="list-group-item bg-light d-flex justify-content-between py-2 px-3">
                              <div>
                                <button class="btn btn-sm btn-default" @click.prevent="sortChildItems(child, 'headerLeft', 1)">
                                  <i class="fas fa-arrow-up fa-fw"></i>
                                </button>
                                <button class="btn btn-sm btn-default" @click.prevent="sortChildItems(child, 'headerLeft', -1)">
                                  <i class="fas fa-arrow-down fa-fw"></i>
                                </button>
                              </div>
                              <div>
                                <strong>{{ getChildMenuItems(child, headerLeft).length }}</strong> menu items
                                <button class="btn btn-sm btn-default ml-1" title="add menu item" @click.prevent="addEntry('header', 'left', 'headerLeft', true, child.id)">
                                  <i class="fas fa-plus fa-fw"></i>
                                </button>
                              </div>
                            </div>
                            <div class="list-unstyled ml-4">
                              <div v-for="grandChild in getChildMenuItems(child, headerLeft)" :key="grandChild.id">
                                <div class="list-group-item bg-light d-flex align-items-start mt-2">
                                  <div class="pr-3">
                                    <i v-if="grandChild.nav_type === 'asset'" class="fas fa-image"></i>
                                    <i v-else-if="grandChild.nav_type === 'spotlights'" class="fas fa-star"></i>
                                    <i v-else class="fas fa-link"></i>
                                  </div>
                                  <ul class="list-unstyled flex-fill">
                                    <li class="row">
                                      <div class="col">
                                        <strong>
                                          {{ grandChild.text }}
                                          </strong> <small class="text-muted pl-2">{{ grandChild.languages }}</small>
                                        <label v-if="grandChild.show_loggedin"><i class="text-muted fas fa-lock fa-xs pl-2"></i></label>
                                        <label v-if="grandChild.show_loggedoff"><i class="text-muted fas fa-lock-open fa-xs pl-2"></i></label>
                                      </div>
                                    </li>
                                    <li v-if="grandChild.link" class="text-muted"><small>{{ grandChild.link }}</small></li>
                                    <li class="text-muted">
                                      <small v-if="grandChild.link_type">Type: {{ grandChild.link_type }} </small>
                                      <small v-if="grandChild.link_type && grandChild.link_target"> | </small>
                                      <small v-if="grandChild.link_target">Target: {{ grandChild.link_target }}</small>
                                    </li>
                                    <li v-if="grandChild.params" class="text-muted"><small>Parameter: {{ grandChild.params }}</small></li>
                                  </ul>
                                  <button class="btn btn-sm btn-primary m-1" @click.prevent="editEditNavigationItem(grandChild)">
                                    <i class="fas fa-edit fa-fw"></i>
                                  </button>
                                  <button class="btn btn-sm btn-danger m-1" @click.prevent="deleteEntry(grandChild, 'headerLeft')">
                                    <i class="fas fa-trash-alt fa-fw"></i>
                                  </button>
                                </div>
                                <div class="list-group-item bg-light d-flex justify-content-start py-2 px-3">
                                  <div>
                                    <button class="btn btn-sm btn-default" @click.prevent="sortChildItems(grandChild, 'headerLeft', 1)">
                                      <i class="fas fa-arrow-up fa-fw"></i>
                                    </button>
                                    <button class="btn btn-sm btn-default" @click.prevent="sortChildItems(grandChild, 'headerLeft', -1)">
                                      <i class="fas fa-arrow-down fa-fw"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                    <button slot="footer" class="btn btn-primary mt-1 mb-3" @click.prevent="addEntry('header', 'left', 'headerLeft')">
                      <i class="fas fa-plus"></i> add menu item
                    </button>
                  </draggable>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="row">
                <div class="col">
                  <label>Right Navigation</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <draggable v-model="headerRight" draggable=".dragitem" group="menuitems-hr" class="list-group dragbox" style="cursor: all-scroll;" @end="resetSort('headerRight')">
                    <div v-for="(item, key) in headerRight" :key="key" class="dragitem">
                      <div v-if="isTopLevelItem(item)" class="list-group-item list-group-item-secondary d-flex align-items-start mt-1" :class="{'is-spotlight': item.nav_type === 'spotlights'}">
                        <div class="pr-3">
                          <i v-if="item.nav_type === 'dropdown'" class="fas fa-chevron-circle-down text-secondary"></i>
                          <i v-else-if="item.nav_type === 'asset'" class="fas fa-image text-secondary"></i>
                          <i v-else-if="item.nav_type === 'spotlights'" class="fas fa-star"></i>
                          <i v-else-if="item.link_type === 'external'" class="fas fa-external-link-alt text-secondary"></i>
                          <i v-else class="fas fa-link text-secondary"></i>
                        </div>
                        <div class="text-nowrap w-100 overflow-hidden">
                          <ul class="list-unstyled flex-fill">
                            <li class="row">
                              <div class="col">
                                <strong>
                                  {{ item.text }}
                                  </strong> <small class="text-muted pl-2">{{ item.languages }}</small>
                                <label v-if="item.show_loggedin"><i class="text-muted fas fa-lock fa-xs pl-2"></i></label>
                                <label v-if="item.show_loggedoff"><i class="text-muted fas fa-lock-open fa-xs pl-2"></i></label>
                              </div>
                            </li>
                            <li v-if="item.link" class="text-muted"><small>{{ item.link }}</small></li>
                            <li class="text-muted">
                              <small v-if="item.link_type">Type: {{ item.link_type }} </small>
                              <small v-if="item.link_type && item.link_target"> | </small>
                              <small v-if="item.link_target">Target: {{ item.link_target }}</small>
                            </li>
                            <li v-if="item.params" class="text-muted"><small>Parameter: {{ item.params }}</small></li>
                          </ul>
                        </div>
                        <div class="d-flex align-items-end">
                          <button class="btn btn-sm btn-primary shadow-sm ml-1" @click.prevent="editEditNavigationItem(item)">
                            <i class="fas fa-edit fa-fw"></i>
                          </button>
                          <button class="btn btn-sm btn-danger shadow-sm ml-1" @click.prevent="deleteEntry(item, 'headerRight')">
                            <i class="fas fa-trash-alt fa-fw"></i>
                          </button>
                        </div>
                      </div>
                      <div v-if="item.nav_type === 'dropdown'">
                          <div class="list-group-item list-group-item-secondary py-2 px-3 d-flex justify-content-between">
                            <div>
                            </div>
                            <div>
                              <button class="btn py-1" type="button" data-toggle="collapse" :data-target="'#collapse-headerRight_' + key" title="toggle menu items">
                                <strong>{{ getChildMenuItems(item, headerRight).length }}</strong> menu items
                                <i class="fas fa-chevron-down fa-fw"></i>
                              </button>
                              <button class="btn btn-sm btn-default shadow-sm" title="add menu item" @click.prevent="addEntry('header', 'right', 'headerRight', true, item.id)">
                                <i class="fas fa-plus fa-fw"></i>
                              </button>
                            </div>
                          </div>
                      </div>
                      <div v-if="item.nav_type === 'dropdown'" :id="'collapse-headerRight_' + key" class="collapse list-unstyled ml-4 mt-1">
                          <div v-for="child in getChildMenuItems(item, headerRight)" :key="child.id">
                            <div class="list-group-item bg-light d-flex align-items-start mt-2">
                              <div class="pr-3">
                                <i v-if="child.nav_type === 'asset'" class="fas fa-image"></i>
                                <i v-else-if="child.nav_type === 'spotlights'" class="fas fa-star"></i>
                                <i v-else class="fas fa-link"></i>
                              </div>
                              <div class="text-nowrap w-100 overflow-hidden">
                                <ul class="list-unstyled flex-fill">
                                  <li class="row">
                                    <div class="col">
                                      <strong>
                                        {{ child.text }}
                                        </strong> <small class="text-muted pl-2">{{ child.languages }}</small>
                                      <label v-if="child.show_loggedin"><i class="text-muted fas fa-lock fa-xs pl-2"></i></label>
                                      <label v-if="child.show_loggedoff"><i class="text-muted fas fa-lock-open fa-xs pl-2"></i></label>
                                    </div>
                                  </li>
                                  <li v-if="child.link" class="text-muted"><small>{{ child.link }}</small></li>
                                  <li class="text-muted">
                                    <small v-if="child.link_type">Type: {{ item.link_type }} </small>
                                    <small v-if="child.link_type && item.link_target"> | </small>
                                    <small v-if="child.link_target">Target: {{ item.link_target }}</small>
                                  </li>
                                  <li v-if="child.params" class="text-muted"><small>Parameter: {{ child.params }}</small></li>
                                </ul>
                              </div>
                              <div class="d-flex align-items-end">
                                <button class="btn btn-sm btn-primary ml-1" @click.prevent="editEditNavigationItem(child)">
                                  <i class="fas fa-edit fa-fw"></i>
                                </button>
                                <button class="btn btn-sm btn-danger ml-1" @click.prevent="deleteEntry(child, 'headerRight')">
                                  <i class="fas fa-trash-alt fa-fw"></i>
                                </button>
                              </div>
                            </div>
                            <div class="list-group-item bg-light d-flex justify-content-between py-2 px-3">
                              <div>
                                <button class="btn btn-sm btn-default" @click.prevent="sortChildItems(child, 'headerRight', 1)">
                                  <i class="fas fa-arrow-up fa-fw"></i>
                                </button>
                                <button class="btn btn-sm btn-default" @click.prevent="sortChildItems(child, 'headerRight', -1)">
                                  <i class="fas fa-arrow-down fa-fw"></i>
                                </button>
                              </div>
                              <div>
                                <strong>{{ getChildMenuItems(child, headerRight).length }}</strong> menu items
                                <button class="btn btn-sm btn-default ml-1" title="add menu item" @click.prevent="addEntry('header', 'right', 'headerRight', true, child.id)">
                                  <i class="fas fa-plus fa-fw"></i>
                                </button>
                              </div>
                            </div>
                            <div class="list-unstyled ml-4">
                              <div v-for="grandChild in getChildMenuItems(child, headerRight)" :key="grandChild.id">
                                <div class="list-group-item bg-light d-flex align-items-start mt-2">
                                  <div class="pr-3">
                                    <i v-if="grandChild.nav_type === 'asset'" class="fas fa-image"></i>
                                    <i v-else-if="grandChild.nav_type === 'spotlights'" class="fas fa-star"></i>
                                    <i v-else class="fas fa-link"></i>
                                  </div>
                                  <ul class="list-unstyled flex-fill">
                                    <li class="row">
                                      <div class="col">
                                        <strong>
                                          {{ grandChild.text }}
                                          </strong> <small class="text-muted pl-2">{{ grandChild.languages }}</small>
                                        <label v-if="grandChild.show_loggedin"><i class="text-muted fas fa-lock fa-xs pl-2"></i></label>
                                        <label v-if="grandChild.show_loggedoff"><i class="text-muted fas fa-lock-open fa-xs pl-2"></i></label>
                                      </div>
                                    </li>
                                    <li v-if="grandChild.link" class="text-muted"><small>{{ grandChild.link }}</small></li>
                                    <li class="text-muted">
                                      <small v-if="grandChild.link_type">Type: {{ grandChild.link_type }} </small>
                                      <small v-if="grandChild.link_type && grandChild.link_target"> | </small>
                                      <small v-if="grandChild.link_target">Target: {{ grandChild.link_target }}</small>
                                    </li>
                                    <li v-if="grandChild.params" class="text-muted"><small>Parameter: {{ grandChild.params }}</small></li>
                                  </ul>
                                  <button class="btn btn-sm btn-primary m-1" @click.prevent="editEditNavigationItem(grandChild)">
                                    <i class="fas fa-edit fa-fw"></i>
                                  </button>
                                  <button class="btn btn-sm btn-danger m-1" @click.prevent="deleteEntry(grandChild, 'headerRight')">
                                    <i class="fas fa-trash-alt fa-fw"></i>
                                  </button>
                                </div>
                                <div class="list-group-item bg-light d-flex justify-content-start py-2 px-3">
                                  <div>
                                    <button class="btn btn-sm btn-default" @click.prevent="sortChildItems(grandChild, 'headerRight', 1)">
                                      <i class="fas fa-arrow-up fa-fw"></i>
                                    </button>
                                    <button class="btn btn-sm btn-default" @click.prevent="sortChildItems(grandChild, 'headerRight', -1)">
                                      <i class="fas fa-arrow-down fa-fw"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                    <button slot="footer" class="btn btn-primary mt-1 mb-3" @click.prevent="addEntry('header', 'right', 'headerRight')">
                      <i class="fas fa-plus"></i> add menu item
                    </button>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mt-4">
      <div class="card">
        <h4 class="card-header">
          Footer Navigation
        </h4>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col">
                  <label>Left Navigation</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <draggable v-model="footerLeft" draggable=".dragitem" group="menuitems-fl" class="list-group dragbox" @end="resetSort('footerLeft')">
                    <div v-for="(item, key) in footerLeft" :key="key" class="list-group-item list-group-item-secondary dragitem" :class="{'is-spotlight': item.nav_type === 'spotlights'}">
                      <div class="row">
                        <div class="col">
                          <ul class="list-unstyled">
                            <li class="row">
                              <div class="col">
                                <strong>
                                  <label v-if="item.nav_type === 'spotlights'"><i class="fas fa-link"></i> </label>
                                  {{ item.text }}
                                </strong> <small class="text-muted pl-2">{{ item.languages }}</small>
                                <label v-if="item.show_loggedin"><i class="text-muted fas fa-lock pl-2"></i></label>
                                <label v-if="item.show_loggedoff"><i class="text-muted fas fa-lock-open pl-2"></i></label>
                              </div>
                              <div class="col-auto">
                                <button class="btn btn-sm btn-default" @click.prevent="editEditNavigationItem(item)">
                                  <i class="fas fa-edit fa-fw"></i>
                                </button>
                                <button class="btn btn-sm btn-danger" @click.prevent="deleteEntry(item, 'headerLeft')">
                                  <i class="fas fa-trash-alt fa-fw"></i>
                                </button>
                              </div>
                            </li>
                            <li v-if="item.link" class="text-muted"><small>{{ item.link }}</small></li>
                            <li class="text-muted">
                              <small v-if="item.link_type">Type: {{ item.link_type }} </small>
                              <small v-if="item.link_type && item.link_target"> | </small>
                              <small v-if="item.link_target">Target: {{ item.link_target }}</small>
                            </li>
                            <li v-if="item.params" class="text-muted"><small>Parameter: {{ item.params }}</small></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <button slot="footer" class="btn btn-primary" @click.prevent="addEntry('footer', 'left', 'footerLeft')">
                      <i class="fas fa-plus"></i> add menu item
                    </button>
                  </draggable>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="row">
                <div class="col">
                  <label>Right Navigation</label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <draggable v-model="footerRight" draggable=".dragitem" group="menuitems-fr" class="list-group dragbox" @end="resetSort('footerRight')">
                    <div v-for="(item, key) in footerRight" :key="key" class="list-group-item list-group-item-secondary dragitem" :class="{'is-spotlight': item.nav_type === 'spotlights'}">
                      <div class="row">
                        <div class="col">
                          <ul class="list-unstyled">
                            <li class="row">
                              <div class="col">
                                <strong>
                                  <label v-if="item.nav_type === 'spotlights'"><i class="fas fa-link"></i> </label>
                                  {{ item.text }}
                                  </strong> <small class="text-muted pl-2">{{ item.languages }}</small>
                                <label v-if="item.show_loggedin"><i class="text-muted fas fa-lock pl-2"></i></label>
                                <label v-if="item.show_loggedoff"><i class="text-muted fas fa-lock-open pl-2"></i></label>
                              </div>
                              <div class="col-auto">
                                <button class="btn btn-sm btn-default" @click.prevent="editEditNavigationItem(item)">
                                  <i class="fas fa-edit fa-fw"></i>
                                </button>
                                <button class="btn btn-sm btn-danger" @click.prevent="deleteEntry(item, 'headerLeft')">
                                  <i class="fas fa-trash-alt fa-fw"></i>
                                </button>
                              </div>
                            </li>
                            <li v-if="item.link" class="text-muted"><small>{{ item.link }}</small></li>
                            <li class="text-muted">
                              <small v-if="item.link_type">Type: {{ item.link_type }} </small>
                              <small v-if="item.link_type && item.link_target"> | </small>
                              <small v-if="item.link_target">Target: {{ item.link_target }}</small>
                            </li>
                            <li v-if="item.params" class="text-muted"><small>Parameter: {{ item.params }}</small></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <button slot="footer" class="btn btn-primary" @click.prevent="addEntry('footer', 'right', 'footerRight')">
                      <i class="fas fa-plus"></i> add menu item
                    </button>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <edit-navigation-modal
      v-model="showModal"
      :title="'Edit Navigation Item: ' + editItem.text"
      :navItem="editItem"
      :action="modalAction"
      @cancelAction="cancelEditNavigationItem()"
      @updateAction="updateNavigationItem"
      />
  </div>
</template>
<script>
import Vue from 'vue'
import draggable from 'vuedraggable'

export default {
  name: 'Navigation',
  components: { draggable },
  props: ['client'],
  data () {
    return {
      usermenu: [],
      spotlightsmenu: [],
      headerLeft: [],
      headerRight: [],
      footerLeft: [],
      footerRight: [],
      editItem: {},
      showModal: false,
      modalAction: ''
    }
  },
  watch: {
    'client.menuitems' (menuitems) {
      if (menuitems) {
        this.usermenu = menuitems.filter(item => {
          return item.position === 'usermenu'
        }).sort((a, b) => a.sort === b.sort ? 0 : (a.sort < b.sort ? -1 : 1))

        this.spotlightsmenu = menuitems.filter(item => {
          return item.position === 'spotlightsmenu'
        }).sort((a, b) => a.sort === b.sort ? 0 : (a.sort < b.sort ? -1 : 1))

        this.headerLeft = menuitems.filter(item => {
          return item.position === 'header' && item.side === 'left'
        }).sort((a, b) => a.sort === b.sort ? 0 : (a.sort < b.sort ? -1 : 1))

        this.headerRight = menuitems.filter(item => {
          return item.position === 'header' && item.side === 'right'
        }).sort((a, b) => a.sort === b.sort ? 0 : (a.sort < b.sort ? -1 : 1))

        this.footerLeft = menuitems.filter(item => {
          return item.position === 'footer' && item.side === 'left'
        }).sort((a, b) => a.sort === b.sort ? 0 : (a.sort < b.sort ? -1 : 1))

        this.footerRight = menuitems.filter(item => {
          return item.position === 'footer' && item.side === 'right'
        }).sort((a, b) => a.sort === b.sort ? 0 : (a.sort < b.sort ? -1 : 1))
      }
    }
  },
  methods: {
    editEditNavigationItem (item) {
      if (item.id != null) {
        this.editItem = JSON.parse(JSON.stringify(item))
      } else {
        this.editItem = item
      }
      this.modalAction = 'edit'
      this.showModal = true
    },
    cancelEditNavigationItem () {
      this.showModal = false
    },
    updateNavigationItem (item, modalAction) {
      if (modalAction === 'edit' && item.id) {
        const index = this.client.menuitems.findIndex(navigationItem => navigationItem.id === item.id)
        Vue.set(this.client.menuitems, index, item)
      }
      if (modalAction === 'add') {
        this.client.menuitems.push(item)
      }
      this.showModal = false
    },
    resetSort (navigation) {
      const nav = this[navigation]
      nav.forEach((item, key) => {
        this.$set(nav[key], 'sort', (key + 1) * 1)
      })
    },
    addEntry (position, side, navigation, isChild = false, parentId) {
      if (isChild && typeof parentId === 'undefined') {
        this.$swal({
          title: 'Save before adding menu items',
          text: 'Before menu items can be added to this entry, please hit the save button below.',
          icon: 'warning'
        })
        return
      }
      const data = {
        position: position,
        side: side,
        parent_id: parentId,
        link_type: 'external',
        link_target: '',
        show_loggedin: 1,
        show_loggedoff: 1,
        sort: this[navigation].length + 1
      }
      this.editItem = data
      this.modalAction = 'add'
      this.showModal = true
    },
    deleteEntry (item, navigation) {
      this.resetSort(navigation)
      const childItems = this.getChildMenuItems(item, this[navigation])
      childItems.forEach(childItem => this.deleteEntry(childItem, navigation))
      this.client.menuitems.splice(this.client.menuitems.indexOf(item), 1)
    },
    isTopLevelItem (item) {
      return !item.parent_id
    },
    getChildMenuItems (item, menuItems) {
      return menuItems
        .filter(menuItem => menuItem.parent_id && menuItem.parent_id === item.id)
        .sort((a, b) => a.sort - b.sort)
    },
    sortChildItems (item, menuItems, direction) {
      const siblings = this[menuItems].filter(e => e.parent_id === item.parent_id)
      const sortedSiblings = siblings.sort((a, b) => a.sort - b.sort)
      const nextSibling = sortedSiblings[siblings.indexOf(item) - direction]
      if (!nextSibling) {
        return
      }
      const itemSort = item.sort
      item.sort = nextSibling.sort
      nextSibling.sort = itemSort
    }
  }
}
</script>

<style style lang="scss">
.is-spotlight {
  background: #8c8d8e;
  color: #222 !important;

  .text-muted {
    color: #222 !important;
  }
}
</style>
