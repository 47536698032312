<template>
  <b-modal
    id="createPageModal"
    v-model="show"
    scrollable
    no-fade
    size="lg"
    :title="'Create new page'"
    hide-footer
    @hide="closeModal">
    <form role="form" @submit.prevent>
      <div class="row pl-3 pr-3">
        <div class="col">
          <h5 class="mb-3 mt-4">Empty Page</h5>
          <ul class="list-group mb-5">
            <button type="button" class="list-group-item list-group-item-action" @click.prevent="createPage()">
              <div class="d-flex align-items-center font-weight-bold">
                <i class="far fa-file mr-2"></i>
                Create Empty Page
              </div>
            </button>
          </ul>

          <div class="mb-3 row">
            <label for="searchTemplates" class="col-sm-6 col-form-label">
              <h5 class="mb-0">Page Templates</h5>
            </label>
            <div class="col-sm-6">
              <form id="search" class="ui form" @submit.prevent>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text"><i class="fas fa-search"></i></div>
                  </div>
                  <input v-model="query" name="query" class="form-control" placeholder="Search page templates">
                </div>
              </form>
            </div>
          </div>

          <ul class="list-group mb-5">
            <li class="list-group-item pt-2 pb-2 bg-light font-weight-bold d-flex justify-content-between">
              <div>Name</div>
              <div>Type</div>
            </li>
            <button v-for="pageTemplate in filteredData" :key="pageTemplate.id" type="button" class="list-group-item list-group-item-action d-flex justify-content-between" @click.prevent="createPage(pageTemplate)">
              <div class="d-flex align-items-center font-weight-bold">
                <i class="far fa-file-alt mr-2"></i>
                {{ pageTemplate.name }}
              </div>
              <span class="badge ml-3 mt-1" :class="[pageTemplate.type === 'clientTemplate' ? 'badge-success' : 'badge-warning']">{{ getType(pageTemplate.type) }}</span>
            </button>
            <li v-if="filteredData.length === 0" class="list-group-item d-flex align-items-center bg-ligh">
                <i class="far fa-question-circle mr-3"></i>
                no templates found
            </li>
          </ul>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
  name: 'create-page-modal',
  props: {
    clientId: {
      type: String
    }
  },
  data () {
    return {
      show: true,
      query: '',
      templates: []
    }
  },
  computed: {
    filteredData () {
      var filterKey = this.query && this.query.toLowerCase()
      var data = this.templates
      if (data && filterKey) {
        data = data.filter(row => {
          return Object.keys(row).some(key => {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1
          })
        })
      }
      if (data) {
        const sortKey = 'type'
        const order = -1
        data = data.slice().sort((a, b) => {
          a = a[sortKey]
          b = b[sortKey]
          return (a === b ? 0 : a > b ? 1 : -1) * order
        })
      }
      return data
    }
  },
  created () {
    this.getPageTemplates()
  },
  methods: {
    createPage (template) {
      this.$emit('createPage', template)
    },
    closeModal () {
      this.$emit('close')
    },
    getType (type) {
      switch (type) {
        case 'adminTemplate':
          return 'master template'
        case 'clientTemplate':
          return 'client template'
        default:
          return 'unknown type'
      }
    },
    getPageTemplates () {
      this.isLoading = true
      this.$store
        .dispatch('getPageTemplates', { clientId: this.clientId })
        .then(response => {
          this.templates = response
          this.isLoading = false
        })
        .catch(error => {
          this.$swal({
            title: 'Error',
            text: 'Could not fetch page templates: ' + error,
            icon: 'error'
          })
        })
    }
  }
}
</script>
