<template>
  <section class="content">
    <edit-giftcodes v-if="client.data.id && client.data.id.length > 0" :client="client"></edit-giftcodes>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import EditGiftcodes from './EditGiftcodes.vue'

export default {
  name: 'editClient',
  components: {
    EditGiftcodes
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      client: 'getClient'
    }),
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    }
  },
  created () {
    this.getClientData(this.$route.params.client)
  },
  methods: {
    getClientData (client) {
      this.isLoading = true
      this.$store
        .dispatch('getClientData', client)
        .then(response => {
          this.isLoading = false
        })
    }
  }
}
</script>
