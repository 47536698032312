<template>
  <section class="container-fluid bg-white">
    <Table
      :data="this.domains || []"
      :columns="table.columns"
      :actionButton="table.actionButton"
      :disableSearch="false"
      :search="table.search"
      :actions="table.actions"
      @rowClicked="table.clickHandler"
    />
  </section>
</template>

<script>

export default {
  data () {
    return {
      domains: [],
      table: {
        columns: [
          {
            label: 'Domain',
            property: 'domain',
            searchable: true,
            sort: {
              enabled: true,
              default: true
            }
          },
          {
            label: 'Client',
            transform: (domain) => domain.client.name,
            searchable: true,
            sort: {
              enabled: true
            }
          },
          {
            label: 'Created at (UTC)',
            property: 'created_at',
            sort: { enabled: true }
          },
          {
            label: 'Updated at (UTC)',
            property: 'updated_at',
            sort: { enabled: true }
          }
        ],
        actions: [
          { icon: 'fas fa-trash-alt', buttonClass: 'btn-danger', action: this.deleteItem }
        ],
        actionButton: {
          icon: 'fa fa-plus',
          label: 'Create new domain',
          action: () => this.$router.push({ name: 'DomainCreate' })
        },
        clickHandler: (item) => this.$router.push({ name: 'DomainEdit', params: { id: item.id } }),
        search: {
          placeholder: 'Search domains',
          disabled: false
        }
      }
    }
  },
  computed: {
    createDomainLink () {
      return { name: 'DomainCreate' }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.isLoading = true
      this.$store
        .dispatch('getDomains')
        .then(response => {
          this.domains = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch domains: ' + error,
            icon: 'error'
          })
        })
    },
    deleteItem (item) {
      this
        .$swal({
          title: 'Are you really sure?',
          html: 'The domain <strong>"' + item.domain + '"</strong> will be deleted. This cannot be undone!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#dd4b39',
          cancelButtonText: 'Cancel'
        })
        .then(
          result => {
            if (result.value) {
              this.isLoading = true
              this.$store
                .dispatch('deleteDomain', item.id)
                .then(response => {
                  const index = this.domains.indexOf(this.domains.find(domain => domain.id === item.id))
                  this.domains.splice(index, 1)
                  this.$swal({
                    title: 'Success',
                    text: 'Domain has been deleted',
                    icon: 'success',
                    showConfirmButton: false,
                    toast: true,
                    position: 'top',
                    timer: 2000
                  })
                  this.isLoading = false
                }).catch(error => {
                  this.isLoading = false
                  this.error = true
                  this.$swal({
                    title: 'Error',
                    text: 'could not delete domain: ' + error,
                    icon: 'error'
                  })
                })
            }
          }
        )
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.fetchData()
    next()
  }
}
</script>
