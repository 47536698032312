<template>
  <ValidationObserver v-if="partialProjectData" ref="observer" v-slot="{ invalid }" tag="div" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h3>API</h3>
        <hr>
      </div>
    </div>

    <div class="row">
      <div class="col-12">

        <form action="#" role="form">

          <div class="row">
            <div class="col-12">
              <h4>Webcastmanager Configuration</h4>
            </div>
          </div>

          <div class="form-row align-items-center">
            <ValidationProvider v-slot="{ errors }" vid="apiClient" name="api client" tag="div" class="col-xl col-md-12">
              <div class="form-group" :class="{'has-error': errors[0]}">
                <label for="apiClientInput">API Client *</label>
                <input id="apiClientInput" v-model="partialProjectData.apiClient" type="text" class="form-control" required="required">
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" vid="apiClientSecret" name="api client secret" tag="div" class="col-xl col-md-12">
              <div class="form-group" :class="{'has-error': errors[0]}">
                <label for="apiClientSecret">API Client Secret *</label>
                <input id="apiClientSecret" v-model="partialProjectData.apiClientSecret" type="text" class="form-control" required="required">
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <div class="col-auto d-none d-xl-block">
              <b-btn id="apiClient" class="btn btn-info mt-3">
                <i class="fas fa-question-circle"></i>
              </b-btn>
              <b-popover target="apiClient" placement="topleft" effect="fade" :triggers="'hover'">
                <template slot="title">Webcastmanager API Client Credentials</template>
                oAuth2 API Client Credentials for the User Group that is granted access to the Collection to be used for this Project
              </b-popover>
            </div>
          </div>

          <div class="form-row align-items-center">
            <ValidationProvider v-slot="{ errors }" vid="apiBaseUrl" name="api base url" rules="required" tag="div" class="col-xl col-md-12">
              <div class="form-group" :class="{'has-error': errors[0]}">
                <label for="apiBaseUrl">API Base URL *</label>
                <input id="apiBaseUrl" v-model="partialProjectData.apiBaseUrl" type="text" class="form-control" required="required">
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" vid="apiTokenBaseUrl" name="api token base url" tag="div" class="col-xl col-md-12">
              <div class="form-group" :class="{'has-error': errors[0]}">
                <label for="apiTokenBaseUrl">API Token Base URL *</label>
                <input id="apiTokenBaseUrl" v-model="partialProjectData.apiTokenBaseUrl" type="text" class="form-control" required="required">
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" vid="apiVersion" name="api version" tag="div" class="col-xl-2 col-md-12">
              <div class="form-group" :class="{'has-error': errors[0]}">
                <label for="apiVersion">API Version *</label>
                <input id="apiVersion" v-model="partialProjectData.apiVersion" type="text" class="form-control" name="apiVersion" required="required">
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <div class="col-auto d-none d-xl-block">
              <b-btn id="apiClientSettings" class="btn btn-info mt-3">
                <i class="fas fa-question-circle"></i>
              </b-btn>
              <b-popover target="apiClientSettings" placement="topleft" effect="fade" :triggers="'hover'">
                <template slot="title">Webcastmanager API Settings</template>
                Base URL, Token Base URL and Version can be overwritten for testing purposes<br>
              </b-popover>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <h4>Data Source</h4>
            </div>
          </div>

          <ValidationProvider v-slot="{ errors }" vid="collection" name="collection" tag="div" class="form-row align-items-center">
            <div class="col-xl col-md-12 form-group" :class="{'has-error': errors[0]}">
              <div v-if="collections.length > 0">
                <label for="collection">Collection *</label>
                <select id="collection" v-model="partialProjectData.collection" name="collection" class="form-control" required="required" @change="collectionChangeHandler">
                  <option value="" selected>Please select collection</option>
                  <option v-for="collection in collections" :key="collection.id" :value="collection.id">{{ collection.title + ' (' + collection.id + ')' }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
              <div v-else class="col">
                <button class="btn btn-success text-center" @click.prevent="getCollections">find Collections</button>
              </div>
            </div>
          </ValidationProvider>

          <div class="row">
            <div class="col-12">
              <h5>Live Content</h5>
            </div>
          </div>

          <ValidationProvider v-slot="{ errors }" vid="liveDistribution" name="live distribution" tag="div" class="form-row align-items-center">
            <div class="col-xl col-md-12 form-group" :class="{'has-error': errors[0]}">
              <label for="liveDistribution">Distribution</label>
              <div v-if="liveDistributions.length > 0">
                <select id="liveDistribution" v-model="partialProjectData.liveDistribution" name="liveDistribution" class="form-control" @change="liveDistributionChangeHandler">
                  <option value="" selected>Please select live distribution</option>
                  <option v-for="liveDistribution in liveDistributions" :key="liveDistribution.id" :value="liveDistribution.id">{{ (liveDistribution.shortname || liveDistribution.title) + ' (' + liveDistribution.id + ')' }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
              <div v-else class="col">
                No live distributions defined for collection.
              </div>
            </div>
          </ValidationProvider>

          <div class="form-row align-items-center">
            <div class="col-xl col-md-12 form-group">
              <label>Channels</label><br>
              <div v-if="channels.length > 0" class="d-flex flex-wrap">
                <button v-for="(channel, index) in channels" :key="index" style="min-width:8em;" class="flex-fill btn mr-1 mb-1" :class="channel.active ? 'btn-success' : 'btn-warning'" @click.prevent="changeChannelStatus(index)">{{ channel.title }}</button>
                <div class="row">
                  <div class="col">
                    <button class="btn btn-light" @click.prevent="selectAllChannels">select all channels</button>
                    <button class="btn btn-light" @click.prevent="deselectAllChannels">deselect all channels</button>
                  </div>
                </div>
              </div>
              <div v-else class="col">
                No channels found for distribution.
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <h5>On Demand Content</h5>
            </div>
          </div>

          <ValidationProvider v-slot="{ errors }" vid="ondemandDistribution" name="ondemand distribution" tag="div" class="form-row align-items-center">
            <div class="col-xl col-md-12 form-group" :class="{'has-error': errors[0]}">
              <label for="ondemandDistribution">Distribution</label>
              <div v-if="ondemandDistributions.length > 0">
                <select id="ondemandDistribution" v-model="partialProjectData.ondemandDistribution" name="ondemandDistribution" class="form-control" @change="ondemandDistributionChangeHandler">
                  <option value="" selected>Please select ondemand distribution</option>
                  <option v-for="ondemandDistribution in ondemandDistributions" :key="ondemandDistribution.id" :value="ondemandDistribution.id">{{ (ondemandDistribution.shortname || ondemandDistribution.title) + ' (' + ondemandDistribution.id + ')' }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
              <div v-else class="col">
                No ondemand distributions defined for collection.
              </div>
            </div>
          </ValidationProvider>

          <div class="form-row align-items-center">
            <div class="col-xl col-md-12">
              <div class="form-group" :class="{'has-error': !searchindexFilter}">
                <label for="filter">Filter for Congressresources</label>
                <div class="input-group">
                  <input id="filter" v-model="searchindex.filterPart1" type="text" class="form-control" name="filterPart1" required="required">
                  <div class="input-group-append input-group-prepend">
                    <span class="input-group-text">!</span>
                  </div>
                  <input v-model="searchindex.filterPart2" type="text" class="form-control" name="filterPart2">
                </div>
                <small v-if="!searchindexFilter" class="text-danger">The filter for congressresources filter field is required.</small>
                <small id="searchindexfilter" class="form-text text-muted">
                  {{searchindexFilter}}
                </small>
              </div>
              <!-- speacial handling because ValidationProvider does not supprt multiple input fields from same v-model -->
              <ValidationProvider v-slot="{ errors }" vid="searchindex.filter" name="searchindex filter" slim>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>

            <div class="col-xl col-md-12">
              <div class="form-group">
                <label for="eventfilter">Filter for Events</label>
                <div class="input-group">
                  <input id="eventfilter" v-model="searchindex.eventfilterPart1" type="text" class="form-control" name="eventfilterPart1">
                  <div class="input-group-append input-group-prepend">
                    <span class="input-group-text">!</span>
                  </div>
                  <input v-model="searchindex.eventfilterPart2" type="text" class="form-control" name="eventfilterPart2">
                </div>
                <small id="searchindexEventfilter" class="form-text text-muted">
                  {{searchindexEventfilter}}
                </small>
              </div>
            </div>
          </div>

          <div class="form-row align-items-center">
            <div class="col-xl col-md-12 form-group">
              <label>Elasticsearch Index Status</label>
              <div class="card" :class="elasicsearchIndexStatusClass">
                <h6 class="card-header">{{partialProjectData.indexname}}</h6>
                <div class="card-body">

                  <div class="row">
                    <div v-if="esStatus.indexExists" class="col">
                      <div class="row mb-2 mb-xl-0">
                        <div class="col-12 col-xl-auto" style="width:11em;">Elasticsearch alias:</div>
                        <strong class="col">{{ esStatus.aliasOnVersion }}</strong>
                      </div>

                      <div class="row mb-2">
                        <div class="col-12 col-xl-auto" style="width:11em;">DB expected alias:</div>
                        <strong class="col">{{ esStatus.actualVersion }}</strong>
                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="row mb-2 mb-xl-0">
                            <div class="col-12 col-xl-auto" style="width:11em;">Number of CRs:</div>
                            <strong class="col">{{ esStatus.counts.congressresources }}</strong>
                          </div>
                          <div class="row">
                            <div class="col-12 col-xl-auto" style="width:11em;">Number of Events:</div>
                            <strong class="col">{{ esStatus.counts.events }}</strong>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div v-else class="col">
                      <h3>No index <br><small>create one, maybe?</small></h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 mt-3 mt-xl-0">
              <div class="row">
                <div class="col">
                  <button v-if="!esStatus.indexExists" class="btn btn-danger btn-block" @click.prevent="createIndex"><i class="fas fa-plus"></i> create Index</button>
                  <button v-else class="btn btn-success btn-block" disabled="true"><i class="fas fa-check"></i> Index exists</button>
                </div>
              </div>
              <div class="row my-2">
                <div class="col d-flex">
                  <button type="button" :disabled="elasicsearchIndexStatus !== 'success' || !esStatus.rollbackPossible" class="mr-1 flex-fill btn btn-secondary" @click.prevent="rollbackIndex"><i class="fas fa-backward"></i> rollback index</button>
                  <button :disabled="elasicsearchIndexStatus === 'success' || !esStatus.indexExists" class="ml-1 flex-fill btn btn-secondary" @click.prevent="resetAlias"><i class="fas fa-undo"></i> reset alias</button>
                </div>
              </div>

              <hr>

              <div v-if="reindexingResources" class="progress mb-2" style="height:3em;">
                <div class="progress-bar progress-bar-striped progress-bar-animated w-100" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                 <h6 class="pt-2"><div class="spinner-grow spinner-grow-sm" role="status"></div> reindexing CRs, Events &amp; Speakers</h6>
                </div>
              </div>
              <button v-else :disabled="elasicsearchIndexStatus !== 'success'" class="btn btn-primary btn-block" @click.prevent="startReindexingResources"><i class="fas fa-sync"></i> reindex CRs, Events &amp; Speakers</button>

              <div v-if="reindexingSpeaker" class="btn btn-block progress" style="height: 31px;">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">reindexing Speakers</div>
              </div>
              <button v-else :disabled="elasicsearchIndexStatus !== 'success'" class="btn btn-primary btn-block" @click.prevent="startReindexingSpeaker"><i class="fas fa-sync"></i> reindex Speakers</button>
            </div>
          </div>

          <div class="row mt-3">
            <div v-if="reindexingLog.length > 0" class="col">
              <div class="card text-white bg-dark">
                <div class="card-body">
                  <div v-for="entry in reindexingLog" :key="entry.message+entry.created_at" class="row" :class="entry.log_type !== 'info'? 'bg-danger' : ''">
                    <code class="text-white col my-0">[{{entry.log_type}}] {{entry.created_at}} - {{entry.message}}</code>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row align-items-center">
            <div class="col-xl col-md-12">
              <div class="form-group">
                <label for="updateCrs">Update CRs by id(s)</label>
                <div class="input-group">
                  <input id="updateCrs" v-model="update.congressresourcesIds" type="text" class="form-control" name="updateCrs">
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="button" @click.prevent="updateResources('congressresources')"><i class="fas fa-sync"></i> update/delete</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto d-none d-xl-block">
              <b-btn id="updateCrsInfo" class="btn btn-info mt-3">
                <i class="fas fa-question-circle"></i>
              </b-btn>
              <b-popover target="updateCrsInfo" placement="topleft" effect="fade" :triggers="'hover'">
                <template slot="title">Update Congressresources</template>
                Update selected Congressresources by id<br>
                <span class="small">comma separated list, e.g. 1001,1002,1003,...</span>
              </b-popover>
            </div>
          </div>

          <div class="form-row align-items-center">
            <div class="col-xl col-md-12">
              <div class="form-group">
                <label for="updateEvents">Update Events by id(s)</label>
                <div class="input-group">
                  <input id="updateEvents" v-model="update.eventsIds" type="text" class="form-control" name="updateEvents">
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="button" @click.prevent="updateResources('events')"><i class="fas fa-sync"></i> update/delete</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto d-none d-xl-block">
              <b-btn id="updateEventInfo" class="btn btn-info mt-3">
                <i class="fas fa-question-circle"></i>
              </b-btn>
              <b-popover target="updateEventInfo" placement="topleft" effect="fade" :triggers="'hover'">
                <template slot="title">Update Events</template>
                Update selected Events by id<br>
                <span class="small">comma separated list, e.g. 1001,1002,1003,...</span>
              </b-popover>
            </div>
          </div>
        </form>
      </div>
    </div>
    <hr>

    <SaveBar
      :saveDisabled="invalid"
      @save="updateProjectData(close = false)"
      @saveAndClose="updateProjectData(close = true)"
      @cancel="cancel"
    />
  </ValidationObserver>
</template>

<script>
export default {
  name: 'ProjectEditApi',
  data () {
    return {
      collections: [],
      channels: [],
      liveDistributions: [],
      ondemandDistributions: [],
      update: {
        congressresourcesIds: '',
        eventsIds: ''
      },
      esStatus: {
        indexExists: false,
        rollbackPossible: false,
        aliasOnVersion: 0,
        actualVersion: 0,
        counts: {
          congressresources: 0,
          events: 0
        }
      },
      reindexingResources: false,
      reindexingSpeaker: false,
      reindeingInterval: [],
      reindexingLog: [],
      reindexingProcess: 'no process running',
      indexingKey: null,
      indexStatusTimeout: null,
      reindexStatusTimeout: null,
      isActiveProject: false,
      partialProjectData: false,
      searchindex: {}
    }
  },
  computed: {
    elasicsearchIndexStatus () {
      if (!this.esStatus.indexExists || !this.esStatus.aliasOnVersion) {
        return 'error'
      }
      if (this.esStatus.aliasOnVersion !== this.esStatus.actualVersion) {
        return 'warning'
      }
      return 'success'
    },
    elasicsearchIndexStatusClass () {
      if (this.elasicsearchIndexStatus === 'error') {
        return 'bg-danger text-white'
      }
      if (this.elasicsearchIndexStatus === 'warning') {
        return 'bg-warning text-white'
      }
      return 'border-success'
    },
    searchindexFilter () {
      if (!this.searchindex.filterPart1 && !this.searchindex.filterPart2) {
        return ''
      }
      if (!this.searchindex.filterPart2) {
        return this.searchindex.filterPart1
      }
      return this.searchindex.filterPart1 + '!' + this.searchindex.filterPart2
    },
    searchindexEventfilter () {
      if (!this.searchindex.eventfilterPart1 && !this.searchindex.eventfilterPart2) {
        return ''
      }
      if (!this.searchindex.eventfilterPart2) {
        return this.searchindex.eventfilterPart1
      }
      return this.searchindex.eventfilterPart1 + '!' + this.searchindex.eventfilterPart2
    }
  },
  created () {
    this.isActiveProject = true
    this.getProjectData()
  },
  beforeDestroy () {
    this.isActiveProject = false
    clearTimeout(this.indexStatusTimeout)
    clearTimeout(this.reindexStatusTimeout)
  },
  methods: {
    mergeChannels () {
      this.channels = this.channels.map(channel => {
        const projectChannel = this.partialProjectData.channels.filter(pchannel => {
          return pchannel.channel_id === channel.id
        })
        if (projectChannel[0]) {
          this.$set(channel, 'active', projectChannel[0].active)
        }
        return channel
      })
      this.partialProjectData.channels = this.channels
    },
    getCollections () {
      const params = {
        apiBaseUrl: this.partialProjectData.apiBaseUrl,
        apiTokenBaseUrl: this.partialProjectData.apiTokenBaseUrl,
        apiClient: this.partialProjectData.apiClient,
        apiClientSecret: this.partialProjectData.apiClientSecret,
        apiVersion: 'v2'
      }
      this.isLoading = true
      this.$store
        .dispatch('getCollections', params)
        .then(
          response => {
            this.collections = response.collections
            this.isLoading = false
          },
          response => { // error
            this.$swal({
              title: 'Error',
              text: 'Could not load collections',
              icon: 'error'
            })
            this.isLoading = false
          })
    },
    getDistributions () {
      const params = {
        apiBaseUrl: this.partialProjectData.apiBaseUrl,
        apiTokenBaseUrl: this.partialProjectData.apiTokenBaseUrl,
        apiClient: this.partialProjectData.apiClient,
        apiClientSecret: this.partialProjectData.apiClientSecret,
        collection: this.partialProjectData.collection,
        apiVersion: 'v2'
      }
      this.$store
        .dispatch('getDistributions', params)
        .then(
          response => {
            this.liveDistributions = response.distributions.filter(distribution => distribution.isLive)
            this.ondemandDistributions = response.distributions.filter(distribution => distribution.isOndemand)
            this.isLoading = false

            if (!this.liveDistributions.length) {
              this.partialProjectData.liveDistribution = ''
            }

            if (!this.ondemandDistributions.length) {
              this.partialProjectData.ondemandDistribution = ''
            }
          },
          response => { // error
            this.$swal({
              title: 'Error',
              text: 'Could not load distributions',
              icon: 'error'
            })
            this.isLoading = false
          })
        .then(() => {
          this.getChannels()
        })
    },
    getChannels () {
      const params = {
        apiBaseUrl: this.partialProjectData.apiBaseUrl,
        apiTokenBaseUrl: this.partialProjectData.apiTokenBaseUrl,
        apiClient: this.partialProjectData.apiClient,
        apiClientSecret: this.partialProjectData.apiClientSecret,
        apiVersion: 'v2'
      }

      if (this.partialProjectData.liveDistribution) {
        params.distribution = this.partialProjectData.liveDistribution
      } else {
        params.collection = this.partialProjectData.collection
      }

      this.$store
        .dispatch('getChannels', params)
        .then(
          response => {
            this.channels = response.channels
            this.mergeChannels()
            this.isLoading = false
          },
          response => { // error
            this.$swal({
              title: 'Error',
              text: 'Could not load channels',
              icon: 'error'
            })
            this.isLoading = false
          })
    },
    collectionChangeHandler () {
      if (this.partialProjectData.collection === '') {
        this.resetDistributions()
      }
      this.getDistributions()
    },
    liveDistributionChangeHandler () {
      this.getChannels()
    },
    ondemandDistributionChangeHandler () {
      this.updateSearchindexFilterPart1()
    },
    resetDistributions () {
      this.liveDistributions = []
      this.ondemandDistributions = []
      this.partialProjectData.liveDistribution = ''
      this.partialProjectData.ondemandDistribution = ''
    },
    changeChannelStatus (channelsIndex) {
      if (this.channels[channelsIndex].active) {
        this.$set(this.channels[channelsIndex], 'active', false)
      } else {
        this.$set(this.channels[channelsIndex], 'active', true)
      }
      this.partialProjectData.channels = this.channels
    },
    selectAllChannels () {
      for (let i = 0; i <= this.channels.length - 1; i++) {
        this.$set(this.channels[i], 'active', true)
      }
      this.partialProjectData.channels = this.channels
    },
    deselectAllChannels () {
      for (let i = 0; i <= this.channels.length - 1; i++) {
        this.$set(this.channels[i], 'active', false)
      }
      this.partialProjectData.channels = this.channels
    },
    updateSearchindexFilterPart1 () {
      if (this.partialProjectData.ondemandDistribution) {
        this.$set(this.searchindex, 'filterPart1', '[distribution.' + this.partialProjectData.ondemandDistribution + ']')
        this.$set(this.searchindex, 'eventfilterPart1', '[distribution.' + this.partialProjectData.ondemandDistribution + ']')
      } else {
        if (this.partialProjectData.collection) {
          this.$set(this.searchindex, 'filterPart1', '[collection.' + this.partialProjectData.collection + ']')
          this.$set(this.searchindex, 'eventfilterPart1', '[collection.' + this.partialProjectData.collection + ']')
        }
      }

      this.$set(this.searchindex, 'filter', this.searchindexFilter)
      this.$set(this.searchindex, 'eventfilter', this.searchindexEventfilter)
    },
    repairSearchindexFilter () {
      if (!this.searchindex.filterPart2) {
        this.$set(this.searchindex, 'filterPart2', '[status.6]')
      }
      if (!this.searchindex.eventfilterPart2) {
        this.$set(this.searchindex, 'eventfilterPart2', '[status.6]')
      }
      if (!this.searchindex.filterPart1 || !this.searchindex.eventfilterPart1) {
        this.updateSearchindexFilterPart1()
      }
    },
    getIndexStatus () {
      this.isLoading = true
      if (this.isActiveProject) {
        this.$store
          .dispatch('getIndexStatus', this.$route.params.id)
          .then(
            response => {
              this.esStatus.indexExists = response.data.indexExists
              this.esStatus.rollbackPossible = response.data.rollbackPossible
              this.esStatus.aliasOnVersion = response.data.alias
              this.esStatus.actualVersion = response.data.indexname
              this.processKey = response.data.key
              this.searchindex.version = response.data.version
              this.searchindex.lock = response.data.lock
              this.searchindex.lock_speaker = response.data.lock_speaker
              this.searchindex.process_key = response.data.processKey
              if (response.data.counts) {
                this.esStatus.counts = response.data.counts
              }
              this.isLoading = false
              if (response.data.lock && !this.reindexingResources) {
                this.reindexingResources = true
                this.indexingProcessStatus(this.processKey)
              } else {
                this.indexStatusTimeout = setTimeout(() => {
                  this.getIndexStatus()
                }, 10000)
              }
            },
            error => {
              this.isLoading = false
              console.log(error)
            })
      }
    },
    createIndex () {
      this.isLoading = true
      this.$store
        .dispatch('createIndex', this.$route.params.id)
        .then(
          response => {
            this.$swal({
              title: 'Success',
              text: 'index created',
              icon: 'success',
              showConfirmButton: false,
              toast: true,
              position: 'top',
              timer: 2000
            })
            clearTimeout(this.indexStatusTimeout)
            this.getIndexStatus()
            this.isLoading = false
          })
        .catch(() => {
          this.$swal({
            title: 'Error creating index',
            text: 'Index could not be created',
            icon: 'error',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
          clearTimeout(this.indexStatusTimeout)
          this.getIndexStatus()
          this.isLoading = false
        })
    },
    rollbackIndex () {
      clearTimeout(this.indexStatusTimeout)
      this.isLoading = true
      this.$store
        .dispatch('rollbackIndex', this.$route.params.id)
        .then(
          response => {
            this.$swal({
              title: 'Success',
              text: 'rollback successfull',
              icon: 'success',
              showConfirmButton: false,
              toast: true,
              position: 'top',
              timer: 2000
            })
            this.isLoading = false
            this.getIndexStatus()
          },
          error => {
            this.$swal({
              title: 'Error rolling back index',
              text: 'Error occourt rolling back the index: ' + error,
              icon: 'error',
              showConfirmButton: false,
              toast: true,
              position: 'top',
              timer: 2000
            })
            this.isLoading = false
            this.getIndexStatus()
          })
    },
    resetAlias () {
      this.isLoading = true
      this.$store
        .dispatch('resetAlias', this.$route.params.id)
        .then(
          response => {
            this.$swal({
              title: 'Success',
              text: 'alias was reset',
              icon: 'success',
              showConfirmButton: false,
              toast: true,
              position: 'top',
              timer: 2000
            })
            clearTimeout(this.indexStatusTimeout)
            this.getIndexStatus()
            this.isLoading = false
          },
          error => {
            this.$swal({
              title: 'Error resetting Alais',
              text: 'Error occourt resetting alias: ' + error,
              icon: 'error',
              showConfirmButton: false,
              toast: true,
              position: 'top',
              timer: 2000
            })
            clearTimeout(this.indexStatusTimeout)
            this.getIndexStatus()
            this.isLoading = false
          })
    },
    startReindexingResources () {
      if (!this.searchindex.filter || this.searchindex.filter.length === 0) {
        this
          .$swal({
            title: 'Error indexing Resources',
            text: 'Be sure to set filters for at least the congressresources',
            icon: 'error'
          }).then(result => {
            return false
          })
      } else {
        this.reindexingLog = []
        this
          .$swal({
            title: 'Reindexing Events, Congressresources and Speakers',
            text: 'Reindexing can take a while. Are you sure you want to procceed?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'start',
            confirmButtonColor: '#5cb85c',
            cancelButtonText: 'cancel'
          })
          .then(
            result => {
              if (result.value) {
                clearTimeout(this.indexStatusTimeout)
                this.reindexingResources = true
                this.$store
                  .dispatch('reindexResources', this.$route.params.id)
                  .then(response => {
                    this.reindexStatusTimeout = setTimeout(() => {
                      this.indexingProcessStatus(this.processKey)
                    }, 2000)
                  }).catch(error => {
                    this.$swal({
                      title: 'Error',
                      text: 'something went wrong :: ' + error.data.message,
                      icon: 'error',
                      showConfirmButton: false,
                      toast: true,
                      position: 'top',
                      timer: 3000
                    })
                    this.reindexingResources = false
                  })
              }
            }
          )
      }
    },
    updateResources (type) {
      this.reindexingLog = []
      this
        .$swal({
          title: 'Updating Events and Congressresources',
          text: 'Updating can take a while. Are you sure you want to procceed?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'start',
          confirmButtonColor: '#5cb85c',
          cancelButtonText: 'cancel'
        })
        .then(
          result => {
            if (result.value) {
              this.isLoading = true
              this.$store
                .dispatch('updateResources', { projectId: this.$route.params.id, type: type, ids: this.update[type + 'Ids'] })
                .then(response => {
                  this.$swal({
                    title: 'Success',
                    text: 'updating was successful',
                    icon: 'success',
                    showConfirmButton: false,
                    toast: true,
                    position: 'top',
                    timer: 2000
                  })
                  this.reindexingLog = response.data.log
                  this.isLoading = false
                }).catch(error => {
                  this.$swal({
                    title: 'Error',
                    text: 'something went wrong :: ' + error.data.message,
                    icon: 'error',
                    showConfirmButton: false,
                    toast: true,
                    position: 'top',
                    timer: 3000
                  })
                  this.isLoading = false
                })
            }
          }
        )
    },
    startReindexingSpeaker () {
      this.reindexingLog = []
      this
        .$swal({
          title: 'Reindexing Speakers',
          text: 'Reindexing can take a while. Are you sure you want to procceed?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'start',
          confirmButtonColor: '#5cb85c',
          cancelButtonText: 'cancel'
        })
        .then(
          result => {
            if (result.value) {
              clearTimeout(this.indexStatusTimeout)
              this.reindexingSpeaker = true
              this.$store
                .dispatch('reindexSpeaker', this.$route.params.id)
                .then(response => {
                  this.reindexStatusTimeout = setTimeout(() => {
                    this.indexingProcessStatus(this.processKey)
                  }, 2000)
                }).catch(error => {
                  this.$swal({
                    title: 'Error',
                    text: 'something went wrong ' + error,
                    icon: 'error',
                    showConfirmButton: false,
                    toast: true,
                    position: 'top',
                    timer: 2000
                  })
                  this.reindexingSpeaker = false
                })
            }
          }
        )
    },
    indexingProcessStatus (processKey) {
      this.$store
        .dispatch('indexingProcessStatus', { projectId: this.$route.params.id, processKey: processKey })
        .then(response => {
          this.reindexingLog = response.log.slice(-5)
          this.reindexingProcess = response.process
          if (response.finished === true) {
            this.reindexingResources = false
            this.reindexingSpeaker = false
            this.getIndexStatus()
          } else {
            this.reindexStatusTimeout = setTimeout(() => {
              this.indexingProcessStatus(this.processKey)
            }, 5000)
          }
        }).catch(error => {
          this.reindexingLog.push({ message: 'something went wrong ' + error, class: 'bg-danger', created_at: new Date() })
          this.reindexingProcess = 'cancel because of errors'
          this.reindexingResources = false
          this.reindexingSpeaker = false
        })
    },
    getProjectData () {
      this.isLoading = true
      this.$store
        .dispatch('getPartialProjectData', { projectId: this.$route.params.id, tab: 'edit-api' })
        .then(response => {
          this.partialProjectData = response
          this.getCollections()
          this.getIndexStatus()
          if (response.collection) {
            this.getDistributions()
          }
          if (!response.collection) {
            this.partialProjectData.collection = ''
          }
          if (!response.liveDistribution) {
            this.partialProjectData.liveDistribution = ''
          }
          if (!response.ondemandDistribution) {
            this.partialProjectData.ondemandDistribution = ''
          }
          if (response.searchindex) {
            this.searchindex = response.searchindex
          } else {
            this.$swal({
              title: 'Whoops, search index configuration missing',
              html: 'We can fix that for you - click repair to create a fresh configuration and save the project.',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: '<i class="fas fa-hammer"></i> repair',
              confirmButtonColor: '#5cb85c',
              cancelButtonText: 'cancel',
              footer: '<div>Check the <i class="fas fa-exchange-alt"></i> API tab to review the new configuration.</div>'
            })
              .then(result => {
                if (result.value) {
                  this.repairSearchindexFilter()
                  this.updateProjectData()
                }
              })
          }
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    cancel () {
      this.$router.push('/clients/' + this.$route.params.client + '/projects')
    },
    updateProjectData (close = false) {
      this.isLoading = true
      this.$set(this.searchindex, 'filter', this.searchindexFilter)
      this.$set(this.searchindex, 'eventfilter', this.searchindexEventfilter)
      this.partialProjectData.searchindex = this.searchindex

      this.$store
        .dispatch('updatePartialProjectData', { projectId: this.$route.params.id, data: this.partialProjectData, tab: 'edit-api' })
        .then(response => {
          this.$swal({
            title: 'Success',
            text: response.message,
            icon: 'success',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
          this.isLoading = false
          if (close) {
            this.cancel()
          }
        }).catch(error => {
          this.$refs.observer.setErrors(error.response.data.errors)
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not update project: ' + error.response.data.message,
            icon: 'error',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
        })
    }
  }
}
</script>

<style>

</style>
