<template>

  <section class="content">
    <div v-if="error" class="alert alert-danger fade in">
      <strong>Sorry!</strong> {{ errorMsg }}
    </div>
    <div v-if="success" class="alert alert-success fade in">
      <strong>Success!</strong> {{ successMsg }}
    </div>

    <router-view></router-view>
  </section>

</template>

<script>
export default {
  data () {
    return {
      error: false,
      errorMsg: '',
      success: false,
      successMsg: ''
    }
  },
  computed: {
    callAPI () {
      return this.$parent.callAPI
    },
    changeloading () {
      return this.$parent.changeloading
    }
  }
}
</script>

<style>

</style>
