
<ValidationObserver v-slot="{ invalid }" tag="section" class="container-fluid">
  <b-modal
    :id="id"
    scrollable
    size="lg"
    no-fade
    :title="title"
    @hide="$emit('close')"
  >
      <form name="create-page-template" class="form row">
        <div class="col-12">
          <general-page-config
            v-model="generalPageConfig"
          ></general-page-config>
        </div>
      </form>

      <template v-slot:modal-footer>
        <SaveBar
          :saveDisabled="invalid"
          :saveButton="{
            iconClass: 'fas fa-save',
            label: 'create',
            class: 'btn btn-success'
          }"
          :saveAndCloseButton="{
            iconClass: 'fas fa-edit',
            label: 'create + edit',
            class: 'btn btn-success'
          }"
          @save="create"
          @saveAndClose="createAndEdit"
          @cancel="cancel"
        />
      </template>

  </b-modal>
  </ValidationObserver>
