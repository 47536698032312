import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

import domainsModule from './domains'
import translationsModule from './translations'
import usersModule from './users'
import assetsModule from './assets'

const state = {
  isLoading: false,
  searching: '',
  serverURI: import.meta.env.VITE_API_BASE_URL,
  assetURI: import.meta.env.VITE_ASSET_BASE_URL,
  token: null,
  userInfo: {
    messages: [{ 1: 'test', 2: 'test' }],
    notifications: [],
    tasks: []
  },
  user: null,
  openImagePanel: false,
  newProject: {
    step: 1,
    client_id: '',
    title: '',
    name: '',
    preview: 1,
    languages: [],
    images: [],
    apiClient: '',
    apiClientSecret: '',
    apiBaseUrl: import.meta.env.VITE_WCM_BASE_URL,
    apiTokenBaseUrl: import.meta.env.VITE_WCM_TOKEN_BASE_URL,
    apiVersion: 'v2',
    cacheTtl: '10',
    crsFilter: '',
    eventFilter: ''
  },
  timezones: [],
  client: {
    intro: {
      asset: {}
    },
    data: {
      title: '',
      name: '',
      id: false,
      description: '',
      apiClient: '',
      apiClientSecret: '',
      apiBaseUrl: import.meta.env.VITE_WCM_BASE_URL,
      apiTokenBaseUrl: import.meta.env.VITE_WCM_TOKEN_BASE_URL,
      apiVersion: 'v2',
      cacheTtl: '10',
      token: '',
      default_styles: '',
      custom_styles: '',
      theme_color: ''
    },
    assets: [],
    security: {
      passcode: false,
      tokenauth: false
    },
    languages: []
  },
  defaultRoutes: [
    {
      name: 'Home',
      protected: false
    },
    {
      name: 'Detail',
      protected: false
    },
    {
      name: 'Live',
      protected: false
    },
    {
      name: 'Project',
      protected: false
    }
  ],
  availableLanguages: false,
  select: {},
  translations: false,
  activeLang: false,
  translationsOverwrites: {},
  securitysettings: false,
  addgroup: {},
  editGroup: false,
  educationitemsList: {}
}

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    domains: domainsModule,
    translations: translationsModule,
    users: usersModule,
    assets: assetsModule
  },
  state,
  getters,
  actions,
  mutations
})
