<script>
export default {
  methods: {
    addModule () {
      let ypos = 0
      this.layout.forEach((item, key) => {
        if (item.y > ypos) {
          ypos = item.y
        }
        item.i = '' + key
      })
      ypos = ypos + 4
      this.layout.push({
        x: 0,
        y: ypos,
        w: 12,
        h: 4,
        i: '' + this.layout.length,
        hide: false
      })
    },
    updateLayout () {
      this.layout.forEach((item, key) => {
        if (
          this.page.pagewidgets[key] &&
          this.page.pagewidgets[key].pivot.layout
        ) {
          this.$set(this.page.pagewidgets[key].pivot, 'layout', JSON.stringify(item))
        }
        if (this.module.key === key) {
          this.$set(this.module, 'layout', JSON.stringify(item))
        }
      })
    },
    getModules () {
      this.isLoading = true
      this.$store
        .dispatch('getModules')
        .then(response => {
          this.availableModules = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch modules: ' + error,
            icon: 'error'
          })
        })
    },
    getModuleName (module) {
      if (!module) {
        return undefined
      }

      const model = JSON.parse(module.pivot.data || '{}')

      if (model['collapse-items']) {
        return model['collapse-items']
          .map(item => {
            return item.name
          })
          .join(',')
      }

      return model.name
    },
    removeItem (item) {
      this.resetModule()
      const itemIndex = this.layout.indexOf(item)
      this.layout.splice(itemIndex, 1)
      this.page.pagewidgets.splice(itemIndex, 1)
      this.layout.forEach((item, key) => {
        item.i = '' + key
      })
    },
    setLayout (key, item) {
      this.$set(this.page.pagewidgets[key], 'pivot', {
        layout: JSON.stringify(item)
      })
    },
    resetModule () {
      this.$set(this, 'module', {})
      this.$set(this, 'editModule', false)
      this.closeEditModuleModal()
    },
    openEditModuleModal () {
      this.$bvModal.show(this.editModuleModalId)
    },
    closeEditModuleModal () {
      this.$bvModal.hide(this.editModuleModalId)
    },
    moduleSelected (key) {
      if (
        typeof this.page.pagewidgets[key] === 'undefined' ||
        !this.page.pagewidgets[key]
      ) {
        return false
      }
      if (typeof this.page.pagewidgets[key].pivot === 'undefined') {
        return false
      }
      if (this.page.pagewidgets[key].pivot.layout == null) {
        return false
      }
      return true
    },
    saveModule (moduleData) {
      const pivot = {
        page_id: this.page.id,
        id: this.module.pivotId,
        data: JSON.stringify(moduleData),
        module_id: this.module.id,
        layout: this.module.layout
      }
      this.$set(this.page.pagewidgets[this.module.key], 'pivot', pivot)
      this.module = {}
      this.editModule = false
      this.closeEditModuleModal()
    },
    cloneModule (sourceModule, key) {
      const clone = JSON.parse(JSON.stringify(this.page.pagewidgets[key]))
      const ypos = sourceModule.y + sourceModule.h
      const layoutItem = {
        x: sourceModule.x,
        y: ypos,
        w: sourceModule.w,
        h: sourceModule.h,
        i: '' + this.layout.length,
        hide: sourceModule.hide
      }
      this.layout.forEach((item, i) => {
        if (item.y >= ypos) {
          item.y = item.y + layoutItem.h
        }
      })
      this.layout.push(layoutItem)
      clone.pivot.layout = JSON.stringify(layoutItem)
      this.page.pagewidgets.push(clone)
      this.updateLayout()

      this.$nextTick(() => {
        this.$refs.layout[this.layout.length - 1].$el.classList.add('is-clone')
      })
    },
    showModuleSettings (module, GridId) {
      let pivotId = module.id * GridId
      if (module.pivot) {
        if (module.pivot.id) {
          pivotId = module.pivot.id
        }
      }
      this.resetModule()
      this.module.key = GridId * 1
      this.module.layout = JSON.stringify(this.layout[GridId])
      this.module.id = module.id
      this.module.pivotId = pivotId
      this.module.configuration = JSON.parse(module.configuration)
      this.module.model = JSON.parse(module.model)
      if (module.pivot) {
        if (module.pivot.data) {
          this.module.model = JSON.parse(module.pivot.data)
        }
      }
      this.editModule = module.name
      this.openEditModuleModal()
    },
    removeModule (key) {
      this.resetModule()
      this.$set(this.page.pagewidgets[key], 'pivot', {})
    }
  }
}
</script>
