<template>
  <section>
    <div class="row">
      <div class="col-12">
        <h3>{{title}}</h3>
      </div>
    </div>
    <div v-if="showModules">
      <grid-layout
        :layout="layout"
        :col-num="12"
        :row-height="30"
        :is-draggable="true"
        :is-resizable="true"
        :vertical-compact="true"
        :margin="[10, 10]"
        :use-css-transforms="true"
      >
        <grid-item
          v-for="(item, key) in layout"
          :key="item.i"
          ref="layout"
          class="card"
          :class="{'text-light bg-secondary border-none': item.hide, 'border-secondary': !item.hide} "
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          drag-allow-from=".vue-draggable-handle"
          drag-ignore-from=".no-drag"
          @resized="updateLayout"
          @moved="updateLayout"
        >
          <div :class="{'bg-transparent': item.hide} " class="card-header vue-draggable-handle">
            <template v-if="moduleSelected(key)">
              <span v-if="getModuleName(data.modules[key])" class="text-nowrap">
                <strong>
                  {{getModuleName(data.modules[key])}}
                </strong>
              </span>
            </template>
            <span v-else class="text-nowrap">new module</span>
            <button type="button" class="close no-drag" title="remove" aria-label="remove" @click.prevent="removeItem(item)">
              <span aria-hidden="true">&times;</span>
            </button>
            <span
              v-if="moduleSelected(key)"
              class="float-right no-drag mr-4 mt-1"
            >
              <input :id="'checkbox-'+title+item.i" v-model="item.hide" type="checkbox" @change="updateLayout">
              <label :for="'checkbox-'+title+item.i">hide</label>
            </span>
            <div v-if="moduleSelected(key)">
              <span class="text-nowrap">
                <div
                  class="badge badge-dark"
                >
                  <span>{{data.modules[key].name}}</span>
                </div>
              </span>
            </div>
          </div>
          <div class="card-body">
            <div v-if="moduleSelected(key)" class="row">
              <div class="col">
                <button class="btn btn-primary" @click.prevent="showModuleSettings(data.modules[key],key)">
                  <i class="fas fa-pencil-alt"></i><span class="d-none d-xl-inline"> settings</span>
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-primary" title="clone module" @click.prevent="cloneModule(item, key)">
                  <i class="fas fa-copy"></i>
                </button>
                <button class="btn btn-danger" @click.prevent="removeModule(key)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
            <div v-else class="form-group">
              <select id="module" v-model="data.modules[key]" class="form-control" @change.prevent="setLayout(key, item)">
                <option value="undefined" selected disabled>Please select module template</option>
                <option v-for="module in availableModules" :key="module.name + key" :value="JSON.parse(JSON.stringify(module))">{{module.name}}</option>
              </select>
            </div>
          </div>
        </grid-item>
      </grid-layout>
      <div class="row">
        <div class="col-12 text-center">
          <button class="btn btn-primary" @click.prevent="addRow">
            <i class="fa fa-plus"></i> add module
          </button>
        </div>
      </div>
    </div>
    <div v-else class="container-fluid">
      <div class="row form-inline">
        <div class="col-12 text-center">
          <button class="btn btn-primary" @click.prevent="showCreatePageModal = true">
            Create New Page
          </button>
        </div>
      </div>
    </div>

    <edit-module-modal
      :id="editModuleModalId"
      :title="'Edit Module Settings for: ' + editModule"
      :module="module"
      :moduletype="editModule || ''"
      @cancelAction="resetModule()"
      @updateAction="saveModule($event)"
      />

    <create-page-modal
      v-if="showCreatePageModal"
      :clientId="data.client_id"
      @createPage="createModules($event)"
      @close="showCreatePageModal = false"
      />

  </section>
</template>

<script>
import { GridLayout, GridItem } from 'vue-grid-layout'

export default {
  name: 'editModules',
  components: {
    GridLayout,
    GridItem
  },
  props: ['title', 'availableModules', 'data'],
  data () {
    return {
      search: {
        congress: {}
      },
      editModule: false,
      module: {
        configuration: [],
        model: {},
        id: '',
        layout: {},
        pivotId: 0
      },
      layout: [],
      showCreatePageModal: false,
      showEmptyModules: false,
      editModuleModalId: this.$uuid.v4()
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    },
    showModules () {
      if (this.data?.modules?.length > 0) {
        return true
      }
      if (this.showEmptyModules) {
        return true
      }

      return false
    }
  },
  watch: {
    'data.id' (id) {
      if (id) {
        this.loadLayout()
      }
    }
  },
  methods: {
    getModuleName (module) {
      const model = JSON.parse(module.pivot.data || '{}')

      if (model['collapse-items']) {
        return model['collapse-items']
          .map(item => {
            return item.name
          })
          .join(',')
      }

      return model.name
    },
    openEditModuleModal () {
      this.$bvModal.show(this.editModuleModalId)
    },
    closeEditModuleModal () {
      this.$bvModal.hide(this.editModuleModalId)
    },
    moduleSelected (key) {
      if (
        typeof this.data.modules[key] === 'undefined' ||
        !this.data.modules[key]
      ) {
        return false
      }
      if (typeof this.data.modules[key].pivot === 'undefined') {
        return false
      }
      if (this.data.modules[key].pivot.layout == null) {
        return false
      }
      return true
    },
    addRow () {
      let ypos = 0
      this.layout.forEach((item, key) => {
        if (item.y > ypos) {
          ypos = item.y
        }
        item.i = '' + key
      })
      ypos = ypos + 4
      this.layout.push({
        x: 0,
        y: ypos,
        w: 12,
        h: 4,
        i: '' + this.layout.length
      })
    },
    removeItem (item) {
      this.resetModule()
      const itemIndex = this.layout.indexOf(item)
      this.layout.splice(itemIndex, 1)
      this.data.modules.splice(itemIndex, 1)
      this.layout.forEach((item, key) => {
        item.i = '' + key
      })
    },
    updateLayout () {
      this.layout.forEach((item, key) => {
        if (
          this.data.modules[key] &&
          this.data.modules[key].pivot.layout
        ) {
          this.$set(this.data.modules[key].pivot, 'layout', JSON.stringify(item))
        }
        if (this.module.key === key) {
          this.$set(this.module, 'layout', JSON.stringify(item))
        }
      })
    },
    setLayout (key, item) {
      this.$set(this.data.modules[key], 'pivot', {
        layout: JSON.stringify(item)
      })
    },
    resetModule () {
      this.$set(this, 'module', {})
      this.$set(this, 'editModule', false)
      this.closeEditModuleModal()
    },
    saveModule (moduleData) {
      const pivot = {
        client_id: this.data.client_id,
        id: this.module.pivotId,
        data: JSON.stringify(moduleData),
        module_id: this.module.id,
        layout: this.module.layout
      }
      this.$set(this.data.modules[this.module.key], 'pivot', pivot)
      this.module = {}
      this.editModule = false
      this.closeEditModuleModal()
    },
    cloneModule (sourceModule, key) {
      const clone = JSON.parse(JSON.stringify(this.data.modules[key]))
      const ypos = sourceModule.y + sourceModule.h
      const layoutItem = {
        x: sourceModule.x,
        y: ypos,
        w: sourceModule.w,
        h: sourceModule.h,
        i: '' + this.layout.length,
        hide: sourceModule.hide
      }
      this.layout.forEach((item, i) => {
        if (item.y >= ypos) {
          item.y = item.y + layoutItem.h
        }
      })
      this.layout.push(layoutItem)
      clone.pivot.layout = JSON.stringify(layoutItem)
      this.data.modules.push(clone)
      this.updateLayout()

      this.$nextTick(() => {
        this.$refs.layout[this.layout.length - 1].$el.classList.add('is-clone')
      })
    },
    showModuleSettings (module, GridId) {
      let pivotId = module.id * GridId
      if (module.pivot) {
        if (module.pivot.id) {
          pivotId = module.pivot.id
        }
      }
      this.resetModule()
      this.module.key = GridId * 1
      this.module.layout = JSON.stringify(this.layout[GridId])
      this.module.id = module.id
      this.module.pivotId = pivotId
      this.module.configuration = JSON.parse(module.configuration)
      this.module.model = JSON.parse(module.model)
      if (module.pivot) {
        if (module.pivot.data) {
          this.module.model = JSON.parse(module.pivot.data)
        }
      }
      this.editModule = module.name
      this.openEditModuleModal()
    },
    removeModule (key) {
      this.resetModule()
      this.$set(this.data.modules[key], 'pivot', {})
    },
    createModules (page = null) {
      this.showCreatePageModal = false

      if (page != null) {
        this.data.modules = page.pagewidgets
        this.loadLayout()
      } else {
        this.showEmptyModules = true
      }
    },
    loadLayout (page = null) {
      this.data.modules.forEach(module => {
        this.layout.push(JSON.parse(module.pivot.layout))
      })
      this.layout.forEach((item, key) => {
        item.i = '' + key
      })
    }
  }
}
</script>

<style>
.is-clone {
  opacity: 1;
  animation-name: cloneHighlighting;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes cloneHighlighting {
  0% {
    opacity: 0;
    box-shadow: 0px 0px 2px 6px rgba(3, 102, 214, 0.3);
  }
  10% {
    opacity: 1;
  }
  100% {
    box-shadow: 0px 0px 0px 6px transparent;
  }
}
</style>
