
<ValidationObserver tag="section" class="content">
  <div class="row">
    <div class="col-12">
      <form v-if="pageTemplate" name="create-module" class="form row">
        <input v-model="pageTemplate.id" type="hidden" name="id">
        <div class="col-12">
          <edit-page :name="pageTemplate.name" :client="'cirse'" @update="getPageTemplateById($route.params.id)"></edit-page>
        </div>
      </form>
    </div>
  </div>
</ValidationObserver>
