import axios from 'axios'
import qs from 'qs'

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL
axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params, { arrayFormat: 'indices' })
}

const actions = {
  getDomain (context, domainId) {
    const url = context.state.serverURI + '/api/v2/domains/' + domainId
    return axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get domain', e)
      })
  },
  async getDomains (context) {
    const url = context.state.serverURI + '/api/v2/domains'

    return (await axios.get(url)).data
  },
  createDomain (context, data) {
    const url = context.state.serverURI + '/api/v2/domains'
    return axios
      .post(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('create domain ', e)
      })
  },
  updateDomain (context, { domainId, data }) {
    const url = context.state.serverURI + '/api/v2/domains/' + domainId
    return axios
      .put(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('update domain ', e)
      })
  },
  deleteDomain (context, domainId) {
    const url = context.state.serverURI + '/api/v2/domains/' + domainId
    return axios
      .delete(url)
      .then(response => {})
      .catch(e => {
        console.warn('delete domain', e)
      })
  }
}

export default actions
