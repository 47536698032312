<template>
  <ValidationObserver v-slot="{ invalid }" tag="section" class="container-fluid">
    <div class="row box">
      <div class="col-12">
        <h2>Edit education item</h2>
        <form name="edit-module" role="form">
          <input v-if="educationitemData.id" v-model="educationitemData.id" type="hidden" name="id">
          <div class="row">
            <ValidationProvider v-slot="{ errors }" name="title" tag="div" class="col-6">
              <div class="form-group" :class="{'has-error': errors[0]}">
                <label for="title">Educationitem Title: *</label>
                <input id="title" v-model.trim="educationitemData.title" type="text" class="form-control" required="required">
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
            <div v-if="educationitemData.slug" class="col-6">
              <div class="form-group">
                <label for="slug">slug:</label>
                <input id="slug" v-model.lazy.trim="educationitemData.slug" type="text" class="form-control" name="slug">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              <div class="form-group">
                <label for="parent">Parent:</label>
                <select id="parent" v-model.lazy="educationitemData.parent_id" name="parent" class="form-control">
                  <option value="">select a Parent Educationitem</option>
                  <option v-for="item in educationitems" :key="item.id" :value="item.id"> {{ item.title }}</option>
                </select>
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <label for="draft">draft-mode:</label><br>
                <input id="draft" v-model.lazy.trim="educationitemData.draft" type="checkbox" name="draft" value="1">
              </div>
            </div>
            <div class="col-1">
              <label>&nbsp;</label><br>
              <a :href="'http://' + $route.params.client + '.' + dcrFrontendHost + '/education/' + educationitemData.slug" target="_blank" class="btn btn-default"><i class="fas fa-eye"></i></a>
            </div>
          </div>
        </form>
      </div>
      <div class="col-12 mt-4">
        <edit-modules title="educationitem content" :availableModules="availableModules" :data="educationitemData"></edit-modules>
      </div>
      <SaveBar
        :saveDisabled="invalid"
        @save="saveEducationitem(close = false)"
        @saveAndClose="saveEducationitem(close = true)"
        @cancel="cancel"
      />
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'editEducationitem',
  data () {
    return {
      educationitemData: {
      },
      openerText: 'Open',
      isOpen: false,
      settingsIndex: false,
      asset: false,
      dcrFrontendHost: import.meta.env.VITE_DCR_FRONTEND_HOST,
      availableModules: []
    }
  },
  computed: {
    ...mapGetters({
      client: 'getClient',
      educationitems: 'getEducationitemsPlain'
    }),
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    }
  },
  watch: {
    $route (route) {
      if (route.params.id) {
        this.loadData(route.params.id)
        this.getAvailableModules()
      }
    }
  },
  created () {
    if (this.$route.params.id) {
      this.loadData(this.$route.params.id)
    }
    this.getClientData(this.$route.params.client)
    this.getAvailableModules()
  },
  methods: {
    cancel () {
      this.$router.push('/clients/' + this.$route.params.client + '/educationitems')
    },
    loadData (educationitemId) {
      this.isLoading = true
      this.$store
        .dispatch('getEducationitem', educationitemId)
        .then(response => {
          this.$set(this, 'educationitemData', response.data)
          this.$set(this.educationitemData, 'modules', response.modules)
          this.$set(this.educationitemData, 'asset', response.asset || {})
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    saveEducationitem (close = false) {
      this.isLoading = true
      this.$store
        .dispatch('updateEducationitem', { educationitemId: this.educationitemData.id, client: this.$route.params.client, data: this.educationitemData })
        .then(response => {
          this.$swal({
            title: 'Success',
            text: 'Educationitem was updated',
            icon: 'success',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
          this.isLoading = false
          if (close) {
            this.cancel()
          }
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not save educationitem: ' + error,
            icon: 'error'
          })
        })
    },
    getClientData (client) {
      this.isLoading = true
      this.$store
        .dispatch('getClientData', client)
        .then(response => {
          this.isLoading = false
        })
    },
    getAvailableModules () {
      this.isLoading = true
      this.$store
        .dispatch('getModulesForEducationitems')
        .then(response => {
          this.availableModules = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch modules: ' + error,
            icon: 'error'
          })
        })
    }
  }
}
</script>

<style>
</style>
