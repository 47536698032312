
<div class="imagepanel" :class="{ isOpen: isOpen }">

  <div class="imagepanel__opener">
    <button class="btn btn-primary" @click="toggle">
      <i class="fas fa-clipboard"></i> {{ openerText }}
    </button>
  </div>

  <div class="imagepanel__content container">

    <div class="row mb-3">
      <div v-if="uploadUrl" class="col">
        <h4>Client Assets</h4>
        <div v-if="assets.length > 0 && parentId !== ''">
          <button class="btn btn-primary" @click="oneUp">
            <i class="fas fa-level-up-alt"></i> up <strong>...</strong>
          </button>
          <button class="btn btn-default disabled">
            <i class="far fa-folder-open"></i> <span>{{ currentFolderName }}</span>
          </button>
        </div>
      </div>
    </div>

    <div v-if="assets.length == 0" class="row mb-3">
      <div class="col">
        <div class="alert alert-info" role="alert">
          <h5 class="alert-heading">No assets available...</h5>
          <hr>
          You can upload new assets by dropping them in the upload area below.<br>
          If you expect to have a lot of assets, you can organize them in folders.
        </div>
      </div>
    </div>

    <div v-if="folders.length > 0" class="row mb-3">
      <div class="col">
        <ul class="col list-group">
          <li v-for="folder in folders" :key="folder.id" class="list-group-item">
            <div class="folder">
              <button class="btn btn-primary" @click.prevent="setParentId(folder.id)">
                <span>
                  <i class="far fa-folder"></i> {{ folder.name }}</span>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="form-row mb-3">
      <div class="col">
        <input v-model="newFoldername" type="text" name="newfoldername" placeholder="folder name" class="form-control">
      </div>
      <div class="col">
        <button class="btn btn-success" @click.prevent="createFolder()">
          <i class="fas fa-plus"></i> create new folder
        </button>
      </div>
    </div>

    <div class="row mb-3">
      <div v-if="uploadUrl" class="col">
        <h4>Upload Images</h4>
        <dropzone
          id="uploadProjectImages"
          ref="fileUpload"
          :options="dropzoneOptions"
          class="alert alert-secondary"
          @vdropzone-success="showSuccess"
          @vdropzone-sending="sendingEvent"
        >
        </dropzone>
      </div>
    </div>

    <div v-if="images.length > 0" class="row mb-3">
      <div class="col">
        <ul class="list-group">
          <li v-for="image in images" :key="image.resource" class="list-group-item">

            <div class="row">
              <div class="col-3">
                <img v-if="image.type === 'image'" :src="[assetURI+'/'+client.data.name+'/img/'+image.resource]" width="100%">
                <img v-else src="/static/img/xml-icon.png" width="100%">
              </div>
              <div class="col-auto ml-auto">
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-sm btn-danger" @click.prevent="deleteAsset(image)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col" :title="image.resource">
                {{image.name}}
              </div>
            </div>

            <div class="row">
              <div class="col">
                <small class="text-muted">uploaded at: {{image.created_at}}</small>
              </div>
            </div>

          </li>
        </ul>
      </div>
    </div>

  </div>

</div>
