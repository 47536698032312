<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h3>Pages</h3>
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
       <div v-if="pages.length > 0" class="list-group">
         <a v-for="(page, index) in pages"
          :key="page.id + '_' + index"
          href="#"
          :class="getClassForPageItem(page.id)"
          class="list-group-item list-group-item-action"
          @click.prevent="editPage(page.id)"
          >
            <div v-if="page.title">{{ page.title }}</div>
            <div v-else class="text-danger">Title is missing</div>
          </a>
       </div>
       <div v-else>
         No pages defined.
       </div>
       <button class="btn btn-primary mt-4" @click.prevent="createPage"><i class="fa fa-plus"></i> add new Page</button>
      </div>
      <div v-if="page" class="col-9">
        <ValidationObserver ref="observer" v-slot="{ invalid }" tag="div" class="row">
          <div class="col-12">
            <div class="card mb-4">
              <h4 class="card-header">
                Page Settings: {{page.title}}
              </h4>
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <ValidationProvider v-slot="{ errors }" vid="pageTitle" name="pageTitle" slim>
                      <div class="form-group" :class="{'has-error': errors[0]}">
                        <label for="pageTitle">Title</label>
                        <input id="pageTitle" v-model="page.title" class="form-control" type="text" name="pageTitle" required>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </ValidationProvider>
                    </div>
                    <div class="col-6">
                    <ValidationProvider v-slot="{ errors }" vid="slug" name="slug" slim>
                      <label for="slug">Slug</label>
                      <div class="input-group" :class="{'has-error': errors[0]}">
                        <input id="name" v-model="page.slug" class="form-control" type="text" name="slug" required aria-describedby="nameHelpBlock">
                        <div class="input-group-append">
                          <a :href="pageUrl" target="_blank" title="open page preview in new tab" class="btn btn-default">
                            <i class="fas fa-external-link-alt text-secondary"></i>
                          </a>
                        </div>
                      </div>
                      <small class="text-muted">Unique identifier which can be used in embed codes.</small><br>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>

            <edit-page
              v-if="client.data.id"
              :pageType="pageType"
              :relation="relation"
              :relationType="relationType"
              :clientId="client.data.id"
              :slug="page.slug"
              :title="page.title"
              :pageId="page.id"
              :invalid="invalid"
              @created="getPages(), currentPageId = $event, page.id = $event"
              @updated="getPages()"
              @deleted="getPages(), page = undefined, currentPageId = false"
            ></edit-page>
          </div>
        </ValidationObserver>
      </div>
      <div v-else>
        Please select the page you want to edit.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EditPages',
  props: ['pageType', 'relation', 'relationType'],
  data () {
    return {
      availableModules: [],
      editModule: false,
      module: {
        configuration: [],
        model: {},
        id: '',
        layout: {},
        pivotId: 0
      },
      layout: [],
      page: undefined,
      showModal: false,
      pages: [],
      currentPageId: false,
      dcrFrontendHost: import.meta.env.VITE_DCR_FRONTEND_HOST
    }
  },
  computed: {
    ...mapGetters({
      client: 'getClient'
    }),
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    },
    pageUrl () {
      if (this.pageType === 'pageWidget') {
        return `http://${this.$route.params.client}.${this.dcrFrontendHost}/${this.relation.data.name}/widgets/page/${this.page.slug}`
      }
      if (this.relationType === 'project') {
        return `http://${this.$route.params.client}.${this.dcrFrontendHost}/${this.relation.data.name}/pages/${this.page.slug}`
      }
      return `http://${this.$route.params.client}.${this.dcrFrontendHost}/pages/${this.page.slug}`
    }
  },
  mounted () {
    this.getModules()
    this.getPages()
  },
  methods: {
    getPages () {
      this.isLoading = true
      const params = {
        types: [this.pageType],
        [`${this.relationType}Id`]: this.relation.data.id
      }

      this.$store
        .dispatch('getPages', params)
        .then(response => {
          if (response) {
            this.pages = response
          }
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not load pages: ' + error,
            icon: 'error'
          })
        })
    },
    editPage (id) {
      this.layout = []
      this.getPage(id)
    },
    cancel () {
      this.$router.push('/clients/' + this.$route.params.client + '/projects')
    },
    getClassForPageItem (id) {
      if (this.currentPageId === id) {
        return 'active'
      }
    },
    createPage () {
      this.isLoading = true
      this.page = {
        title: '',
        name: '',
        slug: undefined,
        id: undefined,
        pagewidgets: []
      }
      this.layout = []
      this.currentPageId = undefined
      this.$refs.observer?.reset()
    },
    getPage (id) {
      this.isLoading = true
      this.$store
        .dispatch('getPage', id)
        .then(response => {
          this.page = response
          this.currentPageId = response.id
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch page: ' + error,
            icon: 'error'
          })
        })
    },
    getModules () {
      this.isLoading = true
      this.$store
        .dispatch('getModules')
        .then(response => {
          this.availableModules = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch modules: ' + error,
            icon: 'error'
          })
        })
    }
  }
}
</script>
