
<b-modal
  :id="id"
  scrollable
  size="lg"
  no-fade
  ok-only
  :title="title"
  @hide="$emit('close')"
>
  <div class="row">
    <div class="col-12">
      <div class="container-fluid">
        <form role="form" @submit.prevent>

          <div v-if="isLoading" class="row justify-content-center mb-3">
            <b-spinner label="Loading..."></b-spinner>
          </div>

          <div v-if="!isLoading && assets.length > 0 && parentId !== ''" class="row mb-3">
            <div class="col-12">
              <div class="row">
                <div>
                <b-badge variant="primary" href="#" @click="oneUp">
                  <i class="fas fa-level-up-alt"></i> up <strong>...</strong>
                </b-badge>
                </div>
                <div>
                <b-badge variant="secondary" class="ml-2">
                  <i class="far fa-folder-open"></i> <span>{{ currentFolderName }}</span>
                </b-badge>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!isLoading && assets.length == 0" class="row mb-3">
            <div class="col">
              <div class="alert alert-info" role="alert">
                <h5 class="alert-heading">No assets available...</h5>
                <hr>
                Please use the asset management to add new assets to the client.
              </div>
            </div>
          </div>

          <div v-if="folders.length > 0" class="row mb-3">
            <div class="col-12">
              <div class="row">
                <div v-for="folder in folders" :key="folder.id" class="mr-2">
                  <b-badge variant="primary" href="#" @click.prevent="setParentId(folder.id)">
                    <i class="far fa-folder"></i> {{ folder.name }}
                  </b-badge>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!isLoading && images.length == 0 && folders.length > 0 && currentFolderName == ''" class="row mb-3">
            Please select folder to load assets.
          </div>

          <div v-if="images.length > 0" class="mb-3">
            <div>
              <label>Available Assets</label>
            </div>
            <div>
              <ul class="list-group">
                <li v-for="image in images" :key="image.resource" class="list-group-item">

                  <div class="row">
                    <div class="col-3">
                      <img v-if="image.type === 'image'" style="cursor:pointer;" :src="[assetURI+'/'+client.data.name+'/img/'+image.resource]" width="100%" @click.prevent="imageAction(image)">
                      <img v-else style="cursor:pointer;" src="/static/img/xml-icon.png" width="100%" @click.prevent="imageAction(image)">
                    </div>

                  </div>

                  <div class="row">
                    <div class="col" :title="image.resource">
                      {{image.name}}
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <small class="text-muted">uploaded at: {{image.created_at}}</small>
                    </div>
                  </div>

                </li>
              </ul>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</b-modal>
