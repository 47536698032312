
<div class="searchfields-settings">
   <div class="row">
    <div class="col-12">
      <h3>Searchfields</h3>
    </div>
    <div class="col-12">
      <h4>Congressresources</h4>
      <div class="form-check">
        <input id="overwriteCrsDefaults" v-model="CrsSearchfieldsOverwrite" type="checkbox" class="form-check-input" name="overwriteCrsDefaults" @change.prevent="handleCrsFields">
        <label class="form-check-label" for="overwriteCrsDefaults">overwrite default searchfields for congressresources</label>
      </div>
      <div v-if="CrsSearchfieldsOverwrite">
        <ul class="list-group">
          <li v-for="entry in searchfieldsByType('congressresource')" :key="entry.field" class="list-group-item">
            <div class="row">
              <div class="col-7 form-group">
                <label>field</label>
                <input v-model="entry.field" type="input" class="form-control">
              </div>
              <div class="col-4 form-group">
                <label>boost</label>
                <input v-model="entry.boost" type="input" class="form-control">
              </div>
              <div class="col-1">
                <br>
                <button class="btn btn-sm btn-danger" @click.prevent="deleteEntryByType(entry, 'congressresource')"><i class="fas fa-trash"></i></button>
              </div>
            </div>
          </li>
        </ul>
        <button class="btn btn-primary btn-sm" @click.prevent="addEntryByType('congressresource')"><i class="fas fa-plus"></i> add field</button>
      </div>
    </div>
    <div class="col-12">
      <hr>
    </div>
    <div class="col-12">
      <h4>Events</h4>
      <div class="form-check">
        <input id="overwriteEventDefaults" v-model="EventSearchfieldsOverwrite" type="checkbox" class="form-check-input" name="overwriteEventDefaults" @change="handleEventFields">
        <label class="form-check-label" for="overwriteEventDefaults">overwrite default searchfields for events</label>
      </div>
      <div v-if="EventSearchfieldsOverwrite">
        <ul class="list-group">
          <li v-for="entry in searchfieldsByType('event')" :key="entry.field" class="list-group-item">
            <div class="row">
              <div class="col-7 form-group">
                <label>field</label>
                <input v-model="entry.field" type="input" class="form-control">
              </div>
              <div class="col-4 form-group">
                <label>boost</label>
                <input v-model="entry.boost" type="input" class="form-control">
              </div>
              <div class="col-1">
                <br>
                <button class="btn btn-sm btn-danger" @click.prevent="deleteEntryByType(entry, 'event')"><i class="fas fa-trash"></i></button>
              </div>
            </div>
          </li>
        </ul>
        <button class="btn btn-primary btn-sm" @click.prevent="addEntryByType('event')"><i class="fas fa-plus"></i> add field</button>
      </div>
    </div>
    <div class="col-12">
      <hr>
    </div>
    <div class="col-12">
      <h4>Timelineitems</h4>
      <div class="form-check">
        <input id="overwriteTimelineDefaults" v-model="TimelineSearchfieldsOverwrite" type="checkbox" class="form-check-input" name="overwriteTimelineDefaults" @change="handleTimelineFields">
        <label class="form-check-label" for="overwriteTimelineDefaults">overwrite default searchfields for timelineitems</label>
      </div>
      <div v-if="TimelineSearchfieldsOverwrite">
        <ul class="list-group">
          <li v-for="entry in searchfieldsByType('timeline')" :key="entry.field" class="list-group-item">
            <div class="row">
              <div class="col-7 form-group">
                <label>field</label>
                <input v-model="entry.field" type="input" class="form-control">
              </div>
              <div class="col-4 form-group">
                <label>boost</label>
                <input v-model="entry.boost" type="input" class="form-control">
              </div>
              <div class="col-1">
                <br>
                <button class="btn btn-sm btn-danger" @click.prevent="deleteEntryByType(entry, 'timeline')"><i class="fas fa-trash"></i></button>
              </div>
            </div>
          </li>
        </ul>
        <button class="btn btn-primary btn-sm" @click.prevent="addEntryByType('timeline')"><i class="fas fa-plus"></i> add field</button>
      </div>
    </div>
    <div class="col-12">
      <hr>
    </div>
    <div class="col-12">
      <h4>Speakers</h4>
      <div class="form-check">
        <input id="overwriteSpeakerDefaults" v-model="SpeakerSearchfieldsOverwrite" type="checkbox" class="form-check-input" name="overwriteSpeakerDefaults" @change="handleSpeakerFields">
        <label class="form-check-label" for="overwriteSpeakerDefaults">overwrite default searchfields for speakers</label>
      </div>
      <div v-if="SpeakerSearchfieldsOverwrite">
        <ul class="list-group">
          <li v-for="entry in searchfieldsByType('speaker')" :key="entry.field" class="list-group-item">
            <div class="row">
              <div class="col-7 form-group">
                <label>field</label>
                <input v-model="entry.field" type="input" class="form-control">
              </div>
              <div class="col-4 form-group">
                <label>boost</label>
                <input v-model="entry.boost" type="input" class="form-control">
              </div>
              <div class="col-1">
                <br>
                <button class="btn btn-sm btn-danger" @click.prevent="deleteEntryByType(entry, 'speaker')"><i class="fas fa-trash"></i></button>
              </div>
            </div>
          </li>
        </ul>
        <button class="btn btn-primary btn-sm" @click.prevent="addEntryByType('speaker')"><i class="fas fa-plus"></i> add field</button>
      </div>
    </div>
  </div>
</div>
