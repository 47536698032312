const getters = {
  getAssetURI: state => state.assetURI,
  getLoadingState: state => state.isLoading || false,
  getClient: state => state.client || false,
  getClientsList: state => state.clientsList || false,
  getGroupsList: state => state.groupsList || false,
  getProjectsList: state => state.projectsList || false,
  getProjectName: state => state.projectName,
  getGroupName: state => state.groupName,
  getEducationitemsList: state => state.educationitemsList.tree || false,
  getEducationitemsPlain: state => state.educationitemsList.plain || false,
  getPagesList: state => state.pagesList || false,
  getUser: state => state.user || false,
  getAvailableLanguages: state => state.availableLanguages || false,
  getAdvertisementsList: state => state.advertisementsList || false,
  getTimezones: state => state.timezones,
  getPackagesList: state => {
    if (state.packagesList) {
      const packages = state.packagesList.filter(dcrPackage => {
        if (dcrPackage.draft === 1) {
          dcrPackage.draft = '<i class="fas fa-eye-slash"></i>'
        } else {
          dcrPackage.draft = '<i class="fas fa-eye"></i>'
        }
        return dcrPackage
      })
      return packages
    }
    return false
  },
  getSpotlightsList: state => {
    if (state.spotlightsList) {
      const spotlights = state.spotlightsList.filter(dcrSpotlight => {
        if (dcrSpotlight.draft === 1) {
          dcrSpotlight.draft = '<i class="fas fa-eye-slash"></i>'
        } else {
          dcrSpotlight.draft = '<i class="fas fa-eye"></i>'
        }
        return dcrSpotlight
      })
      return spotlights
    }
    return false
  }
}

export default getters
