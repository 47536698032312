<template>
  <ValidationObserver v-slot="{ errors }" tag="div" class="single-asset">
    <label v-if="showLabel" class="single-asset__label">
      <span v-if="label">{{ label }}</span>
      <small
        v-if="showHelpText"
        class="d-block text-muted"
        style="min-height: 40px;"
      >
        {{ helpText }}
      </small>
    </label>

    <div v-if="isLoading && !adminPageTemplate">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <template v-else>
      <div class="image-container">
        <div
          v-if="adminPageTemplate"
          class="empty-state-picture is-admintemplate"
          :class="{ 'useFullSize': useFullSize === true }"
          title="unable to set assets in master templates"
        ></div>
        <div
          v-else-if="hasAssetId"
          class="preview-image"
          :class="{ 'useFullSize': useFullSize === true }"
          @click="openAssetModal"
        >
          <img
            v-if="getAsset(assetId)"
            :title="`${fileName}.${fileFormat}`"
            :src="getImageUrl(assetId)"
          />
          <div
            v-else
            class="no-image text-secondary text-sm"
            :class="{ 'useFullSize': useFullSize === true }"
            title="asset not found"
          >
            <i class="fa fa-question"></i>
            asset not found
          </div>
        </div>
        <div
          v-else
          class="empty-state-picture"
          :class="[
            { 'useFullSize': useFullSize === true },
            { 'is-disabled': disabled }
          ]"
          title="click to set asset"
          @click="openAssetModal"
        ></div>
        <div class="preview-image__buttons">
          <button
            class="btn btn-sm btn-default"
            type="button"
            title="remove asset"
            :disabled="!(hasAssetId && getAsset(assetId))"
            @click.stop.prevent="deleteAsset"
          >
            <i class="fa fa-times"></i>
          </button>
          <button
            v-if="showDeleteButton"
            class="btn btn-sm btn-danger"
            title="delete list item"
            @click.stop.prevent="deleteItem"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <div class="subline">
        <small
          v-if="hasAssetId && getAsset(assetId)"
          class="d-flex"
          :title="`${fileName}.${fileFormat}`"
        >
          <span class="text-truncate">{{ fileName }}</span>
          .{{ fileFormat }}
        </small>
        <small v-if="errors.badge" class="text-danger">{{ errors.badge[0] }}</small>
      </div>
      <ValidationProvider
        v-if="showInput"
        v-slot="{ errors }"
        immediate
        tag="div"
        name="badge"
        class="form-group"
        :class="{ 'has-error': errors.badge && errors.badge[0] }"
      >
        <input
          v-model.trim="name"
          name="badge name"
          placeholder="badge name"
          type="text"
          required="required"
          class="form-control"
          @input="$emit('input', { ...item, name: name })"
        />
      </ValidationProvider>
    </template>

    <AssetModal
      v-if="!isLoading && !adminPageTemplate"
      :id="assetModalId"
      :clientId="clientId"
      @setAsset="setAsset"
    />

  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'single-asset',
  props: {
    item: {
      type: Object
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    useFullSize: {
      type: Boolean,
      default: true
    },
    showInput: {
      type: Boolean,
      default: false
    },
    showDeleteButton: {
      type: Boolean,
      default: false
    },
    showHelpText: {
      type: Boolean,
      default: true
    },
    helpText: {
      type: String
    }
  },
  data () {
    return {
      assetModalId: this.$uuid.v4(),
      isLoading: true,
      name: this.item?.name
    }
  },
  computed: {
    ...mapGetters({
      assetUri: 'getAssetURI',
      getAssetById: 'getAssetById',
      getClientAsset: 'getClientAsset',
      getGroupAsset: 'getGroupAsset',
      getProjectAsset: 'getProjectAsset',
      client: 'getClient',
      projectName: 'getProjectName',
      groupName: 'getGroupName'
    }),
    fileName () {
      return `${this.getAsset(this.assetId)?.name}`
    },
    fileFormat () {
      return `${this.getAsset(this.assetId)?.resource.split('.')[1]}`
    },
    clientId () {
      return this.client.data.id
    },
    clientName () {
      return this.client.data.name
    },
    hasAssetId () {
      return !!this.item?.asset
    },
    assetId () {
      return this.item.asset
    },
    adminPageTemplate () {
      return this.$route.name === 'PageTemplateEdit'
    }
  },
  created () {
    const { client: routeClient } = this.$route.params

    if (!this.adminPageTemplate && routeClient) {
      this.assertClientIsLoaded(routeClient)
        .then(() => {
          return this.loadAssets(routeClient)
        })
        .then(() => {
          this.isLoading = false
        })
    }
  },
  methods: {
    ...mapActions({
      loadAssets: 'loadAssetsNew',
      getClientData: 'getClientData'
    }),
    async assertClientIsLoaded (client) {
      if (this.clientId && this.clientName === client) {
        return
      }

      return this.getClientData(client)
    },
    getAsset (assetId) {
      let asset = this.getAssetById(this.clientName, assetId)

      if (!asset) {
        if (this.$route.name === 'GroupEdit') {
          asset = this.getGroupAsset(this.clientName, this.groupName, assetId)
        } else if (this.$route.name === 'ProjectEdit') {
          asset = this.getProjectAsset(this.clientName, this.projectName, assetId)
        } else {
          asset = this.getClientAsset(this.clientName, assetId)
        }
      }

      return asset
    },
    getImageUrl (assetId) {
      const asset = this.getAsset(assetId)

      if (asset.type === 'xml') {
        return '/static/img/xml-icon.png'
      }

      return `${this.assetUri}/${this.client.data.name}/img/${asset?.resource}`
    },
    setAsset (asset) {
      this.$emit('input', { ...this.item, asset: asset.id })
      this.closeAssetModal()
    },
    deleteAsset () {
      if (!this.disabled) {
        this.$emit('input', { ...this.item, asset: undefined })
      }
    },
    deleteItem () {
      this.$swal({
        title: 'Are you really sure?',
        text: 'The list item will be deleted. This cannot be undone!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#dd4b39',
        cancelButtonText: 'Cancel'
      })
        .then(result => {
          if (result.isConfirmed) {
            this.$emit('delete', this.item)
          }
        })
    },
    openAssetModal () {
      if (!this.disabled) {
        this.$bvModal.show(this.assetModalId)
      }
    },
    closeAssetModal () {
      this.$bvModal.hide(this.assetModalId)
    }
  }
}

</script>

<style lang="scss">
.single-asset {
  --asset-size: 200px;

  flex-basis: var(--asset-size);
  max-width: var(--asset-size);

  .single-asset__label {
    span {
      display: inline-block;
      min-height: 48px;
    }

    small {
      min-height: 40px;
    }
  }

  .image-container {
    position: relative;

    & .preview-image__buttons {
      visibility: hidden;
      margin: .5rem;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      gap: 6px;

      button {
        position: relative;
        width: 32px;
        height: 32px;
      }

    }
    &:hover {
      & .preview-image__buttons {
        visibility: visible;
      }
    }

    .empty-state-picture {
      position: relative;
      padding-top: 100%;
      cursor: pointer;

        &:before {
        content: "\f03e";
        font-family: "Font Awesome 5 Free";
        font-size: 1.6rem;
        color: #3c8dbc;
        position: absolute;
        top: 0;
        left: 0;
        width: var(--asset-size);
        height: var(--asset-size);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px dashed #ccc;
        background: #eee;
      }

      &.useFullSize {
        &:before {
          width: 100% !important;
          height: 100% !important;
        }
      }

      &.is-admintemplate {
        cursor: not-allowed;

        &:before {
          color: #ccc;
        }
        & .preview-image__buttons {
          visibility: hidden !important;
        }
      }

      &.is-disabled:before {
        border-color: red;
        color: #ccc;
        cursor: not-allowed;
      }
    }

    .preview-image {
      position: relative;
      cursor: pointer;
      width: 100%;
      height: var(--asset-size);
      background: #eee;

      & img {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 100%;
        max-height: 100%;
      }

      &.useFullSize {
        width: 100% !important;
        height: 100% !important;
        padding-top: 100% !important;

        & img {
          max-width: 100% !important;
          max-height: 100% !important;
        }
      }

      & .no-image {
        position: absolute;
        border: 1px dashed #aaa;
        margin: 1rem;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & i {
          margin-bottom: .5rem;
        }
      }
    }

  }

  .subline {
    min-height: 30px;
    display: flex;
    align-items: center;

    small {
      width: 100%;
    }
  }

}
</style>
