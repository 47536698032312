<template>
  <section class="container-fluid bg-white">
    <Table
      :data="modules || []"
      :columns="table.columns"
      :actions="table.actions"
      :actionButton="table.actionButton"
      :disableSearch="false"
      :search="table.search"
      @rowClicked="table.clickHandler"
    />
  </section>
</template>

<script>
export default {
  data () {
    return {
      modules: [],
      table: {
        columns: [
          {
            label: 'Name',
            property: 'name',
            searchable: true,
            sort: { enabled: true, default: true }
          }
        ],
        actions: [
          { icon: 'fas fa-trash-alt', buttonClass: 'btn-danger', action: this.deleteItem }
        ],
        actionButton: {
          icon: 'fa fa-plus',
          label: 'Create new module template',
          action: () => this.$router.push({ name: 'ModuleTemplateCreate' })
        },
        clickHandler: this.editModule,
        search: {
          placeholder: 'Search module templates',
          disabled: false
        }
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    editModule (pageModule) {
      this.$router.push('/moduletemplates/' + pageModule.id)
    },
    fetchData () {
      this.isLoading = true
      this.$store
        .dispatch('getModules')
        .then(response => {
          this.modules = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch modules: ' + error,
            icon: 'error'
          })
        })
    },
    deleteItem (item, index) {
      this
        .$swal({
          title: 'Are you really sure?',
          text: 'The module template"' + item.name + '" will be deleted. This cannot be undone!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#dd4b39',
          cancelButtonText: 'Cancel'
        })
        .then(
          result => {
            if (result.value) {
              this.isLoading = true
              const data = {
                item: item
              }
              this.$store
                .dispatch('deleteModuleTemplate', data)
                .then(response => {
                  this.modules.splice(index, 1)
                  this.$swal({
                    title: 'Success',
                    text: 'Module template"' + item.name + '" has been deleted',
                    icon: 'success',
                    showConfirmButton: false,
                    toast: true,
                    position: 'top',
                    timer: 2000
                  })
                  this.isLoading = false
                }).catch(error => {
                  this.isLoading = false
                  this.error = true
                  this.$swal({
                    title: 'Error',
                    text: 'could not delete module template: ' + error,
                    icon: 'error'
                  })
                })
            }
          }
        )
    }
  }
}
</script>
