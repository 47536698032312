<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <h4 class="card-header">
            Library Settings
          </h4>
          <div class="card-body">
          <form v-if="partialProjectData.library" action="#" role="form">
            <div class="row">
              <div class="form-group col-sm-12 col-md-6">
                <label for="version">Filtertemplate</label>
                <select id="version" v-model="partialProjectData.library.filtertemplate_id" class="form-control" name="version">
                  <option value="0">select a Filtertemplate</option>
                  <option v-for="template in Filtertemplates" :key="template.id" :value="template.id">{{ template.name }}</option>
                </select>
              </div>
              <div class="form-group col-6">
                <label for="defaultContentType">Default Content Type</label>
                <select id="defaultContentType" v-model.trim="partialProjectData.library.defaultContentType" class="form-control" name="defaultContentType">
                  <option key="congressresources" value="congressresources">Congress Resources</option>
                  <option key="events" value="events">Events</option>
                </select>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="form-group col-sm-12 col-md-6">
                <label for="sortOrder">Default Sort By</label>
                <select id="sortBy" v-model="partialProjectData.library.sortBy" class="form-control" name="sortBy" required="required">
                  <option value="date">date</option>
                  <option value="title">title</option>
                  <option value="event.title.raw">sessiontitle</option>
                  <option value="likes">likes</option>
                </select>
              </div>
              <div class="form-group col-sm-12 col-md-6">
                <label for="sortOrder">Default Sort Order</label>
                <select id="sortOrder" v-model="partialProjectData.library.sortOrder" class="form-control" name="version">
                  <option value="asc">ascending</option>
                  <option value="desc">descending</option>
                </select>
              </div>
            </div>
            <hr>
            <HideToolbarElements :library="partialProjectData.library" :hiddenContentTypes="currentHiddenContentTypes"></HideToolbarElements>
          </form>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <SaveBar
      @save="updateProjectData(close = false)"
      @saveAndClose="updateProjectData(close = true)"
      @cancel="cancel"
    />
  </div>
</template>

<script>

export default {
  name: 'ProjectEditLibrary',
  data () {
    return {
      Filtertemplates: [],
      partialProjectData: false,
      hiddenContentTypes: [
        { field: 'cr', value: false },
        { field: 'events', value: false },
        { field: 'slides', value: false },
        { field: 'speaker', value: false }
      ]
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    },
    currentHiddenContentTypes () {
      if (this.partialProjectData?.library && this.partialProjectData?.library?.hiddenContentTypes && this.partialProjectData?.library?.hiddenContentTypes !== '') {
        return JSON.parse(this.partialProjectData.library.hiddenContentTypes)
      }
      return this.hiddenContentTypes
    }
  },
  created () {
    this.getProjectData()
    this.loadFiltertemplates()
  },
  methods: {
    loadFiltertemplates () {
      this.isLoading = true
      this.$store
        .dispatch('getFiltertemplatesList', this.$route.params.client)
        .then(
          response => {
            this.Filtertemplates = response
          },
          response => { // error
            this.$swal({
              title: 'Error',
              text: 'Could not load Filtertemplates',
              icon: 'error'
            })
          })
    },
    getProjectData () {
      this.isLoading = true
      this.$store
        .dispatch('getPartialProjectData', { projectId: this.$route.params.id, tab: 'edit-library' })
        .then(response => {
          this.partialProjectData = response
          if (this.partialProjectData?.library?.hiddenContentTypes !== '') {
            this.hiddenContentTypes = JSON.parse(this.partialProjectData.library.hiddenContentTypes)
          }
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    cancel () {
      this.$router.push('/clients/' + this.$route.params.client + '/projects')
    },
    updateProjectData (close = false) {
      this.isLoading = true
      this.$store
        .dispatch('updatePartialProjectData', { projectId: this.$route.params.id, data: this.partialProjectData, tab: 'edit-library' })
        .then(response => {
          this.$swal({
            title: 'Success',
            text: response.message,
            icon: 'success',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
          this.isLoading = false
          if (close) {
            this.cancel()
          }
        }).catch(error => {
          this.$refs.observer.setErrors(error.response.data.errors)
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not update project: ' + error.response.data.message,
            icon: 'error',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
        })
    }
  }
}
</script>
