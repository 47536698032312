import Vue from 'vue'
import axios from 'axios'

const state = {
  assets: {},
  loading: {},
  loadingPromises: {},
  images: {},
  imagesLoading: {},
  imagesLoadingPromises: {}
}

const getters = {
  getAssetById: state => (client, id) => state.assets?.[client]?.[id],
  getClientAsset: state => (client, reference) => state.assets?.[client]?.[`client#${reference}`],
  getGroupAsset: state => (client, groupName, reference) => state.assets?.[client]?.[`group#${groupName}#${reference}`],
  getProjectAsset: state => (client, projectName, reference) => state.assets?.[client]?.[`project#${projectName}#${reference}`],
  getImages: (state) => (clientId) => state.images[clientId]
}

const actions = {
  loadAssetsNew: (context, clientName) => {
    if (state.loading[clientName] === true) {
      return state.loadingPromises[clientName]
    }

    if (state.assets[clientName]) {
      return Promise.resolve()
    }

    Vue.set(state.loading, clientName, true)

    const loadingPromise = new Promise((resolve, reject) => {
      axios
        .get(`${import.meta.env.VITE_API_BASE_URL}/api/v2/assets`, { params: { client: clientName } })
        .then(response => {
          context.commit('SET_CLIENT_ASSETS', { clientName, assets: response.data })
          resolve()
        })
        .catch(error => {
          Vue.set(state.loading, clientName, false)
          reject(new Error(`Error while loading assets for client: ${error.message}`))
        })
    })

    Vue.set(state.loadingPromises, clientName, loadingPromise)

    return loadingPromise
  },
  loadAssets (context, { clientId, isUploadProcess = false }) {
    if (state.imagesLoading[clientId] === true) {
      return state.imagesLoadingPromises[clientId]
    }

    if (state.images[clientId] && !isUploadProcess) {
      return Promise.resolve()
    }

    Vue.set(state.imagesLoading, clientId, true)

    const loadingPromise = new Promise((resolve, reject) => {
      const url =
      import.meta.env.VITE_API_BASE_URL + '/api/v2/clients/' + clientId + '/images'

      return axios
        .get(url)
        .then(response => {
          context.commit('SET_CLIENT_IMAGES', { clientId, images: response.data })
          resolve()
        })
        .catch(error => {
          Vue.set(state.imagesLoading, clientId, false)
          reject(new Error(`Error while loading images for client: ${error.message}`))
        })
    })

    Vue.set(state.imagesLoadingPromises, clientId, loadingPromise)

    return loadingPromise
  }
}

const mutations = {
  SET_CLIENT_ASSETS: (state, { clientName, assets }) => {
    Vue.set(state.assets, clientName, assets)
    Vue.set(state.loading, clientName, false)
  },
  SET_CLIENT_IMAGES: (state, { clientId, images }) => {
    Vue.set(state.images, clientId, images)
    Vue.set(state.imagesLoading, clientId, false)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
