<template>
  <section>
    <div>
      <div class="form-group">
        <label for="name">Name</label>
        <input id="name" v-model="model.name" type="text" name="name" class="form-control">
      </div>
      <div class="form-group">
        <label for="heading">Heading (optional)</label>
        <input id="heading" v-model="model.heading" type="text" name="heading" class="form-control">
      </div>
      <div class="form-group">
        <label for="title">Title (deprecated)</label>
        <input id="title" v-model="model.title" type="text" name="title" class="form-control">
      </div>
      <div class="form-group">
        <label for="title">Custom Classes (CSS)</label>
        <input id="title" v-model="model.customClasses" type="text" name="title" class="form-control">
        <small class="form-text text-muted">Define custom CSS classes (optional). Separate multiple class names with commas.</small>
      </div>
      <div class="form-group">
        <label for="teaser">Teaser</label>
        <froala-editor
          id="teaser"
          v-model="model.teaser"
        />
      </div>
      <div class="form-group">
        <label for="type">Layout</label>
        <select id="type" v-model="model.layout" name="type" class="form-control">
          <option value="tiles">Tiles</option>
          <option value="slider">Slider</option>
        </select>
      </div>
      <div class="form-group">
        <label for="type">Type</label>
        <select id="type" v-model="model.type" name="type" class="form-control">
          <option value="congressresources">Congress Resources</option>
          <option value="events">Events</option>
        </select>
      </div>
      <div class="form-group">
        <label for="type">Project Name</label>
        <select id="project" v-model="model.project" name="project" class="form-control">
          <option v-for="project in projects" :key="project.name" :value="project.name">{{ project.title }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="ids">Ids</label>
        <div class="input-group">
          <input id="ids" v-model="model.ids" type="text" name="ids" class="form-control">
          <div class="input-group-append">
            <button class="btn btn-default" @click.prevent="openSearch()"><i class="fas fa-plus"></i></button>
          </div>
        </div>
      </div>
      <div v-if="model.layout === 'slider'" class="form-field-group">
        <div class="card mb-3">
          <div class="card-header">Slideshow Options</div>
          <div class="card-body">
            <div class="card-text">
              <fieldset>
                <div class="form-group form-check former-input">
                  <input id="autoplay" v-model="model.slideshowOptions.autoplay" type="checkbox" class="form-check-input">
                  <label for="autoplay" class="form-check-label">Enable autoplay</label>
                </div>
                <div class="form-group form-check former-input former-has-value">
                  <input id="dots" v-model="model.slideshowOptions.dots" type="checkbox" class="form-check-input">
                  <label for="dots" class="form-check-label">Show dot indicators/pagination</label>
                </div>
                <div class="form-group form-check former-input former-has-value">
                  <input id="navButtons" v-model="model.slideshowOptions.navButtons" type="checkbox" class="form-check-input">
                  <label for="navButtons" class="form-check-label">Show prev/next navigation buttons</label>
                </div>
                <div class="form-group form-check former-input">
                  <input id="fade" v-model="model.slideshowOptions.fade" type="checkbox" class="form-check-input">
                  <label for="fade" class="form-check-label">Enable fade effect</label>
                </div>
                <div class="form-group form-check former-input">
                  <input id="randomize" v-model="model.slideshowOptions.randomize" type="checkbox" class="form-check-input">
                  <label for="randomize" class="form-check-label">Randomize order</label>
                  <small class="form-text text-muted">Random slide order on every page load</small>
                </div>
                <div class="form-group former-input former-has-value">
                  <label>Autoplay Speed</label>
                  <input v-model="model.slideshowOptions.autoplaySpeed" type="text" class="form-control">
                  <small class="form-text text-muted">Autoplay interval in milliseconds</small>
                </div>
                <div class="form-group former-input former-has-value">
                  <label>Animation Speed</label>
                  <input v-model="model.slideshowOptions.speed" type="text" class="form-control">
                  <small class="form-text text-muted">Slide animation speed in milliseconds</small>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
    <search-modal
      v-if="showSearch"
      :defaultSearchType="'event'"
      :ids="model.ids"
      @updateIds="model.ids = $event"
      @close="showSearch = false">
    </search-modal>
  </section>
</template>

<script>
export default {
  name: 'Resources-List',
  props: ['model'],
  data () {
    return {
      showSearch: false,
      crList: [],
      search: {
        query: '',
        congress: '',
        type: 'congressresource'
      },
      resources: false
    }
  },
  computed: {
    projects () {
      const data = this.$store.getters.getProjectsList
      if (data !== false) {
        return data.sort((a, b) => (a.title === b.title ? 0 : a.title > b.title ? 1 : -1))
      }
      return null
    }
  },
  created () {
    this.getProjects()
    this.crList = this.model.ids.split(',')
    this.crList = this.crList.filter(cr => cr !== '')
    this.validateSlideshowOptions()
  },
  methods: {
    openSearch () {
      if (this.model.type === 'congressresources') {
        this.search.type = 'congressresource'
      }
      if (this.model.type === 'events') {
        this.search.type = 'event'
      }
      this.showSearch = true
    },
    searchResources () {
      const params = {
        query: this.search.query,
        project: this.search.congress,
        type: [this.search.type],
        besearch: true
      }
      this.isLoading = true
      this.$store
        .dispatch('searchResources', { client: this.$route.params.client, params: params })
        .then(response => {
          this.resources = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.error = true
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    getProjects () {
      if (!this.projects) {
        this.isLoading = true
        this.$store
          .dispatch('getProjectsList', this.$route.params.client)
          .then(response => {
            this.isLoading = false
          }).catch(error => {
            this.isLoading = false
            this.error = true
            this.$swal({
              title: 'Error',
              text: error,
              icon: 'error'
            })
          })
      }
    },
    addResource (id) {
      if (!this.crList.includes(id)) {
        this.crList.push(id)
      }
    },
    removeResource (id) {
      this.crList.splice(this.crList.indexOf(id), 1)
    },
    saveSelection () {
      this.model.ids = this.crList.join(',')
      this.showSearch = false
    },
    validateSlideshowOptions () {
      if (this.model.slideshowOptions) {
        return
      }
      this.model.slideshowOptions = {}
    }
  }
}
</script>

<style lang="scss">
  .bg-success-light {
    background-color: rgba(0,166,90,.3) !important;
  }
</style>
