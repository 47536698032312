
<div class="container">

  <div class="row justify-content-center mb-5">
    <div class="text-center col-6">
      <img src="/static/img/logo-sm.png" class="center-block logo">
      <h1 style="color:#FFF"><i class="fas fa-cogs"></i> meta-dcr.com</h1>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-4">
      <div v-if="response" class="alert alert-danger" role="alert">
      {{response}}
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-4">
      <div class="card">
        <div class="card-body">
          <h3 class="card-title">Sign in</h3>
          <form @submit.prevent="checkCreds">
            <div class="form-group">
              <label for="signInEmail">Email</label>
              <input id="signInEmail" v-model="email" type="email" class="form-control" placeholder="Enter email">
            </div>
            <div class="form-group">
              <label for="signInPassword">Password</label>
              <input id="signInPassword" v-model="password" type="password" class="form-control" placeholder="Password">
            </div>
            <button type="submit" class="mt-4 btn btn-block btn-lg btn-success">Sign in</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
