<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h3>Channels</h3>
        <p>Edit Pages for seperate Channel if it should overwrite ChannelDetail Page</p>
        <hr>
      </div>
    </div>
    <div v-if="partialProjectData" class="row">
      <div class="col-3">
       <div class="list-group">
         <a v-for="channel in channels"
          :key="channel.slug"
          href="#"
          class="list-group-item list-group-item-action"
          :class="getClassForChannel(channel)"
          @click.prevent="editChannelPage(channel)">{{ channel.title }}</a>
       </div>
      </div>
      <div v-if="slug" class="col-9">
        <edit-page
          relationType="project"
          :relation="{ data: project }"
          pageType="channelDetail"
          :slug="slug"
          :clientId="clientId"
        ></edit-page>
      </div>
      <div v-else>
        Please select the page you want to edit.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectEditChannels',
  props: ['type', 'project', 'clientId'],
  data () {
    return {
      channels: [],
      slug: false,
      partialProjectData: false
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    }
  },
  created () {
    this.getProjectData()
    this.getPages()
  },
  methods: {
    getChannels () {
      if (this.channels.length === 0) {
        this.isLoading = true
        const params = {
          apiBaseUrl: this.partialProjectData.apiBaseUrl,
          apiTokenBaseUrl: this.partialProjectData.apiTokenBaseUrl,
          apiClient: this.partialProjectData.apiClient,
          apiClientSecret: this.partialProjectData.apiClientSecret,
          collection: this.partialProjectData.collection,
          apiVersion: 'v2'
        }
        this.$store
          .dispatch('getChannels', params)
          .then(
            response => {
              this.channels = response.channels
              this.isLoading = false
            },
            response => { // error
              this.$swal({
                title: 'Error',
                text: 'Could not load channels',
                icon: 'error'
              })
              this.isLoading = false
            })
      }
    },
    getPages () {
      if (!this.pages) {
        this.isLoading = true
        const params = {
          types: ['channelDetail'],
          projectId: this.$route.params.id
        }

        this.$store
          .dispatch('getPages', params)
          .then(
            response => {
              if (response) {
                this.pages = response
              }
              this.isLoading = false
            },
            response => { // error
              this.$swal({
                title: 'Error',
                text: 'Could not load pages',
                icon: 'error'
              })
              this.isLoading = false
            })
      }
    },
    getClassForChannel (channel) {
      if (this.slug === channel.slug) {
        return 'active'
      }
      if (this.pages && this.pages.find(page => page.slug === channel.slug)) {
        return 'bg-warning'
      }
    },
    editChannelPage (channel) {
      this.$set(this, 'slug', channel.slug)
    },
    getProjectData () {
      this.isLoading = true
      this.$store
        .dispatch('getPartialProjectData', { projectId: this.$route.params.id, tab: 'edit-channels' })
        .then(response => {
          this.partialProjectData = response
          this.getChannels()
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    }
  }
}
</script>
