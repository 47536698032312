<template>
  <ValidationObserver v-slot="{ invalid }" tag="section" class="container-fluid">
    <div class="box row">
      <div class="col-md-12">
        <h2>Create new client</h2>
        <form name="create-client" class="form row">
          <ValidationProvider v-slot="{ errors }" name="client title" tag="div" class="col-md-6">
            <div class="form-group col-md-12" :class="{'has-error': errors[0]}">
              <label for="title">Title: *</label>
              <input id="title" v-model.trim="client.title" type="text" class="form-control" required="required">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="subdomain name" rules="required|verify_name" tag="div" class="col-md-6">
            <div class="form-group col-md-12" :class="{'has-error': errors[0]}">
              <label for="name">Subdomain: *</label>
              <input id="name" v-model.trim="client.name" type="text" class="form-control" required="required">
              <small v-if="client.name" id="subdomainHelpBlock" class="form-text text-muted">
                {{ `${isLocalHost ? 'http': 'https'}://${client.name}.${dcrFrontendHost}` }}
              </small>
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <SaveBar
            :saveDisabled="invalid"
            disableSaveAndClose
            @save="createClient"
            @cancel="cancel"
          />
        </form>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'createClient',
  data () {
    return {
      client: {
        title: '',
        name: '',
        apiBaseUrl: import.meta.env.VITE_WCM_BASE_URL,
        apiTokenBaseUrl: import.meta.env.VITE_WCM_TOKEN_BASE_URL,
        apiVersion: 'v2',
        cacheTtl: '10',
        enableFavorites: 1
      },
      dcrFrontendHost: import.meta.env.VITE_DCR_FRONTEND_HOST
        .replace('http://', '')
        .replace('https://', ''),
      error: false
    }
  },
  computed: {
    isLocalHost () {
      return this.dcrFrontendHost.includes('localhost')
    },
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    }
  },
  methods: {
    cancel () {
      this.$router.push('/clients')
    },
    createClient () {
      this.$store
        .dispatch('createClient', this.client)
        .then(response => {
          this.isLoading = false
          if (response.data.client) {
            this.$swal({
              title: 'Success',
              text: 'New Client added',
              icon: 'success',
              showConfirmButton: false,
              toast: true,
              position: 'top',
              timer: 2000
            })
            this.$router.push('/clients/' + response.data.client.name + '/edit/')
          } else {
            this.$swal('Error', 'Something went wrong', 'error')
          }
        }, response => {
          this.$swal('Error', 'Something went wrong', 'error')
        })
    }
  }
}
</script>

<style>
.box {
  padding-bottom: 15px;
}
</style>
