import axios from 'axios'
import qs from 'qs'

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL
axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params, { arrayFormat: 'indices' })
}

const actions = {
  getUserData (context, params) {
    const url = context.state.serverURI + '/api/v2/users/email'
    axios
      .get(url, params)
      .then(response => {
        context.commit('SET_USER', response.data)
      })
      .catch(e => {
        console.warn('get userdata', e)
      })
  },
  getUser (context, userId) {
    const url = context.state.serverURI + '/api/v2/users/' + userId + '/edit'
    return axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get userdata', e)
      })
  },
  getUsers (context, params) {
    const url = context.state.serverURI + '/api/v2/users'
    return axios
      .get(url, params)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get users', e)
      })
  },
  createUser (context, data) {
    const url = context.state.serverURI + '/api/v2/users'
    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  async deleteUser (context, userId) {
    const url = `${context.state.serverURI}/api/v2/users/${userId}`

    try {
      await axios.delete(url)
    } catch (error) {
      console.warn('delete user', error)
      throw error
    }
  },
  updateUser (context, { userId, data }) {
    const url = context.state.serverURI + '/api/v2/users/' + userId
    return new Promise((resolve, reject) => {
      axios
        .put(url, data)
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },

  getUsergroup (context, usergroup) {
    const url =
          context.state.serverURI + '/api/v2/usergroups/' + usergroup + '/edit'
    return axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get usergroup data ', e)
      })
  },
  getUsergroups (context, params) {
    const url = context.state.serverURI + '/api/v2/usergroups'
    return axios
      .get(url, params)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get usergroups', e)
      })
  },
  createUsergroup (context, data) {
    const url = context.state.serverURI + '/api/v2/usergroups'
    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  updateUsergroup (context, { usergroupId, data }) {
    const url = context.state.serverURI + '/api/v2/usergroups/' + usergroupId
    return axios
      .put(url, data)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('update usergroup', e)
      })
  },
  deleteUsergroup (context, data) {
    const url = context.state.serverURI + '/api/v2/usergroups/' + data.item.id
    return axios
      .delete(url)
      .then(response => {})
      .catch(e => {
        console.warn('delete usergroup', e)
      })
  },

  getUserrole (context, userrole) {
    const url =
          context.state.serverURI + '/api/v2/userroles/' + userrole + '/edit'
    return axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get userrole data ', e)
      })
  },
  getUserroles (context, params) {
    const url = context.state.serverURI + '/api/v2/userroles'
    return axios
      .get(url, params)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get userroles', e)
      })
  },
  createUserrole (context, data) {
    const url = context.state.serverURI + '/api/v2/userroles'
    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  updateUserrole (context, { userroleId, data }) {
    const url = context.state.serverURI + '/api/v2/userroles/' + userroleId
    return axios
      .put(url, data)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('update userrole', e)
      })
  },
  deleteUserrole (context, data) {
    const url = context.state.serverURI + '/api/v2/userroles/' + data.item.id
    return axios
      .delete(url)
      .then(response => {})
      .catch(e => {
        console.warn('delete userrole', e)
      })
  }
}

export default actions
