<template>
  <section class="container-fluid bg-white">
    <Table
      :data="this.filtertemplates || []"
      :columns="table.columns"
      :actionButton="table.actionButton"
      :disableSearch="false"
      :search="table.search"
      :actions="table.actions"
      @rowClicked="table.clickHandler"
    />
  </section>
</template>

<script>
export default {
  name: 'FiltertemplatesList',
  data () {
    return {
      filtertemplates: [],
      table: {
        columns: [
          {
            label: 'Name',
            property: 'name',
            searchable: true,
            sort: { enabled: true, default: true }
          }
        ],
        actions: [
          { icon: 'fas fa-trash-alt', buttonClass: 'btn-danger', action: this.deleteItem }
        ],
        actionButton: {
          icon: 'fa fa-plus',
          label: 'Create new filter template',
          action: () => this.$router.push({ name: 'FiltertemplateCreate', params: { client: this.$route.params.client } })
        },
        clickHandler: this.editFiltertemplate,
        search: {
          placeholder: 'Search filter templates',
          disabled: false
        }
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    editFiltertemplate (filtertemplate) {
      this.$router.push('/clients/' + this.$route.params.client + '/filtertemplates/' + filtertemplate.id)
    },
    fetchData () {
      this.isLoading = true
      this.$store
        .dispatch('getFiltertemplatesList', this.$route.params.client)
        .then(response => {
          this.filtertemplates = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch filtertemplates: ' + error,
            icon: 'error'
          })
        })
    },
    deleteItem (item, index) {
      this
        .$swal({
          title: 'Are you really sure?',
          text: 'The filtertemplate "' + item.name + '" will be deleted. This cannot be undone!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#dd4b39',
          cancelButtonText: 'Cancel'
        })
        .then(
          result => {
            if (result.value) {
              this.isLoading = true
              const data = {
                item: item
              }
              this.$store
                .dispatch('deleteFiltertemplate', { filtertemplateId: data.item.id })
                .then(response => {
                  this.filtertemplates.splice(index, 1)
                  this.$swal({
                    title: 'Success',
                    text: 'Filtertemplate "' + item.name + '" has been deleted',
                    icon: 'success',
                    showConfirmButton: false,
                    toast: true,
                    position: 'top',
                    timer: 2000
                  })
                  this.isLoading = false
                }).catch(error => {
                  this.isLoading = false
                  this.error = true
                  this.$swal({
                    title: 'Error',
                    text: 'could not delete filtertemplate: ' + error,
                    icon: 'error'
                  })
                })
            }
          }
        )
    }
  }
}
</script>
