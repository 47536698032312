import actions from './actions'

const state = {
  serverURI: import.meta.env.VITE_API_BASE_URL
}

export default {
  actions,
  state
}
