
<ValidationObserver ref="observer" v-slot="{ invalid }" tag="section">
  <div class="mb-4">
    <div class="card mb-4">
      <h4 class="card-header">
        Project Settings
      </h4>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <form v-if="partialProjectData" action="#" role="form">
              <div class="row">
                <ValidationProvider v-slot="{ errors }" vid="title" name="title" slim>
                  <div class="form-group col" :class="{'has-error': errors[0]}">
                    <label for="title">Title</label>
                    <input id="title" v-model="partialProjectData.title" class="form-control" type="text" name="title">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" vid="name" name="name" slim>
                  <div class="form-group col" :class="{'has-error': errors[0]}">
                    <label for="name">Name
                      <small class="text-muted">(URL slug)</small>
                    </label>
                    <input
                      id="name"
                      v-model="partialProjectData.name"
                      class="form-control"
                      type="text"
                      name="name"
                      aria-describedby="nameHelpBlock"
                      @click="showConfirmProjectRenameModal"
                    >
                    <b-modal
                      id="confirmNameChangeModal"
                      v-model="showConfirmNameChangeModal"
                      title="Change Project Name"
                      ok-title="Understood"
                      ok-only
                      size="md"
                    >
                      <p>
                        Looks like you want to change the project name.<br>
                        Don't do that! Things will break immediately.
                      </p>
                    <p>
                      If you know what you're doing, remember to <strong>not</strong> use underscores!
                    </p>
                    </b-modal>
                    <small v-if="partialProjectData.name" id="nameHelpBlock" class="form-text text-muted">
                      https://{{$route.params.client}}.{{dcrFrontendHost}}/{{partialProjectData.name}}/
                    </small>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
              </div>

              <div class="row">
                <div class="form-group col">
                  <label for="live"><strong>is Live</strong></label><br>
                  <input id="live" v-model.lazy.trim="partialProjectData.live" type="checkbox" name="live">
                </div>
                <div class="form-group col">
                  <label for="preview"><strong>is Preview</strong></label><br>
                  <input id="preview" v-model.lazy.trim="partialProjectData.preview" type="checkbox" name="preview">
                </div>
                <div class="form-group col-6">
                  <label for="group_id">Group</label>
                  <select id="group_id" v-model="partialProjectData.group_id" class="form-control" name="group_id">
                    <option value="">select a Group</option>
                    <option v-for="group in groups" :key="group.id" :value="group.id">{{ group.title }}</option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="form-group col">
                  <label for="description">Description</label>
                  <froala-editor id="description" v-model="partialProjectData.description" />
                </div>
              </div>

              <div class="row">
                <ValidationProvider v-slot="{ errors }" vid="date" name="date" slim>
                  <div class="form-group col-6" :class="{'has-error': errors[0]}">
                    <label for="date">Date</label>
                    <input id="date" v-model="partialProjectData.date" class="form-control" type="text" name="date">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
                <div class="form-group col-6">
                  <label for="color">Color</label>
                  <input id="color" v-model="partialProjectData.color" class="form-control" type="text" name="color" placeholder="black, white, #FFFFFF, #ccc">
                </div>
              </div>

              <div class="row">
                <div class="form-group col-4">
                  <label for="locale">Locale</label>
                  <!-- todo: select input -->
                  <input id="locale" v-model="partialProjectData.locale" class="form-control" type="text" name="locale" placeholder="en-gb" aria-describedby="localeHelpBlock">
                  <small id="localeHelpBlock" class="form-text text-muted">
                    <a href="http://tools.ietf.org/rfc/bcp/bcp47.txt" target="_blank">BCP 47</a> language tag (en, en-gb, de,
                    <a href="http://www.iana.org/assignments/language-subtag-registry" target="_blank">...</a>)
                  </small>
                </div>
                <div class="form-group col">
                  <label for="timezone">Timezone</label>
                  <v-select id="timezone" v-model="partialProjectData.timezone" :options="timezones" :reduce="label => label.value" label="label"></v-select>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="partialProjectData" class="mb-4">
      <div class="card">
        <h4 class="card-header">
          AI Features
        </h4>
        <div class="card-body">
          <div class="row col-12" style="gap: 16px;">
            <div class="col">
              <div class="form-group form-check">
                <input id="aiFeaturesEnabled" v-model="partialProjectData.enableAIFeatures" type="checkbox" name="aiFeaturesEnabled" class="form-check-input">
                <label class="form-check-label" for="aiFeaturesEnabled">Enable AI Features</label>
                <small class="form-text text-muted">Enable all the features with built-in AI capabilities.</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <div class="card">
        <h4 class="card-header">
          Assets
        </h4>
        <div class="card-body">
          <div class="row col-12" style="gap: 16px;">
            <SingleAsset
              :item="logoAsset"
              label="Logo"
              helpText="Set custom project logo"
              :useFullSize="false"
              @input="item => setAsset({ ...logoAsset, asset: item.asset })"
            />
            <SingleAsset
              :item="avatarAsset"
              label="Avatar"
              helpText="Icon shown on detail pages"
              :useFullSize="false"
              @input="item => setAsset({ ...avatarAsset, asset: item.asset })"
            />
            <SingleAsset
              :item="teaserAsset"
              label="Teaser"
              helpText=""
              :useFullSize="false"
              @input="item => setAsset({ ...teaserAsset, asset: item.asset })"
            />
            <SingleAsset
              :item="headerBannerAsset"
              label="Header Banner"
              helpText="Banner for project pages"
              :useFullSize="false"
              @input="item => setAsset({ ...headerBannerAsset, asset: item.asset })"
            />
            <SingleAsset
              :item="headerBannerMobileAsset"
              label="Header Banner (mobile)"
              helpText="Banner for short resolutions"
              :useFullSize="false"
              @input="item => setAsset({ ...headerBannerMobileAsset, asset: item.asset })"
            />

          </div>
        </div>
      </div>
    </div>
  </div>

  <hr>
  <SaveBar
    :saveDisabled="invalid"
    @save="updateProjectData(close = false)"
    @saveAndClose="updateProjectData(close = true)"
    @cancel="cancel"
  />
</ValidationObserver>
