<template>
  <div>
    <ValidationProvider
      v-slot="{ errors }"
      name="name"
      tag="div"
    >
      <div class="form-group" :class="{'has-error': errors[0]}">
        <label for="name">Name *</label>
        <input id="name" v-model.trim="value.name" type="text" class="form-control" name="name" required="required">
        <small class="text-danger">{{ errors[0] }}</small>
      </div>
    </ValidationProvider>

    <ValidationProvider
      v-if="user.is_admin"
      v-slot="{ errors }"
      rules="required"
      name="type"
      tag="div"
    >
      <div class="form-group" :class="{'has-error': errors[0]}">
        <b-form-group v-slot="{ ariaDescribedby }" label="Type">
          <b-form-radio-group
            id="type"
            v-model="value.type"
            :aria-describedby="ariaDescribedby"
            name="type"
          >
            <b-form-radio value="clientTemplate">Client Template</b-form-radio>
            <b-form-radio value="adminTemplate">Master Template</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <small class="text-danger">{{ errors[0] }}</small>
      </div>
    </ValidationProvider>

    <ValidationProvider
      v-if="value.type === 'clientTemplate' && user.is_admin"
      v-slot="{ errors }"
      name="client"
      tag="div"
      rules="required"
    >
      <div class="form-group" :class="{'has-error': errors[0]}">
        <label for="collection">Client *</label>
        <select id="collection" v-model="value.clientId" class="form-control">
          <option value="" hidden disabled>Please select a client</option>
          <option
            v-for="clientOption in clientsSortedByTitle"
            :key="clientOption.id"
            :value="clientOption.id"
          >
            {{ clientOption.title }} ({{ clientOption.name }})
          </option>
        </select>
        <small class="text-danger">{{ errors[0] }}</small>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GeneralPageConfig',
  props: ['value'],
  computed: {
    ...mapGetters({
      clients: 'getClientsList',
      user: 'getUser'
    }),
    clientsSortedByTitle () {
      return Array.from(this.clients).sort((a, b) => {
        var titleA = a.title.toUpperCase()
        var titleB = b.title.toUpperCase()
        if (titleA < titleB) {
          return -1
        }
        if (titleA > titleB) {
          return 1
        }

        return 0
      })
    }
  },
  watch: {
    value: {
      deep: true,
      handler (newVal) {
        this.value.clientName = this.getClientName(newVal.clientId)
      }
    },
    clients (newVal) {
      if (newVal) {
        this.value.clientName = this.getClientName(newVal.clientId)
      }
    }
  },
  created () {
    if (!this.clients) {
      return this.$store.dispatch('getClientsList')
    }

    this.value.clientName = this.getClientName(this.value.clientId)
  },
  methods: {
    getClientName (clientId) {
      return this.clients.find(client => client.id === clientId)?.name
    }
  }
}
</script>
