import Vue from 'vue'

import './components'
import App from './App'
import './customize.scss'

import store from './store/'

import router from './router'
import './router/hooks'

import axios from 'axios'

import slugify from 'slugify'

// Import Helpers for filters
import './filters'

slugify.extend({
  ä: 'ae',
  ö: 'oe',
  ü: 'ue',
  ß: 'ss'
})

Vue.config.productionTip = false

// Check local storage to handle refreshes
if (window.localStorage) {
  if (store.state.user !== window.localStorage.getItem('user')) {
    store.commit('SET_USER', window.localStorage.getItem('user'))
    store.commit('SET_TOKEN', window.localStorage.getItem('token'))
    if (window.localStorage.getItem('project')) {
      store.commit('LOAD_PROJECT', JSON.parse(window.localStorage.getItem('project')))
    }
  }
}

// configure axios defaults
if (store.state.token) {
  axios.defaults.headers.common.authorization = 'Bearer ' + store.state.token
}

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
