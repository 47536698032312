<template>

<div class="tablelist pt-3">

  <div class="row pb-2">
    <div class="col mr-auto">
      <SearchBar
        v-if="searchEnabled"
        ref="searchInput"
        v-model="query"
        :placeholder="searchPlaceHolder"
        @focus="searchbarFocused=true"
        @blur="searchbarFocused=false"
      />
    </div>
    <div v-if="actionButton" class="col-auto">
      <button
        class="btn btn-success"
        tabindex="-1"
        @click.prevent="actionButton.action"
      >
        <i v-if="actionButton.icon" :class="actionButton.icon"></i>
        <span class="d-none d-xl-inline"> {{actionButton.label}} </span>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <table class="table table-striped table-hover table-bordered">
        <thead>
          <tr>
            <th
              v-for="(column, index) in columns"
              :key="`tablehead-${index}`"
              scope="col"
              :class="{ active: isActiveSortColumn(index) }"
              @click="onTableheadClick(column, index)"
            >
              {{ column.label }}
              <span v-if="column.sort && column.sort.enabled">
                <i v-if="!isActiveSortColumn(index)" class="fas fa-sort"/>
                <i v-if="isActiveSortColumn(index) && sortOptions.order === 'ASC'" class="fas fa-sort-up" />
                <i v-if="isActiveSortColumn(index) && sortOptions.order === 'DESC'" class="fas fa-sort-down" />
              </span>
            </th>

            <th v-if="actions" class="w-1 text-center text-nowrap">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, dataIndex) in filteredData"
            :key="`data-${dataIndex}`"
            ref="row"
            :class="{
              focused: focusIndex === dataIndex,
              notclickable: rowsNotClickable
            }"
            tabindex="0"
            @focus="onRowFocus(item.data, dataIndex)"
            @blur="onRowBlur(item.data, dataIndex)"
            @click="onRowClick(item.data, item.meta.index)"
          >
            <td
              v-for="(column, columnIndex) in columns"
              :key="`column-${columnIndex}`"
              class="p-10"
            >
              <div
                v-if="column.badge"
                :class="[column.badgeClass(getColumnData(item, column))]"
                class="badge"
              >
                <span> {{getColumnData(item, column)}} </span>
              </div>
              <i
                v-else-if="column.icon"
                :class="[column.iconClass(getColumnData(item, column))]"
              />
              <input v-else-if="column.editable"
                 :value="getColumnData(item, column)"
                 @input="event => onDataChanged(item, event.target.value)"/>
              <span v-else > {{getColumnData(item, column)}} </span>
            </td>

            <template v-if="actions">
              <td
                class="p-10 w-1 text-center text-nowrap"
              >
                <button
                  v-for="(action, actionIndex) in actions"
                  :key="`action-${actionIndex}`"
                  :class="[action.buttonClass ? action.buttonClass : 'btn-primary']"
                  class="btn btn-sm mr-1"
                  tabindex="-1"
                  :title="action.title"
                  @click.stop="action.action(item.data, item.meta.index)"
                >
                  <i v-if="action.icon" :class="action.icon"></i>
                  <span class="d-none d-xl-inline"> {{action.label}} </span>
                </button>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>

</template>

<script>
export default {
  name: 'Table',
  props: {
    data: {
      type: Array
    },
    columns: {
      type: Array
    },
    actions: {
      type: Array
    },
    actionButton: {
      type: Object
    },
    search: {
      type: Object
    },
    clickHandler: {
      type: Function
    },
    rowsNotClickable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      query: undefined,
      focusIndex: undefined,
      searchbarFocused: false,
      sortOptions: {
        sorted: false,
        columnIndex: undefined,
        order: 'ASC'
      }
    }
  },
  computed: {
    searchPlaceHolder () {
      return this.search?.placeholder || 'Search'
    },
    searchEnabled () {
      return !this.search || !this.search.disabled
    },
    dataWithIndex () {
      return this.data.map((data, index) => ({ data, meta: { index } }))
    },
    sortedData () {
      if (!this.sortOptions.sorted) {
        return this.dataWithIndex
      }

      const sortColumn = this.columns[this.sortOptions.columnIndex]

      // Using slice to clone the array since sort works in-place on the array
      return this.dataWithIndex.slice().sort((item1, item2) => {
        if (sortColumn.property) {
          return String(item1.data[sortColumn.property])
            .localeCompare(item2.data[sortColumn.property], undefined, { numeric: true }) *
                (this.sortOptions.order === 'ASC' ? 1 : -1)
        }

        if (sortColumn.transform) {
          return String(sortColumn.transform(item1.data))
            .localeCompare(sortColumn.transform(item2.data), undefined, { numeric: true }) *
                (this.sortOptions.order === 'ASC' ? 1 : -1)
        }
      })
    },
    filteredData () {
      const columns = this.columns.filter(column => column.searchable)
      const query = this.query?.toLowerCase().trim()

      if (!query || !columns) {
        return this.sortedData
      }

      return this.sortedData
        .filter((item) => {
          return columns.some(column => {
            if (column.property) {
              return item.data[column.property].toLowerCase().trim().includes(query)
            }

            if (column.transform) {
              return column.transform(item.data).toLowerCase().trim().includes(query)
            }

            return false
          })
        })
    }
  },
  created () {
    const defaultSortColumnIndex = this.columns.findIndex(column => column.sort?.default)

    if (defaultSortColumnIndex >= 0) {
      this.sortOptions.sorted = true
      this.sortOptions.columnIndex = defaultSortColumnIndex
      this.sortOptions.order = this.columns[defaultSortColumnIndex].sort.order || 'ASC'
    }

    window.addEventListener('keydown', this.handleKeyDown, false)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.handleKeyDown, false)
  },
  methods: {
    getColumnData (item, column) {
      if (column.property) {
        return item.data[column.property]
      }

      if (column.transform) {
        return column.transform(item.data)
      }
    },
    isActiveSortColumn (index) {
      return index === this.sortOptions.columnIndex
    },
    onTableheadClick (column, index) {
      if (!column.sort?.enabled) {
        return
      }

      this.sortOptions.sorted = true

      if (this.sortOptions.columnIndex === index) {
        this.sortOptions.order = this.sortOptions.order === 'ASC' ? 'DESC' : 'ASC'
        return
      }

      this.sortOptions.columnIndex = index
      this.sortOptions.order = column.sort.order || 'ASC'
    },
    onDataChanged (item, newValue) {
      this.$emit('dataChanged', item, newValue)
    },
    onRowClick (item, index) {
      this.$emit('rowClicked', item, index)
    },
    onRowFocus (item, index) {
      this.focusIndex = index
    },
    onRowBlur () {
      this.focusIndex = undefined
    },
    handleKeyDown (keyboardEvent) {
      switch (keyboardEvent.key) {
        case 'Enter':
          if (this.focusIndex !== undefined) {
            this.$refs.row[this.focusIndex].click()
          }
          break
        case 'ArrowDown':
          if (this.focusIndex !== undefined) {
            this.focusIndex < this.filteredData.length - 1 ? this.focusIndex++ : this.focusIndex = 0
            keyboardEvent.preventDefault()
            this.$refs.row[this.focusIndex].focus()
          } else if (this.searchbarFocused) {
            this.focusIndex = 0
            keyboardEvent.preventDefault()
            this.$refs.row[this.focusIndex].focus()
          }
          break
        case 'ArrowUp':
          if (this.focusIndex !== undefined) {
            this.focusIndex > 0 ? this.focusIndex-- : this.focusIndex = this.filteredData.length - 1
            keyboardEvent.preventDefault()
            this.$refs.row[this.focusIndex].focus()
          }
          break
      }
    }
  }
}
</script>
<style lang="scss">
  .tablelist {
    background: white;

    th {
      background-color: #3c8dbc;
      color: rgba(white, .66);
      cursor: pointer;

      &.active {
        color: white;
      }
    }

    tr {
      cursor: pointer;
      user-select: none;

      &.notclickable {
        cursor: default;
      }
    }
  }
</style>
