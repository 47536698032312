
<ValidationObserver v-slot="{ invalid }" tag="section" class="content">
  <div class="box row">
    <div class="col-12">
      <h2>edit usergroup</h2>
      <form name="edit-usergroup" class="form row">
        <ValidationProvider v-slot="{ errors }" name="name" tag="div" class="col-12">
          <div class="form-group" :class="{'has-error': errors[0]}">
            <label for="name">Name: *</label>
            <input id="name" v-model.trim="usergroup.name" type="text" class="form-control" required="required">
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <div class="col-12">
          <div class="form-group">
            <label for="description">Description:</label>
            <textarea id="description" v-model.lazy.trim="usergroup.description" name="description" class="form-control" rows="3">
            </textarea>
          </div>
        </div>
        <div class="col-12">
          <div v-for="(client, index) in usergroup.clients" :key="client.id + '_' +index">
            <div class="row">
              <div class="col-10">
                <div class="form-group">
                  <label for="client">select a client the usergroup should have access to:</label>
                  <select id="client" v-model="usergroup.clients[index].id" name="client" class="form-control">
                    <option value="0" selected="selected">select a client</option>
                    <option v-for="item in clients" :key="item.id" :value="item.id">{{ item.title }}</option>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <button class="btn btn-danger" @click.prevent="deleteClientFromUsergroup(index)">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 text-center">
            <button type="submit" class="btn btn-primary" @click.prevent="addClientToUsergroup">
              <i class="fa fa-plus"></i> add client
            </button>
          </div>
        </div>
        <SaveBar
          :saveDisabled="invalid"
          @save="updateUsergroup(close = false)"
          @saveAndClose="updateUsergroup(close = true)"
          @cancel="cancel"
        />
      </form>
    </div>
  </div>
</ValidationObserver>
