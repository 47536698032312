
<ValidationObserver v-slot="{ invalid }" tag="section" class="container-fluid">
  <div class="box row">
    <div class="col-md-12">
      <h2>Create new domain</h2>
      <form name="create-domain" class="form row">
        <ValidationProvider v-slot="{ errors }" name="domain host" tag="div" class="col-md-6"
          rules="required|hostname">
          <div class="form-group col-md-12" :class="{'has-error': errors[0]}">
            <label for="host">Host: *</label>
            <input id="host" v-model.trim="domain.domain" type="text" class="form-control" required="required" placeholder="congress.client.com">
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <ValidationProvider v-slot="{ errors }" name="domain client" tag="div" class="col-md-6"
          rules="required|client">
          <div class="form-group col-md-12" :class="{'has-error': errors[0]}">
            <label for="client_id">Client: *</label>
            <b-form-select id="client_id" v-model="domain.client_id" :options="clients"></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <SaveBar
          :saveDisabled="invalid"
          disableSaveAndClose
          @save="createDomain"
          @cancel="cancel"
        />
      </form>
    </div>
  </div>
</ValidationObserver>
