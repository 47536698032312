
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h3>Pages</h3>
      <hr>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
     <div v-if="pages.length > 0" class="list-group">
       <a v-for="(page, index) in pages"
        :key="page.id + '_' + index"
        href="#"
        :class="getClassForPageItem(page.id)"
        class="list-group-item list-group-item-action"
        @click.prevent="editPage(page.id)"
        >
          <div v-if="page.title">{{ page.title }}</div>
          <div v-else class="text-danger">Title is missing</div>
        </a>
     </div>
     <div v-else>
       No pages defined.
     </div>
     <button class="btn btn-primary mt-4" @click.prevent="createPage"><i class="fa fa-plus"></i> add new Page</button>
    </div>
    <div v-if="page" class="col-9">
      <ValidationObserver ref="observer" v-slot="{ invalid }" tag="div" class="row">
        <div class="col-12">
          <div class="card mb-4">
            <h4 class="card-header">
              Page Settings: {{page.title}}
            </h4>
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <ValidationProvider v-slot="{ errors }" vid="pageTitle" name="pageTitle" slim>
                    <div class="form-group" :class="{'has-error': errors[0]}">
                      <label for="pageTitle">Title</label>
                      <input id="pageTitle" v-model="page.title" class="form-control" type="text" name="pageTitle" required>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                  </div>
                  <div class="col-6">
                  <ValidationProvider v-slot="{ errors }" vid="slug" name="slug" slim>
                    <label for="slug">Slug</label>
                    <div class="input-group" :class="{'has-error': errors[0]}">
                      <input id="name" v-model="page.slug" class="form-control" type="text" name="slug" required aria-describedby="nameHelpBlock">
                      <div class="input-group-append">
                        <a :href="pageUrl" target="_blank" title="open page preview in new tab" class="btn btn-default">
                          <i class="fas fa-external-link-alt text-secondary"></i>
                        </a>
                      </div>
                    </div>
                    <small class="text-muted">Unique identifier which can be used in embed codes.</small><br>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>

          <edit-page
            v-if="client.data.id"
            :pageType="pageType"
            :relation="relation"
            :relationType="relationType"
            :clientId="client.data.id"
            :slug="page.slug"
            :title="page.title"
            :pageId="page.id"
            :invalid="invalid"
            @created="getPages(), currentPageId = $event, page.id = $event"
            @updated="getPages()"
            @deleted="getPages(), page = undefined, currentPageId = false"
          ></edit-page>
        </div>
      </ValidationObserver>
    </div>
    <div v-else>
      Please select the page you want to edit.
    </div>
  </div>
</div>
