<template>
<ValidationObserver v-slot="{ invalid }" tag="section" class="container-fluid">
  <b-modal
    :id="id"
    scrollable
    size="lg"
    no-fade
    :title="title"
    @hide="$emit('close')"
  >
      <form name="create-page-template" class="form row">
        <div class="col-12">
          <general-page-config
            v-model="generalPageConfig"
          ></general-page-config>
        </div>
      </form>

      <template v-slot:modal-footer>
        <SaveBar
          :saveDisabled="invalid"
          :saveButton="{
            iconClass: 'fas fa-save',
            label: 'create',
            class: 'btn btn-success'
          }"
          :saveAndCloseButton="{
            iconClass: 'fas fa-edit',
            label: 'create + edit',
            class: 'btn btn-success'
          }"
          @save="create"
          @saveAndClose="createAndEdit"
          @cancel="cancel"
        />
      </template>

  </b-modal>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'create-page-template-modal',
  props: {
    id: {
      type: String
    },
    title: {
      type: String,
      default: 'Create page template'
    },
    page: {
      type: Object,
      required: true
    },
    clientId: {
      type: String
    }
  },
  data () {
    return {
      generalPageConfig: {
        name: this.page.name || this.page.title,
        type: 'clientTemplate',
        clientId: this.clientId,
        clientName: undefined
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    })
  },
  watch: {
    'page.type': {
      handler: function (value) {
        if (['adminTemlate', 'clientTemplate'].includes(value)) {
          this.generalPageConfig.type = value
        }
      },
      deep: true
    }
  },
  created () {
    if (['adminTemplate', 'clientTemplate'].includes(this.page.type)) {
      this.generalPageConfig.type = this.page.type
    }
  },
  methods: {
    async create () {
      const { name } = await this.saveAsPageTemplate()

      this.$swal({
        title: 'Success',
        text: `Page template "${name}" created`,
        icon: 'success',
        showConfirmButton: false,
        toast: true,
        position: 'top',
        timer: 2000
      })

      this.$emit('create')
    },
    async createAndEdit () {
      const { id } = await this.saveAsPageTemplate()

      if (this.generalPageConfig.type === 'clientTemplate') {
        this.$router.push({ path: `/clients/${this.generalPageConfig.clientName}/pagetemplates/${id}` })
      } else {
        this.$router.push({ path: `/pagetemplates/${id}` })
      }

      this.$emit('createAndEdit')
    },
    cancel () {
      this.$emit('cancel')
    },
    resetModalState () {
      this.generalPageConfig.name = ''
    },
    async saveAsPageTemplate () {
      const template = JSON.parse(JSON.stringify(this.page))
      template.name = this.generalPageConfig.name
      template.title = this.generalPageConfig.name
      template.type = this.generalPageConfig.type
      template.slug = null

      if (this.generalPageConfig.type === 'adminTemplate') {
        delete template.client_id
      }

      if (this.generalPageConfig.type === 'clientTemplate') {
        template.client_id = this.generalPageConfig.clientId
      }

      const createdTemplate = await this.$store
        .dispatch('copyPageTemplate', template)

      const result = {
        id: createdTemplate.id,
        name: this.generalPageConfig.name
      }

      this.resetModalState()

      return result
    }
  }
}
</script>
