<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }" tag="section" class="container-fluid">
    <div class="row box">
      <div class="col-12">
        <h2>Update package</h2>
        <form name="edit-module" role="form">
          <input v-if="packageData.id" v-model="packageData.id" type="hidden" name="id">
          <div class="row">
            <div class="col-12">
              <ValidationProvider v-slot="{ errors }" vid="title" name="package title" tag="div">
                <div class="form-group" :class="{'has-error': errors[0]}">
                  <label for="title">Package Title: *</label>
                  <div class="input-group">
                    <input v-model.trim="packageData.title" type="text" class="form-control" required="required">
                    <div class="input-group-append">
                      <a :href="'http://' + $route.params.client + '.' + dcrFrontendHost + '/packages/' + packageData.slug" target="_blank" class="btn btn-default">
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                    </div>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <ValidationProvider v-slot="{ errors }" vid="slug" name="package slug" tag="div">
                <div class="form-group" :class="{'has-error': errors[0]}">
                  <label for="slug">Slug: *</label>
                  <input v-model.trim="packageData.slug" type="text" class="form-control" name="slug" required="required">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
              <div class="form-group">
                <input id="draft" v-model.lazy.trim="packageData.draft" type="checkbox" name="draft" value="1">
                <label for="draft">Draft Mode</label><br>
                <small class="text-muted">This package is only accessible via the direct link.</small>
              </div>
            </div>
            <div v-if="packageData.sku" class="col-6">
              <div class="form-group">
                <label for="sku">SKU:</label>
                <input v-model.lazy.trim="packageData.sku" type="text" class="form-control" name="sku" readonly="true">
              </div>
            </div>
          </div>
          <div class="row">
            <div style="width:230px; padding-left:15px; padding-right:15px">
              <SingleAsset
                label="Logo:"
                :item="assetItem"
                :useFullSize="false"
                @input="setAsset"
              />
            </div>
              <div class="col">
              <div class="form-group">
                <label for="subline">Subline:</label>
                <textarea v-model.lazy.trim="packageData.subline" name="subline" class="form-control" cols="30" rows="10"></textarea>
              </div>
            </div>
            </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="author">Author: </label>
                <input v-model.lazy.trim="packageData.author" type="text" class="form-control" name="author">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="date">Date: </label>
                <input v-model.lazy.trim="packageData.date" type="text" class="form-control" name="date">
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="topics">Topics: </label>
                <input v-model.lazy.trim="packageData.topics" type="text" class="form-control" name="topics" placeholde="topic 1,topic 2,third topic">
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-12 mt-4">
        <edit-modules title="package content" :availableModules="availableModules" :data="packageData"></edit-modules>
        <hr/>
      </div>
      <SaveBar
        :saveDisabled="invalid"
        @save="savePackage(close = false)"
        @saveAndClose="savePackage(close = true)"
        @cancel="cancel"
      />
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'editPackage',
  data () {
    return {
      packageData: {},
      asset: false,
      dcrFrontendHost: import.meta.env.VITE_DCR_FRONTEND_HOST,
      availableModules: []
    }
  },
  computed: {
    ...mapGetters({
      getAssetById: 'getAssetById',
      client: 'getClient'
    }),
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    },
    clientName () {
      return this.client.data.name
    },
    assetItem () {
      return { asset: this.packageData.asset?.image?.id }
    }
  },
  watch: {
    $route (route) {
      if (route.params.id) {
        this.loadData(route.params.id)
        this.getAvailableModules()
      }
    }
  },
  created () {
    if (this.$route.params.id) {
      this.loadData(this.$route.params.id)
    }
    this.getClientData(this.$route.params.client)
    this.getAvailableModules()
  },
  methods: {
    loadData (packageId) {
      this.isLoading = true
      this.$store
        .dispatch('getPackage', packageId)
        .then(response => {
          this.$set(this, 'packageData', response.data)
          this.$set(this.packageData, 'modules', response.modules)
          this.$set(this.packageData, 'asset', response.asset || {})
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    savePackage (close = false) {
      this.isLoading = true
      this.$store
        .dispatch('updatePackage', { packageId: this.packageData.id, client: this.$route.params.client, data: this.packageData })
        .then(response => {
          this.$swal({
            title: 'Success',
            text: 'Package was updated',
            icon: 'success',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
          this.isLoading = false
          if (close) {
            this.cancel()
          }
        }).catch(error => {
          this.$refs.observer.setErrors(error.response.data.errors)
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not update package: ' + error.response.data.message,
            icon: 'error'
          })
        })
    },
    getClientData (client) {
      this.isLoading = true
      this.$store
        .dispatch('getClientData', client)
        .then(response => {
          this.isLoading = false
        })
    },
    setAsset (item) {
      if (!item) {
        this.deleteAsset()
      }
      this.$set(this.packageData.asset, 'image', this.getAssetById(this.clientName, item.asset))
    },
    deleteAsset () {
      this.$set(this.packageData.asset, 'image', {})
    },
    getAvailableModules () {
      this.isLoading = true
      this.$store
        .dispatch('getModulesForPackages')
        .then(response => {
          this.availableModules = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not fetch modules: ' + error,
            icon: 'error'
          })
        })
    },
    cancel () {
      this.$router.push('/clients/' + this.$route.params.client + '/packages')
    }
  }
}
</script>
