
<b-modal
  :id="id"
  :scrollable="scrollableModal"
  size="lg"
  no-fade
  :title="title"
  @hide="$emit('close')"
>
  <form v-if="moduletype !== ''" role="form" @submit.prevent>
    <div class="row">
      <div class="col-12">
        <component
          :is="moduletype"
          v-if="isComponent"
          :model="mod.model"
        ></component>
        <former-form
          v-else
          :model="mod.model"
          :form="mod.form"
          :formerfields="mod.fields"
        ></former-form>
      </div>
    </div>
  </form>
  <template v-slot:modal-footer>
    <button class="btn btn-default" @click.prevent="cancelSubmission">
      <i class="fas fa-close"></i> Cancel
    </button>
    <button class="btn btn-primary" @click.prevent="handleSubmission">
      <i class="fa fa-check"></i> Ok
    </button>
  </template>
</b-modal>
