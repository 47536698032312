
<ValidationObserver v-slot="{ invalid, errors }" tag="section" class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h2>{{client.data.title}}</h2>
    </div>
  </div>

  <div class="row">
    <form name="create-client" class="form col-12">
      <input v-model="client.data.id" type="hidden" name="id">
      <b-card no-body class="row">
        <b-tabs v-model="activeTab" card>
          <b-tab>
            <template slot="title">
              <div :class="{ tab__error: hasTabErrors('General', errors)}">
                <i class="fas fa-database"></i> General
              </div>
            </template>

            <div class="row mb-4">
              <div class="col-12">
                <div class="card h-100">
                  <h4 class="card-header">
                    Client Settings
                  </h4>
                  <div class="card-body">
                    <div class="row">
                      <ValidationProvider v-slot="{ errors }" name="title" tag="div" class="col-6">
                        <div class="form-group" :class="{'has-error': errors[0]}">
                          <label for="title">Title *</label>
                          <input id="title" v-model.trim="client.data.title" type="text" class="form-control" required="required">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                      <ValidationProvider v-slot="{ errors }" name="subdomain name" tag="div" class="col-6">
                        <div class="form-group" :class="{'has-error': errors[0]}">
                          <label for="name">Subdomain *</label>
                          <input id="name" v-model.trim="client.data.name" type="text" class="form-control" required="required">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                      <div class="form-group col-6">
                        <label for="home_target_url">Home Target URL</label>
                        <input id="home_target_url" v-model="client.data.homeTargetUrl" type="text" name="home_target_url" class="form-control">
                        <small class="text-muted">This URL will replace the usual home URL of the logo.</small>
                      </div>
                      <div class="form-group col-6">
                        <label for="default_redirect_url">Default Redirect URL</label>
                        <input id="default_redirect_url" v-model="client.data.defaultRedirectUrl" type="text" name="default_redirect_url" class="form-control">
                        <small class="text-muted">After login redirect the user to this URL instead of the home URL (e.g.: "/project-slug")</small>
                      </div>
                      <div class="form-group col-6">
                        <label for="theme_color">Theme color</label>
                        <input id="theme_color" v-model="client.data.theme_color" type="text" name="theme_color" class="form-control" placeholder="#ffffff">
                      </div>
                      <div class="form-group col-6">
                      </div>
                      <div class="form-group col-12">
                        <label for="decription">Description</label>
                        <froala-editor id="description" v-model="client.data.description" name="description"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12">
                <div class="card h-100">
                  <h4 class="card-header">
                    General
                  </h4>
                  <div class="card-body">
                    <div class="form-group col-6">
                      <input id="enableSentry" v-model="client.data.enableSentry" type="checkbox" name="enableSentry"
                        class="form-check-input" value="0">
                      <label for="enableSentry">Enable Sentry</label>
                      <small class="form-text text-muted">Sentry can be activated over this checkbox.</small>
                    </div>
                    <hr>
                    <ValidationProvider v-slot="{ errors }" name="Matomo Site Id" rules="integer" tag="div"
                      class="col-md-6">
                      <div class="form-group">
                        <label for="matomoSiteId">Matomo Site ID</label>
                        <input id="matomoSiteId" v-model="client.data.matomoSiteId" type="text" class="form-control"
                          name="apiClientSecret">
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-6">
                <div class="card h-100">
                  <h4 class="card-header">
                    Speaker Format
                  </h4>
                  <div class="card-body">
                    <div class="form-group form-check">
                      <input id="speakerShowTitle" v-model="client.data.speakerShowTitle" type="checkbox" name="speakerShowTitle" class="form-check-input" value="1">
                      <label for="speakerShowTitle">show speaker title</label>
                      <small class="form-text text-muted">display a speaker's title (e.g. Prof., Dr.)</small>
                    </div>
                    <div class="form-group">
                      <label for="speakerNameFormat">speaker name format</label>
                      <select id="speakerNameFormat" v-model="client.data.speakerNameFormat" class="form-control">
                        <option value="shortname" selected="selected">shortname [J. Schmidt]</option>
                        <option value="fullname" selected="selected">fullname [Josefine Werner Schmidt]</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="speakerLocationFormat">speaker location format</label>
                      <select id="speakerLocationFormat" v-model="client.data.speakerLocationFormat" class="form-control">
                        <option value="none" selected="selected">none</option>
                        <option value="city" selected="selected">city [Cologne]</option>
                        <option value="cityandshortcountry" selected="selected">city + short country [Cologne, DE]</option>
                        <option value="cityandcountry" selected="selected">city + country [Cologne, Germany]</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="loggedInUser.is_admin" class="col-6">
                <div class="card h-100">
                  <h4 class="card-header">
                    Video Tracking
                  </h4>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group form-check">
                          <input id="enableVideoTracking" :checked="client.data.enableVideoTracking" type="checkbox" class="form-check-input"
                            @click="enforceTrackingDisableConfirmation">
                          <label class="form-check-label" for="enableVideoTracking">Enable Video Tracking</label>
                        </div>
                      </div>
                    </div>
                    <div v-if="client.data.enableVideoTracking" class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <label for="videoTrackingEndpoint">Endpoint URI</label>
                           <input id="videoTrackingEndpoint" v-model="client.data.videoTrackingEndpoint" type="text" name="videoTrackingEndpoint" class="form-control">
                          <small class="form-text text-muted">This endpoint is used to receive tracking data from the videos players.</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-6 h-100">
                <div class="card">
                  <h4 class="card-header">
                    User Engagement
                  </h4>
                  <div class="card-body">
                    <div class="form-group form-check">
                      <input id="enableFavorites" v-model="client.data.enableFavorites" type="checkbox" name="enableFavorites" class="form-check-input" value="1">
                      <label for="enableFavorites">Enable Favorites</label>
                      <small class="form-text text-muted">You can add a menu item under "Navigation" to access the favorites.</small>
                    </div>
                    <div class="form-group form-check">
                      <input id="enableLikes" v-model="client.data.enableLikes" type="checkbox" name="enableLikes" class="form-check-input" value="1">
                      <label for="enableLikes">Enable Likes</label>
                      <small class="form-text text-muted">Liking allows users to rate congress resources and sort by likes in the library.</small>
                    </div>
                    <div class="form-group form-check">
                      <input id="enableSocialShare" v-model="client.data.enableSocialShare" type="checkbox" name="enableSocialShare" class="form-check-input" value="1">
                      <label for="enableSocialShare">Enable Social Sharing</label>
                      <small class="form-text text-muted">Enables social share buttons on a lecture detail level.</small>
                    </div>
                    <div class="form-group form-check">
                      <input id="enableCitation" v-model="client.data.enableCitation" type="checkbox" name="enableCitation" class="form-check-input" value="1">
                      <label for="enableCitation">Enable Citation</label>
                      <small class="form-text text-muted">Enables citation of lectures.</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="card h-100">
                  <h4 class="card-header">
                    Live Counter
                  </h4>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group form-check">
                          <input id="liveCounterEnabled" v-model="client.data.enableLiveCounter" type="checkbox" name="liveCounterEnabled" class="form-check-input">
                          <label class="form-check-label" for="liveCounterEnabled">Enable Live Counter</label>
                          <small class="form-text text-muted">Enables displaying amount of currently active users (scoped) in supported modules. You can configure this in supported modules.</small>
                        </div>
                      </div>
                    </div>
                    <div v-if="client.data.enableLiveCounter" class="form-row">
                      <div class="col">
                        <div class="form-group form-check">
                          <input id="hideLiveCounterInHeader" v-model="client.data.hideLiveCounterInHeader" type="checkbox" name="hideLiveCounterInHeader" class="form-check-input"/>
                          <label class="form-check-label" for="hideLiveCounterInHeader">Hide Live Counter in header</label>
                          <small class="form-text text-muted">Hides the display of live users in the website's header.</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12">
                <div class="card h-100">
                  <h4 class="card-header">
                    Cookies
                  </h4>
                  <div class="card-body">
                    <div class="form-group">
                      <b><label>Required Cookie Settings</label></b>
                      <small class="form-text text-muted"><i>"Legal Company Name"</i> and <i>"Privacy Policy URL"</i> are important fields that must conform to legal requirements. Omitting these will result in a non-compliant page.</small>
                    </div>
                    <div class="form-row">
                      <ValidationProvider v-slot="{ errors }" name="Legal company label" tag="div" class="col">
                        <div class="form-group" :class="{'has-error': errors[0]}">
                          <b><label for="cookieLegalCompanyLabel">Legal company label</label></b>
                          <span v-if="!client.data.cookieLegalCompanyLabel" class="badge ml-3 mt-1 badge-warning">Not filled</span>
                          <small class="form-text text-muted">Complete the customer's company name, e.g., meta-fusion GmbH, Germany (www.meta-fusion.com).</small>
                          <input id="cookieLegalCompanyLabel" v-model="client.data.cookieLegalCompanyLabel" type="text" class="form-control col" name="cookieLegalCompanyLabel">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                      <ValidationProvider v-slot="{ errors }" name="Privacy Policy Url" tag="div" class="col">
                        <div class="form-group" :class="{'has-error': errors[0]}">
                          <b><label for="cookiePrivacyPolicyUrl">Privacy Policy Url</label></b>
                          <span v-if="!client.data.cookiePrivacyPolicyUrl" class="badge ml-3 mt-1 badge-warning">Not filled</span>
                          <small class="form-text text-muted">URL to customer's privacy policy.</small>
                          <input id="cookiePrivacyPolicyUrl" v-model="client.data.cookiePrivacyPolicyUrl" type="text" class="form-control col" name="cookiePrivacyPolicyUrl">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                    </div>
                    <hr>
                    <div class="form-group form-check">
                      <input id="cookieConsentEnabled" v-model="client.data.cookieConsentEnabled" type="checkbox" name="cookieConsentEnabled" class="form-check-input">
                      <label for="cookieConsentEnabled">Enable Cookie Consent</label>
                      <small class="form-text text-muted">You can edit the content for the banner text and button under "Languages".</small>
                    </div>
                    <ValidationProvider v-slot="{ errors }" name="Cookie Validity Period" rules="positiveInteger" tag="div" class="col-md-12">
                      <div class="form-group">
                        <label for="cookieValidityPeriodInDays">Cookie Validity Period in days</label>
                        <small class="form-text text-muted">You can customize the cookie validity duration in days.</small>
                        <input id="cookieValidityPeriodInDays" v-model="client.data.cookieValidityPeriodInDays" type="text" class="form-control col-md-4" name="cookieValidityPeriodInDays">
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-6">
                <div class="card h-100">
                  <h4 class="card-header">
                    Intro
                  </h4>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group form-check">
                          <input id="introEnabled" v-model="client.intro.enabled" type="checkbox" class="form-check-input">
                          <label class="form-check-label" for="introEnabled">Enable Intro</label>
                        </div>
                      </div>
                    </div>
                    <div v-if="client.intro.enabled" class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <label for="type">Asset type</label>
                          <select v-model="client.intro.asset.type" class="form-control">
                            <option value="video" selected="selected">Video</option>
                            <option value="image" selected="selected">Image</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label for="duration">Intro duration</label>
                          <input id="duration" v-model="client.intro.duration" type="text" name="duration" class="form-control">
                          <small class="form-text text-muted">Auto close intro overlay after x seconds. Set to 0 for automatic duration (video).</small>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="asset-url">Asset URL</label>
                          <input id="asset-url" v-model="client.intro.asset.url" type="text" name="asset-url" class="form-control">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="card h-100">
                  <h4 class="card-header">
                    meta-player
                  </h4>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group form-check">
                          <input id="metaPlayerEnabled" v-model="client.data.enableMetaPlayer" type="checkbox" name="metaPlayerEnabled" class="form-check-input">
                          <label class="form-check-label" for="metaPlayerEnabled">Enable meta-player</label>
                          <small class="form-text text-muted">Enable the new and advanced meta-player.</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <b><label>Special meta-player Features</label></b>
                      <small class="form-text text-muted">You can opt-in to use our special features integrated into the meta-player.</small>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group form-check">
                          <input id="generateTranscriptSearch" v-model="client.data.enableTranscriptSearch" type="checkbox" name="generateTranscriptSearch" class="form-check-input">
                          <label class="form-check-label" for="generateTranscriptSearch">Search in transcript</label>
                          <small class="form-text text-muted">Use the automatically generated transcript in the search function of the player.</small>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group form-check">
                          <input id="generateTranscriptDownload" v-model="client.data.enableTranscriptDownload" type="checkbox" name="generateTranscriptDownload" class="form-check-input">
                          <label class="form-check-label" for="generateTranscriptDownload">Transcript download</label>
                          <small class="form-text text-muted">Adds a Download button in the Info-View, which downloads the transcript as a PDF file.</small>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group form-check">
                          <input id="enableSubtitles" v-model="client.data.enableSubtitles" type="checkbox" name="enableSubtitles" class="form-check-input">
                          <label class="form-check-label" for="enableSubtitles">Subtitles feature</label>
                          <small class="form-text text-muted">Enables subtitles for the chosen languages.</small>
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="form-group">
                      <label class="form-check-label" for="">Choose Languages</label>
                      <small class="form-text text-muted">{{ numberSelectedLanguages }}</small>
                    </div>
                    <div class="form-row">
                      <p class="btn btn-primary" @click.prevent="() => showAllLanguagesState = !showAllLanguagesState">{{showLanguagesText}}</p>
                    </div>
                    <div v-if="showAllLanguagesState">
                      <div class="languages">
                        <div v-for="(language, index) in transcriptLanguages" :key="index" class="form-group form-check">
                          <input :id="language.language" v-model="language.value" :name="language.language" type="checkbox" class="form-check-input">
                          <label :for="language.language" class="form-check-label">{{ language.language }}</label>
                        </div>
                      </div>
                      <button class="btn btn-primary mt-3" @click.prevent="selectAllLanguages">{{ showAllButtonText }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
               <div class="col-12">
                <div class="card h-100">
                  <h4 class="card-header">
                    Whitelisted URLs
                  </h4>
                  <div class="card-body">
                    <div class="form-group col-6">
                      <label for="whitelist_url">All Whitelisted URLs</label>
                      <small class="form-text text-muted">You can add multiple URLs that will be added to the whitelist.</small>
                    </div>
                    <Table
                      :data="currentWhitelistUrls || []"
                      :columns="table.columns"
                      :actionButton="table.actionButton"
                      :disableSearch="true"
                      :search="table.search"
                      :actions="table.actions"
                    />
                    <div v-if="showTextfield">
                      <div class="form-group col-6">
                        <ValidationProvider v-slot="{ errors }" name="Whitelisted URLs" rules="absolut_url" tag="div">
                          <small for="whitelist_url">Add your new url here</small>
                          <input id="whitelist_url" v-model="newEntry" type="text" name="whitelist_url" class="form-control">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-6">
                        <button class="btn btn-primary" @click.prevent="addWhitelistUrl">
                          <i class="fa fa-plus"></i> add url
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
               </div>
            </div>

            <div class="row mb-4">
              <div class="col-12">
                <div class="card h-100">
                  <h4 class="card-header">
                    Webcastmanager Config
                  </h4>
                  <div class="card-body">
                    <div class="form-row">
                      <ValidationProvider v-slot="{ errors }" name="api client" tag="div" class="col">
                        <div class="form-group" :class="{'has-error': errors[0]}">
                          <label for="apiClient">apiClient *</label>
                          <input id="apiClient" v-model="client.data.apiClient" type="text" class="form-control" required="required">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                      <ValidationProvider v-slot="{ errors }" name="api client secret" tag="div" class="col">
                        <div class="form-group" :class="{'has-error': errors[0]}">
                          <label for="apiClientSecret">apiClientSecret *</label>
                          <input id="apiClientSecret" v-model="client.data.apiClientSecret" type="text" class="form-control" required="required">
                          <small class="text-danger">{{ errors[0] }}</small>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <label for="apiClient">apiBaseUrl</label>
                          <input id="apiBaseUrl" v-model="client.data.apiBaseUrl" type="text" class="form-control" name="apiClient">
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label for="apiTokenBaseUrl">apiTokenBaseUrl</label>
                          <input id="apiTokenBaseUrl" v-model="client.data.apiTokenBaseUrl" type="text" class="form-control" name="apiClientSecret">
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label for="apiVersion">apiVersion</label>
                          <input id="apiVersion" v-model="client.data.apiVersion" type="text" class="form-control" name="apiClientSecret">
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label for="cacheTtl">cacheTtl</label>
                          <input id="cacheTtl" v-model="client.data.cacheTtl" type="text" class="form-control" name="apiClientSecret">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12">
                <div class="card h-100">
                  <h4 class="card-header">
                    Assets
                  </h4>
                  <div class="card-body">
                    <div class="row col-12" style="gap: 16px;">
                      <SingleAsset
                        :item="scheduledAsset"
                        label="Scheduled Thumbnail"
                        helpText="Displayed if the congress resource is scheduled"
                        :useFullSize="false"
                        @input="item => setAsset({ ...scheduledAsset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="processingAsset"
                        label="Processing Thumbnail"
                        helpText="Displayed while the congress resource has a processing status"
                        :useFullSize="false"
                        @input="item => setAsset({ ...processingAsset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="lectureThumbnailAsset"
                        label="Lecture Thumbnail"
                        helpText="Default image for lectures"
                        :useFullSize="false"
                        @input="item => setAsset({ ...lectureThumbnailAsset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="ePosterThumbnailAsset"
                        label="E-Poster Thumbnail"
                        helpText="Default image for ePosters"
                        :useFullSize="false"
                        @input="item => setAsset({ ...ePosterThumbnailAsset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="abstractThumbnailAsset"
                        label="Abstract Thumbnail"
                        helpText="Default image for abstracts"
                        :useFullSize="false"
                        @input="item => setAsset({ ...abstractThumbnailAsset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="slidesetThumbnailAsset"
                        label="Slideset Thumbnail"
                        helpText="Default image for slidesets"
                        :useFullSize="false"
                        @input="item => setAsset({ ...slidesetThumbnailAsset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="logoAsset"
                        label="Logo"
                        helpText="Set custom project logo"
                        :useFullSize="false"
                        @input="item => setAsset({ ...logoAsset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="headerBannerAsset"
                        label="Header Banner"
                        helpText="Banner for project pages"
                        :useFullSize="false"
                        @input="item => setAsset({ ...headerBannerAsset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="headerBannerMobileAsset"
                        label="Header Banner (mobile)"
                        helpText="Banner for short resolutions"
                        :useFullSize="false"
                        @input="item => setAsset({ ...headerBannerMobileAsset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="teaserIconAsset"
                        label="Teaser Icon"
                        helpText="Icon used on teaser buttons"
                        :useFullSize="false"
                        @input="item => setAsset({ ...teaserIconAsset, asset: item.asset })"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12">
                <div class="card h-100">
                  <h4 class="card-header">
                    Favicons
                  </h4>
                  <div class="card-body">
                    <div class="row col-12" style="gap: 16px;">
                      <SingleAsset
                        :item="faviconAsset"
                        label="default Favicon"
                        helpText="Favicon for the platform site if no other resolution is set"
                        :useFullSize="false"
                        @input="item => setAsset({ ...faviconAsset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="favicon16Asset"
                        label="Favicon 16x16"
                        helpText="Classic Favicon"
                        :useFullSize="false"
                        @input="item => setAsset({ ...favicon16Asset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="favicon32Asset"
                        label="Favicon 32x32"
                        helpText="For Safari on Mac OS"
                        :useFullSize="false"
                        @input="item => setAsset({ ...favicon32Asset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="favicon150Asset"
                        label="Favicon 150x150 (ms)"
                        helpText="For Windows 8+ / IE11"
                        :useFullSize="false"
                        @input="item => setAsset({ ...favicon150Asset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="favicon180Asset"
                        label="Favicon 180x180 (apple)"
                        helpText="iPhone and iPad"
                        :useFullSize="false"
                        @input="item => setAsset({ ...favicon180Asset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="favicon192Asset"
                        label="Favicon 192x192"
                        helpText="For Android Chrome M39+ with 4.0 screen density"
                        :useFullSize="false"
                        @input="item => setAsset({ ...favicon192Asset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="favicon256Asset"
                        label="Favicon 256x256"
                        helpText="For Android Chrome M47+ Splash screen with 1.5 screen density"
                        :useFullSize="false"
                        @input="item => setAsset({ ...favicon256Asset, asset: item.asset })"
                      />
                      <SingleAsset
                        :item="favicon512Asset"
                        label="Favicon 512x512"
                        helpText="For Android Chrome M47+ Splash screen with 4.0 screen density"
                        :useFullSize="false"
                        @input="item => setAsset({ ...favicon512Asset, asset: item.asset })"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12">
                <div class="card h-100">
                  <h4 class="card-header">
                    Badges
                  </h4>
                  <div class="card-body">
                    <div class="row col-12">
                      <AssetList
                        :items="badges"
                        prefix="badge"
                        :isMutable="true"
                        :showInput="true"
                        @input="item => setAsset(item)"
                        @delete="item => deleteAsset(item)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fas fa-home"></i> Startpage
            </template>
            <edit-page v-if="client.data.id" pageType="home" :relation="client" relationType="client" :clientId="client.data.id"></edit-page>
            <edit-startpage class="mt-4" :client="client"></edit-startpage>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fas fa-university"></i> Library
            </template>
            <edit-page v-if="client.data.id" pageType="library" :relation="client" relationType="client" :clientId="client.data.id"></edit-page>
            <edit-library class="mt-4" :client="client"></edit-library>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fa fas fa-lock"></i> Login
            </template>
            <edit-page v-if="client.data.id" pageType="login" :relation="client" relationType="client" :clientId="client.data.id"></edit-page>
            <edit-login class="mt-4" :client="client"></edit-login>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="far fa-clone"></i> GroupsHome
            </template>
            <edit-page v-if="client.data.id" pageType="groups" :relation="client" relationType="client" :clientId="client.data.id"></edit-page>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fas fa-table"></i> ProjectsHome
            </template>
            <edit-page v-if="client.data.id" pageType="projects" :relation="client" relationType="client" :clientId="client.data.id"></edit-page>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fas fa-boxes"></i> PackagesHome
            </template>
            <edit-page v-if="client.data.id" pageType="packages" :relation="client" relationType="client" :clientId="client.data.id"></edit-page>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fas fa-boxes"></i> SpotlightsHome
            </template>
            <edit-page v-if="client.data.id" pageType="spotlights" :relation="client" relationType="client" :clientId="client.data.id"></edit-page>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fas fa-graduation-cap"></i> EducationHome
            </template>
            <edit-page v-if="client.data.id" pageType="educationitems" :relation="client" relationType="client" :clientId="client.data.id"></edit-page>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fas fa-gavel"></i> PrivacyPage
            </template>
            <edit-page v-if="client.data.id" pageType="privacy" :relation="client" relationType="client" :clientId="client.data.id"></edit-page>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fa fa-level-down-alt"></i> FooterContent
            </template>
            <edit-page v-if="client.data.id" pageType="footer" :relation="client" relationType="client" :clientId="client.data.id"></edit-page>
          </b-tab>
          <b-tab>
            <template slot="title">
              <div :class="{ tab__error: hasTabErrors('Pages', errors)}">
                <i class="fa fas fa-window-restore"></i> Pages
              </div>
            </template>
            <edit-pages v-if="client.data.id" pageType="page" :relation="client" relationType="client"></edit-pages>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fab fa-css3"></i> Styles
            </template>
            <div class="row">
              <div class="col-12">
                <button class="btn btn-primary pull-right" @click.prevent="compileStyles()">
                  <i class="fas fa-cogs"></i> compile styles
                </button>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="default">Default Styles:</label>
                  <textarea id="default" v-model.lazy.trim="client.data.default_styles" class="form-control" name="default" rows="10"></textarea>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="custom">Custom Styles:</label>
                  <textarea id="custom" v-model.lazy.trim="client.data.custom_styles" class="form-control" name="custom" rows="10"></textarea>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fas fa-globe"></i> Languages
            </template>
            <edit-language :client="client"></edit-language>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fas fa-sitemap"></i> Navigation
            </template>
            <edit-navigation :client="client"></edit-navigation>
          </b-tab>
          <b-tab>
            <template slot="title">
              <div :class="{ tab__error: hasTabErrors('Security', errors)}">
                <i class="fas fa-lock"></i> Security
              </div>
            </template>
            <edit-security v-if="client.capabilities" :client="client" :capabilities="client.capabilities"></edit-security>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="fas fa-search"></i> SearchFields
            </template>
            <edit-searchfields :client="client"></edit-searchfields>
          </b-tab>
        </b-tabs>
      </b-card>
    </form>
  </div>
  <hr>
  <div class="row">
    <SaveBar
      disableSaveAndClose
      :saveDisabled="invalid"
      @save="updateClient(close = false)"
      @cancel="cancel"
    />
  </div>
  <image-panel v-if="client.data.id" :clientId="client.data.id"></image-panel>
</ValidationObserver>
