import FormerForm from './FormerForm'
import FormerField from './FormerField'

export default function (Vue) {
  Vue.component('former-form', FormerForm)

  Vue.component('former-field', (resolve) => {
    /**
     * FormlyField must be loaded asyncronously so that any fields added in
     * via Formly.addType are available
     */
    resolve(FormerField)
  })
}
