<template>
  <b-modal
    :id="id"
    :scrollable="scrollableModal"
    size="lg"
    no-fade
    :title="title"
    @hide="$emit('close')"
  >
    <form v-if="moduletype !== ''" role="form" @submit.prevent>
      <div class="row">
        <div class="col-12">
          <component
            :is="moduletype"
            v-if="isComponent"
            :model="mod.model"
          ></component>
          <former-form
            v-else
            :model="mod.model"
            :form="mod.form"
            :formerfields="mod.fields"
          ></former-form>
        </div>
      </div>
    </form>
    <template v-slot:modal-footer>
      <button class="btn btn-default" @click.prevent="cancelSubmission">
        <i class="fas fa-close"></i> Cancel
      </button>
      <button class="btn btn-primary" @click.prevent="handleSubmission">
        <i class="fa fa-check"></i> Ok
      </button>
    </template>
  </b-modal>
</template>

<script>
import ResourcesList from '../views/ModuleTemplates/components/ResourcesList.vue'
import HighlightTeaser from '../views/ModuleTemplates/components/HighlightTeaser.vue'

export default {
  name: 'edit-module-modal',
  components: {
    ResourcesList,
    HighlightTeaser
  },
  props: {
    id: {
      type: String
    },
    title: {
      type: String
    },
    module: {
      type: Object
    },
    moduletype: {
      type: String
    }
  },
  data () {
    return {
      mod: {
        form: {},
        model: {},
        fields: []
      },
      isComponent: false
    }
  },
  computed: {
    scrollableModal () {
      // disable scrolling for froala popups
      if (this.moduletype === 'htmltext') {
        return false
      }
      return true
    }
  },
  watch: {
    module (value) {
      this.mod.model = value.model
      this.mod.fields = value.configuration
      if (this.moduletype === 'resources-list' || this.moduletype === 'highlight-teaser' || this.moduletype === 'resource-list-item') {
        this.isComponent = true
      } else {
        this.isComponent = false
      }
    }
  },
  created () {
    this.mod.model = this.module.model
    this.mod.fields = this.module.configuration
  },
  methods: {
    cancelSubmission () {
      this.$emit('cancelAction')
    },
    handleSubmission () {
      this.$emit('updateAction', this.mod.model)
    }
  }
}
</script>
