
<ValidationObserver v-slot="{ errors }" tag="div" class="single-asset">
  <label v-if="showLabel" class="single-asset__label">
    <span v-if="label">{{ label }}</span>
    <small
      v-if="showHelpText"
      class="d-block text-muted"
      style="min-height: 40px;"
    >
      {{ helpText }}
    </small>
  </label>

  <div v-if="isLoading && !adminPageTemplate">
    <b-spinner label="Loading..."></b-spinner>
  </div>

  <template v-else>
    <div class="image-container">
      <div
        v-if="adminPageTemplate"
        class="empty-state-picture is-admintemplate"
        :class="{ 'useFullSize': useFullSize === true }"
        title="unable to set assets in master templates"
      ></div>
      <div
        v-else-if="hasAssetId"
        class="preview-image"
        :class="{ 'useFullSize': useFullSize === true }"
        @click="openAssetModal"
      >
        <img
          v-if="getAsset(assetId)"
          :title="`${fileName}.${fileFormat}`"
          :src="getImageUrl(assetId)"
        />
        <div
          v-else
          class="no-image text-secondary text-sm"
          :class="{ 'useFullSize': useFullSize === true }"
          title="asset not found"
        >
          <i class="fa fa-question"></i>
          asset not found
        </div>
      </div>
      <div
        v-else
        class="empty-state-picture"
        :class="[
          { 'useFullSize': useFullSize === true },
          { 'is-disabled': disabled }
        ]"
        title="click to set asset"
        @click="openAssetModal"
      ></div>
      <div class="preview-image__buttons">
        <button
          class="btn btn-sm btn-default"
          type="button"
          title="remove asset"
          :disabled="!(hasAssetId && getAsset(assetId))"
          @click.stop.prevent="deleteAsset"
        >
          <i class="fa fa-times"></i>
        </button>
        <button
          v-if="showDeleteButton"
          class="btn btn-sm btn-danger"
          title="delete list item"
          @click.stop.prevent="deleteItem"
        >
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
    <div class="subline">
      <small
        v-if="hasAssetId && getAsset(assetId)"
        class="d-flex"
        :title="`${fileName}.${fileFormat}`"
      >
        <span class="text-truncate">{{ fileName }}</span>
        .{{ fileFormat }}
      </small>
      <small v-if="errors.badge" class="text-danger">{{ errors.badge[0] }}</small>
    </div>
    <ValidationProvider
      v-if="showInput"
      v-slot="{ errors }"
      immediate
      tag="div"
      name="badge"
      class="form-group"
      :class="{ 'has-error': errors.badge && errors.badge[0] }"
    >
      <input
        v-model.trim="name"
        name="badge name"
        placeholder="badge name"
        type="text"
        required="required"
        class="form-control"
        @input="$emit('input', { ...item, name: name })"
      />
    </ValidationProvider>
  </template>

  <AssetModal
    v-if="!isLoading && !adminPageTemplate"
    :id="assetModalId"
    :clientId="clientId"
    @setAsset="setAsset"
  />

</ValidationObserver>
