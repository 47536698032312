import Vue from 'vue'

const urlParser = document.createElement('a')

export const domain = (url) => {
  urlParser.href = url
  return urlParser.hostname
}

export const count = (arr) => {
  return arr.length
}

export const prettyDate = (date) => {
  var a = new Date(date)
  return a.toDateString()
}

export const pluralize = (time, label) => {
  if (time === 1) {
    return time + label
  }

  return time + label + 's'
}

export const currency = (value, icon) => {
  return '€ ' + value.toFixed(2)
}

Vue.filter('count', count)
Vue.filter('domain', domain)
Vue.filter('prettyDate', prettyDate)
Vue.filter('pluralize', pluralize)
Vue.filter('currency', currency)
