<template>
  <router-view></router-view>
</template>

<script>
import axios from 'axios'
export default {
  name: 'App',
  data: function () {
    return {
      section: 'Foot',
      version: '0.10.0',
      callingAPI: false
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    }
  },
  created () {
    this.getTimezones()
  },
  updated () {
    if (this.$store.state.token) {
      axios.defaults.headers.common.authorization = 'Bearer ' + this.$store.state.token
    }
  },
  methods: {
    getTimezones () {
      this.isLoading = true
      this.$store
        .dispatch('getTimezones')
        .then(response => {
          this.isLoading = false
        })
    },
    logout: function () {
      this.$store.commit('SET_USER', null)
      this.$store.commit('SET_TOKEN', null)

      if (window.localStorage) {
        window.localStorage.setItem('user', null)
        window.localStorage.setItem('token', null)
      }
      window.localStorage.clear() // try this to clear all local storage

      this.$router.push('/login')
    }
  }
}
</script>

<style>
.w-1 {
  width: 1%!important;
}
</style>
